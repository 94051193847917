﻿ul.ui-autocomplete {
    z-index: 10031;
    background: $sayt-widget-bg-color;
    position: fixed;
    /*max-height: 32.8125rem;
    overflow: auto !important;
    border-right: 0.5rem solid #1b3171 !important;
    border-top: 1rem solid #1b3171 !important;
    border-bottom: 2.125rem solid #1b3171 !important;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 0.5rem;
        border: 0.5rem solid transparent;
    }

    &::-webkit-scrollbar-button {
        display: none;
        color: transparent;
        background: transparent;
        height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        width: 0.25rem;
        background-color: #0a0b0d;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4);
    }*/
    @media(min-width: $grid-float-breakpoint) {
        margin-top: 1rem;


        &:before {
            content: '';
            display: block;
            @include point(bottom,  #1b3171, 10px, 10px, -20px, 0, 0, 2em)
        }
    }
}

ul.ui-autocomplete.ui-widget-content {
    border-top: none;
    border-left: $sayt-exterior-border;
    border-right: $sayt-exterior-border;
    border-bottom: $sayt-exterior-border;
    font-family: $sayt-font-family;
    padding-top: 1.36875rem;
    padding-bottom: 1.36875rem;

    &.zoomLevel{
        max-height: 300px;
        overflow: auto;
    }

    li {
        &.ui-autocomplete-category {
            font-weight: $sayt-font-weight;
            text-align: center;
            position: relative;
            padding: 0.25em;

            .ui-autocomplete-category-text {
                display: inline-block;
                background: $sayt-bg-color;
                color: $sayt-header-color;
                font-weight: 600;
                z-index: 1;
                position: relative;
                padding: 0 8px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 1em;
                left: 0;
                border-bottom: $sayt-border;
                width: 100%;
                z-index: 0;
            }
        }

        &.ui-menu-item {
            padding: 0.5125rem 1rem 0.5125rem 0.625em;

            &.sayt-product-content {
                list-style-image: none;
                border-top: $sayt-border;
                border-bottom: $sayt-border;
                background: $sayt-bg-color;
                font-weight: $sayt-font-weight;

                &:hover,
                &:focus {
                    list-style-image: none;
                    border-top: $sayt-highlighted-border;
                    border-bottom: $sayt-highlighted-border;
                    background: $sayt-highlighted-bg-color;
                    font-weight: $sayt-font-weight;
                }

                &.ui-state-focus {
                    border: none;
                    margin: 0;

                    .sayt-product-anchor {
                        color: $sayt-highlight-color;
                    }
                }

                a {
                    &:hover,
                    &:focus {
                        color: $sayt-highlight-color;
                    }
                }

                .sayt-product-anchor {
                    color: $sayt-color;
                }
            }

            &.sayt-autocomplete-navigation {
                border-top: $sayt-border;
                border-bottom: $sayt-border;
                background: $sayt-bg-color;
                font-weight: $sayt-font-weight;
                font-size: 1em;

                a {
                    .sayt-content {
                        font-size: 1em;
                    }

                    &:hover,
                    &:focus {
                        color: $sayt-highlight-color;
                        text-decoration: none;
                    }

                    &.ui-state-active {
                        color: inherit;
                        list-style-image: none;
                        border: none;
                        background: inherit;
                        font-weight: $sayt-font-weight;
                        box-shadow: none;
                        outline: none;
                    }
                }

                &:hover,
                &:focus {
                    color: inherit;
                    list-style-image: none;
                    border-top: $sayt-highlighted-border;
                    border-bottom: $sayt-highlighted-border;
                    background: $sayt-highlighted-bg-color;
                    font-weight: $sayt-font-weight;

                    a {
                        border-top: $sayt-highlighted-border;
                        border-bottom: $sayt-highlighted-border;
                        background: $sayt-highlighted-bg-color;
                        outline: none;
                        color: inherit;
                        border: 0px;
                    }
                }
            }
        }

        &.sayt-autocomplete-navigation {
            background: $sayt-bg-color;
            border-top: $sayt-border;
            border-bottom: $sayt-border;
            font-weight: $sayt-font-weight;

            &:hover,
            &:focus {
                background: $sayt-highlighted-bg-color;
                border-top: $sayt-highlighted-border;
                border-bottom: $sayt-highlighted-border;
                font-weight: $sayt-font-weight;
            }
        }

        .sayt-content.sayt-product-title {
            font-size: 1em;
        }

        .sayt-director-name {
            display: none;
        }
    }
}


li:hover a.sayt-navigation, a.sayt-navigation:hover, ul:hover a.sayt-navigation {
    background: inherit;
}

.sayt-product-anchor.ui-state-focus {
    padding: 0;
    margin: 0;
    border: none;
    background: inherit;
    font-weight: $sayt-font-weight;

    a {
        border-width: 0;
    }
}

.sayt-content {
    font-size: 11px;
}

.sayt-director-name {
    float: left;
    font-size: 11px;
    padding-left: 5px;
}

li.ui-menu-item.sayt-product-content {
    list-style-image: none;
    border-top: $sayt-border;
    border-bottom: $sayt-border;
}

.sayt-product-title {
    float: left;
    padding-top: 1rem;
    padding-left: 1rem;
}

.sayt-product-image {
    float: left;

    @media(max-width: $screen-sm){
        display: none;
    }
}

.sayt-title-wrap {
    display: block;
    overflow: hidden;
}

a.ui-corner-all.ui-state-focus {
    border: 0;
    border-radius: 0;
    /*background: inherit;*/
    color: $sayt-color;
    font-weight: $sayt-font-weight;
}