﻿.full-width-promo-area {
    background-color: #000;
    position: relative;
    margin-top: -$body-top-padding;
    margin-bottom: 0;
    margin-left: - percentage(($container-gutter-mobile/100%) / (1 - 2 * ($container-gutter-mobile / 100%)));
    margin-right: - percentage(($container-gutter-mobile/100%) / (1 - 2 * ($container-gutter-mobile / 100%)));
    z-index: 0;

    @media(max-width: $screen-md + 148) {
        height: 435px;
    }

    @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 149) {
        height: 560px;
    }
    //Option 2 1024x800 Large viewport
    @media(min-width: $screen-md + 150) and (max-width: $screen-md + 539) {
        height: 480px;
    }
    //Option 2 1440x1024 Large viewport
    @media(min-width: $screen-md + 540) and (max-width: $screen-lg + 119) {
        height: 560px;
    }
    //Option 2 1920x1080 Large viewport
    @media(min-width: $screen-lg + 120) {
        height: 664px;
    }
    //Option 2 bigger screen Large viewport
    @media(min-width: $screen-max - 320) {
        height: auto !important;
    }



    &.subscription {
        @media(min-width: $screen-lg - 359) {
            margin-top: -100px;
        }

        @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
            margin-top: -80px;
        }

        @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
            margin-top: -80px;
        }
    }
    /*@media(min-width: $screen-md) {
        margin-bottom: -20.72%;
    }*/
    @media(min-width: $gutter-breakpoint) {
        margin-left: -$container-gutter-desktop-scaled;
        margin-right: -$container-gutter-desktop-scaled;
    }

    + * {
        position: relative;
    }

    .content-container {
        text-align: center;

        .background-movie-image-wrap {

            @media(max-width: $screen-md + 148) {
                height: 435px;
            }

            @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 149) {
                height: 560px;
            }
            //Option 2 1024x800 Large viewport
            @media(min-width: $screen-md + 150) and (max-width: $screen-md + 539) {
                height: 480px;
            }
            //Option 2 1440x1024 Large viewport
            @media(min-width: $screen-md + 540) and (max-width: $screen-lg + 119) {
                height: 560px;
            }
            //Option 2 1920x1080 Large viewport
            @media(min-width: $screen-lg + 120) {
                height: 664px;
            }
            //Option 2 bigger screen Large viewport
            @media(min-width: $screen-max - 320) {
                height: auto !important;
            }

            position: relative;


            @media(min-width: $screen-sm) {
                padding-bottom: 60.42%;
            }

            @media(min-width: $screen-md) {
                padding-bottom: 33.33%;
            }
            /*@media(min-width: $screen-md){
                padding-bottom: 56.18%;

            }*/
            .background-movie-image,
            .background-movie-image-load {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                //height: 100%;
            }

            .background-movie-image-load {
                z-index: -1;
            }


            .background-movie-image-fade-bottom,
            .background-movie-image-fade-left,
            .background-movie-image-fade-right {
                position: absolute;
                z-index: 0;
                bottom: 0;
            }
            /*.background-movie-image-fade-bottom {
                left: 0;
                width: 100%;
                height: 48px;
                background: linear-gradient(to bottom, rgba(10,11,13,0), rgba(10,11,13,1));

                @media(min-width: $screen-md) {
                    height: 80px;
                }
            }*/
        }

        .promo-text-container,
        .promo-html-wrapper {
            position: absolute;
            text-align: center;
            top: auto;
            bottom: 10.625vw;
            left: 0%;
            right: 0%;
            width: 60.41666666667%;
            margin: 0 auto;

            @media(min-width: $screen-xs) {
                bottom: 54px;
            }

            @media(min-width: $screen-sm) {
                bottom: 72px;
                width: 33.98%;
            }

            @media(min-width: $screen-md + 150 ) {
                left: auto;
                right: $container-gutter-desktop;
                width: 29.861%;
            }
            //Option 2 1024x800 Large viewport
            @media(min-width: $screen-md + 150) and (max-width: $screen-md + 539) {
                bottom: 64px;
            }
            //Option 2 1440x1024 Large viewport
            @media(min-width: $screen-md + 540) and (max-width: $screen-lg + 119) {
                bottom: 88px;
            }
            //Option 2 1920x1080 Large viewport
            @media(min-width: $screen-lg + 120) {
                bottom: 160px;
            }



            .promotion-text {
                font-size: 0.8rem;
                font-style: normal;
                font-weight: normal;

                @media(min-width: $screen-xs) {
                    font-size: 10px;
                    line-height: 14px;
                }

                @media(min-width: $screen-sm) {
                    font-size: 16px;
                    line-height: 22px;
                }

                @media(min-width: $screen-md) {
                    font-size: 1.25rem;
                }
            }

            .background-image-movie-title {

                @media(min-width: $screen-xs) {
                    margin-top: 4px;
                    margin-bottom: 16px;
                }

                @media(min-width: $screen-sm) {
                    margin-top: 8px;
                    margin-bottom: 28px;
                }

                @media(min-width: $screen-md) {
                    margin-top: 16px;
                    margin-bottom: 32px;
                }

                img {
                    max-width: 100%;
                    max-height: 21.09375vw;

                    @media(min-width: $screen-sm) {
                        max-height: 15.4296875vw;
                    }

                    @media(min-width: $screen-md) {
                        max-height: 15.141vw;
                    }
                }
            }

            .promo-btn-container {
                position: static;
                width: 100%;


                .promo-btn {
                    padding: 8px 24px;
                    font-size: 0.8rem;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    z-index: 1;

                    @media(min-width: $screen-sm) {
                        font-size: 1rem;
                    }

                    @media(min-width: $screen-md) {
                        min-width: 316px;
                        min-height: 60px;
                        font-size: 25px;
                        padding-top: 13px;
                    }

                    @media(min-width: $screen-lg) {
                        min-width: 316px;
                        min-height: 60px;
                        font-size: 25px;
                        padding-top: 13px;
                    }
                }
            }
        }
    }

    &.promo-area-slider {
        /*@media(min-width: $screen-sm) {
            margin-bottom: 32px;
        }

        @media(min-width: $screen-md) {
            margin-bottom: 5%;
        }*/
        .content-container {
            outline: none;
            display: none;
            position: relative;

            &:first-child {
                display: block;
            }

            .background-movie-image-wrap {
                margin-bottom: 0;
            }
        }

        .promo-indicator {
            display: none !important;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 5;
            cursor: pointer;
            width: 2.34375vw;
            overflow: visible;

            @media(min-width:$screen-sm) {
                display: block !important;
            }

            @media(min-width: $screen-md) {
                width: 1.979vw;
            }

            &:hover,
            &:focus {
                color: $yellow;
            }

            .billboard-chevron {
                font-size: 4.6875vw;

                @media(min-width: $screen-md) {
                    font-size: 3.958vw;
                }
            }

            .store-icon-billboardright {
                &:before {
                    position: relative;
                    left: -100%;
                }
            }
        }

        .promo-next {
            right: 18px;

            :focus-visible {
                outline: #fff auto 5px;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: 15px;
                outline-width: medium;
            }
        }

        .promo-prev {
            left: 18px;

            :focus-visible {
                outline: #fff auto 5px;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: 15px;
                outline-width: medium;
            }
        }

        .slick-dots {
            position: absolute;
            margin: 0;
            z-index: 0;
            bottom: 5.3125vw;
            padding: 0;
            transform: translate(0, 50%);

            @media(min-width: $screen-xs) {
                bottom: 0px;
            }

            @media(min-width: $screen-sm) {
                bottom: 10px;
                padding: 0;
                transform: translate(0,0);
            }

            .slick-arrows {
                display: none;
                font-size: 1.77em;
                line-height: 0.4em;
                vertical-align: -25%;
                padding: 1px 23.33px;
                font-weight: bold;


                @media(min-width: $screen-md + 150) {
                    display: inline-block;
                }

                :hover {
                    color: #f1c400;
                }

                :focus-visible {
                    outline: 5px auto #fff;
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: 15px;
                    outline-width: medium;
                }
            }

            li {
                width: auto;
                height: auto;
                padding: 0;
                margin: 0;

                button {
                    box-sizing: content-box;
                    width: $indicator-height-xs;
                    height: $indicator-height-xs;
                    padding: $indicator-vertical-padding-xs $indicator-horizontal-padding-xs;
                    text-align: center;

                    @media(min-width: $screen-sm) {
                        width: $indicator-height-sm;
                        height: $indicator-height-sm;
                        padding: $indicator-vertical-padding-sm $indicator-horizontal-padding-sm;
                    }

                    @media(min-width: $screen-md) {
                        width: $indicator-height-md;
                        height: $indicator-height-md;
                        padding: $indicator-vertical-padding-md $indicator-horizontal-padding-md;
                    }

                    @media(min-width: $screen-lg) {
                        width: $indicator-height-lg;
                        height: $indicator-height-lg;
                        padding: $indicator-vertical-padding-lg $indicator-horizontal-padding-lg;
                    }



                    &:before {
                        position: relative;
                        display: inline-block;
                        opacity: 0.8;
                        border: solid 1px #d8d8d8;
                        content: '';
                        width: $indicator-height-xs;
                        height: $indicator-height-xs;

                        @media(min-width: $screen-sm) {
                            width: $indicator-height-sm;
                            height: $indicator-height-sm;
                        }

                        @media(min-width: $screen-md) {
                            width: $indicator-height-md;
                            height: $indicator-height-md;
                        }

                        @media(min-width: $screen-lg) {
                            width: $indicator-height-lg;
                            height: $indicator-height-lg;
                        }
                    }

                    &:hover,
                    &:focus {
                        &:before {
                            border-color: $yellow;
                        }
                    }
                }

                &.slick-active {
                    button {
                        font-size: 0;

                        &:before {
                            opacity: 0.8;
                            background-color: #d8d8d8;
                        }

                        &:hover,
                        &:focus {
                            &:before {
                                background: $yellow;
                            }
                        }
                    }
                }
            }
        }

        &.slick-initialized {
            .content-container {
                display: block;
            }

            .slick-list {
                overflow: visible;
            }
        }
    }
}

.slider-arrows {
    margin: 0;
    bottom: 12px;
    padding: 0;
    transform: translate(0);
    z-index: 9999;
    position: absolute;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    font-size: 1.77em;
    line-height: 0.4em;
    pointer-events: none;
    outline: none;

    @media(max-width: $screen-md + 149) {
        display: none;
    }

    .slider-prev {
        float: left;
        margin-right: 200px;
        pointer-events: auto;
    }

    .slider-next {
        float: left;
        margin-left: 200px;
        pointer-events: auto;
    }

    :hover {
        outline: none;
        color: #f1c400;
    }

    a, a:active, a:visited {
        outline: none;
    }

    :focus-visible {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 10px;
        outline-width: medium;
    }
}

.push-top-alignment {
    @media(min-width: $screen-xs) {
        padding-bottom: 30px;
    }

    @media(min-width: $screen-sm) {
        padding-bottom: 0;
    }
}

.banner-navigation {
    position: absolute;
    bottom: 2%;
    right: 30px;
    background: none;
    z-index: 999;

    @media(min-width: $screen-xs) {
        bottom: -18px;
        right: 12px;
    }

    @media(min-width: $screen-sm) {
        bottom: 1px;
        right: 40px;
    }

    @media(min-width: $screen-md) {
        bottom: 10px;
        right: 40px;
    }


    @media(min-width: $screen-lg) {
        bottom: 7px;
        right: 3.3vh;
    }


    .promo-banner-pause {
        background: url('https://storemedia.cineplex.com/pause-button.svg');
        background-position: center;
        background-size: cover;
        border: none;
        padding: 0;

        @media(min-width: $screen-xs) {
            width: 22.75px;
            height: 22.75px;
        }

        @media(min-width: $screen-sm) {
            width: 26px;
            height: 26px;
        }

        @media(min-width: $screen-md) {
            width: 26px;
            height: 26px;
        }

        @media(min-width: $screen-lg) {
            width: 32.5px;
            height: 32.5px;
        }

        &:hover, &:focus-visible {
            background-image: url('https://storemedia.cineplex.com/pause-bg-hover.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 1px;
            left: 1px;

            @media(min-width: $screen-xs) {
                width: 22.75px;
                height: 22.75px;
            }

            @media(min-width: $screen-sm) {
                width: 26px;
                height: 26px;
            }

            @media(min-width: $screen-md) {
                width: 26px;
                height: 26px;
            }

            @media(min-width: $screen-lg) {
                width: 32.5px;
                height: 32.5px;
            }
        }
    }

    .promo-banner-play {
        background: url('https://storemedia.cineplex.com/play-button.svg');
        background-position: center;
        background-size: cover;
        border: none;
        display: none;
        padding: 0;

        @media(min-width: $screen-xs) {
            width: 22.75px;
            height: 22.75px;
        }

        @media(min-width: $screen-sm) {
            width: 26px;
            height: 26px;
        }

        @media(min-width: $screen-md) {
            width: 26px;
            height: 26px;
        }

        @media(min-width: $screen-lg) {
            width: 32.5px;
            height: 32.5px;
        }

        &:hover, &:focus-visible {
            background-image: url('https://storemedia.cineplex.com/play-bg-hover.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 1px;
            left: 1px;

            @media(min-width: $screen-xs) {
                width: 22.75px;
                height: 22.75px;
            }

            @media(min-width: $screen-sm) {
                width: 26px;
                height: 26px;
            }

            @media(min-width: $screen-md) {
                width: 26px;
                height: 26px;
            }

            @media(min-width: $screen-lg) {
                width: 32.5px;
                height: 32.5px;
            }
        }
    }
}
