﻿@mixin linear-gradient($startDirection, $endDirection, $start-color, $end-color) {
    background: $end-color;
    background: -moz-linear-gradient($startDirection, $start-color, $end-color);
    background: -webkit-linear-gradient($startDirection, $start-color, $end-color);
    background: -o-linear-gradient($startDirection, $start-color, $end-color );
    background: -ms-linear-gradient($startDirection, $start-color, $end-color);
    background: linear-gradient($startDirection $endDirection, $start-color, $end-color);
}

@mixin point($direction, $color, $width, $length, $topOffset, $rightOffset, $bottomOffset, $leftOffset) {
    position: absolute;

    @if($topOffset != 0) {
        top: $topOffset;
    }

    @if($leftOffset!=0) {
        left: $leftOffset;
    }

    @if($rightOffset!=0) {
        right: $rightOffset;
    }

    @if($bottomOffset!=0) {
        bottom: $bottomOffset;
    }

    border: $width solid transparent;
    border-#{$direction}: $length solid $color;
}



@mixin absoluteCenter() {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
}

@mixin placeholder($size, $color) {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: $size;
        color: $color;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        font-size: $size;
        color: $color;
    }

    :-ms-input-placeholder { /* IE 10+ */
        font-size: $size;
        color: $color;
    }

    :-moz-placeholder { /* Firefox 18- */
        font-size: $size;
        color: $color;
    }
}
