﻿//Style used if nav not fixed
.unfixed-nav {
    .nav-wrapper {
        .navbar.navbar-default {
            border: none;
        }
    }
}

.nav-wrapper {
    .dropdown-open-backdrop {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -50vw;
        width: 200vw;
        height: 100vh;
        opacity: 0.95;
        background-color: rgba(10, 11, 13, 0.8);
        z-index: 991;

        &.in {
            @media(min-width: $grid-float-breakpoint) {
                display: block;
                z-index: 992;
            }
        }
    }
}

.align-search-language-login {
    right: 0;
    overflow: visible;
    width: 100%;
    display: -webkit-inline-box;
    position: absolute;
    display: flex !important;
    justify-content: flex-end;
    padding-right: 5vw;
}

.visible-below-md {
    @media(max-width: 1049px) {
        display: block !important;
    }

    @media(min-width: 1050px) {
        display: none !important;
    }
}

.visible-above-md {
    @media(max-width: 1049px) {
        display: none !important;
    }

    @media(min-width: 1050px) {
        display: block !important;
    }
}

.screenreader-text {
    font-family: 'Mark OT';
    font-style: normal;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    width: 1px;
    height: 1px;
    text-align: center;

    &:focus {
        font-family: 'Mark OT' !important;
        font-style: normal !important;
        font-size: 10px;
        line-height: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #fff;
        width: 92px;
        height: 38px;
        left: 11vw;
        top: 14px;
        outline: none;
        overflow: visible !important;

        @media(min-width: 1866px) {
            left: 9vw;
            top: 0.98vw;
        }

        @media(min-width: 3360px) {
            left: 7vw;
            top: 0.58vw;
        }

        @media(min-width: 4500px) {
            left: 5.7vw;
            top: 0.45vw;
        }

        @media(min-width: $screen-sm) and (max-width: 1049px) {
            width: 135px;
            height: 38px;
            left: 105px; //adjusted Figma left:78px;
            top: 10px; //adjusted Figma top:4px;
            &.fr {
                top: 14px;
            }
        }

        @media(max-width: $screen-sm - 1) {
            width: 135px;
            height: 38px;
            font-size: 8px;
            line-height: 10px;
            left: 145px; //adjusted Figma left:102px;
            top: 10px; //adjusted Figma top:4px;
            &.fr {
                top: 14px;
            }
        }

        @media(max-width: $screen-sm - 180) {
            width: 135px;
            height: 38px;
            font-size: 8px;
            line-height: 10px;
            left: 110px; //adjusted Figma left:102px;
            top: 10px; //adjusted Figma top:4px;
            &.fr {
                top: 14px;
            }
        }
    }
}

.navbar.navbar-default {
    background: $navbar-full-color;
    background: linear-gradient(180deg, $navbar-full-color 1.85%, $navbar-start-color 51.09%, $navbar-medium-color 80.63%, $navbar-end-color 121.25%);

    &.primary-nav-scrolled,
    &.search-open {
        background: $navbar-full-color;
        background: linear-gradient(180deg, $navbar-full-color 1.85%, $navbar-start-color 51.09%, $navbar-medium-color 80.63%, $navbar-end-color 121.25%);
    }
}

.navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translatex(-50%);
    @include inline-flex();
    @include align-items(center);

    @media(min-width: $grid-float-breakpoint) {
        transform: none;
        left: 0;
    }

    a, img {
        display: inline-block;
        max-height: 100%;
        max-width: 100%;
    }

    &.navbar-brand-mobile {
        margin-top: 5px;
    }
}

.navbar-brand-mobile {
    position: absolute;
    top: 0.8rem;
    left: 25%;
    -webkit-transform: translateX(-50%);
    transform: translatex(-50%);
    @include inline-flex();
    @include align-items(center);
    padding-right: $main-nav-right-padding;

    a, img {
        display: inline-block;
        height: 28px;
    }
}

.primary-nav {
    transition: height linear 0.25s;
    height: 4rem;
    z-index: 993;

    &.primary-nav-dropdown-open {
        background: #00358e;
        background-image: linear-gradient(to bottom, #00358e, rgba(27, 49, 113, 0.1));

        > .navbar-toggle {
            display: none;
        }

        .dropdown-open-backdrop {
            @media(min-width: $grid-float-breakpoint) {
                display: block;
            }
        }

        .dropdown-open-menu-gradient {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 4rem;
            background: #00358e;
            background: linear-gradient(to bottom, #00358e, rgba(27, 49, 113, 0.1));
            z-index: 993;

            @media(min-width: $grid-float-breakpoint) {
                display: block;
            }
        }
    }

    &.search-open {
        &.search-open {
            @media(min-width: $grid-float-breakpoint) {
                height: 10rem;
            }

            > * {
                @media(max-width: ($grid-float-breakpoint - 1)) {
                    display: none;
                }
            }

            .nav-search-dropdown {
                display: block;
            }

            .primary-nav-search {
                display: block;

                .search-open {
                    display: none;

                    @media(min-width: $grid-float-breakpoint) {
                        display: block;
                    }

                    z-index: 991;
                }
            }
        }
    }

    .navbar-nav.nav {
        .menu-item {
            &.connect-logged-in-only {
                display: none;
            }

            .store-icon-down {
                font-size: 0.8rem;
                padding-left: 8px;
                display: none;

                @media(min-width: $grid-float-breakpoint) {
                    display: inline;
                }
            }
        }
    }

    .navbar-nav {
        margin-top: 0px;

        &.navbar-collapse {
            background: $mobile-navbar-start-color;
            background: linear-gradient(to bottom, $mobile-navbar-start-color, $mobile-navbar-end-color);
            width: 100% !important;
            height: 100vh;
            max-height: unset;
            transition: all 0.25s linear;
            left: -100%;
            position: fixed;

            @media(min-width: $screen-sm) {
                left: -52%;
                width: 52% !important;
                transition-duration: 0.1s;
            }

            @media(min-width: $grid-float-breakpoint) {
                background: transparent;
                position: static;
                width: auto !important;
                height: 4rem;
            }

            &.in {
                left: 0;
            }

            .mobile-nav-logo {

                img {
                    margin-top: 8px;
                    margin-left: 50vw;
                    transform: translate(-50%, 0);
                }
            }
        }

        > li {

            &:last-of-type {
                > a {
                    padding-right: 0;
                }
            }

            > a {
                text-transform: uppercase;
                font-size: 0.8em;
                padding-left: 0;
                padding-right: $main-nav-right-padding;
                line-height: 1rem;
                outline: none;

                &.current {
                    color: $navbar-default-link-hover-color;
                    font-weight: 700;
                }
            }
        }
    }

    .navbar-brand {
        @media(min-width: $grid-float-breakpoint) {
            position: relative;
            z-index: 994;
        }
    }

    .primary-nav-search {
        padding: 1rem 0;
        vertical-align: middle;
        white-space: nowrap;
        position: relative;
        z-index: 0;

        @media(min-width: $grid-float-breakpoint) {
            z-index: 994;
            padding: 1rem 1.6667vw 0 0;
        }

        a {
            display: block;
            text-decoration: none;
            color: $navbar-default-link-color;
            height: auto;

            &:hover,
            &:focus {
                color: $navbar-default-link-hover-color;
                outline: none;
            }

            &.current {
                color: $navbar-default-link-hover-color;
            }
        }

        .search-open {
            color: $navbar-default-link-hover-color;
        }

        .store-icon-search {
            display: inline-block;
            font-size: 2rem;
            vertical-align: top;
            font-weight: normal;
            line-height: 0.9;
        }

        .search-text {
            font-size: 0.8em;
            line-height: 1rem;
            text-transform: uppercase;
            display: none;
            vertical-align: top;

            @media(min-width: $navbar-search-collapse-breakpoint) {
                display: inline-block;
                padding-top: 0.5rem;
            }
        }
    }

    li {
        z-index: 994;

        &.dropdown {
            position: static;

            .dropdown-toggle {
                z-index: 994;
            }
        }
    }

    .nav-search-dropdown {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: opacity linear 0.25s;
        text-align: center;
        background: $navbar-start-color;
        background: linear-gradient(to bottom, $navbar-start-color, $navbar-end-color);
        padding: 0.75rem 0;

        @media(max-width: 1049px) {
            z-index: 991;
            background: $navbar-start-color !important;
        }


        @media(min-width: 520px) {
            padding: 0.75rem 3rem;
        }

        @media(min-width: $grid-float-breakpoint) {
            height: 2rem;
            top: 5rem;
            background: transparent;
            padding: 0;
        }

        &.fadeIn {
            opacity: 1;
        }

        .search-area-wrap {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 0 0.5rem;
            white-space: nowrap;

            @media(min-width: $grid-float-breakpoint) {
                width: 65.104166666666666666666%;
            }

            .search-form-wrapper {
                position: relative;
                display: inline-block;
                width: calc(100% - 3rem);
                vertical-align: top;

                .store-icon-search {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    padding-left: 10px;
                    padding-top: 0.6rem;
                    font-size: 1.25rem;

                    @media(min-width: $grid-float-breakpoint) {
                        padding-top: 1rem;
                    }

                    @media(min-width: $font-size-increase-breakpoint) {
                        font-weight: 700;
                        color: $inactive-color;
                    }
                }

                .nav-search-input {
                    padding-left: 2.5rem;
                    padding-right: 2.25rem;
                    height: 2.75rem;
                    background-color: #202733;
                    font-size: 1.25rem;

                    @media(min-width: $grid-float-breakpoint) {
                        height: 3.5rem;
                    }

                    @include placeholder(1.25rem, $inactive-color)
                }

                .nav-search-submit {
                    position: absolute;
                    right: 0;
                    top: 0rem;
                    background: transparent;
                    background-image: none;
                    height: 100%;
                    padding: 0.5rem 0;
                    width: 2rem;
                    font-size: 1.5rem;
                    border-width: 0;
                    vertical-align: baseline;
                    line-height: 1;

                    @media(min-width: $grid-float-breakpoint) {
                        width: 2.25rem;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;
                        padding-right: 0.25rem;
                    }
                }
            }

            .nav-search-close {
                display: inline-block;
                width: 3rem;
                text-align: right;
                vertical-align: top;

                .store-icon-close {
                    display: inline-block;
                    padding: 0.375rem 0.5rem;
                    font-size: 2rem;

                    @media(min-width: $grid-float-breakpoint) {
                        padding: 0.75rem 0.5rem 0.75rem;
                        /*font-size: 1.5rem;*/
                    }
                }
            }
        }
    }

    .open {

        .dropdown-menu {
            border-width: 0;
            opacity: 1;

            @media(min-width: $grid-float-breakpoint) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: auto;
                max-height: 600px;
                background: #00358e;
                background: linear-gradient(114deg, #00358e, #560075);
                padding: 5rem $navbar-padding-desktop-horizontal 0;
                border-bottom: solid 1px #576DB3;
                z-index: 992;
            }

            .subnav-heading {
                display: none;
            }

            img {
                display: block;
            }

            .non-ft-menu-items {
                margin-left: 0;
                padding-left: 0;

                @media(min-width: $grid-float-breakpoint) {
                    display: block;
                    float: left;
                    width: 16.666666666666666666666666666667%;
                    padding: 0 (2*$column-gutter-desktop) 0 0;
                    margin-left: 0px;
                }
            }

            li {

                &.ft-menu-item {
                    @media(min-width: $grid-float-breakpoint) {
                        float: right;
                        width: 32.5136612%;
                        max-width: 432px;
                        padding-bottom: 64px;

                        a {
                            width: 100%;
                            padding-bottom: 100%;
                            height: 0px;
                            white-space: normal;
                            position: relative;

                            .ft-menu-img {
                                position: absolute;
                                height: 100%;
                                width: 100%;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                z-index: 991;
                            }

                            .ft-menu-load-img {
                                display: none;
                                position: absolute;
                                height: 100%;
                                width: 100%;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }

                            .ft-menu-text {
                                position: absolute;
                                bottom: 0;
                                z-index: 991;
                                margin-left: 0;
                                left: 15px;
                                padding-bottom: $nav-item-top-pad-xs;
                                margin-bottom: $nav-item-vertical-margin-xs;
                            }
                        }
                    }
                }

                &.ft-1-col-menu-item,
                &.ft-2-col-menu-item {
                    @media(min-width: $grid-float-breakpoint) {
                        padding-bottom: 40px;
                    }

                    a {
                        width: 100%;
                        display: block;

                        &:hover,
                        &:focus {
                            font-weight: 400;

                            .ft-col-img-wrap {
                                opacity: 1;
                                border: 1px solid $yellow;
                            }
                        }

                        .ft-col-img-wrap {
                            display: none;
                            max-width: 153px;
                            position: relative;
                            opacity: 0.8;
                            margin-right: 26.13334620769565007483223096606%;
                            margin-bottom: 0;

                            @media(min-width: $grid-float-breakpoint) {
                                display: block;
                                margin-bottom: 24px;
                            }

                            &::before {
                                content: '';
                                width: 100%;
                                padding-bottom: 150.326797385621%;
                                display: block;
                            }

                            .ft-col-menu-loading-img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                            }

                            .ft-col-menu-img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 991;
                            }
                        }

                        .ft-menu-text {
                            white-space: normal;
                            font-size: 1rem;
                            line-height: 1.25;

                            @media(min-width: $grid-float-breakpoint) {
                                margin-bottom: 0.5rem;
                            }
                        }

                        .menu-desc {
                            display: block;
                            white-space: normal;
                            font-size: 0.8rem;
                            text-transform: none;
                        }
                    }
                }

                &.ft-2-col-menu-item {
                    margin-left: 0;

                    @media(min-width: $grid-float-breakpoint) {
                        width: 32.5136612%;
                        float: left;

                        &:nth-child(2) {
                            margin-left: 15px;
                        }
                    }

                    a {
                        width: 100%;

                        .ft-col-img-wrap {
                            display: block;
                            margin-left: 0;
                            margin-right: 0;
                            max-width: unset;
                            max-width: none;

                            @media(min-width: $grid-float-breakpoint) {
                                max-width: 435px;
                            }

                            &::before {
                                content: "";
                                width: 100%;
                                padding-bottom: 52.752293577981651376146788990826%;
                            }
                        }
                    }
                }

                .menu-text {
                    white-space: normal;
                    font-weight: 400;
                }
            }

            a {
                padding: 0 0 (2*$nav-item-vertical-pad-md);
                text-transform: uppercase;
                color: $navbar-dropdown-link-color;
                line-height: 0.8rem;
                font-size: 0.8rem;
                display: inline-block;

                @media(min-width: 1200px) {
                    line-height: 1rem;
                    font-size: 1rem;
                }

                @media(min-width: $screen-md) {
                    &:hover,
                    &:focus {
                        color: $navbar-dropdown-link-hover-color;
                        background: $navbar-dropdown-link-hover-bg;
                        outline: none;
                    }

                    &:active {
                        color: $navbar-dropdown-active-color;
                    }
                }
            }
        }
    }

    .nav-user {
        float: right;
        width: auto;
        height: 4rem;
        padding-left: 0.5rem;

        @media(min-width: 400px) {
            padding-left: 1.25rem;
        }

        @media(min-width: $grid-float-breakpoint) {
            max-width: 18.66666666666666666666666667%;
            padding-left: 0;
        }
    }

    .nav-language-toggle {
        display: inline-block;
        margin-left: -0.25rem;
        margin-right: 0.5rem;
        position: relative;
        z-index: 994;

        @media(max-width: ($grid-float-breakpoint - 1) ) {
            display: none;
        }

        @media(min-width: $navbar-search-collapse-breakpoint) {
            margin-left: 0rem;
            margin-right: 0rem;
            padding-right: 1rem;
        }

        @media(min-width: 1550px) {
            padding-right: 0.25rem;
        }

        a {
            color: #fff;
            display: block;
            text-decoration: none;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            font-size: 0.8rem;
            line-height: 1rem;
        }

        a:focus, a:hover {
            color: #f1c400;
            background-color: transparent;
            outline: none;
        }
    }
}

.promoMembership-msg {
    font-family: MarkOT;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

    p {
        margin: 0px;
    }

    @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
        font-size: 16px;
    }

    @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
        font-size: 12.8px;
        opacity: 0.8;
    }

    @media(max-width: $screen-sm + 167) {
        font-size: 12.8px;
        line-height: 1.41;
        opacity: 0.8;
        width: 285px;
    }
}

.nav-user {
    text-align: right;


    @media(min-width: $grid-float-breakpoint) {
        z-index: 995;
        position: relative;
    }


    .nav-primary--account {
        float: right;
    }

    ul {
        list-style: none;
        padding: 0;

        &:before,
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .loginSignUp {
        font-size: 0.8rem;

        @media(min-width: $grid-float-breakpoint) {
            font-size: 1rem;
        }

        &:first-child {
            float: none;
            line-height: 1rem;
            padding: 0.5rem 1.5rem 0.4rem 1.5rem;
            width: auto;
            border: solid 0.5px #ffffff;
            margin-top: 0.9375rem;
            color: $navbar-default-link-color;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            height: auto;
            font-size: 0.8rem;

            @media(min-width: $screen-lg) {
                margin-top: 1rem;
                padding-top: 0.49rem;
            }

            &:hover,
            &:focus {
                color: $navbar-default-link-hover-color;
                background-color: $navbar-default-link-hover-bg;
                border: solid 0.5px $navbar-default-link-hover-color;
            }

            &:active {
                color: $navbar-default-link-hover-color;
                background-color: $navbar-default-link-hover-bg;
                border: solid 0.5px $navbar-default-link-hover-color;
            }
        }

        &:last-child {
            display: none;
        }
    }

    .menu-account {
        text-align: left;
        cursor: pointer;
        float: right;
        padding: 0;
        height: 4rem;

        @media(min-width: $grid-float-breakpoint) {
            padding: 0 0 0 1.6667vw;
        }

        @media(min-width: $grid-float-breakpoint) {
            height: auto;
        }

        &::before {
            content: " ";
            display: table;
        }

        &::after {
            content: " ";
            display: table;
        }

        > a {
            display: block;
            float: right;

            @media(min-width: $grid-float-breakpoint) {
                height: auto;
            }

            &::before {
                content: " ";
                display: table;
            }

            &::after {
                content: " ";
                display: table;
            }

            &:focus {
                color: $white;
            }

            &:hover {
                @media(min-width: $grid-float-breakpoint) {
                    color: $navbar-default-link-hover-color;

                    .profilePicture-RR {
                        color: $navbar-default-link-hover-color;
                        border: 2px solid $navbar-default-link-hover-color;
                        background-color: $navbar-default-link-hover-bg;

                        .profilePictureInitials {
                            color: $navbar-default-link-hover-color;
                        }
                    }

                    .userInfo {
                        #scenePointPlaceholder {
                            color: $navbar-default-link-hover-color;
                        }
                    }

                    .icon-arrow-down {
                        color: $navbar-default-link-hover-color;
                    }
                }
            }
        }

        &.connect-dropdown-open {
            position: absolute;
            z-index: 992001;
            height: 100vh;
            width: 93.75vw;
            top: 0;
            right: 0;
            background: $connect-dropdown-bg;

            @media(min-width: $screen-sm) {
                width: 50vw;
            }

            @media(min-width: $grid-float-breakpoint) {
                position: static;
                background: transparent;
                width: auto;
                height: auto;
                /*width: 26vw;
                height: auto;
                padding-right: 3.25vw;
                border-bottom: solid 1px rgba(110, 105, 219, 0.57);*/
            }
            /*@media(min-width: $navbar-scene-hidden-breakpoint) {
                position: relative;
                width: auto;
                margin-right: -3.25vw;
                padding-right: 3.25vw;
            }*/
            > a {
                position: relative;
                z-index: 991;
                width: 100%;
                background: rgba(26, 34, 50, 0.3);
                margin-top: 4rem;
                margin-bottom: 12px;
                padding: 32px $mobile-nav-gutter-vw 32px 30px;

                @media(min-width: $screen-xs) {
                    margin-bottom: 2px;
                }

                @media(min-width: $screen-md) {
                    padding-right: $tablet-nav-gutter-vw;
                }

                @media(min-width: $grid-float-breakpoint) {
                    width: auto;
                    padding: 0;
                    margin-top: 0;
                    background: transparent;
                    z-index: 996;
                }
            }

            #ccoptions {
                display: block !important;

                @media(min-width: $grid-float-breakpoint) {
                    position: absolute;
                    right: -5vw;
                    z-index: 995;
                    width: 26vw;
                    height: auto;
                    padding-right: 3.25vw;
                    border-bottom: solid 1px rgba(110, 105, 219, 0.57);
                    background: $connect-dropdown-bg;
                }
            }

            @media(max-width: ( $grid-float-breakpoint - 1)) {

                .picture-icon-container {
                    display: block;
                }

                .profilePicture-RR {
                    display: block;
                }

                .userInfo {
                    display: block;
                    width: 100%;
                    text-align: right;

                    &:before {
                        display: block;
                        position: absolute;
                        top: -2.5rem;
                        right: $mobile-nav-gutter-vw;
                        font-family: 'CineplexStoreFont';
                        font-style: normal;
                        line-height: 1rem;
                        font-size: 24px;
                        font-weight: 700;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\78";

                        @media(min-width: $screen-md) {
                            right: $tablet-nav-gutter-vw;
                        }
                    }

                    &:after {
                        display: none;
                    }

                    .name-label {
                        display: block;
                        width: 100%;
                        white-space: nowrap;
                        margin-right: 0;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }

                    #scenePointPlaceholder {
                        display: block;
                        width: auto;
                        margin: 0;
                        white-space: nowrap;
                    }
                }

                #ccoptions {
                    padding-left: 30px;
                    text-align: right;
                    width: 100%;
                }
            }

            .picture-icon-container {
                margin-bottom: 8px;

                &:before {
                    color: $navbar-default-link-hover-color;
                }
            }

            .profilePicture-RR {
                &:before {
                    color: $navbar-default-link-hover-color;
                }
            }
        }

        .picture-icon-container {
            border: none;
            overflow: visible;
            float: right;
            margin-top: 0;
            margin-right: 0;
            display: none;

            @media(min-width: $grid-float-breakpoint) {
                display: inline-block;
                width: 33px !important;
                height: 33px !important;
                overflow: hidden;
                -webkit-border-radius: 17px;
                -moz-border-radius: 17px;
                -ms-border-radius: 17px;
                -o-border-radius: 17px;
                border-radius: 17px;
                float: left;
                padding-top: 0;
                margin-right: 10px;
                margin-top: 1rem;
                background-color: #FFF;

                img {
                    display: block;
                }
            }

            img {
                display: none;
            }
        }

        .profilePicture-RR {
            background: transparent;
            border: none;
            width: 33px !important;
            height: 33px !important;
            margin: 0 0 8px 0;
            margin-top: 0;
            margin-right: 0;
            display: none;
            float: right;
            border: 2px solid $navbar-default-link-color;
            border-radius: 50%;

            @media(min-width: $grid-float-breakpoint) {
                display: inline-block;
                margin-right: 10px;
                margin-top: 14px;
                float: left;
            }

            @media(min-width: $screen-lg) {
                margin-top: 1.1rem;
            }

            .profilePictureInitials {
                font-size: 0.8rem;
                font-weight: 400;
                line-height: 1rem;
                vertical-align: top;
                padding-top: 21%;

                @media(min-width: $screen-lg) {
                    padding-top: 18%;
                }
            }
        }

        .userInfo {
            display: block;
            float: left;

            @media(min-width: $grid-float-breakpoint) {
                display: block;
            }

            &:after {
                content: "\e906";
                display: inline-block;
                font-family: 'CineplexStoreFont';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 2rem;
                margin-top: 1rem;

                @media(min-width: $grid-float-breakpoint) {
                    margin-top: 1rem;
                }

                @media(min-width: $grid-float-breakpoint) {
                    display: none;
                }
            }

            .name-label {
                display: none;
            }

            #scenePointPlaceholder {
                display: none;
                font-size: 0.8rem;
                font-weight: 300;
                width: auto;
                max-width: none;
                text-wrap: none;
                white-space: nowrap;
                text-transform: uppercase;
                margin-top: 1.8125rem;
                line-height: 1rem;
                cursor: pointer;

                @media(min-width: $grid-float-breakpoint) {
                    margin-top: 1.1rem;
                }

                @media(min-width: $navbar-scene-hidden-breakpoint) {
                    display: inline-block;
                }
            }
        }

        .icon-arrow-down {
            display: none;
            float: left;
            outline: none;
            margin-left: 0.5em;
            margin-top: 1.625rem;
            font-family: 'CineplexStoreFont';
            font-style: normal;
            color: inherit;
            line-height: 1;
            font-size: 0.8rem;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @media(min-width: $grid-float-breakpoint) {
                display: block;
                margin-top: 1.5rem;
                padding-top: 1px;
            }

            &:before {
                content: '\e900';
            }
        }

        #ccoptions {
            display: none !important;
            padding-right: $mobile-nav-gutter-vw;

            @media(min-width: $screen-md) {
                padding-right: $tablet-nav-gutter-vw;
            }

            @media(min-width: $grid-float-breakpoint) {
                height: auto;
                top: 0;
                position: relative;
                background: transparent;
                padding: 0;

                &::before {
                    display: none;
                }
            }

            .dropdown-container--list {
                padding-top: 1.5rem;

                @media(min-width: $grid-float-breakpoint) {
                    padding-top: 5rem;
                    padding-bottom: 80px;
                }

                li {
                    white-space: nowrap;
                    padding-top: 30px;

                    @media(min-width: $grid-float-breakpoint) {
                        padding: 16px 0;
                        max-width: 100%;
                        text-align: right;
                    }

                    > a {
                        font-size: 1rem;
                        text-transform: uppercase;
                        white-space: normal;
                    }
                }
            }
        }
    }

    .dropdown-container {
        display: none;
    }
}

.navbar-toggle.collapsed {
    position: absolute;
    float: left;
    z-index: 992000;
    padding-top: $mobile-nav-pad-top;
    padding-left: $container-gutter-mobile;
    border: 0;
    top: 0;
    left: 0;

    .store-icon-menu {
        display: block;
        font-size: 1.5rem;
    }

    .store-icon-close {
        display: none;
    }
}

.navbar-toggle {
    position: absolute;
    float: left;
    z-index: 991000;
    padding-top: $mobile-nav-pad-top;
    padding-left: $mobile-dropdown-subnav-gutter;
    border: 0;
    top: 0;
    left: 0;

    &.close-button {
        z-index: 1000;
    }

    @media(min-width: $screen-sm) {
        position: fixed;
        left: 0;
        padding-left: $container-gutter-mobile;
    }

    .store-icon-menu {
        display: none;
    }

    .store-icon-close {
        display: block;
        font-size: 1.5rem;
    }
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.navbar-fixed-top.navbar-collapse {
    max-height: none;
}

@media(min-width: ($grid-float-breakpoint) ) {
    #navbar.navbar-nav.navbar-collapse {
        .nav-language-toggle {
            display: none;
        }
    }
}

@media(max-width: ($grid-float-breakpoint - 1) ) {
    #navbar.navbar-nav.navbar-collapse {
        background: $mobile-navbar-start-color;
        background: linear-gradient(to bottom, $mobile-navbar-start-color, $mobile-navbar-end-color);
        width: $collapse-menu-width-xs !important;
        height: 100vh;
        max-height: unset;
        transition: all 0.25s linear;
        left: -100%;
        position: fixed;
        margin: 0;
        padding: 0 0 80px;

        @media(min-width: $screen-sm) {
            left: -$collapse-menu-width-sm;
            width: $collapse-menu-width-sm !important;
            transition-duration: 0.1s;
        }

        &.in {
            left: 0;
            z-index: 1;
        }

        .mobile-nav-logo {

            img {
                margin-top: 8px;
                margin-left: 50vw;
                transform: translate(-50%, 0);
            }
        }

        .nav-language-toggle {
            display: block;
            padding-top: 4em;

            a {
                display: block;
            }
        }

        .navbar-nav.nav {
            margin-left: 0;
            margin-top: (5rem - $nav-item-vertical-margin-xs - $nav-item-top-pad-xs);

            li {
                a {
                    margin-top: $nav-item-vertical-margin-xs;
                    margin-bottom: $nav-item-vertical-margin-xs;
                    margin-left: 0;
                    padding-right: 20px;
                    padding-left: $mobile-dropdown-subnav-gutter;
                    padding-top: $nav-item-top-pad-xs;
                    padding-bottom: 0;
                    font-size: 1rem;

                    @media(min-width: $screen-sm) {
                        padding-top: $nav-item-top-pad-sm;
                        padding-bottom: 0;
                        margin-top: $nav-item-vertical-margin-sm;
                        margin-bottom: $nav-item-vertical-margin-sm;
                        padding-left: $tablet-dropdown-subnav-gutter;
                        padding-right: 20px;
                    }
                }
            }

            .dropdown.open {

                .dropdown-menu {
                    background: $mobile-navbar-start-color;
                    background: linear-gradient(to bottom, $mobile-navbar-start-color, $mobile-navbar-end-color);
                    height: 100vh;
                    width: $collapse-menu-width-xs;
                    overflow: auto;
                    position: fixed;
                    top: 0;
                    left: 0;
                    padding-top: 0;
                    padding-bottom: $mobile-bottom-padding;

                    @media(min-width: $screen-sm) {
                        width: $collapse-menu-width-sm;
                    }

                    @media(min-width: $grid-float-breakpoint) {
                        padding-bottom: 0rem;
                    }

                    .ft-menu-items {
                        margin-left: 0;
                        padding-left: 0;
                    }

                    .ft-menu-item {
                        img {
                            display: none;
                            max-height: 620px;
                        }
                    }

                    .ft-2-col-menu-item {
                        margin-left: 0;

                        a {
                            padding-top: 0;
                            padding-left: 0;
                            padding-right: 0;
                            margin-left: 0;
                            margin-right: 0;

                            .ft-menu-text {
                                padding-top: $nav-item-top-pad-xs;
                                padding-left: $mobile-dropdown-subnav-gutter;
                                padding-right: 20px;
                                padding-bottom: 0.5rem;

                                @media(min-width: $screen-sm) {
                                    padding-top: $nav-item-top-pad-sm;
                                    padding-left: $tablet-dropdown-subnav-gutter;
                                }
                            }

                            .menu-desc {
                                padding-left: $mobile-dropdown-subnav-gutter;
                                padding-right: 20px;

                                @media(min-width: $screen-sm) {
                                    padding-left: $tablet-dropdown-subnav-gutter;
                                }
                            }
                        }
                    }

                    ul {
                        &:first-of-type {
                            padding-top: $subnav-heading-height-xs-sm;
                        }
                    }

                    li {
                        a {
                            line-height: 1.5;
                            font-weight: 400;
                        }
                    }

                    .subnav-heading {
                        position: fixed;
                        display: block;
                        top: 0;
                        left: 0;
                        text-align: center;
                        width: $collapse-menu-width-xs;
                        height: $subnav-heading-height-xs-sm;
                        padding-top: 20px;
                        background: rgba(0, 53, 142, 0.95);
                        font-weight: 700;
                        z-index: 995;

                        @media(min-width: $screen-sm) {
                            width: $collapse-menu-width-sm;
                        }

                        .subnav-heading-text {
                            line-height: 1.5em;
                        }

                        button {
                            top: 0;
                            left: 0;
                        }

                        .dropdown-back-button {
                            cursor: pointer;
                            text-align: left;
                            margin-top: 32px;
                            margin-left: $mobile-dropdown-subnav-gutter;
                            @include flexbox();
                            @include align-items(center);
                            font-weight: 400;

                            @media(min-width: $screen-sm) {
                                margin-left: $tablet-dropdown-subnav-gutter;
                            }

                            i {
                                display: inline-block;
                                vertical-align: middle;
                            }

                            span {
                                display: inline-block;
                                vertical-align: text-bottom;
                            }
                        }
                    }
                }
            }
        }
    }
}
