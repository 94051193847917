﻿@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT.eot?#iefix');
    src: url('../../fonts/MarkOT.woff2') format('woff2'), url('../../fonts/MarkOT.woff') format('woff'), url('../../fonts/MarkOT.ttf') format('truetype'), url('../../fonts/MarkOT.svg#MarkOT') format('svg'), url('../../fonts/MarkOT.eot?#iefix') format('embedded-opentype');
    font-weight: $text-weight-reg;
    font-style: normal;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT-Medium.eot?#iefix');
    src: url('../../fonts/MarkOT-Medium.woff2') format('woff2'), url('../../fonts/MarkOT-Medium.woff') format('woff'), url('../../fonts/MarkOT-Medium.ttf') format('truetype'), url('../../fonts/MarkOT-Medium.svg#MarkOT') format('svg'), url('../../fonts/MarkOT-Medium.eot?#iefix') format('embedded-opentype');
    font-weight: $text-weight-medium;
    font-style: normal;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT-Bold.eot');
    src: url('../../fonts/MarkOT-Bold.woff2') format('woff2'), url('../../fonts/MarkOT-Bold.woff') format('woff'), url('../../fonts/MarkOT-Bold.ttf') format('truetype'), url('../../fonts/MarkOT-Bold.svg#MarkOT-Bold') format('svg'), url('../../fonts/MarkOT-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: $text-weight-heavy;
    font-style: normal;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT-Bold.eot');
    src: url('../../fonts/MarkOT-Bold.woff2') format('woff2'), url('../../fonts/MarkOT-Bold.woff') format('woff'), url('../../fonts/MarkOT-Bold.ttf') format('truetype'), url('../../fonts/MarkOT-Bold.svg#MarkOT-Bold') format('svg'), url('../../fonts/MarkOT-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
    font-family: "MarkOT-Medium";
    src: url('../../fonts/MarkOT-Medium.eot?#iefix');
    src: url('../../fonts/MarkOT-Medium.woff2') format('woff2'), url('../../fonts/MarkOT-Medium.woff') format('woff'), url('../../fonts/MarkOT-Medium.ttf') format('truetype'), url('../../fonts/MarkOT-Medium.svg#MarkOT') format('svg'), url('../../fonts/MarkOT-Medium.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
    font-family: 'CineplexStoreFont';
    src: url('../../fonts/CineplexStoreFont.eot?sr8alj');
    src: url('../../fonts/CineplexStoreFont.eot?sr8alj#iefix') format('embedded-opentype'), url('../../fonts/CineplexStoreFont.ttf?sr8alj') format('truetype'), url('../../fonts/CineplexStoreFont.woff?sr8alj') format('woff'), url('../../fonts/CineplexStoreFont.svg?sr8alj#CineplexStoreFont') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="store-icon-"], [class*=" store-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CineplexStoreFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//TRANSFORM OF STORE ICON CHEVRON - TO BE USED UNTIL BACK ONE CREATED
.store-icon-back {
    transform: rotate(180deg);
}

.store-icon-back:before{
  content: "\3e";
}

//END OF TRANSFORM STORE ICONS

.store-icon-billboardleft:before {
    content: "\e905";
}

.store-icon-billboardright:before {
    content: "\e907";
}

.store-icon-railleft:before {
    content: "\e908";
}

.store-icon-railright:before {
    content: "\e909";
}

.store-icon-movie:before {
    content: "\e904";
}

.store-icon-redeemst:before {
    content: "\e902";
}

.store-icon-upgrade:before {
    content: "\e903";
}

.store-icon-sale:before {
    content: "\e901";
}

.store-icon-down:before {
    content: "\e900";
}

.store-icon-check:before {
    content: "\79";
}

.store-icon-removewatchlist:before {
    content: "\2d";
}

.store-icon-reveal:before {
    content: "\72";
}

.store-icon-browse:before {
    content: "\62";
}

.store-icon-close:before {
    content: "\78";
}

.store-icon-menu:before {
    content: "\6d";
}

.store-icon-account_1:before {
    content: "\e906";
}

.store-icon-addwatchlist:before {
    content: "\2b";
}

.store-icon-mylibrary:before {
    content: "\6c";
}

.store-icon-filter:before {
    content: "\66";
}

.store-icon-genres:before {
    content: "\67";
}

.store-icon-watchlist:before {
    content: "\77";
}

.store-icon-chromecast:before {
    content: "\e90c";
}

.store-icon-download:before {
    content: "\64";
}

.store-icon-info:before {
    content: "\69";
}

.store-icon-career:before {
    content: "\31";
}

.store-icon-dateconfirm:before {
    content: "\44";
}

.store-icon-join:before {
    content: "\6a";
}

.store-icon-signin:before {
    content: "\32";
}

.store-icon-chevron:before {
    content: "\3e";
}

.store-icon-date:before {
    content: "\e914";
}

.store-icon-film:before {
    content: "\4d";
}

.store-icon-cart:before {
    content: "\63";
}

.store-icon-play:before {
    content: "\70";
}

.store-icon-search:before {
    content: "\73";
}

.store-icon-time:before {
    content: "\74";
}

.store-icon-explore:before {
    content: "\65";
}

.store-icon-more:before {
    content: "\2e";
}

.store-icon-wallet:before {
    content: "\24";
}

.store-icon-account:before {
    content: "\61";
}

.store-icon-food:before {
    content: "\46";
}

.store-icon-order:before {
    content: "\6f";
}

.store-icon-overview:before {
    content: "\4f";
}

.store-icon-tickets:before {
    content: "\6b";
}

.store-icon-locations:before {
    content: "\33";
}