﻿/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1500;
    background-color: rgb(39, 7, 53);
    color: #ffffff;
    padding-bottom: 25px;
    padding-top: 25px;

    .outdated-title {
        font-size: 32px;
        padding-right: 30px;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 15px;
        margin-left: 0;
    }

    .outdated-prompt {
        font-size: 24px;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 15px;
        margin-left: 0;
    }

    * html & {
        position: absolute;
    }
}
