﻿#rental-confirm-modal, #order-success-modal {
    button.confirm-button {
        position: relative;
        padding-left: 3rem;
        padding-right: 3rem;

        .success-play-wrapper {
            .store-icon-play{
                position: absolute;
                left: 1rem;
                top: 50%;
                font-size: 32px;
                transform: translateY(-50%);
            }
        }
    }
}
