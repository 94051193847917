@charset "UTF-8";
/*VARIABLES*/
/*Fonts*/
/*Loaders*/
/*Modals*/
/*Generic Modals*/
/*Movie Grid Styles*/
/*Input / Form Styles*/
/*Navbar Styles*/
/*Connect Styles*/
/*Search Styles*/
/*Footer Styles*/
/*Mobile Menu Styles*/
/*Full width promo styles*/
/*Movie Rail/ Carousel Styles*/
/*Overlay Styles*/
/*Error Styles*/
/*---------------------PAGE SPECIFIC VARIABLES--------------------*/
/*Search Results Page and Genres Variables*/
/*PDP variables*/
/*PVOD variables*/
/*CHECKOUT VARIABLES*/
/*SUPERTICKET VARIABLES*/
/*MY LIBRARY VARIABLES*/
/*MIXINS*/
/*GENERAL STYLES*/
@font-face {
  font-family: 'MarkOT';
  src: url("../../fonts/MarkOT.eot?#iefix");
  src: url("../../fonts/MarkOT.woff2") format("woff2"), url("../../fonts/MarkOT.woff") format("woff"), url("../../fonts/MarkOT.ttf") format("truetype"), url("../../fonts/MarkOT.svg#MarkOT") format("svg"), url("../../fonts/MarkOT.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */ }

@font-face {
  font-family: 'MarkOT';
  src: url("../../fonts/MarkOT-Medium.eot?#iefix");
  src: url("../../fonts/MarkOT-Medium.woff2") format("woff2"), url("../../fonts/MarkOT-Medium.woff") format("woff"), url("../../fonts/MarkOT-Medium.ttf") format("truetype"), url("../../fonts/MarkOT-Medium.svg#MarkOT") format("svg"), url("../../fonts/MarkOT-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */ }

@font-face {
  font-family: 'MarkOT';
  src: url("../../fonts/MarkOT-Bold.eot");
  src: url("../../fonts/MarkOT-Bold.woff2") format("woff2"), url("../../fonts/MarkOT-Bold.woff") format("woff"), url("../../fonts/MarkOT-Bold.ttf") format("truetype"), url("../../fonts/MarkOT-Bold.svg#MarkOT-Bold") format("svg"), url("../../fonts/MarkOT-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */ }

@font-face {
  font-family: 'MarkOT';
  src: url("../../fonts/MarkOT-Bold.eot");
  src: url("../../fonts/MarkOT-Bold.woff2") format("woff2"), url("../../fonts/MarkOT-Bold.woff") format("woff"), url("../../fonts/MarkOT-Bold.ttf") format("truetype"), url("../../fonts/MarkOT-Bold.svg#MarkOT-Bold") format("svg"), url("../../fonts/MarkOT-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */ }

@font-face {
  font-family: "MarkOT-Medium";
  src: url("../../fonts/MarkOT-Medium.eot?#iefix");
  src: url("../../fonts/MarkOT-Medium.woff2") format("woff2"), url("../../fonts/MarkOT-Medium.woff") format("woff"), url("../../fonts/MarkOT-Medium.ttf") format("truetype"), url("../../fonts/MarkOT-Medium.svg#MarkOT") format("svg"), url("../../fonts/MarkOT-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */ }

@font-face {
  font-family: 'CineplexStoreFont';
  src: url("../../fonts/CineplexStoreFont.eot?sr8alj");
  src: url("../../fonts/CineplexStoreFont.eot?sr8alj#iefix") format("embedded-opentype"), url("../../fonts/CineplexStoreFont.ttf?sr8alj") format("truetype"), url("../../fonts/CineplexStoreFont.woff?sr8alj") format("woff"), url("../../fonts/CineplexStoreFont.svg?sr8alj#CineplexStoreFont") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="store-icon-"], [class*=" store-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CineplexStoreFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.store-icon-back {
  transform: rotate(180deg); }

.store-icon-back:before {
  content: "\3e"; }

.store-icon-billboardleft:before {
  content: "\e905"; }

.store-icon-billboardright:before {
  content: "\e907"; }

.store-icon-railleft:before {
  content: "\e908"; }

.store-icon-railright:before {
  content: "\e909"; }

.store-icon-movie:before {
  content: "\e904"; }

.store-icon-redeemst:before {
  content: "\e902"; }

.store-icon-upgrade:before {
  content: "\e903"; }

.store-icon-sale:before {
  content: "\e901"; }

.store-icon-down:before {
  content: "\e900"; }

.store-icon-check:before {
  content: "\79"; }

.store-icon-removewatchlist:before {
  content: "\2d"; }

.store-icon-reveal:before {
  content: "\72"; }

.store-icon-browse:before {
  content: "\62"; }

.store-icon-close:before {
  content: "\78"; }

.store-icon-menu:before {
  content: "\6d"; }

.store-icon-account_1:before {
  content: "\e906"; }

.store-icon-addwatchlist:before {
  content: "\2b"; }

.store-icon-mylibrary:before {
  content: "\6c"; }

.store-icon-filter:before {
  content: "\66"; }

.store-icon-genres:before {
  content: "\67"; }

.store-icon-watchlist:before {
  content: "\77"; }

.store-icon-chromecast:before {
  content: "\e90c"; }

.store-icon-download:before {
  content: "\64"; }

.store-icon-info:before {
  content: "\69"; }

.store-icon-career:before {
  content: "\31"; }

.store-icon-dateconfirm:before {
  content: "\44"; }

.store-icon-join:before {
  content: "\6a"; }

.store-icon-signin:before {
  content: "\32"; }

.store-icon-chevron:before {
  content: "\3e"; }

.store-icon-date:before {
  content: "\e914"; }

.store-icon-film:before {
  content: "\4d"; }

.store-icon-cart:before {
  content: "\63"; }

.store-icon-play:before {
  content: "\70"; }

.store-icon-search:before {
  content: "\73"; }

.store-icon-time:before {
  content: "\74"; }

.store-icon-explore:before {
  content: "\65"; }

.store-icon-more:before {
  content: "\2e"; }

.store-icon-wallet:before {
  content: "\24"; }

.store-icon-account:before {
  content: "\61"; }

.store-icon-food:before {
  content: "\46"; }

.store-icon-order:before {
  content: "\6f"; }

.store-icon-overview:before {
  content: "\4f"; }

.store-icon-tickets:before {
  content: "\6b"; }

.store-icon-locations:before {
  content: "\33"; }

.bg-white {
  background: #FFF; }

.bg-transparent {
  background: transparent; }

.text-underline {
  text-decoration: underline; }

.no-text-decoration {
  text-decoration: none; }
  .no-text-decoration:hover, .no-text-decoration:focus {
    text-decoration: none; }

.text-small {
  font-size: 0.8rem; }

.text-large {
  font-size: 1.25rem; }

.text-bold {
  font-weight: bold; }

.font-wt-lt {
  font-weight: 200; }

.font-wt-reg {
  font-weight: 400; }

.font-wt-md {
  font-weight: 600; }

.font-wt-hvy {
  font-weight: 700; }

.prevent-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis; }

.no-wrap {
  white-space: nowrap;
  text-wrap: none; }

.icon-lg {
  font-size: 48px; }

.fill {
  width: 100%;
  height: 100%; }

.fill-width {
  width: 100%; }

.fill-height {
  height: 100%; }

.max-width-fill {
  max-width: 100%; }

.max-height-fill {
  max-height: 100%; }

.content-width-fill > * {
  width: 100%; }

.content-max-width-fill > * {
  max-width: 100%; }

content-height-fill > * {
  height: 100%; }

.content-max-height-fill > * {
  max-height: 100%; }

.overflow-hidden {
  overflow: hidden; }

.clear {
  clear: both; }

.no-pad {
  padding: 0; }

.pad-none {
  padding: 0; }

.pad-top-none {
  padding-top: 0; }

.pad-left-none {
  padding-left: 0; }

.pad-right-none {
  padding-right: 0; }

.pad-bottom-none {
  padding-bottom: 0; }

.pad-xs {
  padding: 5px; }

.pad-top-xs {
  padding-top: 5px; }

.pad-left-xs {
  padding-left: 5px; }

.pad-right-xs {
  padding-right: 5px; }

.pad-bottom-xs {
  padding-bottom: 5px; }

.pad-sm {
  padding: 10px; }

.pad-top-sm {
  padding-top: 10px; }

.pad-left-sm {
  padding-left: 10px; }

.pad-right-sm {
  padding-right: 10px; }

.pad-bottom-sm {
  padding-bottom: 10px; }

.pad-md {
  padding: 15px; }

.pad-top-md {
  padding-top: 15px; }

.pad-left-md {
  padding-left: 15px; }

.pad-right-md {
  padding-right: 15px; }

.pad-bottom-md {
  padding-bottom: 15px; }

.pad-lg {
  padding: 20px; }

.pad-top-lg {
  padding-top: 20px; }

.pad-left-lg {
  padding-left: 20px; }

.pad-right-lg {
  padding-right: 20px; }

.pad-bottom-lg {
  padding-bottom: 20px; }

.pad-xl {
  padding: 30px; }

.pad-top-xl {
  padding-top: 30px; }

.pad-left-xl {
  padding-left: 30px; }

.pad-right-xl {
  padding-right: 30px; }

.pad-bottom-xl {
  padding-bottom: 30px; }

.pad-half-rem {
  padding: 8px; }

.pad-top-half-rem {
  padding-top: 8px; }

.pad-left-half-rem {
  padding-left: 8px; }

.pad-right-half-rem {
  padding-right: 8px; }

.pad-bottom-half-rem {
  padding-bottom: 8px; }

.pad-rem {
  padding: 16px; }

.pad-top-rem {
  padding-top: 16px; }

.pad-left-rem {
  padding-left: 16px; }

.pad-right-rem {
  padding-right: 16px; }

.pad-bottom-rem {
  padding-bottom: 16px; }

.pad-2-rem {
  padding: 32px; }

.pad-top-2-rem {
  padding-top: 32px; }

.pad-left-2-rem {
  padding-left: 32px; }

.pad-right-2-rem {
  padding-right: 32px; }

.pad-bottom-2-rem {
  padding-bottom: 32px; }

.pad-3-rem {
  padding: 32px; }

.pad-top-3-rem {
  padding-top: 32px; }

.pad-left-3-rem {
  padding-left: 32px; }

.pad-right-3-rem {
  padding-right: 32px; }

.pad-bottom-3-rem {
  padding-bottom: 32px; }

.pad-4-rem {
  padding: 64px; }

.pad-top-4-rem {
  padding-top: 64px; }

.pad-left-4-rem {
  padding-left: 64px; }

.pad-right-4-rem {
  padding-right: 64px; }

.pad-bottom-4-rem {
  padding-bottom: 64px; }

.pad-8-rem {
  padding: 128px; }

.pad-top-8-rem {
  padding-top: 128px; }

.pad-left-8-rem {
  padding-left: 128px; }

.pad-right-8-rem {
  padding-right: 128px; }

.pad-bottom-8-rem {
  padding-bottom: 128px; }

.no-pad-top {
  padding-top: 0; }

.no-pad-left {
  padding-left: 0; }

.no-pad-right {
  padding-right: 0; }

.no-pad-bottom {
  padding-bottom: 0; }

@media (min-width: 600px) {
  .pad-top-sm-none {
    padding-top: 0; }
  .pad-left-sm-none {
    padding-left: 0; }
  .pad-right-sm-none {
    padding-right: 0; }
  .pad-bottom-sm-none {
    padding-bottom: 0; }
  .pad-top-sm-xs {
    padding-top: 5px; }
  .pad-left-sm-xs {
    padding-left: 5px; }
  .pad-right-sm-xs {
    padding-right: 5px; }
  .pad-bottom-sm-xs {
    padding-bottom: 5px; }
  .pad-top-sm-sm {
    padding-top: 10px; }
  .pad-left-sm-sm {
    padding-left: 10px; }
  .pad-right-sm-sm {
    padding-right: 10px; }
  .pad-bottom-sm-sm {
    padding-bottom: 10px; }
  .pad-top-sm-md {
    padding-top: 15px; }
  .pad-left-sm-md {
    padding-left: 15px; }
  .pad-right-sm-md {
    padding-right: 15px; }
  .pad-bottom-sm-md {
    padding-bottom: 15px; }
  .pad-top-sm-lg {
    padding-top: 20px; }
  .pad-left-sm-lg {
    padding-left: 20px; }
  .pad-right-sm-lg {
    padding-right: 20px; }
  .pad-bottom-sm-lg {
    padding-bottom: 20px; }
  .pad-top-sm-xl {
    padding-top: 30px; }
  .pad-left-sm-xl {
    padding-left: 30px; }
  .pad-right-sm-xl {
    padding-right: 30px; }
  .pad-bottom-sm-xl {
    padding-bottom: 30px; }
  .pad-top-sm-half-rem {
    padding-top: 8px; }
  .pad-left-sm-half-rem {
    padding-left: 8px; }
  .pad-right-sm-half-rem {
    padding-right: 8px; }
  .pad-bottom-sm-half-rem {
    padding-bottom: 8px; }
  .pad-top-sm-rem {
    padding-top: 16px; }
  .pad-left-sm-rem {
    padding-left: 16px; }
  .pad-right-sm-rem {
    padding-right: 16px; }
  .pad-bottom-sm-rem {
    padding-bottom: 16px; }
  .pad-top-sm-2-rem {
    padding-top: 32px; }
  .pad-left-sm-2-rem {
    padding-left: 32px; }
  .pad-right-sm-2-rem {
    padding-right: 32px; }
  .pad-bottom-sm-2-rem {
    padding-bottom: 32px; }
  .pad-top-sm-3-rem {
    padding-top: 32px; }
  .pad-left-sm-3-rem {
    padding-left: 32px; }
  .pad-right-sm-3-rem {
    padding-right: 32px; }
  .pad-bottom-sm-3-rem {
    padding-bottom: 32px; }
  .pad-top-sm-4-rem {
    padding-top: 64px; }
  .pad-left-sm-4-rem {
    padding-left: 64px; }
  .pad-right-sm-4-rem {
    padding-right: 64px; }
  .pad-bottom-sm-4-rem {
    padding-bottom: 64px; }
  .pad-top-sm-8-rem {
    padding-top: 128px; }
  .pad-left-sm-8-rem {
    padding-left: 128px; }
  .pad-right-sm-8-rem {
    padding-right: 128px; }
  .pad-bottom-sm-8-rem {
    padding-bottom: 128px; } }

@media (min-width: 900px) {
  .pad-top-md-none {
    padding-top: 0; }
  .pad-left-md-none {
    padding-left: 0; }
  .pad-right-md-none {
    padding-right: 0; }
  .pad-bottom-md-none {
    padding-bottom: 0; }
  .pad-top-md-xs {
    padding-top: 5px; }
  .pad-left-md-xs {
    padding-left: 5px; }
  .pad-right-md-xs {
    padding-right: 5px; }
  .pad-bottom-md-xs {
    padding-bottom: 5px; }
  .pad-top-md-sm {
    padding-top: 10px; }
  .pad-left-md-sm {
    padding-left: 10px; }
  .pad-right-md-sm {
    padding-right: 10px; }
  .pad-bottom-md-sm {
    padding-bottom: 10px; }
  .pad-top-md-md {
    padding-top: 15px; }
  .pad-left-md-md {
    padding-left: 15px; }
  .pad-right-md-md {
    padding-right: 15px; }
  .pad-bottom-md-md {
    padding-bottom: 15px; }
  .pad-top-md-lg {
    padding-top: 20px; }
  .pad-left-md-lg {
    padding-left: 20px; }
  .pad-right-md-lg {
    padding-right: 20px; }
  .pad-bottom-md-lg {
    padding-bottom: 20px; }
  .pad-top-md-xl {
    padding-top: 30px; }
  .pad-left-md-xl {
    padding-left: 30px; }
  .pad-right-md-xl {
    padding-right: 30px; }
  .pad-bottom-md-xl {
    padding-bottom: 30px; }
  .pad-top-md-half-rem {
    padding-top: 8px; }
  .pad-left-md-half-rem {
    padding-left: 8px; }
  .pad-right-md-half-rem {
    padding-right: 8px; }
  .pad-bottom-md-half-rem {
    padding-bottom: 8px; }
  .pad-top-md-rem {
    padding-top: 16px; }
  .pad-left-md-rem {
    padding-left: 16px; }
  .pad-right-md-rem {
    padding-right: 16px; }
  .pad-bottom-md-rem {
    padding-bottom: 16px; }
  .pad-top-md-2-rem {
    padding-top: 32px; }
  .pad-left-md-2-rem {
    padding-left: 32px; }
  .pad-right-md-2-rem {
    padding-right: 32px; }
  .pad-bottom-md-2-rem {
    padding-bottom: 32px; }
  .pad-top-md-3-rem {
    padding-top: 32px; }
  .pad-left-md-3-rem {
    padding-left: 32px; }
  .pad-right-md-3-rem {
    padding-right: 32px; }
  .pad-bottom-md-3-rem {
    padding-bottom: 32px; }
  .pad-top-md-4-rem {
    padding-top: 64px; }
  .pad-left-md-4-rem {
    padding-left: 64px; }
  .pad-right-md-4-rem {
    padding-right: 64px; }
  .pad-bottom-md-4-rem {
    padding-bottom: 64px; }
  .pad-top-md-8-rem {
    padding-top: 128px; }
  .pad-left-md-8-rem {
    padding-left: 128px; }
  .pad-right-md-8-rem {
    padding-right: 128px; }
  .pad-bottom-md-8-rem {
    padding-bottom: 128px; } }

@media (min-width: 1800px) {
  .pad-top-lg-none {
    padding-top: 0; }
  .pad-left-lg-none {
    padding-left: 0; }
  .pad-right-lg-none {
    padding-right: 0; }
  .pad-bottom-lg-none {
    padding-bottom: 0; }
  .pad-top-lg-xs {
    padding-top: 5px; }
  .pad-left-lg-xs {
    padding-left: 5px; }
  .pad-right-lg-xs {
    padding-right: 5px; }
  .pad-bottom-lg-xs {
    padding-bottom: 5px; }
  .pad-top-lg-sm {
    padding-top: 10px; }
  .pad-left-lg-sm {
    padding-left: 10px; }
  .pad-right-lg-sm {
    padding-right: 10px; }
  .pad-bottom-lg-sm {
    padding-bottom: 10px; }
  .pad-top-lg-md {
    padding-top: 15px; }
  .pad-left-lg-md {
    padding-left: 15px; }
  .pad-right-lg-md {
    padding-right: 15px; }
  .pad-bottom-lg-md {
    padding-bottom: 15px; }
  .pad-top-lg-lg {
    padding-top: 20px; }
  .pad-left-lg-lg {
    padding-left: 20px; }
  .pad-right-lg-lg {
    padding-right: 20px; }
  .pad-bottom-lg-lg {
    padding-bottom: 20px; }
  .pad-top-lg-xl {
    padding-top: 30px; }
  .pad-left-lg-xl {
    padding-left: 30px; }
  .pad-right-lg-xl {
    padding-right: 30px; }
  .pad-bottom-lg-xl {
    padding-bottom: 30px; }
  .pad-top-lg-half-rem {
    padding-top: 8px; }
  .pad-left-lg-half-rem {
    padding-left: 8px; }
  .pad-right-lg-half-rem {
    padding-right: 8px; }
  .pad-bottom-lg-half-rem {
    padding-bottom: 8px; }
  .pad-top-lg-rem {
    padding-top: 16px; }
  .pad-left-lg-rem {
    padding-left: 16px; }
  .pad-right-lg-rem {
    padding-right: 16px; }
  .pad-bottom-lg-rem {
    padding-bottom: 16px; }
  .pad-top-lg-2-rem {
    padding-top: 32px; }
  .pad-left-lg-2-rem {
    padding-left: 32px; }
  .pad-right-lg-2-rem {
    padding-right: 32px; }
  .pad-bottom-lg-2-rem {
    padding-bottom: 32px; }
  .pad-top-lg-3-rem {
    padding-top: 32px; }
  .pad-left-lg-3-rem {
    padding-left: 32px; }
  .pad-right-lg-3-rem {
    padding-right: 32px; }
  .pad-bottom-lg-3-rem {
    padding-bottom: 32px; }
  .pad-top-lg-4-rem {
    padding-top: 64px; }
  .pad-left-lg-4-rem {
    padding-left: 64px; }
  .pad-right-lg-4-rem {
    padding-right: 64px; }
  .pad-bottom-lg-4-rem {
    padding-bottom: 64px; }
  .pad-top-lg-8-rem {
    padding-top: 128px; }
  .pad-left-lg-8-rem {
    padding-left: 128px; }
  .pad-right-lg-8-rem {
    padding-right: 128px; }
  .pad-bottom-lg-8-rem {
    padding-bottom: 128px; } }

.no-margin {
  margin: 0; }

.margin-none {
  margin: 0; }

.margin-top-none {
  margin-top: 0; }

.margin-left-none {
  margin-left: 0; }

.margin-right-none {
  margin-right: 0; }

.margin-bottom-none {
  margin-bottom: 0; }

.margin-xs {
  margin: 5px; }

.margin-top-xs {
  margin-top: 5px; }

.margin-left-xs {
  margin-left: 5px; }

.margin-right-xs {
  margin-right: 5px; }

.margin-bottom-xs {
  margin-bottom: 5px; }

.margin-sm {
  margin: 10px; }

.margin-top-sm {
  margin-top: 10px; }

.margin-left-sm {
  margin-left: 10px; }

.margin-right-sm {
  margin-right: 10px; }

.margin-bottom-sm {
  margin-bottom: 10px; }

.margin-md {
  margin: 15px; }

.margin-top-md {
  margin-top: 15px; }

.margin-left-md {
  margin-left: 15px; }

.margin-right-md {
  margin-right: 15px; }

.margin-bottom-md {
  margin-bottom: 15px; }

.margin-lg {
  margin: 20px; }

.margin-top-lg {
  margin-top: 20px; }

.margin-left-lg {
  margin-left: 20px; }

.margin-right-lg {
  margin-right: 20px; }

.margin-bottom-lg {
  margin-bottom: 20px; }

.margin-xl {
  margin: 30px; }

.margin-top-xl {
  margin-top: 30px; }

.margin-left-xl {
  margin-left: 30px; }

.margin-right-xl {
  margin-right: 30px; }

.margin-bottom-xl {
  margin-bottom: 30px; }

.margin-half-rem {
  margin: 8px; }

.margin-top-half-rem {
  margin-top: 8px; }

.margin-left-half-rem {
  margin-left: 8px; }

.margin-right-half-rem {
  margin-right: 8px; }

.margin-bottom-half-rem {
  margin-bottom: 8px; }

.margin-rem {
  margin: 16px; }

.margin-top-rem {
  margin-top: 16px; }

.margin-left-rem {
  margin-left: 16px; }

.margin-right-rem {
  margin-right: 16px; }

.margin-bottom-rem {
  margin-bottom: 16px; }

.margin-2-rem {
  margin: 32px; }

.margin-top-2-rem {
  margin-top: 32px; }

.margin-left-2-rem {
  margin-left: 32px; }

.margin-right-2-rem {
  margin-right: 32px; }

.margin-bottom-2-rem {
  margin-bottom: 32px; }

.margin-3-rem {
  margin: 32px; }

.margin-top-3-rem {
  margin-top: 32px; }

.margin-left-3-rem {
  margin-left: 32px; }

.margin-right-3-rem {
  margin-right: 32px; }

.margin-bottom-3-rem {
  margin-bottom: 32px; }

.margin-4-rem {
  margin: 64px; }

.margin-top-4-rem {
  margin-top: 64px; }

.margin-left-4-rem {
  margin-left: 64px; }

.margin-right-4-rem {
  margin-right: 64px; }

.margin-bottom-4-rem {
  margin-bottom: 64px; }

.margin-8-rem {
  margin: 128px; }

.margin-top-8-rem {
  margin-top: 128px; }

.margin-left-8-rem {
  margin-left: 128px; }

.margin-right-8-rem {
  margin-right: 128px; }

.margin-bottom-8-rem {
  margin-bottom: 128px; }

.no-margin-top {
  margin-top: 0; }

.no-margin-left {
  margin-left: 0; }

.no-margin-right {
  margin-right: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

@media (min-width: 600px) {
  .pad-top-sm-none {
    padding-top: 0; }
  .pad-left-sm-none {
    padding-left: 0; }
  .pad-right-sm-none {
    padding-right: 0; }
  .pad-bottom-sm-none {
    padding-bottom: 0; }
  .pad-top-sm-xs {
    padding-top: 5px; }
  .pad-left-sm-xs {
    padding-left: 5px; }
  .pad-right-sm-xs {
    padding-right: 5px; }
  .pad-bottom-sm-xs {
    padding-bottom: 5px; }
  .pad-top-sm-sm {
    padding-top: 10px; }
  .pad-left-sm-sm {
    padding-left: 10px; }
  .pad-right-sm-sm {
    padding-right: 10px; }
  .pad-bottom-sm-sm {
    padding-bottom: 10px; }
  .pad-top-sm-md {
    padding-top: 15px; }
  .pad-left-sm-md {
    padding-left: 15px; }
  .pad-right-sm-md {
    padding-right: 15px; }
  .pad-bottom-sm-md {
    padding-bottom: 15px; }
  .pad-top-sm-lg {
    padding-top: 20px; }
  .pad-left-sm-lg {
    padding-left: 20px; }
  .pad-right-sm-lg {
    padding-right: 20px; }
  .pad-bottom-sm-lg {
    padding-bottom: 20px; }
  .pad-top-sm-xl {
    padding-top: 30px; }
  .pad-left-sm-xl {
    padding-left: 30px; }
  .pad-right-sm-xl {
    padding-right: 30px; }
  .pad-bottom-sm-xl {
    padding-bottom: 30px; }
  .pad-top-sm-half-rem {
    padding-top: 8px; }
  .pad-left-sm-half-rem {
    padding-left: 8px; }
  .pad-right-sm-half-rem {
    padding-right: 8px; }
  .pad-bottom-sm-half-rem {
    padding-bottom: 8px; }
  .pad-top-sm-rem {
    padding-top: 16px; }
  .pad-left-sm-rem {
    padding-left: 16px; }
  .pad-right-sm-rem {
    padding-right: 16px; }
  .pad-bottom-sm-rem {
    padding-bottom: 16px; }
  .pad-top-sm-2-rem {
    padding-top: 32px; }
  .pad-left-sm-2-rem {
    padding-left: 32px; }
  .pad-right-sm-2-rem {
    padding-right: 32px; }
  .pad-bottom-sm-2-rem {
    padding-bottom: 32px; }
  .pad-top-sm-3-rem {
    padding-top: 32px; }
  .pad-left-sm-3-rem {
    padding-left: 32px; }
  .pad-right-sm-3-rem {
    padding-right: 32px; }
  .pad-bottom-sm-3-rem {
    padding-bottom: 32px; }
  .pad-top-sm-4-rem {
    padding-top: 64px; }
  .pad-left-sm-4-rem {
    padding-left: 64px; }
  .pad-right-sm-4-rem {
    padding-right: 64px; }
  .pad-bottom-sm-4-rem {
    padding-bottom: 64px; }
  .pad-top-sm-8-rem {
    padding-top: 128px; }
  .pad-left-sm-8-rem {
    padding-left: 128px; }
  .pad-right-sm-8-rem {
    padding-right: 128px; }
  .pad-bottom-sm-8-rem {
    padding-bottom: 128px; } }

@media (min-width: 900px) {
  .pad-top-md-none {
    padding-top: 0; }
  .pad-left-md-none {
    padding-left: 0; }
  .pad-right-md-none {
    padding-right: 0; }
  .pad-bottom-md-none {
    padding-bottom: 0; }
  .pad-top-md-xs {
    padding-top: 5px; }
  .pad-left-md-xs {
    padding-left: 5px; }
  .pad-right-md-xs {
    padding-right: 5px; }
  .pad-bottom-md-xs {
    padding-bottom: 5px; }
  .pad-top-md-sm {
    padding-top: 10px; }
  .pad-left-md-sm {
    padding-left: 10px; }
  .pad-right-md-sm {
    padding-right: 10px; }
  .pad-bottom-md-sm {
    padding-bottom: 10px; }
  .pad-top-md-md {
    padding-top: 15px; }
  .pad-left-md-md {
    padding-left: 15px; }
  .pad-right-md-md {
    padding-right: 15px; }
  .pad-bottom-md-md {
    padding-bottom: 15px; }
  .pad-top-md-lg {
    padding-top: 20px; }
  .pad-left-md-lg {
    padding-left: 20px; }
  .pad-right-md-lg {
    padding-right: 20px; }
  .pad-bottom-md-lg {
    padding-bottom: 20px; }
  .pad-top-md-xl {
    padding-top: 30px; }
  .pad-left-md-xl {
    padding-left: 30px; }
  .pad-right-md-xl {
    padding-right: 30px; }
  .pad-bottom-md-xl {
    padding-bottom: 30px; }
  .pad-top-md-half-rem {
    padding-top: 8px; }
  .pad-left-md-half-rem {
    padding-left: 8px; }
  .pad-right-md-half-rem {
    padding-right: 8px; }
  .pad-bottom-md-half-rem {
    padding-bottom: 8px; }
  .pad-top-md-rem {
    padding-top: 16px; }
  .pad-left-md-rem {
    padding-left: 16px; }
  .pad-right-md-rem {
    padding-right: 16px; }
  .pad-bottom-md-rem {
    padding-bottom: 16px; }
  .pad-top-md-2-rem {
    padding-top: 32px; }
  .pad-left-md-2-rem {
    padding-left: 32px; }
  .pad-right-md-2-rem {
    padding-right: 32px; }
  .pad-bottom-md-2-rem {
    padding-bottom: 32px; }
  .pad-top-md-3-rem {
    padding-top: 32px; }
  .pad-left-md-3-rem {
    padding-left: 32px; }
  .pad-right-md-3-rem {
    padding-right: 32px; }
  .pad-bottom-md-3-rem {
    padding-bottom: 32px; }
  .pad-top-md-4-rem {
    padding-top: 64px; }
  .pad-left-md-4-rem {
    padding-left: 64px; }
  .pad-right-md-4-rem {
    padding-right: 64px; }
  .pad-bottom-md-4-rem {
    padding-bottom: 64px; }
  .pad-top-md-8-rem {
    padding-top: 128px; }
  .pad-left-md-8-rem {
    padding-left: 128px; }
  .pad-right-md-8-rem {
    padding-right: 128px; }
  .pad-bottom-md-8-rem {
    padding-bottom: 128px; } }

@media (min-width: 1800px) {
  .pad-top-lg-none {
    padding-top: 0; }
  .pad-left-lg-none {
    padding-left: 0; }
  .pad-right-lg-none {
    padding-right: 0; }
  .pad-bottom-lg-none {
    padding-bottom: 0; }
  .pad-top-lg-xs {
    padding-top: 5px; }
  .pad-left-lg-xs {
    padding-left: 5px; }
  .pad-right-lg-xs {
    padding-right: 5px; }
  .pad-bottom-lg-xs {
    padding-bottom: 5px; }
  .pad-top-lg-sm {
    padding-top: 10px; }
  .pad-left-lg-sm {
    padding-left: 10px; }
  .pad-right-lg-sm {
    padding-right: 10px; }
  .pad-bottom-lg-sm {
    padding-bottom: 10px; }
  .pad-top-lg-md {
    padding-top: 15px; }
  .pad-left-lg-md {
    padding-left: 15px; }
  .pad-right-lg-md {
    padding-right: 15px; }
  .pad-bottom-lg-md {
    padding-bottom: 15px; }
  .pad-top-lg-lg {
    padding-top: 20px; }
  .pad-left-lg-lg {
    padding-left: 20px; }
  .pad-right-lg-lg {
    padding-right: 20px; }
  .pad-bottom-lg-lg {
    padding-bottom: 20px; }
  .pad-top-lg-xl {
    padding-top: 30px; }
  .pad-left-lg-xl {
    padding-left: 30px; }
  .pad-right-lg-xl {
    padding-right: 30px; }
  .pad-bottom-lg-xl {
    padding-bottom: 30px; }
  .pad-top-lg-half-rem {
    padding-top: 8px; }
  .pad-left-lg-half-rem {
    padding-left: 8px; }
  .pad-right-lg-half-rem {
    padding-right: 8px; }
  .pad-bottom-lg-half-rem {
    padding-bottom: 8px; }
  .pad-top-lg-rem {
    padding-top: 16px; }
  .pad-left-lg-rem {
    padding-left: 16px; }
  .pad-right-lg-rem {
    padding-right: 16px; }
  .pad-bottom-lg-rem {
    padding-bottom: 16px; }
  .pad-top-lg-2-rem {
    padding-top: 32px; }
  .pad-left-lg-2-rem {
    padding-left: 32px; }
  .pad-right-lg-2-rem {
    padding-right: 32px; }
  .pad-bottom-lg-2-rem {
    padding-bottom: 32px; }
  .pad-top-lg-3-rem {
    padding-top: 32px; }
  .pad-left-lg-3-rem {
    padding-left: 32px; }
  .pad-right-lg-3-rem {
    padding-right: 32px; }
  .pad-bottom-lg-3-rem {
    padding-bottom: 32px; }
  .pad-top-lg-4-rem {
    padding-top: 64px; }
  .pad-left-lg-4-rem {
    padding-left: 64px; }
  .pad-right-lg-4-rem {
    padding-right: 64px; }
  .pad-bottom-lg-4-rem {
    padding-bottom: 64px; }
  .pad-top-lg-8-rem {
    padding-top: 128px; }
  .pad-left-lg-8-rem {
    padding-left: 128px; }
  .pad-right-lg-8-rem {
    padding-right: 128px; }
  .pad-bottom-lg-8-rem {
    padding-bottom: 128px; } }

.op-10 {
  opacity: 0.1; }

.op-20 {
  opacity: 0.2; }

.op-25 {
  opacity: 0.25; }

.op-30 {
  opacity: 0.3; }

.op-40 {
  opacity: 0.4; }

.op-50 {
  opacity: 0.5; }

.op-60 {
  opacity: 0.6; }

.op-70 {
  opacity: 0.7; }

.op-75 {
  opacity: 0.75; }

.op-80 {
  opacity: 0.8; }

.op-90 {
  opacity: 0.9; }

.anti-container-gutter {
  margin-left: -7.14286%;
  margin-right: -7.14286%; }
  @media (min-width: 900px) {
    .anti-container-gutter {
      margin-left: -3.27869%;
      margin-right: -3.27869%; } }

.position-absolute {
  position: absolute; }

.position-relative {
  position: relative; }

.vertical-align-middle {
  vertical-align: middle;
  white-space: nowrap; }

.vertical-align-middle > * {
  vertical-align: middle;
  white-space: initial;
  display: inline-block;
  float: none; }

.vertical-align-middle:before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

.vertical-align-bottom {
  vertical-align: bottom;
  /*white-space: nowrap;*/ }

.vertical-align-bottom > * {
  vertical-align: bottom;
  white-space: initial;
  display: inline-block; }

.vertical-align-top {
  vertical-align: top; }

.vertical-align-top > * {
  vertical-align: top;
  white-space: initial;
  display: inline-block; }

.vertical-align-bottom:before {
  border-width: 0px;
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom; }

.absolute-align-center {
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.absolute-vertical-align-bottom {
  position: absolute;
  bottom: 0; }

.top {
  top: 0; }

.left {
  left: 0; }

.right {
  right: 0; }

.bottom {
  bottom: 0; }

.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-align-top {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.flex-align-bottom {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.flex-align-center {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.flex-align-center {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.inline-align-center {
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.flex-justify-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.flex-justify-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.flex-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.flex-justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.align-self-start {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.align-self-end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.align-self-center {
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flex-direction-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-direction-row {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-direction-column-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-direction-row-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.no-list-style {
  list-style: none; }

.cursor-default {
  cursor: default; }

.cursor-pointer {
  cursor: pointer; }

.inline-block {
  display: inline-block; }

.block {
  display: block; }

.inline {
  display: inline; }

.border-grey {
  border: solid 1px #404040; }

/*Error classes*/
.redeem-error {
  color: #ff2f49; }

.redeem-success {
  color: #3c763d; }

.error-box {
  background: #1B3171;
  height: auto;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  max-width: 360px;
  margin-top: 25px; }
  .error-box .arrow-up {
    position: absolute;
    position: absolute;
    right: 73.3333%;
    bottom: 100%;
    border: 25px solid transparent;
    border-bottom: 25px solid #1B3171;
    border-top-width: 0; }
  .error-box .error-popup-message {
    padding: 20px; }
    @media (min-width: 600px) {
      .error-box .error-popup-message {
        padding: 50px; } }

@media (max-width: 1049px) {
  .tooltip {
    display: none !important; } }

.tooltip.bottom .tooltip-inner {
  background-color: #0A0B0D;
  border: 2px solid #9DACC1;
  color: #9DACC1;
  padding: 8px 16px 8px 16px; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #9DACC1; }

.tooltip.bottom .tooltip.show {
  opacity: 1; }

body,
html {
  overflow-x: hidden; }

/*body.modal-open{
    padding-right: 0px !important;
}*/
body.connect-open {
  overflow: hidden;
  position: fixed;
  width: 100%; }
  @media (min-width: 1050px) {
    body.connect-open {
      position: static;
      overflow-y: auto;
      width: auto; } }

#Wrapper {
  position: relative;
  padding-top: 4rem;
  display: block;
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 2560px; }
  #Wrapper.unfixed-nav {
    padding-top: 0; }
  #Wrapper .wrapper-fade-right,
  #Wrapper .wrapper-fade-left {
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
    width: 2.3438%;
    z-index: 10000; }
    @media (min-width: 2560px) {
      #Wrapper .wrapper-fade-right,
      #Wrapper .wrapper-fade-left {
        display: block; } }
  @media (min-width: 2560px) {
    #Wrapper .wrapper-fade-left {
      left: 0;
      height: 100%;
      background: linear-gradient(to left, rgba(10, 11, 13, 0), #0a0b0d); } }
  @media (min-width: 2560px) {
    #Wrapper .wrapper-fade-right {
      right: 0;
      height: 100%;
      background: linear-gradient(to right, rgba(10, 11, 13, 0), #0a0b0d); } }
  #Wrapper .body-content {
    min-height: 60vh;
    padding-bottom: 128px; }

#Footer {
  position: relative; }

form {
  position: relative; }

.validationMessage {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  color: #ff2f49;
  padding-top: 0.5rem; }

body.ie {
  font-family: Arial, sans-serif; }

.loader-wrap {
  padding-top: 200px;
  padding-bottom: 200px;
  padding-left: 0px;
  padding-right: 0px; }

.loader-wrap-entire-page {
  position: relative;
  height: 100%;
  width: 100%; }
  .loader-wrap-entire-page.loader-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.loader-spinner {
  display: inline-block;
  position: relative; }
  .loader-spinner.loader-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .loader-spinner.small-loader .spinner-img {
    height: 50px;
    width: 50px; }
  .loader-spinner.md-loader .spinner-img {
    height: 50px;
    width: 50px; }
  .loader-spinner.large-loader .spinner-img {
    height: 75px;
    width: 75px; }
  .loader-spinner .spinner-img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    animation: spin 1s linear infinite; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loader-box {
  display: inline-block;
  position: relative;
  height: 50px;
  width: 50px;
  overflow: visible; }
  .loader-box.loader-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .loader-box.small-loader {
    height: 25px;
    width: 25px; }
  .loader-box.md-loader {
    height: 50px;
    width: 50px; }
  .loader-box.large-loader {
    height: 150px;
    width: 150px; }
  .loader-box .load-line {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0px solid #F2C40D;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .loader-box .top-line {
    border-top-width: 1px;
    animation: box-loader-top 1s ease infinite; }
  .loader-box .right-line {
    border-right-width: 1px;
    animation: box-loader-right 1s ease infinite; }
  .loader-box .bottom-line {
    border-bottom-width: 1px;
    animation: box-loader-bottom 1s ease infinite; }
  .loader-box .left-line {
    border-left-width: 1px;
    animation: box-loader-left 1s ease infinite; }

@keyframes box-loader-top {
  0% {
    transform: translateY(-10px);
    opacity: 0; }
  15% {
    opacity: 1; }
  25% {
    transform: translateY(0); }
  50% {
    transform: translateY(0); }
  60% {
    opacity: 1; }
  75% {
    transform: translateY(-10px);
    opacity: 0; }
  100% {
    transform: translateY(-10px);
    opacity: 0; } }

@keyframes box-loader-right {
  0% {
    transform: translateX(10px);
    opacity: 0; }
  8.33% {
    transform: translateX(10px);
    opacity: 0; }
  23.33% {
    opacity: 1; }
  33.33% {
    transform: translateX(0); }
  58.33% {
    transform: translateX(0); }
  68.33% {
    opacity: 1; }
  83.33% {
    transform: translateX(10px);
    opacity: 0; }
  100% {
    transform: translateX(10px);
    opacity: 0; } }

@keyframes box-loader-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0; }
  16.67% {
    transform: translateY(10px);
    opacity: 0; }
  31.67% {
    opacity: 1; }
  41.67% {
    transform: translateY(0); }
  66.67% {
    transform: translateY(0); }
  76.67% {
    opacity: 1; }
  91.67% {
    transform: translateY(10px);
    opacity: 0; }
  100% {
    transform: translateY(10px);
    opacity: 0; } }

@keyframes box-loader-left {
  0% {
    transform: translateX(-10px);
    opacity: 0; }
  25% {
    transform: translateX(-10px);
    opacity: 0; }
  40% {
    opacity: 1; }
  50% {
    transform: translateX(0); }
  75% {
    transform: translateX(0); }
  85% {
    opacity: 1; }
  100% {
    transform: translateX(-10px);
    opacity: 0; } }

ul.ui-autocomplete {
  z-index: 10031;
  background: #1b3171;
  position: fixed;
  /*max-height: 32.8125rem;
    overflow: auto !important;
    border-right: 0.5rem solid #1b3171 !important;
    border-top: 1rem solid #1b3171 !important;
    border-bottom: 2.125rem solid #1b3171 !important;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 0.5rem;
        border: 0.5rem solid transparent;
    }

    &::-webkit-scrollbar-button {
        display: none;
        color: transparent;
        background: transparent;
        height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        width: 0.25rem;
        background-color: #0a0b0d;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4);
    }*/ }
  @media (min-width: 1050px) {
    ul.ui-autocomplete {
      margin-top: 1rem; }
      ul.ui-autocomplete:before {
        content: '';
        display: block;
        position: absolute;
        top: -20px;
        left: 2em;
        border: 10px solid transparent;
        border-bottom: 10px solid #1b3171; } }

ul.ui-autocomplete.ui-widget-content {
  border-top: none;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid transparent;
  font-family: "MarkOT", Helvetica, Arial, sans-serif;
  padding-top: 1.36875rem;
  padding-bottom: 1.36875rem; }
  ul.ui-autocomplete.ui-widget-content.zoomLevel {
    max-height: 300px;
    overflow: auto; }
  ul.ui-autocomplete.ui-widget-content li.ui-autocomplete-category {
    font-weight: 400;
    text-align: center;
    position: relative;
    padding: 0.25em; }
    ul.ui-autocomplete.ui-widget-content li.ui-autocomplete-category .ui-autocomplete-category-text {
      display: inline-block;
      background: transparent;
      color: #FFF;
      font-weight: 600;
      z-index: 1;
      position: relative;
      padding: 0 8px; }
    ul.ui-autocomplete.ui-widget-content li.ui-autocomplete-category:before {
      content: "";
      position: absolute;
      top: 1em;
      left: 0;
      border-bottom: 0px solid rgba(0, 0, 0, 0);
      width: 100%;
      z-index: 0; }
  ul.ui-autocomplete.ui-widget-content li.ui-menu-item {
    padding: 0.5125rem 1rem 0.5125rem 0.625em; }
    ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content {
      list-style-image: none;
      border-top: 0px solid rgba(0, 0, 0, 0);
      border-bottom: 0px solid rgba(0, 0, 0, 0);
      background: transparent;
      font-weight: 400; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content:hover, ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content:focus {
        list-style-image: none;
        border-top: 0px solid rgba(0, 0, 0, 0);
        border-bottom: 0px solid rgba(0, 0, 0, 0);
        background: transparent;
        font-weight: 400; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content.ui-state-focus {
        border: none;
        margin: 0; }
        ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content.ui-state-focus .sayt-product-anchor {
          color: #F1C400; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content a:hover, ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content a:focus {
        color: #F1C400; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-product-content .sayt-product-anchor {
        color: #FFF; }
    ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation {
      border-top: 0px solid rgba(0, 0, 0, 0);
      border-bottom: 0px solid rgba(0, 0, 0, 0);
      background: transparent;
      font-weight: 400;
      font-size: 1em; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation a .sayt-content {
        font-size: 1em; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation a:hover, ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation a:focus {
        color: #F1C400;
        text-decoration: none; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation a.ui-state-active {
        color: inherit;
        list-style-image: none;
        border: none;
        background: inherit;
        font-weight: 400;
        box-shadow: none;
        outline: none; }
      ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation:hover, ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation:focus {
        color: inherit;
        list-style-image: none;
        border-top: 0px solid rgba(0, 0, 0, 0);
        border-bottom: 0px solid rgba(0, 0, 0, 0);
        background: transparent;
        font-weight: 400; }
        ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation:hover a, ul.ui-autocomplete.ui-widget-content li.ui-menu-item.sayt-autocomplete-navigation:focus a {
          border-top: 0px solid rgba(0, 0, 0, 0);
          border-bottom: 0px solid rgba(0, 0, 0, 0);
          background: transparent;
          outline: none;
          color: inherit;
          border: 0px; }
  ul.ui-autocomplete.ui-widget-content li.sayt-autocomplete-navigation {
    background: transparent;
    border-top: 0px solid rgba(0, 0, 0, 0);
    border-bottom: 0px solid rgba(0, 0, 0, 0);
    font-weight: 400; }
    ul.ui-autocomplete.ui-widget-content li.sayt-autocomplete-navigation:hover, ul.ui-autocomplete.ui-widget-content li.sayt-autocomplete-navigation:focus {
      background: transparent;
      border-top: 0px solid rgba(0, 0, 0, 0);
      border-bottom: 0px solid rgba(0, 0, 0, 0);
      font-weight: 400; }
  ul.ui-autocomplete.ui-widget-content li .sayt-content.sayt-product-title {
    font-size: 1em; }
  ul.ui-autocomplete.ui-widget-content li .sayt-director-name {
    display: none; }

li:hover a.sayt-navigation, a.sayt-navigation:hover, ul:hover a.sayt-navigation {
  background: inherit; }

.sayt-product-anchor.ui-state-focus {
  padding: 0;
  margin: 0;
  border: none;
  background: inherit;
  font-weight: 400; }
  .sayt-product-anchor.ui-state-focus a {
    border-width: 0; }

.sayt-content {
  font-size: 11px; }

.sayt-director-name {
  float: left;
  font-size: 11px;
  padding-left: 5px; }

li.ui-menu-item.sayt-product-content {
  list-style-image: none;
  border-top: 0px solid rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0); }

.sayt-product-title {
  float: left;
  padding-top: 1rem;
  padding-left: 1rem; }

.sayt-product-image {
  float: left; }
  @media (max-width: 600px) {
    .sayt-product-image {
      display: none; } }

.sayt-title-wrap {
  display: block;
  overflow: hidden; }

a.ui-corner-all.ui-state-focus {
  border: 0;
  border-radius: 0;
  /*background: inherit;*/
  color: #FFF;
  font-weight: 400; }

.jspContainer .jspPane {
  width: 100%; }

.jspContainer .jspVerticalBar {
  width: 8px; }

.jspContainer .jspTrack {
  background: #202733; }

.jspContainer .jspDrag {
  background: #455b87; }

.checkradio {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
  cursor: pointer; }
  .checkradio.round .faux-input {
    border-radius: 50%; }
    .checkradio.round .faux-input::before {
      top: 2px !important; }
  .checkradio input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    padding: 0;
    z-index: 1; }
    .checkradio input[type=checkbox]:hover + .faux-input, .checkradio input[type=checkbox]:focus + .faux-input {
      color: #F1C400;
      width: 100%;
      height: 100%;
      border: 1px solid #F1C400; }
    .checkradio input[type=checkbox]:checked + .faux-input, .checkradio input[type=checkbox].checked + .faux-input {
      color: #000;
      background: #d8d8d8;
      border: 1px solid #d8d8d8; }
      .checkradio input[type=checkbox]:checked + .faux-input::before, .checkradio input[type=checkbox].checked + .faux-input::before {
        content: "\79";
        font-family: 'CineplexStoreFont' !important;
        speak: none;
        font-style: normal;
        font-weight: bold;
        font-variant: normal;
        font-size: 1.25rem;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        top: 0;
        left: 1px; }
    .checkradio input[type=checkbox]:checked:hover + .faux-input, .checkradio input[type=checkbox]:checked:focus + .faux-input, .checkradio input[type=checkbox].checked:hover + .faux-input, .checkradio input[type=checkbox].checked:focus + .faux-input {
      width: 100%;
      height: 100%;
      border: 1px solid #F1C400;
      background: #F1C400; }
  .checkradio .faux-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #FFF;
    background: transparent; }
    .checkradio .faux-input::before {
      content: ""; }

.checkbox-btn {
  border: 1px solid #6e96db;
  color: #6e96db;
  background: transparent;
  padding: 5px;
  text-align: center; }
  .checkbox-btn span {
    font-size: 1rem; }
  .checkbox-btn:hover, .checkbox-btn:focus {
    border: 1px solid #F1C400;
    color: #F1C400; }

.refinement-chkbox {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 !important; }
  .refinement-chkbox:hover + .checkbox-btn, .refinement-chkbox:focus + .checkbox-btn {
    border: 1px solid #F1C400;
    color: #F1C400; }
  .refinement-chkbox:checked + .checkbox-btn {
    color: #00358e;
    background: #6e96db; }
  .refinement-chkbox:checked:hover + .checkbox-btn, .refinement-chkbox:checked:focus + .checkbox-btn {
    background: #F1C400;
    color: #000; }

#Wrapper.legacy-wrapper .navbar.navbar-default {
  background: #23326F; }
  #Wrapper.legacy-wrapper .navbar.navbar-default.primary-nav-scrolled, #Wrapper.legacy-wrapper .navbar.navbar-default.search-open {
    background: #23326F; }

#Wrapper.legacy-wrapper .body-content {
  color: #000; }
  #Wrapper.legacy-wrapper .body-content a {
    color: #2d6892; }
    #Wrapper.legacy-wrapper .body-content a:hover, #Wrapper.legacy-wrapper .body-content a:focus {
      color: #2c3e4c; }
    #Wrapper.legacy-wrapper .body-content a:active, #Wrapper.legacy-wrapper .body-content a:visited {
      color: #2c3e4c; }

#Wrapper.legacy-wrapper .btn-primary, #Wrapper.legacy-wrapper .btn-primary-small {
  color: #FFF;
  border: 1px solid #345c7c;
  font-size: 1em;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #2a4e6b;
  background: -moz-linear-gradient(top, #447ea3, #2a4e6b);
  background: -webkit-linear-gradient(top, #447ea3, #2a4e6b);
  background: -o-linear-gradient(top, #447ea3, #2a4e6b);
  background: -ms-linear-gradient(top, #447ea3, #2a4e6b);
  background: linear-gradient(top bottom, #447ea3, #2a4e6b);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  padding: 8px 12px 7px;
  text-shadow: rgba(0, 0, 0, 0.901961) 0px -1px 0px; }
  #Wrapper.legacy-wrapper .btn-primary:hover, #Wrapper.legacy-wrapper .btn-primary:focus, #Wrapper.legacy-wrapper .btn-primary-small:hover, #Wrapper.legacy-wrapper .btn-primary-small:focus {
    color: #FFF;
    background: #1f4a69;
    background: #193b53;
    background: -moz-linear-gradient(top, #285e84, #193b53);
    background: -webkit-linear-gradient(top, #285e84, #193b53);
    background: -o-linear-gradient(top, #285e84, #193b53);
    background: -ms-linear-gradient(top, #285e84, #193b53);
    background: linear-gradient(top bottom, #285e84, #193b53);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #183a52;
    outline: 0 none; }
  #Wrapper.legacy-wrapper .btn-primary.disabled, #Wrapper.legacy-wrapper .btn-primary-small.disabled {
    background: #2A4E6B;
    background: -moz-linear-gradient(top, #447EA3, #2A4E6B);
    background: -webkit-linear-gradient(top, #447EA3, #2A4E6B);
    background: -o-linear-gradient(top, #447EA3, #2A4E6B);
    background: -ms-linear-gradient(top, #447EA3, #2A4E6B);
    background: linear-gradient(top bottom, #447EA3, #2A4E6B);
    opacity: 0.5; }
    #Wrapper.legacy-wrapper .btn-primary.disabled:hover, #Wrapper.legacy-wrapper .btn-primary.disabled:focus, #Wrapper.legacy-wrapper .btn-primary-small.disabled:hover, #Wrapper.legacy-wrapper .btn-primary-small.disabled:focus {
      background: #2A4E6B;
      background: -moz-linear-gradient(top, #447EA3, #2A4E6B);
      background: -webkit-linear-gradient(top, #447EA3, #2A4E6B);
      background: -o-linear-gradient(top, #447EA3, #2A4E6B);
      background: -ms-linear-gradient(top, #447EA3, #2A4E6B);
      background: linear-gradient(top bottom, #447EA3, #2A4E6B);
      opacity: 0.5; }
  #Wrapper.legacy-wrapper .btn-primary:disabled, #Wrapper.legacy-wrapper .btn-primary-small:disabled {
    background: #2A4E6B;
    background: -moz-linear-gradient(top, #447EA3, #2A4E6B);
    background: -webkit-linear-gradient(top, #447EA3, #2A4E6B);
    background: -o-linear-gradient(top, #447EA3, #2A4E6B);
    background: -ms-linear-gradient(top, #447EA3, #2A4E6B);
    background: linear-gradient(top bottom, #447EA3, #2A4E6B);
    opacity: 0.5; }
    #Wrapper.legacy-wrapper .btn-primary:disabled:hover, #Wrapper.legacy-wrapper .btn-primary:disabled:focus, #Wrapper.legacy-wrapper .btn-primary-small:disabled:hover, #Wrapper.legacy-wrapper .btn-primary-small:disabled:focus {
      background: #2A4E6B;
      background: -moz-linear-gradient(top, #447EA3, #2A4E6B);
      background: -webkit-linear-gradient(top, #447EA3, #2A4E6B);
      background: -o-linear-gradient(top, #447EA3, #2A4E6B);
      background: -ms-linear-gradient(top, #447EA3, #2A4E6B);
      background: linear-gradient(top bottom, #447EA3, #2A4E6B);
      opacity: 0.5; }

#Wrapper.legacy-wrapper .btn-primary-small, #Wrapper.legacy-wrapper .btn-secondary-small {
  padding: 4px 6px; }

#Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card {
  position: relative; }
  #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content {
    background: #FFF;
    border: 1px solid #2d6892; }
    #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content:hover, #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content:focus {
      color: #2c3e4c;
      border: 1px solid #2c3e4c; }
      #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content:hover .see-all-card-text, #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content:focus .see-all-card-text {
        color: #2c3e4c; }
    #Wrapper.legacy-wrapper .movie-card-section-wrapper .see-all-card .see-all-card-content .see-all-card-text {
      color: #2d6892; }

.cineplex_connect_modal .modal.connect-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100001;
  display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  transform: translate(0, 0); }
  .cineplex_connect_modal .modal.connect-modal.in {
    display: block !important; }
  .cineplex_connect_modal .modal.connect-modal .modal-dialog {
    max-height: 100%;
    overflow: auto;
    margin: 10px; }
    @media (min-width: 600px) {
      .cineplex_connect_modal .modal.connect-modal .modal-dialog {
        margin: 30px auto; } }

#DotComConnectMobileMenu .navbar-fixed-bottom .navbar-nav li a {
  color: #FFF; }

.carousel-indicators .playlist-indicator {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 5.17241%; }
  @media (min-width: 900px) {
    .carousel-indicators .playlist-indicator {
      width: 2.45902%; } }
  .carousel-indicators .playlist-indicator:hover {
    cursor: pointer;
    color: #F1C400; }
  .carousel-indicators .playlist-indicator.slick-arrow.slick-disabled {
    opacity: 0.3;
    color: #FFF;
    cursor: not-allowed; }
  .carousel-indicators .playlist-indicator .rail-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    font-size: 4.6875vw; }
    @media (min-width: 900px) {
      .carousel-indicators .playlist-indicator .rail-icon {
        font-size: 3.958vw; } }

.carousel-indicators .playlist-back-indicator {
  right: 100%; }

.carousel-indicators .playlist-next-indicator {
  left: 100%; }
  .carousel-indicators .playlist-next-indicator .store-icon-railright:before {
    position: relative;
    left: -50%; }

.modal-icon-area {
  padding-bottom: 1rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }
  .modal-icon-area .icon {
    font-size: 1.25rem;
    padding-right: 8px; }
    @media (min-width: 600px) {
      .modal-icon-area .icon {
        font-size: 2.4rem; } }
  .modal-icon-area .icon-text {
    font-size: 1.25rem; }
    @media (min-width: 600px) {
      .modal-icon-area .icon-text {
        font-size: 1.5625rem; } }

.modal .modal-content .half-width-modal-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media (min-width: 600px) {
    .modal .modal-content .half-width-modal-buttons {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 0; } }
  .modal .modal-content .half-width-modal-buttons .confirm-button {
    width: 100%;
    margin: 0; }
    @media (min-width: 600px) {
      .modal .modal-content .half-width-modal-buttons .confirm-button {
        margin: 0 0 16px 24px; } }
    .modal .modal-content .half-width-modal-buttons .confirm-button:first-child {
      margin: 0 0 16px; }
      @media (min-width: 600px) {
        .modal .modal-content .half-width-modal-buttons .confirm-button:first-child {
          margin: 0 24px 16px 0; } }
    @media (min-width: 600px) {
      .modal .modal-content .half-width-modal-buttons .confirm-button {
        max-width: 50%; } }

.modal .modal-content .modal-legal {
  font-size: 0.8rem;
  color: #a7a8aa; }

.modal .modal-input {
  color: #FFF;
  background-color: #202733;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  border: none;
  height: 48px; }
  .modal .modal-input ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 1rem;
    color: #d8d8d8; }
  .modal .modal-input ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 1rem;
    color: #d8d8d8; }
  .modal .modal-input :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 1rem;
    color: #d8d8d8; }
  .modal .modal-input :-moz-placeholder {
    /* Firefox 18- */
    font-size: 1rem;
    color: #d8d8d8; }

.pricing-modal .modal-content {
  padding-bottom: 48px; }
  @media (min-width: 600px) {
    .pricing-modal .modal-content {
      width: 688px;
      padding-bottom: 24px; } }
  @media (min-width: 768px) {
    .pricing-modal .modal-content {
      width: 768px; } }
  @media (min-width: 900px) {
    .pricing-modal .modal-content {
      padding-bottom: 0; } }
  @media (min-width: 1800px) {
    .pricing-modal .modal-content {
      width: 920px; } }

.pricing-modal .modal-header {
  padding-top: 65vw;
  padding-bottom: 0; }
  @media (min-width: 600px) {
    .pricing-modal .modal-header {
      padding-top: 248px; } }
  @media (min-width: 1800px) {
    .pricing-modal .modal-header {
      padding-top: 326px; } }
  .pricing-modal .modal-header .modal-title {
    font-size: 1.955rem;
    padding-bottom: 0.5rem;
    font-weight: 600; }
  .pricing-modal .modal-header .pricingmodal-subtitle--fullwidth {
    width: 100%; }
    .pricing-modal .modal-header .pricingmodal-subtitle--fullwidth div {
      margin-bottom: 0.5rem; }
  .pricing-modal .modal-header .title-details {
    color: #d8d8d8;
    -ms-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }
    .pricing-modal .modal-header .title-details .quality-labels-wrap {
      display: flex;
      height: 1.5rem; }
      .pricing-modal .modal-header .title-details .quality-labels-wrap span {
        border: solid 1px;
        padding: 0.125rem 0.25rem;
        font-weight: 700;
        margin-right: 0.25rem; }
      .pricing-modal .modal-header .title-details .quality-labels-wrap img {
        height: 1.5rem;
        margin-right: 0.25rem; }

.pricing-modal .modal-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 24px; }
  @media (min-width: 900px) {
    .pricing-modal .modal-body {
      padding-top: 32px; } }
  @media (min-width: 1800px) {
    .pricing-modal .modal-body {
      padding-top: 40px; } }
  .pricing-modal .modal-body .pricing-plan-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    width: 100%; }
    @media (min-width: 600px) {
      .pricing-modal .modal-body .pricing-plan-wrap {
        width: 50%;
        margin-bottom: 2.5rem; } }
    .pricing-modal .modal-body .pricing-plan-wrap > :nth-child(2) {
      margin-top: -0.5rem; }
      @media (min-width: 600px) {
        .pricing-modal .modal-body .pricing-plan-wrap > :nth-child(2) {
          margin-top: 0; } }
    .pricing-modal .modal-body .pricing-plan-wrap.preorder-plans {
      width: 100%;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      @media (min-width: 600px) {
        .pricing-modal .modal-body .pricing-plan-wrap.preorder-plans {
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row; } }
      @media (min-width: 600px) {
        .pricing-modal .modal-body .pricing-plan-wrap.preorder-plans .price-btn-wrapper {
          margin-bottom: 0; } }
    @media (max-width: 599px) {
      .pricing-modal .modal-body .pricing-plan-wrap:last-child .price-btn-wrapper:last-child {
        margin-bottom: 0; } }
    .pricing-modal .modal-body .pricing-plan-wrap.noAvailableRentPlans {
      margin: auto; }
    .pricing-modal .modal-body .pricing-plan-wrap.noAvailableBuyPlans {
      margin: auto; }
  .pricing-modal .modal-body .price-btn-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 32px; }
    @media (min-width: 600px) {
      .pricing-modal .modal-body .price-btn-wrapper {
        margin-bottom: 0px; } }
    @media (min-width: 280px) {
      .pricing-modal .modal-body .price-btn-wrapper .price-scene-align-right {
        padding-right: 0; } }
    @media (min-width: 900px) {
      .pricing-modal .modal-body .price-btn-wrapper .price-scene-align-right {
        padding-right: 24px; } }
    @media (min-width: 280px) {
      .pricing-modal .modal-body .price-btn-wrapper .price-scene-align-left {
        padding-left: 0; } }
    @media (min-width: 900px) {
      .pricing-modal .modal-body .price-btn-wrapper .price-scene-align-left {
        padding-left: 14px; } }
    .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn {
      margin-bottom: 2rem; }
      @media (min-width: 600px) {
        .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn {
          margin-bottom: 3.25rem;
          font-size: 1.25rem; } }
      @media (min-width: 1800px) {
        .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn {
          margin-bottom: 4.2rem; } }
      .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.rent-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-hd {
        padding-right: 0; }
        @media (min-width: 600px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.rent-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-hd {
            padding-right: 20px; } }
        @media (min-width: 1800px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.rent-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-hd {
            padding-right: 24px; } }
      .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.buy-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-sd {
        padding-left: 0; }
        @media (min-width: 600px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.buy-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-sd {
            padding-left: 20px; } }
        @media (min-width: 1800px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.buy-btn, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn.preorder-sd {
            padding-left: 24px; } }
      .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn {
        width: 100%;
        border: 1px solid #FFF;
        padding: 9px 0; }
        @media (min-width: 600px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn {
            padding: 12px 0 11px; } }
        @media (min-width: 1800px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn {
            padding: 15px 0 14px; } }
        .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn:hover, .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn:focus {
          border: 1px solid #F1C400; }
        .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .modal-price-btn.disabled {
          color: #75787b;
          border: 1px solid #404040; }
      .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .price-scene-info {
        font-size: 0.8rem;
        padding-top: 0.5rem; }
        @media (min-width: 600px) {
          .pricing-modal .modal-body .price-btn-wrapper.highlighted-btn .price-scene-info {
            position: absolute;
            padding-top: 16px; } }
    .pricing-modal .modal-body .price-btn-wrapper.preorder-sd .price-scene-info, .pricing-modal .modal-body .price-btn-wrapper.preorder-hd .price-scene-info {
      position: static; }
    .pricing-modal .modal-body .price-btn-wrapper.unavailable-price-btn {
      color: #75787b; }
    .pricing-modal .modal-body .price-btn-wrapper .modal-price-btn {
      border-width: 0px; }
      .pricing-modal .modal-body .price-btn-wrapper .modal-price-btn.disabled {
        color: #75787b;
        border: 1px solid #404040; }
    .pricing-modal .modal-body .price-btn-wrapper .price-scene-info {
      position: static;
      text-align: center;
      font-size: 0.8rem;
      color: #89a8e1; }
      @media (min-width: 600px) {
        .pricing-modal .modal-body .price-btn-wrapper .price-scene-info {
          /*position: absolute;*/
          top: 100%;
          left: 0;
          right: 0; } }
    .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment {
      font-family: "MarkOT", Helvetica, Arial, sans-serif;
      display: inline-flex;
      font-weight: 400;
      line-height: 28px;
      text-align: center; }
      @media (min-width: 280px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment {
          font-size: 0.96rem; } }
      @media (min-width: 900px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment {
          font-size: 1.1rem; } }
      @media (min-width: 280px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment .align-rent-sd-text {
          margin-right: 0; } }
      @media (min-width: 900px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment .align-rent-sd-text {
          margin-right: 20px; } }
      @media (min-width: 280px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment .align-buy-sd-text {
          margin-left: 0; } }
      @media (min-width: 900px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment .align-buy-sd-text {
          margin-left: 20px; } }
      @media (min-width: 280px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment span {
          margin-right: 3px; } }
      @media (min-width: 900px) {
        .pricing-modal .modal-body .price-btn-wrapper .rent-buy-text-aligment span {
          margin-right: 5px; } }
    .pricing-modal .modal-body .price-btn-wrapper .pdp-discounted-wrapper {
      white-space: nowrap; }
      .pricing-modal .modal-body .price-btn-wrapper .pdp-discounted-wrapper .pdp-discounted {
        color: #6C6F75;
        text-decoration: line-through;
        padding-left: 5px; }
    .pricing-modal .modal-body .price-btn-wrapper.noPaddingRentPlans {
      padding-right: 0 !important; }
    .pricing-modal .modal-body .price-btn-wrapper.noPaddingBuyPlans {
      padding-left: 0 !important; }
  .pricing-modal .modal-body .pricing-faq {
    font-style: italic;
    color: #b7b7b7;
    text-align: center; }
    @media (min-width: 600px) {
      .pricing-modal .modal-body .pricing-faq {
        margin-top: -1rem;
        margin-bottom: 1rem;
        text-align: left; } }

.movie-card-section-wrapper {
  font-family: MarkOT;
  color: #FFF;
  text-decoration: none;
  padding-bottom: 32px; }
  @media (min-width: 600px) {
    .movie-card-section-wrapper {
      padding-bottom: 9.23%; } }
  @media (min-width: 900px) {
    .movie-card-section-wrapper {
      padding-bottom: 4.23%; } }
  @media (min-width: 1440px) {
    .movie-card-section-wrapper {
      padding-bottom: 56px; } }
  .movie-card-section-wrapper .card {
    padding: 0 5.17242%; }
    @media (min-width: 600px) {
      .movie-card-section-wrapper .card {
        padding: 0 6.89656%; } }
    @media (min-width: 900px) {
      .movie-card-section-wrapper .card {
        padding: 0 4.91802%; } }

.movie-card-wrap {
  display: block; }
  .movie-card-wrap .movie {
    width: 100%; }
  .movie-card-wrap .card-caption {
    font-size: 1rem;
    font-style: italic;
    line-height: 1;
    text-align: left;
    color: #6e96db; }

.image-div-container .image-div {
  padding-bottom: 149.6410256410256%;
  background: transparent;
  height: 0;
  position: relative; }
  .image-div-container .image-div .movie-french-tag-outline {
    position: absolute;
    bottom: 0;
    left: 0;
    border-width: 24.5px;
    border-color: transparent transparent #202633 #202633;
    z-index: 1; }
    @media (min-width: 768px) {
      .image-div-container .image-div .movie-french-tag-outline {
        border-width: 32.5px; } }
    @media (min-width: 1080px) {
      .image-div-container .image-div .movie-french-tag-outline {
        border-width: 36.5px; } }
    @media (min-width: 1440px) {
      .image-div-container .image-div .movie-french-tag-outline {
        border-width: 40.5px; } }
    @media (min-width: 1900px) {
      .image-div-container .image-div .movie-french-tag-outline {
        border-width: 48.5px; } }
    .image-div-container .image-div .movie-french-tag-outline .movie-french-tag {
      position: absolute;
      bottom: 0px;
      left: 0px;
      border-style: solid;
      border-width: 24px;
      border-color: transparent transparent #89a8e1 #89a8e1;
      z-index: 1; }
      @media (min-width: 768px) {
        .image-div-container .image-div .movie-french-tag-outline .movie-french-tag {
          border-width: 32px; } }
      @media (min-width: 1080px) {
        .image-div-container .image-div .movie-french-tag-outline .movie-french-tag {
          border-width: 36px; } }
      @media (min-width: 1440px) {
        .image-div-container .image-div .movie-french-tag-outline .movie-french-tag {
          border-width: 40px; } }
      @media (min-width: 1900px) {
        .image-div-container .image-div .movie-french-tag-outline .movie-french-tag {
          border-width: 48px; } }
      .image-div-container .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 12.8px;
        font-weight: bold;
        letter-spacing: -1.5px; }
        @media (min-width: 768px) {
          .image-div-container .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
            top: 3px;
            font-size: 16px; } }
        @media (min-width: 1080px) {
          .image-div-container .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
            top: 5px;
            font-size: 18px; } }
        @media (min-width: 1440px) {
          .image-div-container .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
            top: 7px;
            font-size: 20px; } }
        @media (min-width: 1900px) {
          .image-div-container .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
            top: 9px;
            font-size: 25px; } }
  .image-div-container .image-div .image {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute; }
    .image-div-container .image-div .image img {
      width: 100%; }

.movie-cover a .image-div, a:focus .image-div {
  border: 1px solid #fff; }

.movie .card .image-div-container .image-div {
  outline: 1px solid white;
  outline-offset: -2px; }
  @media (min-width: 3000px) {
    .movie .card .image-div-container .image-div {
      outline-offset: -3px; } }
  @media (min-width: 4500px) {
    .movie .card .image-div-container .image-div {
      outline-offset: -4px; } }
  .movie .card .image-div-container .image-div .movie-title-container {
    position: absolute;
    display: table;
    height: 100%;
    width: 100%;
    padding: 8px; }
    .movie .card .image-div-container .image-div .movie-title-container .movie-title-text {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 12.8px; }
      @media (min-width: 1024px) {
        .movie .card .image-div-container .image-div .movie-title-container .movie-title-text {
          font-size: 16px; } }
      @media (min-width: 1920px) {
        .movie .card .image-div-container .image-div .movie-title-container .movie-title-text {
          font-size: 20px; } }

.pdp-generic-image.pull-right .image-div {
  padding-bottom: inherit;
  background: inherit;
  height: inherit;
  position: relative; }
  .pdp-generic-image.pull-right .image-div .movie-french-tag-outline {
    border-width: 32.5px; }
    .pdp-generic-image.pull-right .image-div .movie-french-tag-outline .movie-french-tag {
      bottom: -32.5px;
      left: -32.5px;
      border-width: 32px; }
      .pdp-generic-image.pull-right .image-div .movie-french-tag-outline .movie-french-tag .movie-french-tag-written {
        top: 4px;
        right: 4px;
        font-size: 16px; }

.card-text {
  margin-top: 0.5625rem;
  display: none; }
  @media (min-width: 900px) {
    .card-text {
      display: block; } }
  .card-text .card-title {
    font-size: 1rem;
    line-height: 1rem;
    color: #A7A8AA;
    visibility: hidden; }
    @media (min-width: 900px) {
      .card-text .card-title {
        visibility: visible; } }
  .card-text .purchase-buttons {
    margin-top: 15px;
    text-transform: uppercase;
    white-space: nowrap; }
    @media (min-width: 900px) {
      .card-text .purchase-buttons {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        vertical-align: middle; } }
    .card-text .purchase-buttons .card-buy-button,
    .card-text .purchase-buttons .card-buy-button-price,
    .card-text .purchase-buttons .purchase-button-divider,
    .card-text .purchase-buttons .card-rent-button,
    .card-text .purchase-buttons .card-rent-button-price {
      visibility: hidden; }
    .card-text .purchase-buttons .card-buy-button,
    .card-text .purchase-buttons .card-rent-button {
      font-size: 0.6rem;
      font-weight: 700; }
      @media (min-width: 1100px) {
        .card-text .purchase-buttons .card-buy-button,
        .card-text .purchase-buttons .card-rent-button {
          font-size: 0.8rem; } }
      @media (min-width: 1350px) {
        .card-text .purchase-buttons .card-buy-button,
        .card-text .purchase-buttons .card-rent-button {
          font-size: 1rem; } }
    .card-text .purchase-buttons .card-buy-button-price,
    .card-text .purchase-buttons .card-rent-button-price,
    .card-text .purchase-buttons .purchase-button-divider {
      font-size: 0.6rem;
      font-weight: 400; }
      @media (min-width: 1100px) {
        .card-text .purchase-buttons .card-buy-button-price,
        .card-text .purchase-buttons .card-rent-button-price,
        .card-text .purchase-buttons .purchase-button-divider {
          font-size: 0.8rem; } }
      @media (min-width: 1350px) {
        .card-text .purchase-buttons .card-buy-button-price,
        .card-text .purchase-buttons .card-rent-button-price,
        .card-text .purchase-buttons .purchase-button-divider {
          font-size: 1rem; } }

@media (min-width: 900px) {
  .movie-card-wrap:hover .movie {
    transform: scale(1.16425); }
    .movie-card-wrap:hover .movie .card {
      text-decoration: none; }
      .movie-card-wrap:hover .movie .card img {
        margin-bottom: 10px; }
      .movie-card-wrap:hover .movie .card .card-text {
        visibility: visible; }
        .movie-card-wrap:hover .movie .card .card-text .card-title {
          /*font-size: 1.25rem;*/
          color: #FFF; }
        .movie-card-wrap:hover .movie .card .card-text .card-title:hover {
          color: #F1C400; }
        .movie-card-wrap:hover .movie .card .card-text .purchase-buttons .card-buy-button,
        .movie-card-wrap:hover .movie .card .card-text .purchase-buttons .card-rent-button,
        .movie-card-wrap:hover .movie .card .card-text .purchase-buttons .card-buy-button-price,
        .movie-card-wrap:hover .movie .card .card-text .purchase-buttons .card-rent-button-price,
        .movie-card-wrap:hover .movie .card .card-text .purchase-buttons .purchase-button-divider {
          visibility: visible; } }

.movie-grid-heading-area {
  padding: 32px 0; }
  @media (min-width: 900px) {
    .movie-grid-heading-area {
      padding-bottom: 3.7125847%; } }
  @media (min-width: 1800px) {
    .movie-grid-heading-area {
      padding-bottom: 4.2%; } }
  .movie-grid-heading-area .movie-grid-title-text {
    font-size: 1.5625rem; }

.movie-card-section-wrapper .movie-card-grid-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap {
    display: inline-block;
    width: 33.33333%; }
    @media (min-width: 600px) {
      .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap {
        width: 25%; } }
    @media (min-width: 900px) {
      .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap {
        width: 16.66667%; } }
    @media (min-width: 1800px) {
      .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap {
        width: 12.5%; } }
    .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap .movie {
      width: 100%;
      display: inline-block;
      padding-bottom: 32px; }
      @media (min-width: 600px) {
        .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap .movie {
          padding-bottom: 36.9182%; } }
      @media (min-width: 900px) {
        .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap .movie {
          padding-bottom: 22.276137%; } }
      @media (min-width: 1800px) {
        .movie-card-section-wrapper .movie-card-grid-wrap .movie-card-wrap .movie {
          padding-bottom: 33.6%; } }

.movie-grid-with-overlay .library-section-title-name {
  font-family: 'Mark OT';
  font-style: normal;
  font-weight: 200;
  line-height: 32px; }
  @media (min-width: 320px) {
    .movie-grid-with-overlay .library-section-title-name {
      font-size: 1rem;
      padding-bottom: 16.19px; } }
  @media (min-width: 600px) {
    .movie-grid-with-overlay .library-section-title-name {
      font-size: 1.25rem;
      padding-bottom: 35.54px; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .library-section-title-name {
      font-size: 1.563rem;
      padding-bottom: 41.4px; } }

.movie-grid-with-overlay.even-vert-pad .movie-card-wrap .movie {
  padding-top: 16px;
  padding-bottom: 0; }
  @media (min-width: 600px) {
    .movie-grid-with-overlay.even-vert-pad .movie-card-wrap .movie {
      padding-top: 32.9506%;
      padding-bottom: 20.64%; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay.even-vert-pad .movie-card-wrap .movie {
      padding-top: 17.805%;
      padding-bottom: 17.805%; } }
  @media (min-width: 1800px) {
    .movie-grid-with-overlay.even-vert-pad .movie-card-wrap .movie {
      padding-top: 26.395%;
      padding-bottom: 16.63289%; } }

.movie-grid-with-overlay.no-scale .movie-card-wrap:hover {
  transform: none; }

.movie-grid-with-overlay.no-scale .movie-card-wrap .movie {
  transform: none; }
  @media (min-width: 900px) {
    .movie-grid-with-overlay.no-scale .movie-card-wrap .movie:hover {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 -8.7805%;
      transform: none;
      width: auto;
      height: 100%; } }
  @media (min-width: 1800px) {
    .movie-grid-with-overlay.no-scale .movie-card-wrap .movie:hover {
      padding-top: 9.76211%;
      padding-bottom: 0; } }

@media (min-width: 600px) and (max-width: 899px) {
  .movie-grid-with-overlay.no-scale.not-touch .movie-card-wrap .movie:hover {
    width: auto;
    margin: 0 -13.79312%;
    padding-top: 12.31043%;
    padding-bottom: 0;
    height: 100%; } }

@media (min-width: 600px) {
  .movie-grid-with-overlay.not-touch .movie:hover .movie-overlay-gradient {
    display: block; }
    .movie-grid-with-overlay.not-touch .movie:hover .movie-overlay-gradient.wl-overlay {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex; } }

.movie-grid-with-overlay .movies-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.movie-grid-with-overlay .movie-card-wrap {
  width: 33.33333%;
  padding-left: 1.72414%;
  padding-right: 1.72414%; }
  @media (min-width: 600px) {
    .movie-grid-with-overlay .movie-card-wrap {
      width: 25%;
      padding-left: 1.72414%;
      padding-right: 1.72414%; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie-card-wrap {
      width: 16.66667%;
      padding-left: 0.81967%;
      padding-right: 0.81967%; } }
  @media (min-width: 1800px) {
    .movie-grid-with-overlay .movie-card-wrap {
      width: 12.5%; } }

.movie-grid-with-overlay .movie {
  padding-bottom: 41.86%; }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie {
      padding-bottom: 35.61%; } }
  @media (min-width: 1800px) {
    .movie-grid-with-overlay .movie {
      padding-bottom: 52.79%; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover {
      transform: scale(1.17561); } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover .movie-overlay-gradient {
      display: block; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover .movie-overlay-gradient.wl-overlay {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover .movie-playback-percent-wrap {
      bottom: -1px; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover .card-text {
      top: -1.19502px; } }
  @media (min-width: 1300px) {
    .movie-grid-with-overlay .movie:hover .card-text {
      top: -2.39004px; } }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie:hover .card-caption {
      top: -4px;
      font-size: 0.72303rem; } }
  @media (min-width: 1300px) {
    .movie-grid-with-overlay .movie:hover .card-caption {
      font-size: 1.06328rem; } }
  @media (min-width: 1800px) {
    .movie-grid-with-overlay .movie:hover .card-caption {
      font-size: 0.85062rem; } }
  .movie-grid-with-overlay .movie .image-div {
    overflow: hidden; }
  .movie-grid-with-overlay .movie .card-text {
    position: relative;
    display: block;
    font-size: 0.85rem;
    padding-top: 8px;
    margin-top: 0; }
    @media (min-width: 1300px) {
      .movie-grid-with-overlay .movie .card-text {
        padding-top: 16px;
        font-size: 1.25rem; } }
  .movie-grid-with-overlay .movie .card-title {
    position: relative;
    display: none;
    font-size: 0.85rem;
    white-space: nowrap;
    width: auto;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.25; }
    @media (min-width: 900px) {
      .movie-grid-with-overlay .movie .card-title {
        display: inline-block; } }
    @media (min-width: 1300px) {
      .movie-grid-with-overlay .movie .card-title {
        font-size: 1.25rem; } }
    @media (min-width: 1800px) {
      .movie-grid-with-overlay .movie .card-title {
        font-size: 1rem; } }
  .movie-grid-with-overlay .movie .card-caption {
    position: relative;
    display: block;
    font-size: 0.85rem;
    line-height: 1;
    height: 1.25rem; }
    @media (min-width: 1300px) {
      .movie-grid-with-overlay .movie .card-caption {
        margin-top: 0.25rem;
        font-size: 1.25rem; } }
    @media (min-width: 1800px) {
      .movie-grid-with-overlay .movie .card-caption {
        margin-top: 0.2rem;
        font-size: 1rem; } }

.movie-grid-with-overlay .movie-overlay-gradient {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 100%;
  width: 100%;
  background: rgba(11, 12, 15, 0.1);
  background: -moz-linear-gradient(bottom, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1));
  background: -webkit-linear-gradient(bottom, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1));
  background: -o-linear-gradient(bottom, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1));
  background: -ms-linear-gradient(bottom, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1));
  background: linear-gradient(bottom top, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1)); }
  .movie-grid-with-overlay .movie-overlay-gradient .mc-rating {
    height: 34px;
    width: 34px;
    font-size: 1rem; }
    @media (min-width: 1800px) {
      .movie-grid-with-overlay .movie-overlay-gradient .mc-rating {
        height: 44px;
        width: 44px; } }
  .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap {
    z-index: 2;
    padding: 32px 16px 16px; }
    @media (min-width: 600px) {
      .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap {
        padding: 32px 16px 12px; } }
    .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap .rmv-wl-btn {
      position: relative;
      top: -4px;
      font-size: 32px; }
      @media (min-width: 1200px) {
        .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap .rmv-wl-btn {
          font-size: 40px; } }
      @media (min-width: 1800px) {
        .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap .rmv-wl-btn {
          font-size: 48px; } }
    .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap .movie-title {
      font-size: 1.0736525660296328108224178655787rem; }
    .movie-grid-with-overlay .movie-overlay-gradient .movie-overlay-info-wrap .preordered-display {
      color: #FFF;
      border-color: #FFF;
      cursor: initial; }
  .movie-grid-with-overlay .movie-overlay-gradient .st-upgrade-to-hd {
    display: block;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 2; }
    @media (min-width: 1800px) {
      .movie-grid-with-overlay .movie-overlay-gradient .st-upgrade-to-hd {
        font-size: 0.80em; } }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay .movie-play-link {
    transform: none;
    position: static;
    display: none; }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay .buy-btn {
    padding-top: 4px;
    padding-bottom: 4px; }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay .movie-overlay-info-wrap .movie-title {
    display: block;
    font-size: 0.8rem; }
    @media (min-width: 1200px) {
      .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay .movie-overlay-info-wrap .movie-title {
        font-size: 1rem; } }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay.purchased .movie-play-link {
    display: block; }
    @media (min-width: 1800px) {
      .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay.purchased .movie-play-link {
        height: 120px;
        width: 120px;
        font-size: 120px; } }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay.purchased .buy-btn {
    display: none; }
  .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay.purchased.not-preorder .movie-overlay-info-wrap {
    z-index: 2;
    padding: 32px 16px 32px; }
    @media (min-width: 1200px) {
      .movie-grid-with-overlay .movie-overlay-gradient.wl-overlay.purchased.not-preorder .movie-overlay-info-wrap {
        padding: 32px 16px 48px; } }

.movie-grid-with-overlay .movie-playback-percent-wrap {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  background: rgba(32, 39, 51, 0.8);
  height: 8px; }
  @media (min-width: 900px) {
    .movie-grid-with-overlay .movie-playback-percent-wrap {
      height: 16px; } }
  .movie-grid-with-overlay .movie-playback-percent-wrap .movie-playback-percentage {
    position: absolute;
    left: 0;
    height: 100%;
    background-color: #6e96db; }

.movie-grid-with-overlay .movie-play-link {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  font-size: 99px;
  height: 99px;
  width: 99px;
  z-index: 2; }

.movie-grid-with-overlay .movie-pdp-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.mc-rating {
  height: 42px;
  width: 42px;
  background: rgba(80, 164, 27, 0.8); }

.mpaa-rating {
  font-weight: bold;
  border: solid 1px #a7a8aa;
  padding: 1px 5px;
  color: #d8d8d8; }

.quality-box {
  font-size: 0.8rem;
  line-height: 1;
  border: 1px solid #a7a8aa;
  padding: 0.3125rem 0 0.1875rem;
  width: 2.25rem; }
  @media (min-width: 900px) {
    .quality-box {
      font-size: 1rem;
      padding: 0.3125rem 0;
      width: 2.625rem; } }

.cta-btn {
  font-size: 0.8rem;
  padding: 6px 24px 4px; }
  @media (min-width: 900px) {
    .cta-btn {
      font-size: 1rem;
      padding: 11px 32px 10px; } }
  @media (min-width: 1800px) {
    .cta-btn {
      padding: 14px 32px 13px; } }

.movie-grid-filters {
  width: 100%;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 2rem; }
  @media (min-width: 600px) {
    .movie-grid-filters {
      margin-top: 20px;
      height: 2.5rem; } }
  @media (min-width: 900px) {
    .movie-grid-filters {
      width: auto;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 1rem; } }
  @media (min-width: 1800px) {
    .movie-grid-filters {
      height: 2rem;
      margin-bottom: 0; } }
  @media (min-width: 600px) {
    .movie-grid-filters .filter-items {
      height: 2.5rem; } }
  @media (min-width: 1800px) {
    .movie-grid-filters .filter-items {
      height: 2rem; } }
  .movie-grid-filters .filters-wrap {
    display: inline-block;
    width: 100%; }
    @media (min-width: 600px) {
      .movie-grid-filters .filters-wrap {
        width: 26.75rem; } }
    @media (min-width: 1800px) {
      .movie-grid-filters .filters-wrap {
        width: 21.4rem; } }
  .movie-grid-filters .filter-wrap {
    position: relative;
    width: 50%;
    margin: 0 auto;
    font-size: 0.8rem; }
    @media (min-width: 600px) {
      .movie-grid-filters .filter-wrap {
        font-size: 1rem; } }
    @media (min-width: 1800px) {
      .movie-grid-filters .filter-wrap {
        font-size: 0.8rem; } }
    .movie-grid-filters .filter-wrap .checkbox-btn {
      padding: 4px 0; }
      @media (min-width: 600px) {
        .movie-grid-filters .filter-wrap .checkbox-btn {
          padding: 9px 0; } }

.empty-scaling-icon {
  font-size: 3.125rem; }
  @media (min-width: 900px) {
    .empty-scaling-icon {
      font-size: 6rem; } }

.large-play-btn {
  font-size: 64px; }
  @media (min-width: 1800px) {
    .large-play-btn {
      font-size: 90px; } }

/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1500;
  background-color: #270735;
  color: #ffffff;
  padding-bottom: 25px;
  padding-top: 25px; }
  #outdated .outdated-title {
    font-size: 32px;
    padding-right: 30px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 0; }
  #outdated .outdated-prompt {
    font-size: 24px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 0; }
  * html #outdated {
    position: absolute; }

/*TEMPLATE STYLES*/
.unfixed-nav .nav-wrapper .navbar.navbar-default {
  border: none; }

.nav-wrapper .dropdown-open-backdrop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -50vw;
  width: 200vw;
  height: 100vh;
  opacity: 0.95;
  background-color: rgba(10, 11, 13, 0.8);
  z-index: 991; }
  @media (min-width: 1050px) {
    .nav-wrapper .dropdown-open-backdrop.in {
      display: block;
      z-index: 992; } }

.align-search-language-login {
  right: 0;
  overflow: visible;
  width: 100%;
  display: -webkit-inline-box;
  position: absolute;
  display: flex !important;
  justify-content: flex-end;
  padding-right: 5vw; }

@media (max-width: 1049px) {
  .visible-below-md {
    display: block !important; } }

@media (min-width: 1050px) {
  .visible-below-md {
    display: none !important; } }

@media (max-width: 1049px) {
  .visible-above-md {
    display: none !important; } }

@media (min-width: 1050px) {
  .visible-above-md {
    display: block !important; } }

.screenreader-text {
  font-family: 'Mark OT';
  font-style: normal;
  position: absolute;
  z-index: 999;
  overflow: hidden;
  width: 1px;
  height: 1px;
  text-align: center; }
  .screenreader-text:focus {
    font-family: 'Mark OT' !important;
    font-style: normal !important;
    font-size: 10px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    width: 92px;
    height: 38px;
    left: 11vw;
    top: 14px;
    outline: none;
    overflow: visible !important; }
    @media (min-width: 1866px) {
      .screenreader-text:focus {
        left: 9vw;
        top: 0.98vw; } }
    @media (min-width: 3360px) {
      .screenreader-text:focus {
        left: 7vw;
        top: 0.58vw; } }
    @media (min-width: 4500px) {
      .screenreader-text:focus {
        left: 5.7vw;
        top: 0.45vw; } }
    @media (min-width: 600px) and (max-width: 1049px) {
      .screenreader-text:focus {
        width: 135px;
        height: 38px;
        left: 105px;
        top: 10px; }
        .screenreader-text:focus.fr {
          top: 14px; } }
    @media (max-width: 599px) {
      .screenreader-text:focus {
        width: 135px;
        height: 38px;
        font-size: 8px;
        line-height: 10px;
        left: 145px;
        top: 10px; }
        .screenreader-text:focus.fr {
          top: 14px; } }
    @media (max-width: 420px) {
      .screenreader-text:focus {
        width: 135px;
        height: 38px;
        font-size: 8px;
        line-height: 10px;
        left: 110px;
        top: 10px; }
        .screenreader-text:focus.fr {
          top: 14px; } }

.navbar.navbar-default {
  background: #05327D;
  background: linear-gradient(180deg, #05327D 1.85%, rgba(5, 50, 125, 0.701613) 51.09%, rgba(5, 50, 125, 0.453985) 80.63%, rgba(5, 50, 125, 0) 121.25%); }
  .navbar.navbar-default.primary-nav-scrolled, .navbar.navbar-default.search-open {
    background: #05327D;
    background: linear-gradient(180deg, #05327D 1.85%, rgba(5, 50, 125, 0.701613) 51.09%, rgba(5, 50, 125, 0.453985) 80.63%, rgba(5, 50, 125, 0) 121.25%); }

.navbar-brand {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translatex(-50%);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }
  @media (min-width: 1050px) {
    .navbar-brand {
      transform: none;
      left: 0; } }
  .navbar-brand a, .navbar-brand img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%; }
  .navbar-brand.navbar-brand-mobile {
    margin-top: 5px; }

.navbar-brand-mobile {
  position: absolute;
  top: 0.8rem;
  left: 25%;
  -webkit-transform: translateX(-50%);
  transform: translatex(-50%);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding-right: 2.1vw; }
  .navbar-brand-mobile a, .navbar-brand-mobile img {
    display: inline-block;
    height: 28px; }

.primary-nav {
  transition: height linear 0.25s;
  height: 4rem;
  z-index: 993; }
  .primary-nav.primary-nav-dropdown-open {
    background: #00358e;
    background-image: linear-gradient(to bottom, #00358e, rgba(27, 49, 113, 0.1)); }
    .primary-nav.primary-nav-dropdown-open > .navbar-toggle {
      display: none; }
    @media (min-width: 1050px) {
      .primary-nav.primary-nav-dropdown-open .dropdown-open-backdrop {
        display: block; } }
    .primary-nav.primary-nav-dropdown-open .dropdown-open-menu-gradient {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4rem;
      background: #00358e;
      background: linear-gradient(to bottom, #00358e, rgba(27, 49, 113, 0.1));
      z-index: 993; }
      @media (min-width: 1050px) {
        .primary-nav.primary-nav-dropdown-open .dropdown-open-menu-gradient {
          display: block; } }
  @media (min-width: 1050px) {
    .primary-nav.search-open.search-open {
      height: 10rem; } }
  @media (max-width: 1049px) {
    .primary-nav.search-open.search-open > * {
      display: none; } }
  .primary-nav.search-open.search-open .nav-search-dropdown {
    display: block; }
  .primary-nav.search-open.search-open .primary-nav-search {
    display: block; }
    .primary-nav.search-open.search-open .primary-nav-search .search-open {
      display: none;
      z-index: 991; }
      @media (min-width: 1050px) {
        .primary-nav.search-open.search-open .primary-nav-search .search-open {
          display: block; } }
  .primary-nav .navbar-nav.nav .menu-item.connect-logged-in-only {
    display: none; }
  .primary-nav .navbar-nav.nav .menu-item .store-icon-down {
    font-size: 0.8rem;
    padding-left: 8px;
    display: none; }
    @media (min-width: 1050px) {
      .primary-nav .navbar-nav.nav .menu-item .store-icon-down {
        display: inline; } }
  .primary-nav .navbar-nav {
    margin-top: 0px; }
    .primary-nav .navbar-nav.navbar-collapse {
      background: #00358e;
      background: linear-gradient(to bottom, #00358e, #560075);
      width: 100% !important;
      height: 100vh;
      max-height: unset;
      transition: all 0.25s linear;
      left: -100%;
      position: fixed; }
      @media (min-width: 600px) {
        .primary-nav .navbar-nav.navbar-collapse {
          left: -52%;
          width: 52% !important;
          transition-duration: 0.1s; } }
      @media (min-width: 1050px) {
        .primary-nav .navbar-nav.navbar-collapse {
          background: transparent;
          position: static;
          width: auto !important;
          height: 4rem; } }
      .primary-nav .navbar-nav.navbar-collapse.in {
        left: 0; }
      .primary-nav .navbar-nav.navbar-collapse .mobile-nav-logo img {
        margin-top: 8px;
        margin-left: 50vw;
        transform: translate(-50%, 0); }
    .primary-nav .navbar-nav > li:last-of-type > a {
      padding-right: 0; }
    .primary-nav .navbar-nav > li > a {
      text-transform: uppercase;
      font-size: 0.8em;
      padding-left: 0;
      padding-right: 2.1vw;
      line-height: 1rem;
      outline: none; }
      .primary-nav .navbar-nav > li > a.current {
        color: #F1C400;
        font-weight: 700; }
  @media (min-width: 1050px) {
    .primary-nav .navbar-brand {
      position: relative;
      z-index: 994; } }
  .primary-nav .primary-nav-search {
    padding: 1rem 0;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    z-index: 0; }
    @media (min-width: 1050px) {
      .primary-nav .primary-nav-search {
        z-index: 994;
        padding: 1rem 1.6667vw 0 0; } }
    .primary-nav .primary-nav-search a {
      display: block;
      text-decoration: none;
      color: #FFF;
      height: auto; }
      .primary-nav .primary-nav-search a:hover, .primary-nav .primary-nav-search a:focus {
        color: #F1C400;
        outline: none; }
      .primary-nav .primary-nav-search a.current {
        color: #F1C400; }
    .primary-nav .primary-nav-search .search-open {
      color: #F1C400; }
    .primary-nav .primary-nav-search .store-icon-search {
      display: inline-block;
      font-size: 2rem;
      vertical-align: top;
      font-weight: normal;
      line-height: 0.9; }
    .primary-nav .primary-nav-search .search-text {
      font-size: 0.8em;
      line-height: 1rem;
      text-transform: uppercase;
      display: none;
      vertical-align: top; }
      @media (min-width: 1350px) {
        .primary-nav .primary-nav-search .search-text {
          display: inline-block;
          padding-top: 0.5rem; } }
  .primary-nav li {
    z-index: 994; }
    .primary-nav li.dropdown {
      position: static; }
      .primary-nav li.dropdown .dropdown-toggle {
        z-index: 994; }
  .primary-nav .nav-search-dropdown {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: opacity linear 0.25s;
    text-align: center;
    background: rgba(5, 50, 125, 0.701613);
    background: linear-gradient(to bottom, rgba(5, 50, 125, 0.701613), rgba(5, 50, 125, 0));
    padding: 0.75rem 0; }
    @media (max-width: 1049px) {
      .primary-nav .nav-search-dropdown {
        z-index: 991;
        background: rgba(5, 50, 125, 0.701613) !important; } }
    @media (min-width: 520px) {
      .primary-nav .nav-search-dropdown {
        padding: 0.75rem 3rem; } }
    @media (min-width: 1050px) {
      .primary-nav .nav-search-dropdown {
        height: 2rem;
        top: 5rem;
        background: transparent;
        padding: 0; } }
    .primary-nav .nav-search-dropdown.fadeIn {
      opacity: 1; }
    .primary-nav .nav-search-dropdown .search-area-wrap {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding: 0 0.5rem;
      white-space: nowrap; }
      @media (min-width: 1050px) {
        .primary-nav .nav-search-dropdown .search-area-wrap {
          width: 65.104166666666666666666%; } }
      .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper {
        position: relative;
        display: inline-block;
        width: calc(100% - 3rem);
        vertical-align: top; }
        .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .store-icon-search {
          position: absolute;
          left: 0px;
          top: 0px;
          padding-left: 10px;
          padding-top: 0.6rem;
          font-size: 1.25rem; }
          @media (min-width: 1050px) {
            .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .store-icon-search {
              padding-top: 1rem; } }
          @media (min-width: 1800px) {
            .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .store-icon-search {
              font-weight: 700;
              color: #A7A8AA; } }
        .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input {
          padding-left: 2.5rem;
          padding-right: 2.25rem;
          height: 2.75rem;
          background-color: #202733;
          font-size: 1.25rem; }
          @media (min-width: 1050px) {
            .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input {
              height: 3.5rem; } }
          .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-size: 1.25rem;
            color: #A7A8AA; }
          .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input ::-moz-placeholder {
            /* Firefox 19+ */
            font-size: 1.25rem;
            color: #A7A8AA; }
          .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input :-ms-input-placeholder {
            /* IE 10+ */
            font-size: 1.25rem;
            color: #A7A8AA; }
          .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-input :-moz-placeholder {
            /* Firefox 18- */
            font-size: 1.25rem;
            color: #A7A8AA; }
        .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-submit {
          position: absolute;
          right: 0;
          top: 0rem;
          background: transparent;
          background-image: none;
          height: 100%;
          padding: 0.5rem 0;
          width: 2rem;
          font-size: 1.5rem;
          border-width: 0;
          vertical-align: baseline;
          line-height: 1; }
          @media (min-width: 1050px) {
            .primary-nav .nav-search-dropdown .search-area-wrap .search-form-wrapper .nav-search-submit {
              width: 2.25rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              padding-right: 0.25rem; } }
      .primary-nav .nav-search-dropdown .search-area-wrap .nav-search-close {
        display: inline-block;
        width: 3rem;
        text-align: right;
        vertical-align: top; }
        .primary-nav .nav-search-dropdown .search-area-wrap .nav-search-close .store-icon-close {
          display: inline-block;
          padding: 0.375rem 0.5rem;
          font-size: 2rem; }
          @media (min-width: 1050px) {
            .primary-nav .nav-search-dropdown .search-area-wrap .nav-search-close .store-icon-close {
              padding: 0.75rem 0.5rem 0.75rem;
              /*font-size: 1.5rem;*/ } }
  .primary-nav .open .dropdown-menu {
    border-width: 0;
    opacity: 1; }
    @media (min-width: 1050px) {
      .primary-nav .open .dropdown-menu {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        max-height: 600px;
        background: #00358e;
        background: linear-gradient(114deg, #00358e, #560075);
        padding: 5rem 3.125% 0;
        border-bottom: solid 1px #576DB3;
        z-index: 992; } }
    .primary-nav .open .dropdown-menu .subnav-heading {
      display: none; }
    .primary-nav .open .dropdown-menu img {
      display: block; }
    .primary-nav .open .dropdown-menu .non-ft-menu-items {
      margin-left: 0;
      padding-left: 0; }
      @media (min-width: 1050px) {
        .primary-nav .open .dropdown-menu .non-ft-menu-items {
          display: block;
          float: left;
          width: 16.666666666666666666666666666667%;
          padding: 0 1.63934% 0 0;
          margin-left: 0px; } }
    @media (min-width: 1050px) {
      .primary-nav .open .dropdown-menu li.ft-menu-item {
        float: right;
        width: 32.5136612%;
        max-width: 432px;
        padding-bottom: 64px; }
        .primary-nav .open .dropdown-menu li.ft-menu-item a {
          width: 100%;
          padding-bottom: 100%;
          height: 0px;
          white-space: normal;
          position: relative; }
          .primary-nav .open .dropdown-menu li.ft-menu-item a .ft-menu-img {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 991; }
          .primary-nav .open .dropdown-menu li.ft-menu-item a .ft-menu-load-img {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
          .primary-nav .open .dropdown-menu li.ft-menu-item a .ft-menu-text {
            position: absolute;
            bottom: 0;
            z-index: 991;
            margin-left: 0;
            left: 15px;
            padding-bottom: 2rem;
            margin-bottom: 0; } }
    @media (min-width: 1050px) {
      .primary-nav .open .dropdown-menu li.ft-1-col-menu-item, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item {
        padding-bottom: 40px; } }
    .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a {
      width: 100%;
      display: block; }
      .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a:hover, .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a:focus, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a:hover, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a:focus {
        font-weight: 400; }
        .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a:hover .ft-col-img-wrap, .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a:focus .ft-col-img-wrap, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a:hover .ft-col-img-wrap, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a:focus .ft-col-img-wrap {
          opacity: 1;
          border: 1px solid #F1C400; }
      .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-col-img-wrap, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap {
        display: none;
        max-width: 153px;
        position: relative;
        opacity: 0.8;
        margin-right: 26.13334620769565007483223096606%;
        margin-bottom: 0; }
        @media (min-width: 1050px) {
          .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-col-img-wrap, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap {
            display: block;
            margin-bottom: 24px; } }
        .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-col-img-wrap::before, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap::before {
          content: '';
          width: 100%;
          padding-bottom: 150.326797385621%;
          display: block; }
        .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-col-img-wrap .ft-col-menu-loading-img, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap .ft-col-menu-loading-img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%; }
        .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-col-img-wrap .ft-col-menu-img, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap .ft-col-menu-img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 991; }
      .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-menu-text, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-menu-text {
        white-space: normal;
        font-size: 1rem;
        line-height: 1.25; }
        @media (min-width: 1050px) {
          .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .ft-menu-text, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-menu-text {
            margin-bottom: 0.5rem; } }
      .primary-nav .open .dropdown-menu li.ft-1-col-menu-item a .menu-desc, .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .menu-desc {
        display: block;
        white-space: normal;
        font-size: 0.8rem;
        text-transform: none; }
    .primary-nav .open .dropdown-menu li.ft-2-col-menu-item {
      margin-left: 0; }
      @media (min-width: 1050px) {
        .primary-nav .open .dropdown-menu li.ft-2-col-menu-item {
          width: 32.5136612%;
          float: left; }
          .primary-nav .open .dropdown-menu li.ft-2-col-menu-item:nth-child(2) {
            margin-left: 15px; } }
      .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a {
        width: 100%; }
        .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap {
          display: block;
          margin-left: 0;
          margin-right: 0;
          max-width: unset;
          max-width: none; }
          @media (min-width: 1050px) {
            .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap {
              max-width: 435px; } }
          .primary-nav .open .dropdown-menu li.ft-2-col-menu-item a .ft-col-img-wrap::before {
            content: "";
            width: 100%;
            padding-bottom: 52.752293577981651376146788990826%; }
    .primary-nav .open .dropdown-menu li .menu-text {
      white-space: normal;
      font-weight: 400; }
    .primary-nav .open .dropdown-menu a {
      padding: 0 0 2rem;
      text-transform: uppercase;
      color: #FFF;
      line-height: 0.8rem;
      font-size: 0.8rem;
      display: inline-block; }
      @media (min-width: 1200px) {
        .primary-nav .open .dropdown-menu a {
          line-height: 1rem;
          font-size: 1rem; } }
      @media (min-width: 900px) {
        .primary-nav .open .dropdown-menu a:hover, .primary-nav .open .dropdown-menu a:focus {
          color: #F1C400;
          background: transparent;
          outline: none; }
        .primary-nav .open .dropdown-menu a:active {
          color: #F1C400; } }
  .primary-nav .nav-user {
    float: right;
    width: auto;
    height: 4rem;
    padding-left: 0.5rem; }
    @media (min-width: 400px) {
      .primary-nav .nav-user {
        padding-left: 1.25rem; } }
    @media (min-width: 1050px) {
      .primary-nav .nav-user {
        max-width: 18.66666666666666666666666667%;
        padding-left: 0; } }
  .primary-nav .nav-language-toggle {
    display: inline-block;
    margin-left: -0.25rem;
    margin-right: 0.5rem;
    position: relative;
    z-index: 994; }
    @media (max-width: 1049px) {
      .primary-nav .nav-language-toggle {
        display: none; } }
    @media (min-width: 1350px) {
      .primary-nav .nav-language-toggle {
        margin-left: 0rem;
        margin-right: 0rem;
        padding-right: 1rem; } }
    @media (min-width: 1550px) {
      .primary-nav .nav-language-toggle {
        padding-right: 0.25rem; } }
    .primary-nav .nav-language-toggle a {
      color: #fff;
      display: block;
      text-decoration: none;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.8rem;
      line-height: 1rem; }
    .primary-nav .nav-language-toggle a:focus, .primary-nav .nav-language-toggle a:hover {
      color: #f1c400;
      background-color: transparent;
      outline: none; }

.promoMembership-msg {
  font-family: MarkOT;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }
  .promoMembership-msg p {
    margin: 0px; }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .promoMembership-msg {
      font-size: 16px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .promoMembership-msg {
      font-size: 12.8px;
      opacity: 0.8; } }
  @media (max-width: 767px) {
    .promoMembership-msg {
      font-size: 12.8px;
      line-height: 1.41;
      opacity: 0.8;
      width: 285px; } }

.nav-user {
  text-align: right; }
  @media (min-width: 1050px) {
    .nav-user {
      z-index: 995;
      position: relative; } }
  .nav-user .nav-primary--account {
    float: right; }
  .nav-user ul {
    list-style: none;
    padding: 0; }
    .nav-user ul:before, .nav-user ul:after {
      content: '';
      display: table;
      clear: both; }
  .nav-user .loginSignUp {
    font-size: 0.8rem; }
    @media (min-width: 1050px) {
      .nav-user .loginSignUp {
        font-size: 1rem; } }
    .nav-user .loginSignUp:first-child {
      float: none;
      line-height: 1rem;
      padding: 0.5rem 1.5rem 0.4rem 1.5rem;
      width: auto;
      border: solid 0.5px #ffffff;
      margin-top: 0.9375rem;
      color: #FFF;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      height: auto;
      font-size: 0.8rem; }
      @media (min-width: 1800px) {
        .nav-user .loginSignUp:first-child {
          margin-top: 1rem;
          padding-top: 0.49rem; } }
      .nav-user .loginSignUp:first-child:hover, .nav-user .loginSignUp:first-child:focus {
        color: #F1C400;
        background-color: transparent;
        border: solid 0.5px #F1C400; }
      .nav-user .loginSignUp:first-child:active {
        color: #F1C400;
        background-color: transparent;
        border: solid 0.5px #F1C400; }
    .nav-user .loginSignUp:last-child {
      display: none; }
  .nav-user .menu-account {
    text-align: left;
    cursor: pointer;
    float: right;
    padding: 0;
    height: 4rem; }
    @media (min-width: 1050px) {
      .nav-user .menu-account {
        padding: 0 0 0 1.6667vw; } }
    @media (min-width: 1050px) {
      .nav-user .menu-account {
        height: auto; } }
    .nav-user .menu-account::before {
      content: " ";
      display: table; }
    .nav-user .menu-account::after {
      content: " ";
      display: table; }
    .nav-user .menu-account > a {
      display: block;
      float: right; }
      @media (min-width: 1050px) {
        .nav-user .menu-account > a {
          height: auto; } }
      .nav-user .menu-account > a::before {
        content: " ";
        display: table; }
      .nav-user .menu-account > a::after {
        content: " ";
        display: table; }
      .nav-user .menu-account > a:focus {
        color: #FFF; }
      @media (min-width: 1050px) {
        .nav-user .menu-account > a:hover {
          color: #F1C400; }
          .nav-user .menu-account > a:hover .profilePicture-RR {
            color: #F1C400;
            border: 2px solid #F1C400;
            background-color: transparent; }
            .nav-user .menu-account > a:hover .profilePicture-RR .profilePictureInitials {
              color: #F1C400; }
          .nav-user .menu-account > a:hover .userInfo #scenePointPlaceholder {
            color: #F1C400; }
          .nav-user .menu-account > a:hover .icon-arrow-down {
            color: #F1C400; } }
    .nav-user .menu-account.connect-dropdown-open {
      position: absolute;
      z-index: 992001;
      height: 100vh;
      width: 93.75vw;
      top: 0;
      right: 0;
      background: linear-gradient(139deg, rgba(0, 53, 142, 0.95), rgba(86, 0, 117, 0.95));
      /*@media(min-width: $navbar-scene-hidden-breakpoint) {
                position: relative;
                width: auto;
                margin-right: -3.25vw;
                padding-right: 3.25vw;
            }*/ }
      @media (min-width: 600px) {
        .nav-user .menu-account.connect-dropdown-open {
          width: 50vw; } }
      @media (min-width: 1050px) {
        .nav-user .menu-account.connect-dropdown-open {
          position: static;
          background: transparent;
          width: auto;
          height: auto;
          /*width: 26vw;
                height: auto;
                padding-right: 3.25vw;
                border-bottom: solid 1px rgba(110, 105, 219, 0.57);*/ } }
      .nav-user .menu-account.connect-dropdown-open > a {
        position: relative;
        z-index: 991;
        width: 100%;
        background: rgba(26, 34, 50, 0.3);
        margin-top: 4rem;
        margin-bottom: 12px;
        padding: 32px 6.25vw 32px 30px; }
        @media (min-width: 320px) {
          .nav-user .menu-account.connect-dropdown-open > a {
            margin-bottom: 2px; } }
        @media (min-width: 900px) {
          .nav-user .menu-account.connect-dropdown-open > a {
            padding-right: 3.125vw; } }
        @media (min-width: 1050px) {
          .nav-user .menu-account.connect-dropdown-open > a {
            width: auto;
            padding: 0;
            margin-top: 0;
            background: transparent;
            z-index: 996; } }
      .nav-user .menu-account.connect-dropdown-open #ccoptions {
        display: block !important; }
        @media (min-width: 1050px) {
          .nav-user .menu-account.connect-dropdown-open #ccoptions {
            position: absolute;
            right: -5vw;
            z-index: 995;
            width: 26vw;
            height: auto;
            padding-right: 3.25vw;
            border-bottom: solid 1px rgba(110, 105, 219, 0.57);
            background: linear-gradient(139deg, rgba(0, 53, 142, 0.95), rgba(86, 0, 117, 0.95)); } }
      @media (max-width: 1049px) {
        .nav-user .menu-account.connect-dropdown-open .picture-icon-container {
          display: block; }
        .nav-user .menu-account.connect-dropdown-open .profilePicture-RR {
          display: block; }
        .nav-user .menu-account.connect-dropdown-open .userInfo {
          display: block;
          width: 100%;
          text-align: right; }
          .nav-user .menu-account.connect-dropdown-open .userInfo:before {
            display: block;
            position: absolute;
            top: -2.5rem;
            right: 6.25vw;
            font-family: 'CineplexStoreFont';
            font-style: normal;
            line-height: 1rem;
            font-size: 24px;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\78"; } }
    @media (max-width: 1049px) and (min-width: 900px) {
      .nav-user .menu-account.connect-dropdown-open .userInfo:before {
        right: 3.125vw; } }
      @media (max-width: 1049px) {
          .nav-user .menu-account.connect-dropdown-open .userInfo:after {
            display: none; }
          .nav-user .menu-account.connect-dropdown-open .userInfo .name-label {
            display: block;
            width: 100%;
            white-space: nowrap;
            margin-right: 0;
            text-overflow: ellipsis;
            max-width: 100%; }
          .nav-user .menu-account.connect-dropdown-open .userInfo #scenePointPlaceholder {
            display: block;
            width: auto;
            margin: 0;
            white-space: nowrap; }
        .nav-user .menu-account.connect-dropdown-open #ccoptions {
          padding-left: 30px;
          text-align: right;
          width: 100%; } }
      .nav-user .menu-account.connect-dropdown-open .picture-icon-container {
        margin-bottom: 8px; }
        .nav-user .menu-account.connect-dropdown-open .picture-icon-container:before {
          color: #F1C400; }
      .nav-user .menu-account.connect-dropdown-open .profilePicture-RR:before {
        color: #F1C400; }
    .nav-user .menu-account .picture-icon-container {
      border: none;
      overflow: visible;
      float: right;
      margin-top: 0;
      margin-right: 0;
      display: none; }
      @media (min-width: 1050px) {
        .nav-user .menu-account .picture-icon-container {
          display: inline-block;
          width: 33px !important;
          height: 33px !important;
          overflow: hidden;
          -webkit-border-radius: 17px;
          -moz-border-radius: 17px;
          -ms-border-radius: 17px;
          -o-border-radius: 17px;
          border-radius: 17px;
          float: left;
          padding-top: 0;
          margin-right: 10px;
          margin-top: 1rem;
          background-color: #FFF; }
          .nav-user .menu-account .picture-icon-container img {
            display: block; } }
      .nav-user .menu-account .picture-icon-container img {
        display: none; }
    .nav-user .menu-account .profilePicture-RR {
      background: transparent;
      border: none;
      width: 33px !important;
      height: 33px !important;
      margin: 0 0 8px 0;
      margin-top: 0;
      margin-right: 0;
      display: none;
      float: right;
      border: 2px solid #FFF;
      border-radius: 50%; }
      @media (min-width: 1050px) {
        .nav-user .menu-account .profilePicture-RR {
          display: inline-block;
          margin-right: 10px;
          margin-top: 14px;
          float: left; } }
      @media (min-width: 1800px) {
        .nav-user .menu-account .profilePicture-RR {
          margin-top: 1.1rem; } }
      .nav-user .menu-account .profilePicture-RR .profilePictureInitials {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: top;
        padding-top: 21%; }
        @media (min-width: 1800px) {
          .nav-user .menu-account .profilePicture-RR .profilePictureInitials {
            padding-top: 18%; } }
    .nav-user .menu-account .userInfo {
      display: block;
      float: left; }
      @media (min-width: 1050px) {
        .nav-user .menu-account .userInfo {
          display: block; } }
      .nav-user .menu-account .userInfo:after {
        content: "\e906";
        display: inline-block;
        font-family: 'CineplexStoreFont';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 2rem;
        margin-top: 1rem; }
        @media (min-width: 1050px) {
          .nav-user .menu-account .userInfo:after {
            margin-top: 1rem; } }
        @media (min-width: 1050px) {
          .nav-user .menu-account .userInfo:after {
            display: none; } }
      .nav-user .menu-account .userInfo .name-label {
        display: none; }
      .nav-user .menu-account .userInfo #scenePointPlaceholder {
        display: none;
        font-size: 0.8rem;
        font-weight: 300;
        width: auto;
        max-width: none;
        text-wrap: none;
        white-space: nowrap;
        text-transform: uppercase;
        margin-top: 1.8125rem;
        line-height: 1rem;
        cursor: pointer; }
        @media (min-width: 1050px) {
          .nav-user .menu-account .userInfo #scenePointPlaceholder {
            margin-top: 1.1rem; } }
        @media (min-width: 1400px) {
          .nav-user .menu-account .userInfo #scenePointPlaceholder {
            display: inline-block; } }
    .nav-user .menu-account .icon-arrow-down {
      display: none;
      float: left;
      outline: none;
      margin-left: 0.5em;
      margin-top: 1.625rem;
      font-family: 'CineplexStoreFont';
      font-style: normal;
      color: inherit;
      line-height: 1;
      font-size: 0.8rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      @media (min-width: 1050px) {
        .nav-user .menu-account .icon-arrow-down {
          display: block;
          margin-top: 1.5rem;
          padding-top: 1px; } }
      .nav-user .menu-account .icon-arrow-down:before {
        content: '\e900'; }
    .nav-user .menu-account #ccoptions {
      display: none !important;
      padding-right: 6.25vw; }
      @media (min-width: 900px) {
        .nav-user .menu-account #ccoptions {
          padding-right: 3.125vw; } }
      @media (min-width: 1050px) {
        .nav-user .menu-account #ccoptions {
          height: auto;
          top: 0;
          position: relative;
          background: transparent;
          padding: 0; }
          .nav-user .menu-account #ccoptions::before {
            display: none; } }
      .nav-user .menu-account #ccoptions .dropdown-container--list {
        padding-top: 1.5rem; }
        @media (min-width: 1050px) {
          .nav-user .menu-account #ccoptions .dropdown-container--list {
            padding-top: 5rem;
            padding-bottom: 80px; } }
        .nav-user .menu-account #ccoptions .dropdown-container--list li {
          white-space: nowrap;
          padding-top: 30px; }
          @media (min-width: 1050px) {
            .nav-user .menu-account #ccoptions .dropdown-container--list li {
              padding: 16px 0;
              max-width: 100%;
              text-align: right; } }
          .nav-user .menu-account #ccoptions .dropdown-container--list li > a {
            font-size: 1rem;
            text-transform: uppercase;
            white-space: normal; }
  .nav-user .dropdown-container {
    display: none; }

.navbar-toggle.collapsed {
  position: absolute;
  float: left;
  z-index: 992000;
  padding-top: 1.25rem;
  padding-left: 6.25%;
  border: 0;
  top: 0;
  left: 0; }
  .navbar-toggle.collapsed .store-icon-menu {
    display: block;
    font-size: 1.5rem; }
  .navbar-toggle.collapsed .store-icon-close {
    display: none; }

.navbar-toggle {
  position: absolute;
  float: left;
  z-index: 991000;
  padding-top: 1.25rem;
  padding-left: 6.51042%;
  border: 0;
  top: 0;
  left: 0; }
  .navbar-toggle.close-button {
    z-index: 1000; }
  @media (min-width: 600px) {
    .navbar-toggle {
      position: fixed;
      left: 0;
      padding-left: 6.25%; } }
  .navbar-toggle .store-icon-menu {
    display: none; }
  .navbar-toggle .store-icon-close {
    display: block;
    font-size: 1.5rem; }

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: transparent; }

.navbar-fixed-top.navbar-collapse {
  max-height: none; }

@media (min-width: 1050px) {
  #navbar.navbar-nav.navbar-collapse .nav-language-toggle {
    display: none; } }

@media (max-width: 1049px) {
  #navbar.navbar-nav.navbar-collapse {
    background: #00358e;
    background: linear-gradient(to bottom, #00358e, #560075);
    width: 93.75vw !important;
    height: 100vh;
    max-height: unset;
    transition: all 0.25s linear;
    left: -100%;
    position: fixed;
    margin: 0;
    padding: 0 0 80px; } }
  @media (max-width: 1049px) and (min-width: 600px) {
    #navbar.navbar-nav.navbar-collapse {
      left: -50vw;
      width: 50vw !important;
      transition-duration: 0.1s; } }

@media (max-width: 1049px) {
    #navbar.navbar-nav.navbar-collapse.in {
      left: 0;
      z-index: 1; }
    #navbar.navbar-nav.navbar-collapse .mobile-nav-logo img {
      margin-top: 8px;
      margin-left: 50vw;
      transform: translate(-50%, 0); }
    #navbar.navbar-nav.navbar-collapse .nav-language-toggle {
      display: block;
      padding-top: 4em; }
      #navbar.navbar-nav.navbar-collapse .nav-language-toggle a {
        display: block; }
    #navbar.navbar-nav.navbar-collapse .navbar-nav.nav {
      margin-left: 0;
      margin-top: 3rem; }
      #navbar.navbar-nav.navbar-collapse .navbar-nav.nav li a {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        padding-right: 20px;
        padding-left: 6.51042%;
        padding-top: 2rem;
        padding-bottom: 0;
        font-size: 1rem; } }
      @media (max-width: 1049px) and (min-width: 600px) {
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav li a {
          padding-top: 2rem;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 12.5%;
          padding-right: 20px; } }

@media (max-width: 1049px) {
      #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu {
        background: #00358e;
        background: linear-gradient(to bottom, #00358e, #560075);
        height: 100vh;
        width: 93.75vw;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 0;
        padding-bottom: 128px; } }
      @media (max-width: 1049px) and (min-width: 600px) {
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu {
          width: 50vw; } }
      @media (max-width: 1049px) and (min-width: 1050px) {
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu {
          padding-bottom: 0rem; } }

@media (max-width: 1049px) {
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-menu-items {
          margin-left: 0;
          padding-left: 0; }
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-menu-item img {
          display: none;
          max-height: 620px; }
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item {
          margin-left: 0; }
          #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item a {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0; }
            #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item a .ft-menu-text {
              padding-top: 2rem;
              padding-left: 6.51042%;
              padding-right: 20px;
              padding-bottom: 0.5rem; } }
            @media (max-width: 1049px) and (min-width: 600px) {
              #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item a .ft-menu-text {
                padding-top: 2rem;
                padding-left: 12.5%; } }

@media (max-width: 1049px) {
            #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item a .menu-desc {
              padding-left: 6.51042%;
              padding-right: 20px; } }
            @media (max-width: 1049px) and (min-width: 600px) {
              #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .ft-2-col-menu-item a .menu-desc {
                padding-left: 12.5%; } }

@media (max-width: 1049px) {
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu ul:first-of-type {
          padding-top: 8rem; }
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu li a {
          line-height: 1.5;
          font-weight: 400; }
        #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading {
          position: fixed;
          display: block;
          top: 0;
          left: 0;
          text-align: center;
          width: 93.75vw;
          height: 8rem;
          padding-top: 20px;
          background: rgba(0, 53, 142, 0.95);
          font-weight: 700;
          z-index: 995; } }
        @media (max-width: 1049px) and (min-width: 600px) {
          #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading {
            width: 50vw; } }

@media (max-width: 1049px) {
          #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading .subnav-heading-text {
            line-height: 1.5em; }
          #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading button {
            top: 0;
            left: 0; }
          #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading .dropdown-back-button {
            cursor: pointer;
            text-align: left;
            margin-top: 32px;
            margin-left: 6.51042%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            align-items: center;
            font-weight: 400; } }
          @media (max-width: 1049px) and (min-width: 600px) {
            #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading .dropdown-back-button {
              margin-left: 12.5%; } }

@media (max-width: 1049px) {
            #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading .dropdown-back-button i {
              display: inline-block;
              vertical-align: middle; }
            #navbar.navbar-nav.navbar-collapse .navbar-nav.nav .dropdown.open .dropdown-menu .subnav-heading .dropdown-back-button span {
              display: inline-block;
              vertical-align: text-bottom; } }

.footer-container {
  background: #212833;
  width: 100%;
  height: 100%;
  font-family: MarkOT;
  font-size: 0.8rem;
  color: #ffffff;
  padding-top: 24px;
  padding-bottom: 64px;
  /*.footer-email-area-container {
        float: left;

        @media(min-width: $screen-sm) {
            float: right;
        }


        .footer-email-area {
            float: left;

            @media(min-width: $screen-sm) {
                float: right;
            }

            .footer-email-entry {
                margin-top: 7px;

                .footer-email-box-area {
                    @include flexbox();
                }

                .footer-email-placeholder {
                    border: solid 2px #ffffff;
                    width: calc(100% - 5.25rem);
                    color: $footer-unimportant-txt-color;
                    background: $footer-bg-color;
                    vertical-align: bottom;

                    @media(min-width: 1000px) {
                        width: 14rem;
                    }
                }

                .footer-email-join-button {
                    padding: 7px 1.25rem;
                    line-height: 1rem;
                    font-size: 1rem;
                    border-width: 2px;
                }
            }
        }
    }*/ }
  @media (min-width: 600px) {
    .footer-container {
      padding-top: 48px;
      padding-bottom: 64px; } }
  .footer-container .footer-logo-image img {
    margin-bottom: 48px; }
    @media (min-width: 900px) {
      .footer-container .footer-logo-image img {
        margin-bottom: 0; } }
  .footer-container .footer-links {
    text-align: left;
    margin-bottom: 32px; }
    @media (min-width: 600px) {
      .footer-container .footer-links {
        margin-bottom: 0px; } }
    .footer-container .footer-links .link-array .actual-links {
      margin-bottom: 32px;
      font-weight: 400; }
      @media (min-width: 900px) {
        .footer-container .footer-links .link-array .actual-links {
          margin-bottom: 1rem; } }
      @media (min-width: 1800px) {
        .footer-container .footer-links .link-array .actual-links {
          margin-bottom: 32px; } }
  .footer-container .footer-email-btn-wrapper {
    margin-top: 18px; }
    @media (min-width: 900px) {
      .footer-container .footer-email-btn-wrapper {
        margin-top: 0;
        margin-left: 0;
        width: 33.33334%; } }
    @media (min-width: 1400px) {
      .footer-container .footer-email-btn-wrapper {
        width: 16.66667%;
        margin-left: 16.66667%; } }
  .footer-container .footer-social-media-section {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    margin: 64px 0 12px; }
    @media (min-width: 600px) {
      .footer-container .footer-social-media-section {
        margin-bottom: 0;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between; } }
    @media (min-width: 900px) {
      .footer-container .footer-social-media-section {
        margin-top: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        align-items: flex-end; } }
    .footer-container .footer-social-media-section .footer-follow-us-text {
      margin-right: 20px; }
    .footer-container .footer-social-media-section .footer-social-icons .footer-social-link {
      margin-right: 37px; }
      .footer-container .footer-social-media-section .footer-social-icons .footer-social-link i {
        font-size: 1.5rem; }
      .footer-container .footer-social-media-section .footer-social-icons .footer-social-link:last-child i {
        margin-right: 0px; }
  .footer-container .footer-text-icon-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (min-width: 600px) {
      .footer-container .footer-text-icon-section {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: 48px; } }
    @media (min-width: 900px) {
      .footer-container .footer-text-icon-section {
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        align-items: flex-end;
        margin-top: 0; } }
    .footer-container .footer-text-icon-section .bot-footer-link {
      display: inline-block;
      letter-spacing: 1.1px;
      font-size: 0.64rem;
      width: 100%;
      float: none;
      margin: 0.75rem 0;
      /*&:last-child {
                margin-top: 0;
            }*/ }
      @media (min-width: 600px) {
        .footer-container .footer-text-icon-section .bot-footer-link {
          margin: 0 0 0 32px;
          float: right;
          width: auto; } }
      .footer-container .footer-text-icon-section .bot-footer-link:first-child {
        margin-left: 0; }
  .footer-container .footer-row-two .accesbility-logo {
    height: 1.5rem; }
  @media (min-width: 900px) {
    .footer-container .footer-row-two {
      width: 100%;
      float: left;
      clear: both;
      margin-top: 64px; } }
  .footer-container .footer-spacer-area {
    height: 70px; }
    @media (min-width: 600px) {
      .footer-container .footer-spacer-area {
        height: 118px; } }
    @media (min-width: 900px) {
      .footer-container .footer-spacer-area {
        height: 150px; } }
  .footer-container .footer-row-three {
    margin-top: 4px;
    text-align: left;
    font-size: 0.511875rem;
    letter-spacing: 0.8px;
    color: #FFFFFF;
    float: left;
    width: 100%; }
    @media (min-width: 600px) {
      .footer-container .footer-row-three {
        text-align: right;
        margin-top: 16px; } }
    .footer-container .footer-row-three p {
      line-height: 1em;
      margin-bottom: 0.5em; }
  .footer-container .no-nav-accessability .accesbility-logo {
    width: 64px;
    margin-bottom: 32px;
    float: left; }
    @media (min-width: 600px) {
      .footer-container .no-nav-accessability .accesbility-logo {
        margin-bottom: 42px;
        float: right; } }
    @media (min-width: 900px) {
      .footer-container .no-nav-accessability .accesbility-logo {
        margin-bottom: 15px; } }

/*MODULE STYLES*/
.full-width-promo-area {
  background-color: #000;
  position: relative;
  margin-top: -4rem;
  margin-bottom: 0;
  margin-left: -7.14286%;
  margin-right: -7.14286%;
  z-index: 0;
  /*@media(min-width: $screen-md) {
        margin-bottom: -20.72%;
    }*/ }
  @media (max-width: 1048px) {
    .full-width-promo-area {
      height: 435px; } }
  @media (min-width: 768px) and (max-width: 1049px) {
    .full-width-promo-area {
      height: 560px; } }
  @media (min-width: 1050px) and (max-width: 1439px) {
    .full-width-promo-area {
      height: 480px; } }
  @media (min-width: 1440px) and (max-width: 1919px) {
    .full-width-promo-area {
      height: 560px; } }
  @media (min-width: 1920px) {
    .full-width-promo-area {
      height: 664px; } }
  @media (min-width: 2240px) {
    .full-width-promo-area {
      height: auto !important; } }
  @media (min-width: 1441px) {
    .full-width-promo-area.subscription {
      margin-top: -100px; } }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .full-width-promo-area.subscription {
      margin-top: -80px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .full-width-promo-area.subscription {
      margin-top: -80px; } }
  @media (min-width: 900px) {
    .full-width-promo-area {
      margin-left: -3.27869%;
      margin-right: -3.27869%; } }
  .full-width-promo-area + * {
    position: relative; }
  .full-width-promo-area .content-container {
    text-align: center; }
    .full-width-promo-area .content-container .background-movie-image-wrap {
      position: relative;
      /*@media(min-width: $screen-md){
                padding-bottom: 56.18%;

            }*/
      /*.background-movie-image-fade-bottom {
                left: 0;
                width: 100%;
                height: 48px;
                background: linear-gradient(to bottom, rgba(10,11,13,0), rgba(10,11,13,1));

                @media(min-width: $screen-md) {
                    height: 80px;
                }
            }*/ }
      @media (max-width: 1048px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: 435px; } }
      @media (min-width: 768px) and (max-width: 1049px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: 560px; } }
      @media (min-width: 1050px) and (max-width: 1439px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: 480px; } }
      @media (min-width: 1440px) and (max-width: 1919px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: 560px; } }
      @media (min-width: 1920px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: 664px; } }
      @media (min-width: 2240px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          height: auto !important; } }
      @media (min-width: 600px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          padding-bottom: 60.42%; } }
      @media (min-width: 900px) {
        .full-width-promo-area .content-container .background-movie-image-wrap {
          padding-bottom: 33.33%; } }
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image,
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image-load {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%; }
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image-load {
        z-index: -1; }
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image-fade-bottom,
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image-fade-left,
      .full-width-promo-area .content-container .background-movie-image-wrap .background-movie-image-fade-right {
        position: absolute;
        z-index: 0;
        bottom: 0; }
    .full-width-promo-area .content-container .promo-text-container,
    .full-width-promo-area .content-container .promo-html-wrapper {
      position: absolute;
      text-align: center;
      top: auto;
      bottom: 10.625vw;
      left: 0%;
      right: 0%;
      width: 60.41666666667%;
      margin: 0 auto; }
      @media (min-width: 320px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          bottom: 54px; } }
      @media (min-width: 600px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          bottom: 72px;
          width: 33.98%; } }
      @media (min-width: 1050px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          left: auto;
          right: 3.125%;
          width: 29.861%; } }
      @media (min-width: 1050px) and (max-width: 1439px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          bottom: 64px; } }
      @media (min-width: 1440px) and (max-width: 1919px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          bottom: 88px; } }
      @media (min-width: 1920px) {
        .full-width-promo-area .content-container .promo-text-container,
        .full-width-promo-area .content-container .promo-html-wrapper {
          bottom: 160px; } }
      .full-width-promo-area .content-container .promo-text-container .promotion-text,
      .full-width-promo-area .content-container .promo-html-wrapper .promotion-text {
        font-size: 0.8rem;
        font-style: normal;
        font-weight: normal; }
        @media (min-width: 320px) {
          .full-width-promo-area .content-container .promo-text-container .promotion-text,
          .full-width-promo-area .content-container .promo-html-wrapper .promotion-text {
            font-size: 10px;
            line-height: 14px; } }
        @media (min-width: 600px) {
          .full-width-promo-area .content-container .promo-text-container .promotion-text,
          .full-width-promo-area .content-container .promo-html-wrapper .promotion-text {
            font-size: 16px;
            line-height: 22px; } }
        @media (min-width: 900px) {
          .full-width-promo-area .content-container .promo-text-container .promotion-text,
          .full-width-promo-area .content-container .promo-html-wrapper .promotion-text {
            font-size: 1.25rem; } }
      @media (min-width: 320px) {
        .full-width-promo-area .content-container .promo-text-container .background-image-movie-title,
        .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title {
          margin-top: 4px;
          margin-bottom: 16px; } }
      @media (min-width: 600px) {
        .full-width-promo-area .content-container .promo-text-container .background-image-movie-title,
        .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title {
          margin-top: 8px;
          margin-bottom: 28px; } }
      @media (min-width: 900px) {
        .full-width-promo-area .content-container .promo-text-container .background-image-movie-title,
        .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title {
          margin-top: 16px;
          margin-bottom: 32px; } }
      .full-width-promo-area .content-container .promo-text-container .background-image-movie-title img,
      .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title img {
        max-width: 100%;
        max-height: 21.09375vw; }
        @media (min-width: 600px) {
          .full-width-promo-area .content-container .promo-text-container .background-image-movie-title img,
          .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title img {
            max-height: 15.4296875vw; } }
        @media (min-width: 900px) {
          .full-width-promo-area .content-container .promo-text-container .background-image-movie-title img,
          .full-width-promo-area .content-container .promo-html-wrapper .background-image-movie-title img {
            max-height: 15.141vw; } }
      .full-width-promo-area .content-container .promo-text-container .promo-btn-container,
      .full-width-promo-area .content-container .promo-html-wrapper .promo-btn-container {
        position: static;
        width: 100%; }
        .full-width-promo-area .content-container .promo-text-container .promo-btn-container .promo-btn,
        .full-width-promo-area .content-container .promo-html-wrapper .promo-btn-container .promo-btn {
          padding: 8px 24px;
          font-size: 0.8rem;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          z-index: 1; }
          @media (min-width: 600px) {
            .full-width-promo-area .content-container .promo-text-container .promo-btn-container .promo-btn,
            .full-width-promo-area .content-container .promo-html-wrapper .promo-btn-container .promo-btn {
              font-size: 1rem; } }
          @media (min-width: 900px) {
            .full-width-promo-area .content-container .promo-text-container .promo-btn-container .promo-btn,
            .full-width-promo-area .content-container .promo-html-wrapper .promo-btn-container .promo-btn {
              min-width: 316px;
              min-height: 60px;
              font-size: 25px;
              padding-top: 13px; } }
          @media (min-width: 1800px) {
            .full-width-promo-area .content-container .promo-text-container .promo-btn-container .promo-btn,
            .full-width-promo-area .content-container .promo-html-wrapper .promo-btn-container .promo-btn {
              min-width: 316px;
              min-height: 60px;
              font-size: 25px;
              padding-top: 13px; } }
  .full-width-promo-area.promo-area-slider {
    /*@media(min-width: $screen-sm) {
            margin-bottom: 32px;
        }

        @media(min-width: $screen-md) {
            margin-bottom: 5%;
        }*/ }
    .full-width-promo-area.promo-area-slider .content-container {
      outline: none;
      display: none;
      position: relative; }
      .full-width-promo-area.promo-area-slider .content-container:first-child {
        display: block; }
      .full-width-promo-area.promo-area-slider .content-container .background-movie-image-wrap {
        margin-bottom: 0; }
    .full-width-promo-area.promo-area-slider .promo-indicator {
      display: none !important;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 5;
      cursor: pointer;
      width: 2.34375vw;
      overflow: visible; }
      @media (min-width: 600px) {
        .full-width-promo-area.promo-area-slider .promo-indicator {
          display: block !important; } }
      @media (min-width: 900px) {
        .full-width-promo-area.promo-area-slider .promo-indicator {
          width: 1.979vw; } }
      .full-width-promo-area.promo-area-slider .promo-indicator:hover, .full-width-promo-area.promo-area-slider .promo-indicator:focus {
        color: #F1C400; }
      .full-width-promo-area.promo-area-slider .promo-indicator .billboard-chevron {
        font-size: 4.6875vw; }
        @media (min-width: 900px) {
          .full-width-promo-area.promo-area-slider .promo-indicator .billboard-chevron {
            font-size: 3.958vw; } }
      .full-width-promo-area.promo-area-slider .promo-indicator .store-icon-billboardright:before {
        position: relative;
        left: -100%; }
    .full-width-promo-area.promo-area-slider .promo-next {
      right: 18px; }
      .full-width-promo-area.promo-area-slider .promo-next :focus-visible {
        outline: #fff auto 5px;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 15px;
        outline-width: medium; }
    .full-width-promo-area.promo-area-slider .promo-prev {
      left: 18px; }
      .full-width-promo-area.promo-area-slider .promo-prev :focus-visible {
        outline: #fff auto 5px;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 15px;
        outline-width: medium; }
    .full-width-promo-area.promo-area-slider .slick-dots {
      position: absolute;
      margin: 0;
      z-index: 0;
      bottom: 5.3125vw;
      padding: 0;
      transform: translate(0, 50%); }
      @media (min-width: 320px) {
        .full-width-promo-area.promo-area-slider .slick-dots {
          bottom: 0px; } }
      @media (min-width: 600px) {
        .full-width-promo-area.promo-area-slider .slick-dots {
          bottom: 10px;
          padding: 0;
          transform: translate(0, 0); } }
      .full-width-promo-area.promo-area-slider .slick-dots .slick-arrows {
        display: none;
        font-size: 1.77em;
        line-height: 0.4em;
        vertical-align: -25%;
        padding: 1px 23.33px;
        font-weight: bold; }
        @media (min-width: 1050px) {
          .full-width-promo-area.promo-area-slider .slick-dots .slick-arrows {
            display: inline-block; } }
        .full-width-promo-area.promo-area-slider .slick-dots .slick-arrows :hover {
          color: #f1c400; }
        .full-width-promo-area.promo-area-slider .slick-dots .slick-arrows :focus-visible {
          outline: 5px auto #fff;
          outline: 5px auto -webkit-focus-ring-color;
          outline-offset: 15px;
          outline-width: medium; }
      .full-width-promo-area.promo-area-slider .slick-dots li {
        width: auto;
        height: auto;
        padding: 0;
        margin: 0; }
        .full-width-promo-area.promo-area-slider .slick-dots li button {
          box-sizing: content-box;
          width: 6px;
          height: 6px;
          padding: 4px 4px;
          text-align: center; }
          @media (min-width: 600px) {
            .full-width-promo-area.promo-area-slider .slick-dots li button {
              width: 8px;
              height: 8px;
              padding: 6px 6px; } }
          @media (min-width: 900px) {
            .full-width-promo-area.promo-area-slider .slick-dots li button {
              width: 10px;
              height: 10px;
              padding: 11px 8px; } }
          @media (min-width: 1800px) {
            .full-width-promo-area.promo-area-slider .slick-dots li button {
              width: 12px;
              height: 12px;
              padding: 14px 10px; } }
          .full-width-promo-area.promo-area-slider .slick-dots li button:before {
            position: relative;
            display: inline-block;
            opacity: 0.8;
            border: solid 1px #d8d8d8;
            content: '';
            width: 6px;
            height: 6px; }
            @media (min-width: 600px) {
              .full-width-promo-area.promo-area-slider .slick-dots li button:before {
                width: 8px;
                height: 8px; } }
            @media (min-width: 900px) {
              .full-width-promo-area.promo-area-slider .slick-dots li button:before {
                width: 10px;
                height: 10px; } }
            @media (min-width: 1800px) {
              .full-width-promo-area.promo-area-slider .slick-dots li button:before {
                width: 12px;
                height: 12px; } }
          .full-width-promo-area.promo-area-slider .slick-dots li button:hover:before, .full-width-promo-area.promo-area-slider .slick-dots li button:focus:before {
            border-color: #F1C400; }
        .full-width-promo-area.promo-area-slider .slick-dots li.slick-active button {
          font-size: 0; }
          .full-width-promo-area.promo-area-slider .slick-dots li.slick-active button:before {
            opacity: 0.8;
            background-color: #d8d8d8; }
          .full-width-promo-area.promo-area-slider .slick-dots li.slick-active button:hover:before, .full-width-promo-area.promo-area-slider .slick-dots li.slick-active button:focus:before {
            background: #F1C400; }
    .full-width-promo-area.promo-area-slider.slick-initialized .content-container {
      display: block; }
    .full-width-promo-area.promo-area-slider.slick-initialized .slick-list {
      overflow: visible; }

.slider-arrows {
  margin: 0;
  bottom: 12px;
  padding: 0;
  transform: translate(0);
  z-index: 9999;
  position: absolute;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  font-size: 1.77em;
  line-height: 0.4em;
  pointer-events: none;
  outline: none; }
  @media (max-width: 1049px) {
    .slider-arrows {
      display: none; } }
  .slider-arrows .slider-prev {
    float: left;
    margin-right: 200px;
    pointer-events: auto; }
  .slider-arrows .slider-next {
    float: left;
    margin-left: 200px;
    pointer-events: auto; }
  .slider-arrows :hover {
    outline: none;
    color: #f1c400; }
  .slider-arrows a, .slider-arrows a:active, .slider-arrows a:visited {
    outline: none; }
  .slider-arrows :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 10px;
    outline-width: medium; }

@media (min-width: 320px) {
  .push-top-alignment {
    padding-bottom: 30px; } }

@media (min-width: 600px) {
  .push-top-alignment {
    padding-bottom: 0; } }

.banner-navigation {
  position: absolute;
  bottom: 2%;
  right: 30px;
  background: none;
  z-index: 999; }
  @media (min-width: 320px) {
    .banner-navigation {
      bottom: -18px;
      right: 12px; } }
  @media (min-width: 600px) {
    .banner-navigation {
      bottom: 1px;
      right: 40px; } }
  @media (min-width: 900px) {
    .banner-navigation {
      bottom: 10px;
      right: 40px; } }
  @media (min-width: 1800px) {
    .banner-navigation {
      bottom: 7px;
      right: 3.3vh; } }
  .banner-navigation .promo-banner-pause {
    background: url("https://storemedia.cineplex.com/pause-button.svg");
    background-position: center;
    background-size: cover;
    border: none;
    padding: 0; }
    @media (min-width: 320px) {
      .banner-navigation .promo-banner-pause {
        width: 22.75px;
        height: 22.75px; } }
    @media (min-width: 600px) {
      .banner-navigation .promo-banner-pause {
        width: 26px;
        height: 26px; } }
    @media (min-width: 900px) {
      .banner-navigation .promo-banner-pause {
        width: 26px;
        height: 26px; } }
    @media (min-width: 1800px) {
      .banner-navigation .promo-banner-pause {
        width: 32.5px;
        height: 32.5px; } }
    .banner-navigation .promo-banner-pause:hover, .banner-navigation .promo-banner-pause:focus-visible {
      background-image: url("https://storemedia.cineplex.com/pause-bg-hover.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      top: 1px;
      left: 1px; }
      @media (min-width: 320px) {
        .banner-navigation .promo-banner-pause:hover, .banner-navigation .promo-banner-pause:focus-visible {
          width: 22.75px;
          height: 22.75px; } }
      @media (min-width: 600px) {
        .banner-navigation .promo-banner-pause:hover, .banner-navigation .promo-banner-pause:focus-visible {
          width: 26px;
          height: 26px; } }
      @media (min-width: 900px) {
        .banner-navigation .promo-banner-pause:hover, .banner-navigation .promo-banner-pause:focus-visible {
          width: 26px;
          height: 26px; } }
      @media (min-width: 1800px) {
        .banner-navigation .promo-banner-pause:hover, .banner-navigation .promo-banner-pause:focus-visible {
          width: 32.5px;
          height: 32.5px; } }
  .banner-navigation .promo-banner-play {
    background: url("https://storemedia.cineplex.com/play-button.svg");
    background-position: center;
    background-size: cover;
    border: none;
    display: none;
    padding: 0; }
    @media (min-width: 320px) {
      .banner-navigation .promo-banner-play {
        width: 22.75px;
        height: 22.75px; } }
    @media (min-width: 600px) {
      .banner-navigation .promo-banner-play {
        width: 26px;
        height: 26px; } }
    @media (min-width: 900px) {
      .banner-navigation .promo-banner-play {
        width: 26px;
        height: 26px; } }
    @media (min-width: 1800px) {
      .banner-navigation .promo-banner-play {
        width: 32.5px;
        height: 32.5px; } }
    .banner-navigation .promo-banner-play:hover, .banner-navigation .promo-banner-play:focus-visible {
      background-image: url("https://storemedia.cineplex.com/play-bg-hover.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      top: 1px;
      left: 1px; }
      @media (min-width: 320px) {
        .banner-navigation .promo-banner-play:hover, .banner-navigation .promo-banner-play:focus-visible {
          width: 22.75px;
          height: 22.75px; } }
      @media (min-width: 600px) {
        .banner-navigation .promo-banner-play:hover, .banner-navigation .promo-banner-play:focus-visible {
          width: 26px;
          height: 26px; } }
      @media (min-width: 900px) {
        .banner-navigation .promo-banner-play:hover, .banner-navigation .promo-banner-play:focus-visible {
          width: 26px;
          height: 26px; } }
      @media (min-width: 1800px) {
        .banner-navigation .promo-banner-play:hover, .banner-navigation .promo-banner-play:focus-visible {
          width: 32.5px;
          height: 32.5px; } }

.image-promo-area .image-promo-area-container {
  display: block;
  text-align: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media (min-width: 600px) {
    .image-promo-area .image-promo-area-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; } }
  .image-promo-area .image-promo-area-container .promo-img-wrap {
    margin-top: 32px;
    width: auto;
    height: auto;
    max-width: 100%;
    display: inline-block; }
    @media (min-width: 600px) {
      .image-promo-area .image-promo-area-container .promo-img-wrap {
        max-width: 46.875%; } }
    @media (min-width: 900px) {
      .image-promo-area .image-promo-area-container .promo-img-wrap {
        max-width: 32.291666666666666666666666666667%; } }

.movie-slider + .movie-slider {
  margin-top: 30px; }

.movie-card-section-wrapper {
  position: relative;
  z-index: 1; }
  .movie-card-section-wrapper.movie-playlist-wrapper {
    padding-bottom: 0; }
  .movie-card-section-wrapper .card-section-title {
    text-transform: uppercase;
    margin-bottom: 16px;
    padding-left: 1.72414%;
    padding-right: 1.72414%;
    height: auto; }
    @media (min-width: 900px) {
      .movie-card-section-wrapper .card-section-title {
        padding-left: 0.81967%;
        padding-right: 0.81967%; } }
    .movie-card-section-wrapper .card-section-title .card-section-title-text {
      float: left; }
      .movie-card-section-wrapper .card-section-title .card-section-title-text .card-section-title-name {
        font-size: 1rem;
        margin-top: 0px;
        margin-bottom: 0px; }
        @media (min-width: 900px) {
          .movie-card-section-wrapper .card-section-title .card-section-title-text .card-section-title-name {
            font-size: 1.25rem; } }
        @media (min-width: 1800px) {
          .movie-card-section-wrapper .card-section-title .card-section-title-text .card-section-title-name {
            font-size: 1.25rem; } }
      .movie-card-section-wrapper .card-section-title .card-section-title-text .store-icon-chevron {
        font-size: 1.25rem;
        font-weight: 700;
        padding-bottom: 0.25rem; }
        @media (min-width: 900px) {
          .movie-card-section-wrapper .card-section-title .card-section-title-text .store-icon-chevron {
            font-size: 1.5rem; } }
        @media (min-width: 1800px) {
          .movie-card-section-wrapper .card-section-title .card-section-title-text .store-icon-chevron {
            font-size: 1.6rem; } }
  .movie-card-section-wrapper .movie-card-slider-wrap {
    box-sizing: content-box;
    opacity: 0;
    margin: 0; }
    @media (min-width: 600px) {
      .movie-card-section-wrapper .movie-card-slider-wrap {
        margin: 2.7592953821656050955414012738854% 0; } }
    @media (min-width: 900px) {
      .movie-card-section-wrapper .movie-card-slider-wrap {
        margin: 1.723175% 0; } }
    @media (min-width: 1800px) {
      .movie-card-section-wrapper .movie-card-slider-wrap {
        margin: 1.38826733% 0; } }
    .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap {
      float: left;
      width: 33.33333%;
      display: none; }
      @media (min-width: 600px) {
        .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap {
          width: 25%; } }
      @media (min-width: 900px) {
        .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap {
          width: 16.66666%; } }
      @media (min-width: 1800px) {
        .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap {
          width: 12.5%; } }
      .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap:first-child {
        display: block; }
      .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap.slick-slide.slick-active {
        opacity: 1; }
      .movie-card-section-wrapper .movie-card-slider-wrap .movie-card-wrap.slick-slide {
        opacity: 0.3; }
    .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized {
      opacity: 1; }
      .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .movie-card-wrap {
        display: block; }
      .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized:before {
        display: none; }
      .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card {
        position: relative; }
        .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content {
          background: rgba(0, 0, 0, 0);
          border: 1px solid #F1C400;
          display: block;
          width: 90.16920922133475260%;
          position: relative; }
          .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content:hover {
            background: #F1C400;
            border: 1px solid #F1C400; }
            .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content:hover .see-all-card-text {
              color: #0A0B0D; }
          .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content .see-all-card-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #F1C400;
            font-size: 1rem;
            text-transform: uppercase;
            text-align: center;
            white-space: nowrap; }
            @media (min-width: 600px) {
              .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content .see-all-card-text {
                font-size: 1.3rem; } }
            @media (min-width: 900px) {
              .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content .see-all-card-text {
                font-size: 1.5625rem; } }
        .movie-card-section-wrapper .movie-card-slider-wrap.slick-initialized .see-all-card .see-all-card-content::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 149.6410256410256%; }
    .movie-card-section-wrapper .movie-card-slider-wrap:hover .playlist-indicator {
      display: block !important; }
    .movie-card-section-wrapper .movie-card-slider-wrap .playlist-indicator {
      display: none !important; }
    .movie-card-section-wrapper .movie-card-slider-wrap .slick-list {
      overflow: hidden; }
      @media (min-width: 600px) {
        .movie-card-section-wrapper .movie-card-slider-wrap .slick-list {
          overflow: visible; } }

.deal-banner {
  padding: 6.25vw 6.25%;
  background: linear-gradient(103deg, rgba(0, 53, 142, 0.4), rgba(86, 0, 117, 0.4));
  margin-top: 32px;
  margin-left: -6.89655%;
  margin-bottom: 32px;
  margin-right: -6.89655%; }
  @media (min-width: 900px) {
    .deal-banner {
      padding: 3.125vw 3.125%;
      margin-left: -3.27869%;
      margin-right: -3.27869%; } }
  .deal-banner.trailer-deal {
    padding-top: 0px;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    background: linear-gradient(280deg, #00358e, rgba(86, 0, 117, 0.1)); }
    .deal-banner.trailer-deal .deal-content {
      text-align: center; }
      @media (min-width: 600px) {
        .deal-banner.trailer-deal .deal-content {
          text-align: left; } }
    .deal-banner.trailer-deal .trailer-deal-wrap {
      flex-wrap: wrap; }
      @media (min-width: 900px) {
        .deal-banner.trailer-deal .trailer-deal-wrap {
          flex-wrap: nowrap; } }
  .deal-banner + .deal-banner {
    margin-top: 0; }
  .deal-banner .multi-item-deal {
    visibility: hidden;
    max-height: 15vw; }
    .deal-banner .multi-item-deal.slick-initialized {
      visibility: visible;
      max-height: none; }
    .deal-banner .multi-item-deal .slick-list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center;
      overflow: visible; }
    .deal-banner .multi-item-deal .slick-track {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: none;
      flex-wrap: nowrap; }
    .deal-banner .multi-item-deal .playlist-indicator {
      display: none !important; }
      @media (min-width: 900px) {
        .deal-banner .multi-item-deal .playlist-indicator {
          display: block !important; } }
      .deal-banner .multi-item-deal .playlist-indicator + .slick-list {
        display: block;
        padding-right: 39.5833%;
        margin-right: -6.25%; }
        @media (min-width: 600px) {
          .deal-banner .multi-item-deal .playlist-indicator + .slick-list {
            margin-right: 0;
            padding-right: 0; } }
    .deal-banner .multi-item-deal .deal-item {
      padding: 0 1.5625vw;
      outline: none; }
      @media (min-width: 600px) {
        .deal-banner .multi-item-deal .deal-item {
          padding: 0 1.5625vw; } }
      @media (min-width: 900px) {
        .deal-banner .multi-item-deal .deal-item {
          padding: 0 0.78125vw; } }
      @media (min-width: 1800px) {
        .deal-banner .multi-item-deal .deal-item {
          padding: 0 0.78125vw; } }
    .deal-banner .multi-item-deal .slick-slide {
      position: relative;
      opacity: 0.5;
      height: auto; }
    .deal-banner .multi-item-deal .slick-active {
      opacity: 1; }
    @media (min-width: 600px) {
      .deal-banner .multi-item-deal .deal-img {
        border: 1px solid transparent;
        display: block; }
        .deal-banner .multi-item-deal .deal-img:hover {
          border: 1px solid #F1c400; } }

#promo-banner-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 1050;
  display: none;
  border-bottom: #2253a8 solid 1px; }
  #promo-banner-wrap .promo-banner-body {
    min-height: 4rem;
    display: flex;
    background: #002C6B;
    border-bottom: #1a2232 solid 1px;
    /*&.subscription {

            @media(min-width: $screen-lg - 359) {
                min-height: 60px;
            }

            @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
                min-height: 48px;
            }

            @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
                min-height: 47px;
            }

            @media(max-width: $screen-sm + 167) {
                min-height: 65px;
            }
        }*/ }
    #promo-banner-wrap .promo-banner-body:before, #promo-banner-wrap .promo-banner-body:after {
      display: none; }
  #promo-banner-wrap .promo-html-wrapper {
    padding: 0.5rem;
    font-size: 12px;
    /*@media(min-width: 493px) {
            font-size: 11px;
        }

        @media(max-width: 492px) {
            font-size: 8px;
        }*/ }
  #promo-banner-wrap .promo-banner-close {
    color: #fff;
    font-size: 1rem; }
    @media (min-width: 600px) {
      #promo-banner-wrap .promo-banner-close {
        font-size: 1.5rem; } }
    @media (min-width: 900px) {
      #promo-banner-wrap .promo-banner-close {
        font-size: 1.6rem; } }
  #promo-banner-wrap.banner-in {
    display: block; }
    #promo-banner-wrap.banner-in + #Wrapper {
      padding-top: 8rem; }
      #promo-banner-wrap.banner-in + #Wrapper .primary-nav {
        top: 4rem; }
        @media (min-width: 1441px) {
          #promo-banner-wrap.banner-in + #Wrapper .primary-nav.subscription {
            top: 60px; } }
        @media (min-width: 1024px) and (max-width: 1440px) {
          #promo-banner-wrap.banner-in + #Wrapper .primary-nav.subscription {
            top: 48px; } }
        @media (min-width: 768px) and (max-width: 1023px) {
          #promo-banner-wrap.banner-in + #Wrapper .primary-nav.subscription {
            top: 47px; } }
        @media (max-width: 767px) {
          #promo-banner-wrap.banner-in + #Wrapper .primary-nav.subscription {
            top: 65px; } }
      #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav {
        padding-top: 0;
        margin-top: 4rem; }
        @media (min-width: 1441px) {
          #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav.subscription {
            margin-top: 60px; } }
        @media (min-width: 1024px) and (max-width: 1440px) {
          #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav.subscription {
            margin-top: 48px; } }
        @media (min-width: 768px) and (max-width: 1023px) {
          #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav.subscription {
            margin-top: 47px; } }
        @media (max-width: 767px) {
          #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav.subscription {
            margin-top: 65px; } }
        #promo-banner-wrap.banner-in + #Wrapper.unfixed-nav .primary-nav {
          top: 0rem; }
  #promo-banner-wrap.promo-error {
    background: linear-gradient(92deg, rgba(200, 16, 43, 0.8), rgba(117, 0, 72, 0.8)); }

#rental-confirm-modal button.confirm-button, #order-success-modal button.confirm-button {
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem; }
  #rental-confirm-modal button.confirm-button .success-play-wrapper .store-icon-play, #order-success-modal button.confirm-button .success-play-wrapper .store-icon-play {
    position: absolute;
    left: 1rem;
    top: 50%;
    font-size: 32px;
    transform: translateY(-50%); }

/*VENDOR*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("../../fonts/slick.eot");
  src: url("../../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick.woff") format("woff"), url("../../fonts/slick.ttf") format("truetype"), url("../../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*PAGES*/
.search-genre-page-container ~ .movie-slider {
  visibility: hidden;
  position: relative;
  height: 0px;
  width: 100%; }
  .search-genre-page-container ~ .movie-slider .movie-card-section-wrapper {
    position: absolute;
    left: -10000000px;
    width: 100%; }

.search-genre-page-container.no-search-results .result-container {
  height: 0 !important; }

.search-genre-page-container.no-search-results ~ .movie-slider {
  position: relative;
  height: auto;
  visibility: visible; }
  .search-genre-page-container.no-search-results ~ .movie-slider .movie-card-section-wrapper {
    position: relative;
    left: 0;
    margin-left: 0;
    margin-right: 0; }

.search-genre-page-container .result-container {
  overflow: hidden;
  padding-top: 52px; }
  @media (min-width: 900px) {
    .search-genre-page-container .result-container {
      padding-top: 83px; } }

@media (min-width: 900px) {
  .search-genre-page-container .movie-card-section-wrapper {
    padding-bottom: 3.7125847%; } }

.genre-page-container {
  margin: 0;
  padding-top: calc(56.25vw - 4rem); }
  @media (min-width: 600px) {
    .genre-page-container {
      padding-top: calc(33.333vw - 4rem); } }
  @media (min-width: 900px) {
    .genre-page-container {
      padding-top: calc(16.666vw - 4rem); } }
  .genre-page-container .result-container {
    overflow: hidden;
    padding-top: 32px; }
    @media (min-width: 900px) {
      .genre-page-container .result-container {
        padding-top: 83px; } }
  .genre-page-container .sort-dropdown h3 {
    font-size: 1em;
    line-height: 1.25; }
  @media (min-width: 900px) {
    .genre-page-container .sort-dropdown.no-results {
      display: none; } }

.search-page-container {
  padding-top: 32px; }
  @media (min-width: 900px) {
    .search-page-container {
      padding-top: 96px; } }
  @media (min-width: 1800px) {
    .search-page-container {
      padding-top: 152px; } }
  .search-page-container .result-container {
    padding-top: 32px; }
    @media (min-width: 900px) {
      .search-page-container .result-container {
        padding-top: 60px; } }

.info-header-genre-page {
  margin-bottom: 0; }
  .info-header-genre-page .info-container .genre-result-container .genre-chosen h2 {
    font-size: 1.25rem;
    line-height: 1.25;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px; }
    @media (min-width: 600px) {
      .info-header-genre-page .info-container .genre-result-container .genre-chosen h2 {
        font-size: 1.5625rem; } }
    @media (min-width: 900px) {
      .info-header-genre-page .info-container .genre-result-container .genre-chosen h2 {
        font-size: 1.95625rem; } }
  .info-header-genre-page .info-container .genre-result-container .genre-page-results-filter-area {
    font-size: 1rem;
    line-height: 1.95625rem; }
    .info-header-genre-page .info-container .genre-result-container .genre-page-results-filter-area .num-filters-displayed-section {
      display: inline-block;
      padding-bottom: 0.25rem; }
    .info-header-genre-page .info-container .genre-result-container .genre-page-results-filter-area .clear-filters-button-on-page {
      font-size: 1.5rem;
      border: none;
      background: transparent; }
      .info-header-genre-page .info-container .genre-result-container .genre-page-results-filter-area .clear-filters-button-on-page:hover {
        color: #F1C400; }

.search-page-box {
  position: relative;
  width: 60%;
  height: 3.5rem;
  background: white;
  margin-left: 20%;
  margin-top: 88px;
  margin-bottom: 64px; }
  .search-page-box #search-page-search-form {
    width: 100%;
    height: 100%; }
    .search-page-box #search-page-search-form .store-icon-search {
      position: absolute;
      top: 0;
      left: 0;
      color: #000000;
      font-size: 1.4rem;
      padding-top: 1.125rem;
      padding-left: 1.0625rem;
      font-weight: bold; }
    .search-page-box #search-page-search-form .search-box-text {
      color: #000000;
      font-size: 1.5625rem;
      padding-top: 0.5rem;
      padding-left: 3.125rem;
      padding-right: 3rem;
      width: 100%;
      height: 100%;
      border: none;
      background-color: #E4E4E4; }
    .search-page-box #search-page-search-form .store-icon-chevron {
      position: absolute;
      top: 0;
      right: 0;
      color: #000000;
      font-size: 1.4rem;
      padding-top: 1.125rem;
      padding-left: 0;
      padding-right: 1.0625rem;
      border: none;
      background: transparent;
      font-weight: bold;
      outline: none; }

.search-page-loader {
  visibility: hidden; }
  .search-page-loader.display-loader {
    visibility: visible; }
  .search-page-loader.on-last-search-page {
    display: none; }

.search-page-search-box {
  display: none; }
  .search-page-search-box.loaded {
    display: block; }
  .search-page-search-box .filter-items {
    margin-left: -1.78571%;
    margin-right: -1.78571%; }
    @media (min-width: 900px) {
      .search-page-search-box .filter-items {
        margin-left: 0;
        margin-right: 0; } }
    .search-page-search-box .filter-items .filter-item-name {
      padding-left: 1.72414%;
      padding-right: 1.72414%;
      float: left; }
      @media (min-width: 900px) {
        .search-page-search-box .filter-items .filter-item-name {
          padding-left: 0;
          padding-right: 0; } }

.info-header {
  font-family: MarkOT;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .info-header .info-container .search-result-container {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap; }
    .info-header .info-container .search-result-container.search-only {
      font-size: 1.25rem; }
      @media (min-width: 900px) {
        .info-header .info-container .search-result-container.search-only {
          font-size: 1.95625rem; } }
      .info-header .info-container .search-result-container.search-only .numeric-result,
      .info-header .info-container .search-result-container.search-only .search-result {
        overflow: visible; }
    .info-header .info-container .search-result-container.genre-only .genre-chosen h2 {
      font-size: 1.25rem;
      line-height: 1.25;
      color: #fff;
      margin-top: 0px;
      margin-bottom: 0px; }
      @media (min-width: 900px) {
        .info-header .info-container .search-result-container.genre-only .genre-chosen h2 {
          font-size: 1.95625rem; } }
    .info-header .info-container .search-result-container.genre-only .numeric-result,
    .info-header .info-container .search-result-container.genre-only .numeric-result-mobile,
    .info-header .info-container .search-result-container.genre-only .search-result {
      font-size: 1.25rem;
      /*@media(min-width: $screen-md) {
                font-size: 1.5rem;
            }*/ }
  .info-header .info-container .search-result .search-result-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    max-width: 7rem; }
    @media (min-width: 900px) {
      .info-header .info-container .search-result .search-result-div {
        max-width: 12rem; } }
    @media (min-width: 1200px) {
      .info-header .info-container .search-result .search-result-div {
        max-width: 28rem; } }
    @media (min-width: 1800px) {
      .info-header .info-container .search-result .search-result-div {
        max-width: 35rem; } }
  .info-header .num-filters-displayed-section {
    display: inline-block;
    padding-bottom: 0.33rem; }
  .info-header .clear-filters-button-on-page {
    font-size: 1.25rem;
    border: none;
    background: transparent;
    display: inline-block;
    line-height: 1.25; }
    @media (min-width: 900px) {
      .info-header .clear-filters-button-on-page {
        line-height: 1;
        padding-bottom: 0.33rem; } }
    .info-header .clear-filters-button-on-page:hover {
      color: #F1C400; }
  .info-header .sort-dropdown h3 {
    font-size: 1em;
    line-height: 1.25; }
    @media (max-width: 899px) {
      .info-header .sort-dropdown h3 {
        margin-top: 0px;
        margin-bottom: 0px; } }
  .info-header .sort-dropdown .dropdown-toggle {
    z-index: 1;
    position: relative;
    vertical-align: middle; }
    .info-header .sort-dropdown .dropdown-toggle .store-icon-filter {
      font-size: 1.5rem;
      vertical-align: middle; }
      @media (min-width: 600px) {
        .info-header .sort-dropdown .dropdown-toggle .store-icon-filter {
          font-size: 2rem; } }
    .info-header .sort-dropdown .dropdown-toggle .sort-filter-text {
      vertical-align: middle;
      margin-top: 0px;
      margin-bottom: 0px; }
  .info-header .sort-dropdown .dropdown-menu {
    padding: 0 0;
    width: auto;
    background-color: #00358e;
    margin-top: 25px;
    z-index: 2; }
    @media (max-width: 899px) {
      .info-header .sort-dropdown .dropdown-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 101vh;
        width: 100%;
        overflow: auto;
        margin-top: 0px;
        z-index: 3; } }
    .info-header .sort-dropdown .dropdown-menu .arrow-up {
      position: absolute;
      position: absolute;
      right: 2.35rem;
      bottom: 100%;
      border: 25px solid transparent;
      border-bottom: 25px solid #1F3370;
      border-top-width: 0px; }
  .info-header .sort-dropdown .search-sort {
    color: #FFF;
    width: 100%;
    padding: 67px 6.25% 14px;
    background: #1F3370; }
    @media (min-width: 900px) {
      .info-header .sort-dropdown .search-sort {
        width: 850px;
        padding: 17px 0 0; } }
    @media (min-width: 1050px) {
      .info-header .sort-dropdown .search-sort {
        width: 1000px; } }
    .info-header .sort-dropdown .search-sort .search-sort-title {
      float: left;
      font-size: 1rem;
      font-weight: normal; }
      @media (min-width: 900px) {
        .info-header .sort-dropdown .search-sort .search-sort-title {
          font-size: 1.25rem;
          padding-left: 33px;
          width: 25.6%; } }
      @media (min-width: 1800px) {
        .info-header .sort-dropdown .search-sort .search-sort-title {
          font-size: 1rem; } }
    .info-header .sort-dropdown .search-sort .search-sort-sorting .filter-item-name {
      font-size: 0.8rem; }
      @media (min-width: 900px) {
        .info-header .sort-dropdown .search-sort .search-sort-sorting .filter-item-name {
          font-size: 1rem; } }
      @media (min-width: 1800px) {
        .info-header .sort-dropdown .search-sort .search-sort-sorting .filter-item-name {
          font-size: 0.8rem; } }
      .info-header .sort-dropdown .search-sort .search-sort-sorting .filter-item-name .checkbox-btn {
        padding: 3px 0; }
        @media (min-width: 900px) {
          .info-header .sort-dropdown .search-sort .search-sort-sorting .filter-item-name .checkbox-btn {
            padding: 0;
            padding: 9px 0; } }
    .info-header .sort-dropdown .search-sort .store-icon-close {
      color: #FFF;
      cursor: pointer;
      font-size: 1.5rem; }
      @media (min-width: 900px) {
        .info-header .sort-dropdown .search-sort .store-icon-close.align-for-filter {
          margin-right: 30px; } }
      .info-header .sort-dropdown .search-sort .store-icon-close:hover, .info-header .sort-dropdown .search-sort .store-icon-close:focus {
        color: #f1c400;
        font-weight: 500; }
    .info-header .sort-dropdown .search-sort .filter-items {
      margin-top: 0px;
      margin-left: 1px !important; }
      @media (min-width: 900px) {
        .info-header .sort-dropdown .search-sort .filter-items {
          margin-left: 0px !important;
          padding-bottom: 12px; } }
      .info-header .sort-dropdown .search-sort .filter-items .filter-item-name {
        padding-top: 22px;
        width: 50%;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important; }
        @media (min-width: 900px) {
          .info-header .sort-dropdown .search-sort .filter-items .filter-item-name {
            width: 15.1875rem;
            padding-top: 28px; } }
        .info-header .sort-dropdown .search-sort .filter-items .filter-item-name .checkbox-btn {
          height: 1.375rem;
          width: 100%;
          margin-bottom: 0; }
          @media (min-width: 900px) {
            .info-header .sort-dropdown .search-sort .filter-items .filter-item-name .checkbox-btn {
              height: 2.5625rem; } }
          @media (min-width: 1800px) {
            .info-header .sort-dropdown .search-sort .filter-items .filter-item-name .checkbox-btn {
              height: 2.05rem; } }
  .info-header .sort-dropdown .dropdown-backdrop {
    z-index: 0; }

.filter-section-header {
  background-color: #00358e;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 6.25%; }
  @media (min-width: 900px) {
    .filter-section-header {
      padding-bottom: 32px;
      padding-left: 33px; } }
  .filter-section-header .filter-section-title {
    display: inline-block;
    font-size: 1rem; }
    @media (min-width: 900px) {
      .filter-section-header .filter-section-title {
        font-size: 1.25rem; } }
    @media (min-width: 1800px) {
      .filter-section-header .filter-section-title {
        font-size: 1rem; } }
  .filter-section-header .clear-all-button {
    display: inline-block;
    position: absolute;
    right: 0;
    padding-right: 6.25%;
    font-size: 0.8rem;
    border: none;
    background-color: transparent;
    outline: none; }
    @media (min-width: 900px) {
      .filter-section-header .clear-all-button {
        font-size: 1rem;
        padding-right: 33px; } }
    @media (min-width: 1800px) {
      .filter-section-header .clear-all-button {
        font-size: 0.8rem; } }
    .filter-section-header .clear-all-button:hover {
      color: #F1C400; }
    .filter-section-header .clear-all-button:focus {
      color: #F1C400; }

.filters-dropdown .filter-wrapper {
  color: #000000;
  background-color: #00358e;
  display: block;
  padding: 0 6.25% 128px; }
  @media (min-width: 900px) {
    .filters-dropdown .filter-wrapper {
      padding: 0;
      display: inline-block; } }
  .filters-dropdown .filter-wrapper .filter-section {
    padding-top: 13px;
    margin-right: -12px; }
    @media (min-width: 900px) {
      .filters-dropdown .filter-wrapper .filter-section {
        margin-right: 0;
        padding-top: 0px; } }
    .filters-dropdown .filter-wrapper .filter-section:last-child .filter-items {
      padding-bottom: 12px; }
  .filters-dropdown .filter-wrapper .filter-section-name {
    margin-bottom: 0px;
    display: block;
    font-size: 0.8rem;
    color: #FFF;
    font-weight: 400;
    padding-bottom: 14px; }
    @media (min-width: 900px) {
      .filters-dropdown .filter-wrapper .filter-section-name {
        float: left;
        padding-top: 6px;
        font-size: 1rem;
        padding-left: 33px;
        width: 25.6%; } }
    @media (min-width: 1800px) {
      .filters-dropdown .filter-wrapper .filter-section-name {
        font-size: 0.8rem; } }
  .filters-dropdown .filter-wrapper .filter-items {
    display: block;
    float: left;
    width: 100%;
    /*margin-top: 25px;*/ }
    @media (min-width: 900px) {
      .filters-dropdown .filter-wrapper .filter-items {
        /*padding-left: 268px;*/
        margin-top: 0px;
        padding-right: 32px;
        padding-bottom: 24px;
        width: 74.4%; } }
    .filters-dropdown .filter-wrapper .filter-items .filter-item-name {
      width: 50%;
      height: auto;
      position: relative;
      padding-bottom: 8px;
      font-weight: 400; }
      @media (min-width: 600px) {
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name {
          width: 33.33333333333333%;
          max-width: 300px; } }
      @media (min-width: 900px) {
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name {
          width: 12.5rem;
          margin-right: 24px;
          margin-bottom: 0px;
          padding-bottom: 24px; } }
      @media (min-width: 1800px) {
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name {
          width: 10rem; } }
      .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn {
        border: 1px solid #6e96db;
        color: #6e96db;
        background: transparent;
        padding: 3px 0 4px;
        margin-bottom: 0;
        font-weight: 400;
        /*@include inline-flex;
                    @include justify-content(center);
                    @include align-items(center);*/ }
        @media (min-width: 900px) {
          .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn {
            padding: 6px 0 5px; } }
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn span {
          font-size: 0.6375rem; }
          @media (min-width: 600px) {
            .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn span {
              font-size: 0.8rem; } }
          @media (min-width: 900px) {
            .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn span {
              font-size: 1rem; } }
          @media (min-width: 1800px) {
            .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn span {
              font-size: 0.8rem; } }
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn:hover {
          border: 1px solid #F1C400;
          color: #F1C400; }
      .filters-dropdown .filter-wrapper .filter-items .filter-item-name .checkbox-btn.focused-outline {
        border: 1px solid #F1C400;
        color: #F1C400; }
      .filters-dropdown .filter-wrapper .filter-items .filter-item-name .refinement-chkbox {
        opacity: 0;
        z-index: 1;
        cursor: pointer; }
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name .refinement-chkbox:hover + .checkbox-btn {
          border: 1px solid #F1C400;
          color: #F1C400; }
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name .refinement-chkbox:checked + .checkbox-btn {
          color: #0a0b0d;
          background: #6e96db; }
        .filters-dropdown .filter-wrapper .filter-items .filter-item-name .refinement-chkbox:checked:hover + .checkbox-btn {
          background: #F1C400;
          color: #000000; }
  .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name {
    font-size: 0.8rem;
    width: 33.33333333333333%; }
    @media (min-width: 600px) {
      .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name {
        width: 25%; } }
    @media (min-width: 900px) {
      .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name {
        width: 5.6875rem;
        padding-right: 10px;
        margin-right: 0;
        padding-bottom: 10px; } }
    .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name .checkbox-btn {
      margin-bottom: 0; }
      .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name .checkbox-btn span {
        font-size: 0.6375rem; }
        @media (min-width: 900px) {
          .filters-dropdown .filter-wrapper .filter-items.Release.Year .filter-item-name .checkbox-btn span {
            font-size: 0.8rem; } }

.result-container .search-records .movie-card-section-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }
  .result-container .search-records .movie-card-section-wrapper .movie-card-wrap {
    display: inline-block;
    width: 33.33333%; }
    @media (min-width: 600px) {
      .result-container .search-records .movie-card-section-wrapper .movie-card-wrap {
        width: 25%; } }
    @media (min-width: 900px) {
      .result-container .search-records .movie-card-section-wrapper .movie-card-wrap {
        width: 16.66667%; } }
    @media (min-width: 1800px) {
      .result-container .search-records .movie-card-section-wrapper .movie-card-wrap {
        width: 12.5%; } }

#ui-id-2.ui-menu.ui-autocomplete {
  position: absolute; }

.pdp-movie-card-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /*margin-left: -8px;

    @media(min-width: $screen-sm) {
        margin-left: -15px;
    }*/ }
  .pdp-movie-card-wrap .movie-card-wrap {
    display: inline-block;
    width: 33.33333%;
    margin-top: 0; }
    @media (min-width: 600px) {
      .pdp-movie-card-wrap .movie-card-wrap {
        width: 25%;
        margin: 2.7592953821656050955414012738854% 0; } }
    @media (min-width: 900px) {
      .pdp-movie-card-wrap .movie-card-wrap {
        margin: 1.723175% 0; } }
    @media (min-width: 1800px) {
      .pdp-movie-card-wrap .movie-card-wrap {
        width: 20%;
        margin: 1.38826733% 0; } }

#pdp-background-fade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 0;
  opacity: 0;
  transition: opacity linear 0.5s; }
  #pdp-background-fade.fade-in {
    opacity: 0.7; }

#pvod-background-fade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #22072e;
  z-index: 0;
  opacity: 0.3;
  transition: opacity linear 0.5s; }
  #pvod-background-fade.fade-in {
    opacity: 0.9; }

#pvod-img {
  width: 211px;
  height: 21px;
  margin-left: 0.5vw; }
  @media (min-width: 321px) and (max-width: 899px) {
    #pvod-img {
      margin-left: 1.55vw;
      width: 152px;
      height: 16px; } }
  @media (max-width: 320px) {
    #pvod-img {
      margin-left: 1.55vw;
      width: 86px;
      height: 8px; } }

.pdp-wrapper {
  position: relative;
  margin-top: 8px; }
  @media (min-width: 600px) {
    .pdp-wrapper {
      margin-top: 34px; } }
  .pdp-wrapper .pdp-closed-captions {
    font-weight: bold;
    border: solid 1px #A7A8AA;
    padding: 1px 5px; }
  .pdp-wrapper .pdp-subheading {
    font-size: 1rem;
    text-transform: none;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 0; }
    @media (min-width: 900px) {
      .pdp-wrapper .pdp-subheading {
        font-size: 1.25rem; } }
  .pdp-wrapper .pdp-total-container {
    visibility: hidden;
    position: absolute;
    left: -1000000000px;
    width: 100%; }
    .pdp-wrapper .pdp-total-container.loaded {
      visibility: visible;
      position: static;
      left: 0; }
  .pdp-wrapper .pdp-watchlist-btn {
    display: block;
    font-size: 32px;
    /*height: 32px;*/ }
    @media (min-width: 1800px) {
      .pdp-wrapper .pdp-watchlist-btn {
        font-size: 48px;
        /*height: 48px;*/ } }
    .pdp-wrapper .pdp-watchlist-btn.watchlisted .remove-watchlist {
      display: block; }
    .pdp-wrapper .pdp-watchlist-btn.watchlisted .add-watchlist {
      display: none; }
    @media (max-width: 599px) {
      .pdp-wrapper .pdp-watchlist-btn.with-expiry {
        margin-bottom: 32px; } }
    @media (min-width: 900px) and (max-width: 1350px) {
      .pdp-wrapper .pdp-watchlist-btn.with-expiry {
        margin-bottom: 32px; } }
    .pdp-wrapper .pdp-watchlist-btn .remove-watchlist {
      display: none; }
  .pdp-wrapper .wl-loader-wrap {
    height: 32px;
    width: 32px; }
    @media (min-width: 1800px) {
      .pdp-wrapper .wl-loader-wrap {
        height: 48px;
        width: 48px; } }
    .pdp-wrapper .wl-loader-wrap .wl-loader {
      height: 32px;
      width: 32px; }
      @media (min-width: 1800px) {
        .pdp-wrapper .wl-loader-wrap .wl-loader {
          height: 48px;
          width: 48px; } }
  .pdp-wrapper .pdp-information {
    margin-top: 32.5vw; }
    @media (min-width: 600px) {
      .pdp-wrapper .pdp-information {
        margin-top: calc(56.38vw - 4rem); } }
    @media (min-width: 900px) {
      .pdp-wrapper .pdp-information {
        margin-top: calc(26.96vw - 4rem); } }
    @media (min-width: 1800px) {
      .pdp-wrapper .pdp-information {
        margin-top: calc(23.96vw - 4rem); } }
    @media (max-width: 599px) {
      .pdp-wrapper .pdp-information.pdp-generic-mobile {
        margin-top: 0; } }
    @media (min-width: 600px) and (max-width: 899px) {
      .pdp-wrapper .pdp-information.pdp-generic-mobile {
        margin-top: 11.671631226590379567640900109122%; } }
    .pdp-wrapper .pdp-information.pdp-generic-mobile .pdp-generic-image {
      display: block; }
      @media (min-width: 900px) {
        .pdp-wrapper .pdp-information.pdp-generic-mobile .pdp-generic-image {
          display: none; } }
    @media (min-width: 900px) {
      .pdp-wrapper .pdp-information.pdp-generic {
        margin-top: 11.671631226590379567640900109122%; } }
    .pdp-wrapper .pdp-information .pdp-generic-image {
      width: 46.61%;
      display: none;
      padding: 0 0 15%; }
      @media (min-width: 600px) {
        .pdp-wrapper .pdp-information .pdp-generic-image {
          width: 30.859375%;
          padding-bottom: 5.75%; } }
  .pdp-wrapper .pdp-left-side-image .pdp-movie-image-wrap .movie-img {
    max-width: 93%; }
  .pdp-wrapper .pdp-generic-image {
    display: none; }
  .pdp-wrapper .pdp-right-side-information {
    font-family: MarkOT;
    font-size: 1rem; }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-information {
      font-size: 0.8rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      padding-bottom: 0.25rem;
      margin-top: 8px; }
      @media (min-width: 900px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-information {
          font-size: 1rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information.metacritic--margin > div,
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information.metacritic--margin > img {
        margin-top: 0.5rem; }
        @media (min-width: 900px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information.metacritic--margin > div,
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information.metacritic--margin > img {
            margin-top: 0rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-title-wrap {
        font-size: 1.5625rem;
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px; }
        @media (min-width: 600px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-title-wrap {
            font-size: 3.052rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-subtitle-info {
        flex-basis: 100%;
        height: 22px;
        display: flex;
        vertical-align: middle;
        flex: 1 1 calc(100% - 7rem);
        width: calc(100% - 7rem);
        white-space: nowrap; }
        @media (min-width: 900px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-subtitle-info {
            height: 29px; } }
        .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-subtitle-info .pdp-subtitle-metalogo {
          height: 42px;
          display: flex;
          align-items: center; }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-subtitle-info .pdp-subtitle-metalogo {
              height: 29px; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .title-quality-padding {
        margin-right: 8px; }
        @media (min-width: 600px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .title-quality-padding {
            margin-right: 16px; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper {
        border: 1px solid #a7a8aa;
        font-weight: 700;
        margin-right: .25rem;
        width: 2.625rem;
        height: 22px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 1rem; }
        @media (min-width: 680px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper {
            display: inline-block; } }
        @media (min-width: 900px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper {
            height: 29px; } }
        @media (min-width: 1800px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper {
            margin-bottom: 0; } }
        .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper .movie-quality {
          align-items: center;
          justify-content: center;
          height: 22px; }
          @media (min-width: 320px) {
            .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper .movie-quality {
              line-height: 23px; } }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper .movie-quality {
              height: 29px;
              line-height: 29px; } }
          @media (min-width: 1800px) {
            .pdp-wrapper .pdp-right-side-information .pdp-movie-information .movie-quality-wrapper .movie-quality {
              line-height: 30px; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-quality-label {
        height: 22px;
        margin-right: 0.5rem; }
        @media (min-width: 680px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-quality-label {
            display: inline-block; } }
        @media (min-width: 900px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-quality-label {
            height: 29px; } }
        @media (min-width: 1800px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-quality-label {
            margin-bottom: 0; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-movie-release-year {
        color: #A7A8AA; }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-rating {
        font-weight: bold;
        border: solid 1px #A7A8AA;
        padding: 0 8px;
        line-height: 22px;
        height: 22px;
        margin-right: calc(1rem - 6px); }
        @media (min-width: 900px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-rating {
            height: 29px;
            line-height: 29px; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-genres, .pdp-wrapper .pdp-right-side-information .pdp-movie-information a {
        color: #A7A8AA;
        padding: 5px 0; }
        @media (min-width: 600px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-information .pdp-genres, .pdp-wrapper .pdp-right-side-information .pdp-movie-information a {
            padding: 0; } }
    .pdp-wrapper .pdp-right-side-information .pdp-buttons {
      margin-bottom: .75rem;
      margin-top: .75rem; }
      @media (min-width: 900px) {
        .pdp-wrapper .pdp-right-side-information .pdp-buttons {
          margin-bottom: 1.5rem;
          margin-top: 1.5rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center; }
        @media (min-width: 680px) {
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            -webkit-justify-content: flex-start;
            -moz-justify-content: flex-start;
            justify-content: flex-start; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls {
          cursor: pointer;
          font-weight: normal;
          margin-right: 32px;
          margin-bottom: .75rem;
          margin-top: .75rem; }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls {
              margin-bottom: 1.5rem;
              margin-top: 1.5rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn.with-expiry .pdp-play-btn-expiry .pdp-play-btn,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls.with-expiry .pdp-play-btn-expiry .pdp-play-btn {
            margin-right: 5px; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .expiry-text,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .expiry-text {
            position: absolute;
            top: 100%;
            left: 0;
            font-size: 0.675rem;
            color: #F1C400;
            padding-top: 5px; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .expiry-text,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .expiry-text {
                font-size: 0.8rem; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .expiry-text span,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .expiry-text span {
              white-space: nowrap; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .play-btn-link, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-play, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-mylibrary,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .play-btn-link,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-play,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-mylibrary {
            color: #F1C400;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            align-items: center;
            -webkit-box-direction: normal;
            -webkit-box-orient: horizontal;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-play, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-mylibrary,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-play,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-mylibrary {
            width: 3rem;
            height: 3rem;
            font-size: 3rem;
            position: relative;
            left: -0.25rem; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-play, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-mylibrary,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-play,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-mylibrary {
                width: 4rem;
                height: 4rem;
                font-size: 4rem; } }
            @media (min-width: 900px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-play, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .store-icon-mylibrary,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-play,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .store-icon-mylibrary {
                width: 6rem;
                height: 6rem;
                font-size: 6rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .play-btn-link,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .play-btn-link {
            font-size: 0.8rem; }
            @media (min-width: 900px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn .play-btn-link,
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls .play-btn-link {
                font-size: 1rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn:hover,
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls:hover {
            text-shadow: 0px 1px 0px #F1C400; }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn:hover span:hover,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls:hover span:hover {
              text-shadow: none;
              font-weight: bold; }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased {
          margin-right: 32px;
          margin-bottom: .75rem;
          margin-top: .75rem;
          /*&.with-expiry {
                        margin-bottom: 2rem;
                    }*/ }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased {
              margin-bottom: 1.5rem;
              margin-top: 1.5rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased i {
            font-size: 2rem; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased i {
                font-size: 3rem; } }
            @media (min-width: 900px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased i {
                font-size: 4rem; } }
            @media (min-width: 1800px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased i {
                font-size: 4.5rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased a {
            font-size: 0.8rem;
            line-height: 1; }
            @media (min-width: 900px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased a {
                font-size: 1rem;
                line-height: 1.25; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info {
          position: static;
          top: 100%;
          left: 0;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          -moz-justify-content: flex-start;
          justify-content: flex-start;
          width: 100%;
          padding-top: 16px;
          text-align: center;
          font-size: 0.8rem;
          color: #89a8e1;
          white-space: nowrap; }
          @media (min-width: 680px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info {
              position: absolute;
              max-width: 128px;
              text-align: left; } }
          @media (min-width: 620px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info {
              max-width: 100%; } }
          @media (min-width: 1350px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info {
              font-size: 1rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info .pdp-scene-info {
            font-size: 1.75rem;
            margin-right: 0.25rem;
            display: none; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info .pdp-scene-info {
                display: inline-block; } }
            @media (min-width: 1350px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-deal-info .pdp-scene-info {
                font-size: 2rem; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap {
          position: relative; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn {
            text-align: center;
            padding: 0 0 3px;
            width: 5.625rem; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn {
                width: 10.75rem;
                padding: 7px; } }
            @media (min-width: 900px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn {
                width: 12rem;
                padding: 12px 22px; } }
            @media (min-width: 1350px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn {
                width: 13.125rem; } }
            @media (min-width: 1800px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn {
                width: 13.1rem; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.super-ticket-hd-upgrade-btn, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preordered-btn {
              width: auto;
              padding-left: 5px;
              padding-right: 5px;
              margin-top: -16px; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.super-ticket-hd-upgrade-btn, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preordered-btn {
                  width: 10.75rem;
                  padding: 7px;
                  margin-top: 0; } }
              @media (min-width: 600px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.super-ticket-hd-upgrade-btn, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preordered-btn {
                  width: 12.75rem;
                  padding: 12px 22px; } }
              @media (min-width: 900px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.super-ticket-hd-upgrade-btn, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preordered-btn {
                  width: 16rem;
                  padding: 12px 22px; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preorder-btn {
              line-height: 0.75; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preorder-btn {
                  line-height: 1.5625em; } }
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preorder-btn .movie-price {
                margin-top: 0; }
                @media (min-width: 680px) {
                  .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn.pdp-preorder-btn .movie-price {
                    margin-top: -10px; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .buy-rent-text, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .from-text {
              font-size: 0.6375rem; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .buy-rent-text, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .from-text {
                  font-size: 1rem; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .from-text {
              line-height: 1rem; }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .movie-price {
              font-size: 1rem;
              margin-top: -10px; }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .movie-price {
              display: block; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-buy-rent-btn .movie-price {
                  display: inline-block; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn {
            cursor: default;
            font-size: 0.6375rem;
            padding: 8px 5px; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn {
                padding: 0 5px;
                font-size: 1rem; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn:hover, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn:focus, .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn:active {
              color: #FFF;
              border-color: #FFF;
              opacity: 1; }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn .store-icon-check {
              display: none;
              border: 1px solid #FFF;
              padding: 3px;
              border-radius: 50%;
              margin-right: 5px; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan .pdp-rent-wrap .pdp-preordered-btn .store-icon-check {
                  display: inline-block; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing {
          margin-bottom: .75rem;
          margin-top: .75rem; }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing {
              margin-bottom: 1.5rem;
              margin-top: 1.5rem; } }
          @media (max-width: 679px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing {
              width: 100%;
              margin-top: 1rem; }
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing .two-btn-wrap {
                width: 100%; }
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing .two-btn-wrap .price-btn {
                  width: 100%; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing .two-btn-wrap.pvodRent:hover .pvod-price-btn.rent {
            border: 1px solid #f1c400;
            color: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .harmonized-pricing .two-btn-wrap.pvodRent:hover .pvod-sale-info.rent {
            background: #f1c400; }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .single-btn-wrap {
          margin-top: 16px; }
          @media (min-width: 680px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .single-btn-wrap {
              margin-top: 0; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .single-btn-wrap .price-btn {
            width: 100%; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .single-btn-wrap .price-btn {
                width: auto; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap {
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          position: relative;
          width: 100%; }
          @media (min-width: 680px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap {
              width: auto; } }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap {
              width: 100%; } }
          @media (min-width: 1350px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap {
              width: auto; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap.alt-plans-in {
            display: block; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap {
            width: 100%;
            margin-top: 1.25rem;
            margin-right: 2rem; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap {
                width: auto; } }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap:first-child {
                margin: 1.5rem 2rem 1.5rem 0; } }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap + .two-btn-wrap {
                margin: 1.5rem 2rem 1.5rem 0; } }
            @media (min-width: 1800px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap + .two-btn-wrap {
                margin-right: 3rem; } }
            @media (max-width: 679px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap .price-btn {
                width: 100%;
                padding-left: 0;
                padding-right: 0; } }
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap .pdp-deal-info {
              display: none; }
              @media (min-width: 680px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap .pdp-deal-info {
                  display: block; } }
              @media (min-width: 900px) {
                .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap .pdp-deal-info {
                  font-size: 0.8rem; } }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodBuy:hover .pvod-price-btn.buy {
            border: 1px solid #f1c400;
            color: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodBuy:hover .pvod-sale-info.buy {
            background: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodRent:hover .pvod-price-btn.rent {
            border: 1px solid #f1c400;
            color: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodRent:hover .pvod-sale-info.rent {
            background: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodPre:hover .pvod-price-btn.pre {
            border: 1px solid #f1c400;
            color: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .two-btn-wrap.pvodPre:hover .pvod-sale-info.pre {
            background: #f1c400; }
          .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .pdp-max-reward {
            text-align: left;
            position: static;
            display: block; }
            @media (min-width: 680px) {
              .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .two-btn-pricing-wrap .pdp-max-reward {
                display: none; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn:first-child,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls:first-child,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased:first-child,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn:first-child,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan:first-child,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade:first-child {
          margin-left: 0; }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .pdp-trailer-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .pdp-owned-controls,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .pdp-trailer-btn-purchased,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .pdp-watchlist-btn,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
          margin-left: 0; }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade ~ .pricing-plan,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
          padding-top: 16px;
          margin-left: 0; }
          @media (min-width: 680px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
              padding-top: 0px;
              margin-right: 32px; } }
          @media (min-width: 900px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
              padding-top: 0px;
              margin-right: 32px; } }
          @media (min-width: 1350px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
              padding-top: 0px;
              margin-right: 32px; } }
          @media (min-width: 1800px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .super-ticket-hd-upgrade,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade ~ .pricing-plan,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .super-ticket-hd-upgrade {
              margin-right: 48px; } }
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .single-btn-wrap,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .single-btn-wrap,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .single-btn-wrap,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .single-btn-wrap,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .single-btn-wrap,
        .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .single-btn-wrap {
          margin-left: 0px; }
          @media (min-width: 680px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .single-btn-wrap {
              margin-left: 32px; } }
          @media (min-width: 1800px) {
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-owned-controls + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-trailer-btn-purchased + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pdp-watchlist-btn + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .pricing-plan + .single-btn-wrap,
            .pdp-wrapper .pdp-right-side-information .pdp-buttons .pdp-button-wrap .super-ticket-hd-upgrade + .single-btn-wrap {
              margin-left: 48px; } }
    @media (min-width: 680px) {
      .pdp-wrapper .pdp-right-side-information .mobile-padding {
        margin-bottom: 4rem; } }
    .pdp-wrapper .pdp-right-side-information .pricing-faq {
      font-family: MarkOT;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      font-size: 1rem;
      line-height: 39px;
      color: #ffffff;
      margin-bottom: 1.375rem; }
      @media (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .pricing-faq {
          margin-top: 4px; } }
      @media (min-width: 600px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .pricing-faq {
          font-size: 1rem;
          line-height: 1.95; } }
      @media (min-width: 320px) and (max-width: 599px) {
        .pdp-wrapper .pdp-right-side-information .pricing-faq {
          font-size: 0.8rem;
          line-height: 1.95; } }
    .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig {
      font-family: MarkOT;
      margin: 0px;
      font-size: 1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      color: #ffffff;
      vertical-align: middle;
      height: 26px; }
      .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig.synopsis-space {
        margin-bottom: 48px; }
        @media (min-width: 320px) and (max-width: 599px) {
          .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig.synopsis-space {
            margin-bottom: 35px; } }
  @media (min-width: 320px) and (max-width: 599px) and (min-width: 320px) and (max-width: 415px) {
    .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig.synopsis-space.fr-spacing {
      height: 42px; } }
      @media (min-width: 680px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig {
          margin-top: 45px; } }
      @media (min-width: 600px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig {
          font-size: 1rem;
          line-height: 1.53;
          height: 22px; } }
      @media (min-width: 320px) and (max-width: 599px) {
        .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig {
          font-size: 0.8rem;
          line-height: 1.95;
          height: 21px; } }
      .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig .not-eligible-icon {
        width: 26px;
        height: 26px;
        background-image: url("https://storemedia.cineplex.com/1cineplexstoreassets/promo/icon_info_24px.svg");
        background-size: 100% 100%;
        float: left;
        margin-right: 6px; }
        @media (min-width: 600px) and (max-width: 899px) {
          .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig .not-eligible-icon {
            width: 22px;
            height: 22px; } }
        @media (max-width: 599px) {
          .pdp-wrapper .pdp-right-side-information .not-eligible-for-dig .not-eligible-icon {
            width: 21px;
            height: 21px; } }
    .pdp-wrapper .pdp-right-side-information .dc-plus-form {
      font-size: 0.8rem; }
      @media (min-width: 900px) {
        .pdp-wrapper .pdp-right-side-information .dc-plus-form {
          font-size: 1rem; } }
      .pdp-wrapper .pdp-right-side-information .dc-plus-form .dc-plus-form-popup-btn {
        border: none;
        background: transparent;
        padding: 0;
        font-weight: 600;
        outline: none; }
        .pdp-wrapper .pdp-right-side-information .dc-plus-form .dc-plus-form-popup-btn:hover {
          color: #F1C400; }
      .pdp-wrapper .pdp-right-side-information .dc-plus-form .dc-plus-submit-btn {
        width: 100%; }
        @media (min-width: 600px) {
          .pdp-wrapper .pdp-right-side-information .dc-plus-form .dc-plus-submit-btn {
            width: 50%; } }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-area {
      line-height: 1.95; }
    @media (min-width: 320px) and (max-width: 599px) {
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details.top-synopsis-space {
        margin-top: 25px; } }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis {
      font-size: 1rem; }
      @media (min-width: 600px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis {
          font-size: 1rem; } }
      @media (min-width: 320px) and (max-width: 599px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis {
          font-size: 0.8rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-1,
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-2,
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-3 {
        display: inline-block;
        font-size: 1rem; }
        @media (min-width: 600px) and (max-width: 899px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-1,
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-2,
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-3 {
            font-size: 1rem; } }
        @media (min-width: 320px) and (max-width: 599px) {
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-1,
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-2,
          .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-3 {
            font-size: 0.8rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-2 {
        color: #A7A8AA; }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-main-synopsis .line-3 {
        color: #75787b; }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-details .dots-before-read-more {
      color: #75787b; }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-details .read-more-button {
      display: inline;
      border: none;
      background: transparent;
      color: #FFF;
      text-align: left;
      padding-left: 0;
      padding-right: 0;
      font-size: 1rem; }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .read-more-button:hover {
        color: #F1C400; }
      @media (min-width: 600px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .read-more-button {
          font-size: 1rem; } }
      @media (min-width: 320px) and (max-width: 599px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .read-more-button {
          font-size: 0.8rem; } }
    .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body {
      color: #d8d8d8;
      font-size: 1rem; }
      @media (min-width: 600px) and (max-width: 899px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body {
          font-size: 1rem; } }
      @media (min-width: 320px) and (max-width: 599px) {
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body {
          font-size: 0.8rem; } }
      .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body a {
        color: #d8d8d8; }
        .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body a:hover, .pdp-wrapper .pdp-right-side-information .pdp-movie-details .pdp-info-body a:focus {
          color: #F1C400; }
  .pdp-wrapper .related-movies-section {
    /*.playlist-next-indicator {
            left: auto;
            right: 0.5rem;
        }

        .playlist-back-indicator {
            left: 0.5rem;
            right: auto;
        }*/ }
    @media (min-width: 900px) {
      .pdp-wrapper .related-movies-section .slick-list.draggable {
        overflow: hidden; } }
    .pdp-wrapper .related-movies-section .movie-card-slider-wrap {
      margin: 0; }
    .pdp-wrapper .related-movies-section .movie-card-wrap .movie {
      padding-top: 9%;
      padding-bottom: 9%; }
    .pdp-wrapper .related-movies-section .card-section-title {
      padding-bottom: 1rem;
      margin-bottom: 0; }
  .pdp-wrapper .pdp-purchase-overlay {
    white-space: nowrap; }

.pricing-btn-wrap {
  width: 100%; }
  @media (min-width: 600px) {
    .pricing-btn-wrap {
      width: auto; } }
  .pricing-btn-wrap.with-special .price-btn {
    border: 1px solid #89a8e1; }
  .pricing-btn-wrap.with-special .pvod-price-btn {
    border: 1px solid #9760c8; }
  .pricing-btn-wrap.with-special .pdp-sale-info {
    background: #89a8e1;
    color: #0a0b0d;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    font-size: 0.85rem; }
    @media (min-width: 900px) {
      .pricing-btn-wrap.with-special .pdp-sale-info {
        font-size: 1rem;
        line-height: 1.5; } }
  .pricing-btn-wrap.with-special .pvod-sale-info {
    background: #9760c8;
    color: #0a0b0d;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    font-size: 0.85rem; }
    @media (min-width: 900px) {
      .pricing-btn-wrap.with-special .pvod-sale-info {
        font-size: 1rem;
        line-height: 1.5; } }
  .pricing-btn-wrap.multi-btn-wrap .pvod-price-btn {
    border: 1px solid #9760c8; }
    @media (max-width: 900px) {
      .pricing-btn-wrap.multi-btn-wrap .pvod-price-btn {
        width: 100%;
        margin-bottom: 1.25rem; } }
  .pricing-btn-wrap.multi-btn-wrap .pdp-sale-info {
    position: absolute;
    bottom: 100%;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: -1px; }
    @media (min-width: 900px) {
      .pricing-btn-wrap.multi-btn-wrap .pdp-sale-info {
        width: 66.6666%; } }
  .pricing-btn-wrap.multi-btn-wrap .pvod-sale-info {
    position: absolute;
    bottom: 100%;
    background: #9760c8;
    color: #0a0b0d;
    font-weight: 700;
    font-style: italic;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: -1px; }
    @media (min-width: 900px) {
      .pricing-btn-wrap.multi-btn-wrap .pvod-sale-info {
        width: 66.6666%;
        line-height: 1.5rem; } }
  .pricing-btn-wrap .pdp-deal-info {
    color: #89a8e1; }

#pdp-price-modal .generic-img-wrap {
  display: none;
  padding-bottom: 42px; }

@media (min-width: 900px) {
  #pdp-price-modal.pdp-generic-mobile .generic-img-wrap {
    display: block; } }

#pdp-price-modal.pdp-generic-mobile .generic-img-wrap .generic-img {
  max-height: 238px;
  max-width: 158px; }

#pdp-price-modal.pdp-generic-mobile .modal-header {
  padding-top: 48px; }

@media (max-width: 599px) {
  #rental-confirm-modal .modal-footer {
    padding-top: 32px; } }

.watch-btn-wrap {
  display: none; }

.player {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1060;
  /* Sit on top */
  /*padding-top: 100px; */
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

#checkout-wrap {
  margin-top: 2rem;
  /*.order-success-modal.in,
    .order-failure-modal.in{
        background-color: #151b24;

        @media(min-width: $screen-sm) {
            background-color: transparent;
        }
    }*/ }
  #checkout-wrap .order-failure-modal .modal-content .modal-title {
    margin-top: 34px; }
  #checkout-wrap .order-failure-modal .modal-content .modal-footer {
    padding: 32px 32px 0; }
  #checkout-wrap .edit-btn-wrap {
    margin: auto;
    margin-right: 0; }
  #checkout-wrap .edit-btn {
    border: none;
    background: transparent;
    font-weight: bold;
    margin: auto;
    margin-right: 0;
    padding: 0;
    outline: none;
    color: #FFF; }
    @media (min-width: 900px) {
      #checkout-wrap .edit-btn {
        font-size: 1.25rem; } }
    #checkout-wrap .edit-btn:hover, #checkout-wrap .edit-btn:focus {
      color: #F1C400; }
  #checkout-wrap .add-card-button {
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    #checkout-wrap .add-card-button .add-button {
      font-weight: bold;
      color: #FFF; }
      #checkout-wrap .add-card-button .add-button:hover {
        color: #F1C400; }
  #checkout-wrap .checkout-subtext {
    font-size: 0.8rem; }
    @media (min-width: 800px) {
      #checkout-wrap .checkout-subtext {
        font-size: 1rem; } }
  @media (min-width: 600px) {
    #checkout-wrap .order-button {
      font-size: 0.8rem;
      padding: 8px 8px; } }
  @media (min-width: 900px) {
    #checkout-wrap .order-button {
      padding: 0.75em 1em 0.6875em;
      font-size: 1.25rem; } }
  #checkout-wrap .section-scene {
    margin-top: 0px;
    margin-bottom: 0px; }
  #checkout-wrap .section-title {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 32px;
    margin-top: 0; }
    @media (min-width: 900px) {
      #checkout-wrap .section-title {
        font-size: 1.5625rem; } }
    #checkout-wrap .section-title.scene-title {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -moz-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      -webkit-align-items: baseline;
      -moz-align-items: baseline;
      align-items: baseline; }
    #checkout-wrap .section-title .scene-title-logo {
      height: 1.5rem; }
      #checkout-wrap .section-title .scene-title-logo.fr-align {
        margin-left: 10px; }
      @media (min-width: 900px) {
        #checkout-wrap .section-title .scene-title-logo {
          height: 1.953125rem; } }
  #checkout-wrap .co-scaled-text {
    font-size: 1rem; }
    @media (min-width: 800px) {
      #checkout-wrap .co-scaled-text {
        font-size: 1.25rem; } }
  #checkout-wrap .paypal-account-details {
    width: calc(100% - 4rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #checkout-wrap .scene-gift-card-error-box {
    font-size: 1rem;
    z-index: 2; }
    @media (min-width: 800px) {
      #checkout-wrap .scene-gift-card-error-box {
        margin-left: -1.875rem; } }
  #checkout-wrap .order-success-modal.in {
    display: block; }
  #checkout-wrap .order-success-modal .success-message {
    font-size: 1.25rem; }
  #checkout-wrap .order-success-modal .modal-content {
    padding-bottom: 32px; }
    @media (min-width: 900px) {
      #checkout-wrap .order-success-modal .modal-content {
        padding: 0; } }
  #checkout-wrap .order-success-modal .order-success-modal-footer {
    font-size: 0.75rem;
    margin-top: 16px;
    display: none; }
    @media (min-width: 800px) {
      #checkout-wrap .order-success-modal .order-success-modal-footer {
        margin-top: 0; } }
  #checkout-wrap .rental-modal.order-success-modal .modal-content {
    padding-bottom: 64px; }
    @media (min-width: 900px) {
      #checkout-wrap .rental-modal.order-success-modal .modal-content {
        padding: 0; } }
  #checkout-wrap .rental-modal.order-success-modal .order-success-modal-footer {
    display: block; }

.checkradio {
  vertical-align: top; }

.checkout-checkboxes {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.modal-selection-options {
  padding-left: 1rem;
  padding-right: 40px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }
  @media (min-width: 900px) {
    .modal-selection-options {
      padding-left: 46px; } }
  .modal-selection-options .paypal-user-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding-bottom: 4px;
    max-width: 5rem; }
    @media (min-width: 800px) {
      .modal-selection-options .paypal-user-text {
        max-width: 12rem; } }
    @media (min-width: 900px) {
      .modal-selection-options .paypal-user-text {
        max-width: 20rem; } }

.checkout-error {
  padding-bottom: 100px; }
  @media (min-width: 800px) {
    .checkout-error {
      padding-bottom: 200px; } }
  @media (min-width: 900px) {
    .checkout-error {
      padding-bottom: 400px; } }

.checkout-loader-wrap {
  visibility: hidden;
  position: absolute;
  left: -1000000000px;
  width: 100%; }
  .checkout-loader-wrap.checkout-loaded {
    visibility: visible;
    position: static;
    left: 0; }

.top-row {
  float: none; }

.card-modal {
  width: 100vw;
  height: 100vh;
  padding-right: 0 !important;
  padding-top: 10vh;
  padding-bottom: 10vh; }
  @media (min-width: 800px) {
    .card-modal {
      font-size: 1.25rem;
      /*height: auto;
        max-height: 80vh;*/ } }
  .card-modal.in {
    background: transparent;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
  .card-modal .card-modal-container {
    background: #151b24;
    width: 90.625%;
    margin: auto 0; }
    @media (min-width: 800px) {
      .card-modal .card-modal-container {
        width: 90.625%;
        max-width: 662px; } }
    @media (min-width: 1800px) {
      .card-modal .card-modal-container {
        max-width: 882px; } }
  .card-modal .modal-header {
    background: #1a2232;
    height: auto;
    border-width: 0;
    position: relative;
    width: 100%;
    float: left;
    padding: 32px; }
    @media (min-width: 900px) {
      .card-modal .modal-header {
        padding: 40px; } }
    @media (min-width: 1800px) {
      .card-modal .modal-header {
        padding: 48px; } }
    .card-modal .modal-header .store-icon-close {
      position: absolute;
      cursor: pointer;
      top: 1rem;
      right: 1rem;
      font-size: 1.25rem; }
      @media (min-width: 900px) {
        .card-modal .modal-header .store-icon-close {
          font-size: 1.5rem; } }
      .card-modal .modal-header .store-icon-close:hover, .card-modal .modal-header .store-icon-close:focus {
        color: #F1C400; }
    .card-modal .modal-header .card-modal-header-add-button {
      vertical-align: top; }
      .card-modal .modal-header .card-modal-header-add-button .glyphicon-plus {
        vertical-align: top;
        font-size: 1.25rem;
        line-height: 1.25;
        margin-top: -0.25rem; }
        @media (min-width: 800px) {
          .card-modal .modal-header .card-modal-header-add-button .glyphicon-plus {
            font-size: 2.25rem;
            line-height: 1;
            margin-top: -0.5rem; } }
    .card-modal .modal-header .modal-title {
      margin-bottom: 16px;
      font-size: 1.5625rem;
      color: #FFF; }
    .card-modal .modal-header .add-paypal-button {
      display: block;
      margin-top: 10px; }
      @media (min-width: 800px) {
        .card-modal .modal-header .add-paypal-button {
          display: inline-block;
          margin-top: 0px;
          margin-left: 48px; } }
  .card-modal .modal-content {
    background: transparent;
    height: auto;
    box-shadow: none;
    border: none;
    width: 100%;
    float: left;
    padding-bottom: 64px; }
    @media (min-width: 800px) {
      .card-modal .modal-content {
        padding-bottom: 48px; } }
    .card-modal .modal-content .credit-card-modal-list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center; }
    .card-modal .modal-content a {
      display: block;
      margin-left: 48px; }
      .card-modal .modal-content a.for-card-modals {
        margin-top: 32px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between; }
      .card-modal .modal-content a input {
        margin-right: 6%; }
      .card-modal .modal-content a .credit-card-img {
        width: 56px;
        height: 28px;
        border: 1px solid #FFF; }
        @media (min-width: 800px) {
          .card-modal .modal-content a .credit-card-img {
            width: 80px;
            height: 40px; } }
        @media (min-width: 1800px) {
          .card-modal .modal-content a .credit-card-img {
            width: 96px;
            height: 48px; } }
      .card-modal .modal-content a:hover.credit-card-modal-list, .card-modal .modal-content a:focus.credit-card-modal-list, .card-modal .modal-content a:active.credit-card-modal-list {
        color: #F1C400;
        font-weight: bold; }
      .card-modal .modal-content a:hover .faux-input, .card-modal .modal-content a:focus .faux-input, .card-modal .modal-content a:active .faux-input {
        border-color: #F1C400; }
      .card-modal .modal-content a.option-selected:hover, .card-modal .modal-content a.option-selected:focus {
        color: #F1C400; }
        .card-modal .modal-content a.option-selected:hover .faux-input, .card-modal .modal-content a.option-selected:focus .faux-input {
          background: #F1C400;
          border-color: #F1C400; }
        .card-modal .modal-content a.option-selected:hover .credit-card-img, .card-modal .modal-content a.option-selected:focus .credit-card-img {
          border-color: #F1C400; }
  .card-modal .card-modal-subtext {
    font-size: 0.8rem; }
  @media (min-width: 900px) {
    .card-modal.billing-address-modal .modal-content a.for-card-modals {
      display: block; } }

.scene-points-earned {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline;
  font-size: 0.8rem; }
  @media (min-width: 900px) {
    .scene-points-earned {
      font-size: 1rem; } }
  .scene-points-earned .checkout-scene-logo {
    height: 16px; }
    @media (min-width: 800px) {
      .scene-points-earned .checkout-scene-logo {
        height: 12px; } }
    @media (min-width: 740px) {
      .scene-points-earned .checkout-scene-logo {
        height: 16px; } }
    @media (min-width: 900px) {
      .scene-points-earned .checkout-scene-logo {
        height: 20px; } }
  .scene-points-earned.hide-scene-points-earned {
    display: none !important; }

.checkout-payment-checkboxes:hover .checkradio .faux-input, .checkout-payment-checkboxes:focus .checkradio .faux-input {
  border-color: #F1C400; }

.checkout-payment-checkboxes.disable-checkbox {
  opacity: 0.3; }
  .checkout-payment-checkboxes.disable-checkbox:hover, .checkout-payment-checkboxes.disable-checkbox:focus {
    cursor: not-allowed;
    color: #FFF; }
    .checkout-payment-checkboxes.disable-checkbox:hover .checkradio, .checkout-payment-checkboxes.disable-checkbox:focus .checkradio {
      cursor: not-allowed; }
      .checkout-payment-checkboxes.disable-checkbox:hover .checkradio .checkbox, .checkout-payment-checkboxes.disable-checkbox:focus .checkradio .checkbox {
        cursor: not-allowed; }
      .checkout-payment-checkboxes.disable-checkbox:hover .checkradio .faux-input, .checkout-payment-checkboxes.disable-checkbox:focus .checkradio .faux-input {
        border-color: #FFF; }

.checkout-payment-checkboxes.checkbox-active:hover .checkradio .faux-input, .checkout-payment-checkboxes.checkbox-active:focus .checkradio .faux-input {
  background: #F1C400;
  border-color: #F1C400; }

.checkout-payment-checkboxes .checkradio .faux-input {
  border-color: #d8d8d8; }

.checkout-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }
  @media (min-width: 800px) {
    .checkout-container {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }

.purchase-item.for-mobile {
  margin-left: -5.17241%;
  margin-right: -5.17241%;
  padding-left: 6.25%;
  padding-right: 6.25%; }

@media (min-width: 800px) {
  .purchase-item {
    margin-top: 32px; } }

@media (min-width: 1200px) {
  .purchase-item {
    margin-top: 0px; } }

.purchase-item .purchase-selections {
  /*@include flexbox();
        @include align-items(flex-start);*/ }
  .purchase-item .purchase-selections .movie-purchase-plan {
    color: #d8d8d8;
    font-size: 1.25rem; }
    @media (min-width: 900px) {
      .purchase-item .purchase-selections .movie-purchase-plan {
        font-size: 1.5625rem; } }
  .purchase-item .purchase-selections .movie-purchase-quality {
    font-size: 0.8rem;
    line-height: 1;
    border: 1px solid #a7a8aa;
    padding: 0.3125rem 0 0.1875rem;
    width: 2.25rem; }
    @media (min-width: 900px) {
      .purchase-item .purchase-selections .movie-purchase-quality {
        font-size: 1rem;
        padding: 0.3125rem 0;
        width: 2.625rem; } }
  .purchase-item .purchase-selections .uhd-label {
    height: 1.375rem;
    padding-left: 0.25rem; }
    @media (min-width: 900px) {
      .purchase-item .purchase-selections .uhd-label {
        height: 1.75rem; } }

.purchase-item .pdp-movie-title {
  font-size: 1.5625rem;
  color: #FFF;
  line-height: 1.25;
  margin-bottom: 0px; }
  @media (min-width: 800px) {
    .purchase-item .pdp-movie-title {
      font-size: 1.95625rem; } }
  @media (min-width: 900px) {
    .purchase-item .pdp-movie-title {
      margin-top: 8px;
      font-size: 2.44125rem; } }

.checkout-left-side {
  float: none;
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1; }
  @media (min-width: 800px) {
    .checkout-left-side {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -moz-order: 0;
      -ms-flex-order: 0;
      order: 0;
      width: 53.46674%;
      padding-right: 0;
      margin-right: 5.46%; } }
  @media (min-width: 900px) {
    .checkout-left-side {
      width: 56.537%;
      margin-right: 15.082%; } }
  @media (min-width: 1200px) {
    .checkout-left-side {
      width: 58.333333333333333333333333333333%;
      margin-right: 15.437037037037037%; } }
  @media (min-width: 1800px) {
    .checkout-left-side {
      width: 50%;
      margin-right: 23.29%; } }
  @media (min-width: 800px) {
    .checkout-left-side .checkout-image-container {
      width: 19.53125vw; } }
  @media (min-width: 900px) {
    .checkout-left-side .checkout-image-container {
      width: 28.57%;
      padding-right: 0.81967vw; } }
  @media (min-width: 1800px) {
    .checkout-left-side .checkout-image-container {
      width: 25%; } }
  .checkout-left-side .purchase-button-area {
    float: right;
    color: #FFF; }
    @media (min-width: 800px) {
      .checkout-left-side .purchase-button-area {
        float: left; } }
    @media (min-width: 900px) {
      .checkout-left-side .purchase-button-area {
        width: 71.43%;
        padding-left: 0.81967vw; } }
    @media (min-width: 1800px) {
      .checkout-left-side .purchase-button-area {
        width: 75%; } }
    .checkout-left-side .purchase-button-area .checkout-bundle-section {
      margin-top: 30px;
      margin-bottom: 76px; }
      @media (min-width: 800px) {
        .checkout-left-side .purchase-button-area .checkout-bundle-section {
          margin-top: 0px; } }
      .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button {
        font-size: 1rem;
        border: none;
        background: transparent;
        padding: 0;
        text-align: left;
        outline: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        padding-top: 0; }
        @media (min-width: 800px) {
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button {
            padding-top: 1rem; } }
        @media (min-width: 900px) {
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button {
            padding-top: 0;
            font-size: 1.5625rem; } }
        .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button .bundle-expand-icon {
          display: inline-block;
          border: 2px solid #d8d8d8;
          border-radius: 50%;
          height: 38px;
          width: 38px;
          font-size: 38px; }
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button .bundle-expand-icon .bundle-expand-plus {
            display: inline-block;
            position: relative;
            text-align: center;
            font-size: 38px;
            height: 38px;
            width: 38px;
            top: -6px;
            left: -1px; }
        .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button:hover, .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button:focus {
          color: #F1C400; }
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button:hover .bundle-expand-icon, .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-button:focus .bundle-expand-icon {
            border-color: #F1C400; }
      .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section {
        padding-right: 0px !important;
        background: #090A0C; }
        @media (min-width: 800px) {
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section {
            background: transparent; } }
        .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section .bundle-purchase-image {
          width: 138px;
          height: auto; }
        .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section .checkout-bundle-modal-content {
          border-width: 0;
          background-image: url("/Content/img2017/rock-background-modal.png");
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-color: #090A0C; }
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section .checkout-bundle-modal-content .modal-title {
            font-size: 1.95625rem;
            font-weight: 700; }
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section .checkout-bundle-modal-content .bundle-link {
            font-size: 1rem;
            text-transform: uppercase;
            margin-left: 0;
            margin-bottom: 30px; }
          .checkout-left-side .purchase-button-area .checkout-bundle-section .checkout-bundle-modal-section .checkout-bundle-modal-content .checkout-bundle-modal-back-btn {
            padding: 10px 0;
            width: 50%; }
    .checkout-left-side .purchase-button-area #sceneCard {
      /*.selected-info {

                .select-card {

                    @include flexbox();
                    @include align-items(center);
                }
            }*/ }
      .checkout-left-side .purchase-button-area #sceneCard .scene-card-name {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .checkout-left-side .purchase-button-area #sceneCard .scene-card-name.for-modal {
          max-width: 7rem; }
      .checkout-left-side .purchase-button-area #sceneCard #sceneCardsSummaryDetails .payment-info-line {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        justify-content: flex-start; }
      .checkout-left-side .purchase-button-area #sceneCard .redeem-message {
        font-size: 0.8rem;
        vertical-align: top;
        color: #d8d8d8; }
        @media (min-width: 1000px) {
          .checkout-left-side .purchase-button-area #sceneCard .redeem-message {
            font-size: 1.25rem; } }
    .checkout-left-side .purchase-button-area .payment-method-header {
      width: 100%;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .checkout-left-side .purchase-button-area .payment-method-header h2 {
        font-size: 1.5625rem;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 1.25; }
      .checkout-left-side .purchase-button-area .payment-method-header .payment-title {
        white-space: nowrap; }
      .checkout-left-side .purchase-button-area .payment-method-header .payment-images {
        white-space: nowrap;
        display: block; }
        .checkout-left-side .purchase-button-area .payment-method-header .payment-images .payment-method-img {
          height: 21px; }
    .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
      display: none; }
      @media (min-width: 330px) {
        .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
          display: inline-block; } }
      @media (min-width: 800px) {
        .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
          display: none; } }
      @media (min-width: 645px) {
        .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
          display: inline-block; } }
      @media (min-width: 900px) {
        .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
          display: none; } }
      @media (min-width: 1000px) {
        .checkout-left-side .purchase-button-area #paymentCard #creditCardsSummaryDetails .credit-card-hidden-part {
          display: inline-block; } }
    .checkout-left-side .purchase-button-area #giftCard .pay-with-gift-card-text {
      font-size: 0.8rem;
      color: #d8d8d8; }
      @media (min-width: 1000px) {
        .checkout-left-side .purchase-button-area #giftCard .pay-with-gift-card-text {
          font-size: 1.25rem; } }
    .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area {
      position: relative;
      background: #202733;
      display: none; }
      .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-code-text,
      .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .entered-promo-code {
        display: inline-block;
        border: none;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 15px;
        padding-right: 5rem;
        width: 100%;
        background: transparent;
        outline: none;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis; }
        @media (min-width: 900px) {
          .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-code-text,
          .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .entered-promo-code {
            font-size: 1.25rem;
            padding-right: 7rem; } }
        @media (max-width: 799px) {
          .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-code-text.fr,
          .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .entered-promo-code.fr {
            font-size: 12px; } }
        .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-code-text::placeholder,
        .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .entered-promo-code::placeholder {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
      .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-btn {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        border: none;
        outline: none;
        background: transparent;
        padding: 10px 16px;
        font-weight: 700;
        color: #FFF; }
        @media (max-width: 799px) {
          .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-btn.fr {
            font-size: 14px; } }
        .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-btn:hover, .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-btn:focus {
          color: #F1C400; }
        .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area .promo-btn.promo-apply-btn-visible {
          display: block; }
      .checkout-left-side .purchase-button-area #promoCode .promo-code-entry-area.promo-area-loaded {
        display: block; }
    .checkout-left-side .purchase-button-area #promoCode .checkout-promo-loader {
      padding: 0; }
    .checkout-left-side .purchase-button-area #promoCode .promo-code-wrap-valid-code .promo-code-line {
      width: 100%; }
      @media (min-width: 900px) {
        .checkout-left-side .purchase-button-area #promoCode .promo-code-wrap-valid-code .promo-code-line {
          font-size: 1.25rem; } }
      .checkout-left-side .purchase-button-area #promoCode .promo-code-wrap-valid-code .promo-code-line .promo-btn-valid-code {
        font-weight: bold;
        cursor: pointer; }
        .checkout-left-side .purchase-button-area #promoCode .promo-code-wrap-valid-code .promo-code-line .promo-btn-valid-code:hover, .checkout-left-side .purchase-button-area #promoCode .promo-code-wrap-valid-code .promo-code-line .promo-btn-valid-code:focus {
          color: #F1C400; }
    .checkout-left-side .purchase-button-area #billingAddress {
      padding-bottom: 64px; }
      @media (min-width: 800px) {
        .checkout-left-side .purchase-button-area #billingAddress {
          padding-bottom: 0; } }

.summary-right-column {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -moz-order: 0;
  -ms-flex-order: 0;
  order: 0;
  background: rgba(27, 49, 113, 0.3);
  color: #d8d8d8;
  float: none;
  font-size: 1rem;
  margin: 0 -5.17241% 32px;
  padding-left: 6.25%;
  padding-right: 6.25%;
  padding-bottom: 32px;
  width: auto; }
  @media (min-width: 800px) {
    .summary-right-column {
      float: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-flex-order: 2;
      order: 2;
      margin: -6rem -6.25vw -128px 0;
      padding: 6rem 6.25vw 0; } }
  @media (min-width: 900px) {
    .summary-right-column {
      font-size: 1.25rem;
      margin: -6rem -2.3438vw -128px 0;
      padding: 6rem 2.3438vw 0; } }
  @media (min-width: 900px) {
    .summary-right-column #orderInfo {
      margin-top: 139px; } }
  @media (min-width: 900px) {
    .summary-right-column #orderInfo.has-bundle {
      margin-top: 275px; } }
  .summary-right-column #totalInfo {
    padding-top: 32px; }
    @media (min-width: 800px) {
      .summary-right-column #totalInfo {
        padding-top: 0; } }
    @media (min-width: 900px) {
      .summary-right-column #totalInfo {
        padding-top: 32px; } }
  .summary-right-column .order-summary-title {
    font-weight: 500;
    font-size: 1.25rem;
    margin-top: 0px;
    line-height: 1.25; }
    @media (min-width: 900px) {
      .summary-right-column .order-summary-title {
        font-size: 1.5625rem; } }
  .summary-right-column .total-line {
    font-weight: 500;
    font-size: 1.25rem; }
    @media (min-width: 900px) {
      .summary-right-column .total-line {
        font-size: 1.5625rem; } }
  .summary-right-column .place-order-section {
    display: none; }
    .summary-right-column .place-order-section.checkout-place-order-mobile {
      display: none; }
      .summary-right-column .place-order-section.checkout-place-order-mobile.submit-order-load {
        display: block; }
        @media (min-width: 800px) {
          .summary-right-column .place-order-section.checkout-place-order-mobile.submit-order-load {
            display: none; } }
    .summary-right-column .place-order-section.submit-order-load {
      display: block; }

.checkout-place-order-mobile {
  display: none; }
  .checkout-place-order-mobile.submit-order-load {
    display: block; }
    @media (min-width: 800px) {
      .checkout-place-order-mobile.submit-order-load {
        display: none; } }

.checkout-loader-mobile {
  display: block; }
  @media (min-width: 800px) {
    .checkout-loader-mobile {
      display: none !important; } }

@media (min-width: 600px) and (max-width: 799px) {
  .checkout-hidden-mobile-breakpoint {
    display: none !important; } }

@media (min-width: 600px) and (max-width: 799px) {
  .checkout-visible-mobile-breakpoint {
    display: block !important; } }

.super-ticket-full-width-slider-area {
  margin-bottom: 10%; }
  @media (min-width: 900px) {
    .super-ticket-full-width-slider-area {
      margin-bottom: 5%; } }
  @media (min-width: 900px) {
    .super-ticket-full-width-slider-area {
      margin-bottom: 0; } }
  @media (min-width: 1440px) {
    .super-ticket-full-width-slider-area {
      margin-bottom: -6%; } }
  @media (min-width: 1800px) {
    .super-ticket-full-width-slider-area {
      margin-bottom: -12%; } }
  .super-ticket-full-width-slider-area .slick-list {
    overflow: visible; }

.superticket-upgrade-redeem-header-area {
  margin-bottom: 32px; }
  @media (min-width: 900px) {
    .superticket-upgrade-redeem-header-area {
      margin-bottom: 16px; } }
  .superticket-upgrade-redeem-header-area .superticket-header-logo {
    height: 56px;
    vertical-align: bottom;
    margin-bottom: 16px;
    margin-right: 16px; }
    @media (min-width: 600px) {
      .superticket-upgrade-redeem-header-area .superticket-header-logo {
        height: 75px; } }
    @media (min-width: 900px) {
      .superticket-upgrade-redeem-header-area .superticket-header-logo {
        height: 85px; } }
  .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info {
    vertical-align: bottom;
    margin-bottom: 16px; }
    .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-price {
      font-size: 3.05rem;
      line-height: 1;
      font-weight: 700; }
      @media (min-width: 900px) {
        .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-price {
          font-size: 3.8125rem; } }
    .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-scene {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      -webkit-align-items: baseline;
      -moz-align-items: baseline;
      align-items: baseline; }
      .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-scene .superticket-header-scene-logo {
        height: 16px;
        margin-right: 8px; }
        @media (min-width: 900px) {
          .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-scene .superticket-header-scene-logo {
            height: 20px; } }
      .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-scene .superticket-logo-scene-points {
        font-size: 0.8rem; }
        @media (min-width: 900px) {
          .superticket-upgrade-redeem-header-area .superticket-header-price-scene-info .superticket-header-scene .superticket-logo-scene-points {
            font-size: 1rem; } }
  .superticket-upgrade-redeem-header-area .superticket-header-description {
    font-size: 1.25rem;
    color: #d8d8d8; }
    @media (min-width: 900px) {
      .superticket-upgrade-redeem-header-area .superticket-header-description {
        font-size: 1.5625rem; } }
    @media (min-width: 900px) {
      .superticket-upgrade-redeem-header-area .superticket-header-description {
        font-size: 1.95625rem; } }

.superticket-upgrade-redeem-area-container {
  background: rgba(188, 32, 75, 0.6);
  padding: 15px 20px;
  color: #d8d8d8;
  margin-left: -6.89655%;
  margin-right: -6.89655%; }
  @media (min-width: 600px) {
    .superticket-upgrade-redeem-area-container {
      padding: 48px; } }
  @media (min-width: 900px) {
    .superticket-upgrade-redeem-area-container {
      margin-left: -3.27869%;
      margin-right: -3.27869%; } }
  .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-area-header {
    font-size: 1.5625rem;
    margin-bottom: 32px;
    font-weight: 500; }
  @media (min-width: 600px) {
    .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; } }
  .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step {
    width: 100%;
    margin-bottom: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media (min-width: 600px) {
      .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step {
        width: 29%;
        margin-bottom: 0px; } }
    @media (min-width: 900px) {
      .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step {
        width: 30%; } }
    .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-header {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px; }
      .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-header i {
        font-size: 2.5rem; }
        @media (min-width: 900px) {
          .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-header i {
            font-size: 3rem; } }
    .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-content {
      margin-bottom: 16px;
      width: 100%; }
      .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-content .superticket-upgrade-redeem-step-title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 8px; }
      .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-content .superticket-upgrade-redeem-step-description {
        font-size: 0.8rem; }
        @media (min-width: 900px) {
          .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-step-content .superticket-upgrade-redeem-step-description {
            font-size: 1rem; } }
    .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-btn {
      width: 100%;
      white-space: normal;
      font-size: 1rem;
      line-height: 1.25; }
      @media (min-width: 900px) {
        .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-btn {
          width: 12.8125rem; } }
      @media (min-width: 1800px) {
        .superticket-upgrade-redeem-area-container .superticket-upgrade-redeem-steps-wrapper .superticket-upgrade-redeem-step .superticket-upgrade-redeem-btn {
          width: 20.75rem; } }

.superticket-upgrade-redeem-area-container-second-layer {
  background: linear-gradient(126deg, #151b24, #560075);
  margin-left: -6.89655%;
  margin-right: -6.89655%;
  margin-bottom: -128px;
  padding: 15px 20px; }
  @media (min-width: 600px) {
    .superticket-upgrade-redeem-area-container-second-layer {
      padding: 48px; } }
  @media (min-width: 900px) {
    .superticket-upgrade-redeem-area-container-second-layer {
      margin-left: -3.27869%;
      margin-right: -3.27869%; } }
  .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper {
    padding-bottom: 48px; }
    .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper .superticket-card-section-title {
      margin-bottom: 16px; }
      @media (min-width: 600px) {
        .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper .superticket-card-section-title {
          margin-bottom: 32px; } }
      @media (min-width: 900px) {
        .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper .superticket-card-section-title {
          margin-bottom: 48px; } }
      @media (min-width: 600px) {
        .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper .superticket-card-section-title .card-section-title-name {
          font-size: 1.25rem; } }
      @media (min-width: 900px) {
        .superticket-upgrade-redeem-area-container-second-layer .superticket-movie-card-wrapper .superticket-card-section-title .card-section-title-name {
          font-size: 1.5625rem; } }
  .superticket-upgrade-redeem-area-container-second-layer .superticket-links {
    font-size: 0.8rem; }
    @media (min-width: 900px) {
      .superticket-upgrade-redeem-area-container-second-layer .superticket-links {
        font-size: 1rem; } }
    .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link {
      margin-bottom: 5px;
      display: block;
      color: #d8d8d8; }
      .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link:hover, .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link:focus {
        color: #F1C400; }
      @media (min-width: 600px) {
        .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link {
          display: inline-block;
          margin-bottom: 0px; } }
      .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link.superticket-terms-and-conditions-btn {
        outline: none;
        border: none;
        background: transparent;
        padding: 0; }
        .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link.superticket-terms-and-conditions-btn:hover, .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link.superticket-terms-and-conditions-btn:focus {
          color: #F1C400; }
    .superticket-upgrade-redeem-area-container-second-layer .superticket-links .superticket-link-divider {
      display: inline-block; }

.superticket-modal .superticket-modal-container.superticket-modal-container-available-movies {
  overflow: hidden;
  background: #151b24; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container.superticket-modal-container-available-movies {
      max-width: 552px; } }

.superticket-modal .superticket-modal-container .modal-header .superticket-modal-notice {
  margin-top: 32px;
  font-size: 0.8rem; }
  @media (min-width: 900px) {
    .superticket-modal .superticket-modal-container .modal-header .superticket-modal-notice {
      font-size: 1rem; } }

.superticket-modal .superticket-modal-container .superticket-modal-body.available-movies-superticket-modal-body {
  overflow: hidden;
  padding: 0 16px; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body.available-movies-superticket-modal-body {
      padding: 0 1.5rem 0 48px; } }

.superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap {
  height: 80vh;
  overflow: auto;
  visibility: hidden; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap {
      height: 70vh; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap.jspScrollable {
    visibility: visible; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap .st-movie-wrapper {
      padding-right: 7.773109243697478991596638655462%;
      padding-bottom: 40px; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap .movie-card-wrap {
    display: inline-block;
    width: 33.33333%;
    margin-bottom: 17px;
    float: left; }
    .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-available-movie-wrap .movie-card-wrap:hover .movie {
      transform: scale(1); }

.superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown .superticket-upgrade-modal-how-to-upgrade-link {
  padding-bottom: 5px; }
  .superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown .superticket-upgrade-modal-how-to-upgrade-link .store-icon-down {
    display: none;
    font-size: 1.6rem; }
  .superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown .superticket-upgrade-modal-how-to-upgrade-link .store-icon-chevron {
    display: block;
    font-size: 1.6rem; }

.superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown .st-upgrade-dropdown-content {
  display: none; }

.superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown.open .store-icon-chevron {
  display: none; }

.superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown.open .store-icon-down {
  display: block; }

.superticket-modal .superticket-modal-container .superticket-modal-body .st-upgrade-dropdown.open .st-upgrade-dropdown-content {
  display: block; }

.superticket-modal .superticket-modal-container .superticket-modal-body .superticket-upgrade-modal-how-to-upgrade-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.superticket-modal .superticket-modal-container .superticket-modal-body .superticket-nav-pills {
  margin: 15px -15px; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-nav-pills {
      margin: 48px -15px; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .superticket-nav-pills > li a {
    padding: 0 15px; }

.superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-form {
  position: relative; }

.superticket-modal .superticket-modal-container .superticket-modal-body .superticket-form-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  top: -32px;
  left: 0; }

.superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap.upgrade-redeem-area-loading {
  visibility: hidden; }

.superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: no-wrap;
  -moz-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -moz-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding-bottom: 10px; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field {
        padding-bottom: 0;
        margin-right: 3%; } }
    @media (min-width: 900px) {
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field {
        margin-right: 16px; } }
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field .superticket-upgrade-modal-input-field-name {
      color: #d8d8d8;
      padding-bottom: 7px; }
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field .superticket-upgrade-modal-input {
      background: #202733;
      border: none;
      outline: none;
      height: 48px;
      color: #FFF;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%; }
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field.super-ticket-field-redeem {
      width: 100%; }
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field.super-ticket-field-redeem .validationMessage {
        display: block;
        position: static;
        padding: 10px; }
  .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field1 {
    width: 100%; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field1 {
        width: 30%; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field2 {
    width: 100%; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field2 {
        width: 40%; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field3 {
    width: 100%; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .super-ticket-input-wrapper .super-ticket-field3 {
        width: 21%; } }

.superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .superticket-modal-btn {
  padding: 10px 50px;
  margin-top: 32px;
  margin-bottom: 48px;
  font-size: 1.25rem; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .superticket-modal-btn {
      padding: 12px 100px;
      margin-bottom: 20px; } }
  @media (min-width: 1800px) {
    .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .superticket-modal-btn {
      padding: 14px 125px 15px; } }
  .superticket-modal .superticket-modal-container .superticket-modal-body .super-ticket-field-wrap .superticket-modal-btn.superticket-redeem-modal-btn {
    margin-bottom: 0px; }

.superticket-modal .superticket-modal-container.superticket-modal-container-redirect {
  background-size: cover; }
  @media (min-width: 600px) {
    .superticket-modal .superticket-modal-container.superticket-modal-container-redirect {
      height: auto; } }
  .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-poster {
    width: 138px;
    height: 207px;
    background: #FFF;
    margin-bottom: 16px; }
  .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-title {
    font-size: 1.25rem;
    font-weight: bold; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-title {
        font-size: 1.5625rem; } }
    @media (min-width: 900px) {
      .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-title {
        font-size: 1.95625rem; } }
  .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-add-library-text {
    font-size: 1rem;
    margin-bottom: 32px; }
    @media (min-width: 600px) {
      .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-add-library-text {
        font-size: 1.25rem; } }
    @media (min-width: 900px) {
      .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-add-library-text {
        font-size: 1.5625rem; } }
  .superticket-modal .superticket-modal-container.superticket-modal-container-redirect .superticket-redeem-modal-library-btn {
    margin-bottom: 16px; }

#superticket-redeem-modal .superticket-form-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  top: 0;
  left: 0; }

.my-library-wrapper {
  margin-top: 15px; }
  @media (min-width: 900px) {
    .my-library-wrapper {
      margin-top: 32px; } }
  .my-library-wrapper .st-upgrade-to-hd {
    padding: 10px 0;
    text-align: center; }

.my-library-heading-area {
  margin-bottom: 32px; }
  @media (min-width: 900px) {
    .my-library-heading-area {
      width: auto;
      margin-bottom: 50px; } }
  .my-library-heading-area .my-library-heading-title {
    margin-top: 34px; }
    @media (min-width: 320px) {
      .my-library-heading-area .my-library-heading-title {
        margin-top: 27px;
        font-size: 1.54rem;
        margin-bottom: 36px; } }
    @media (min-width: 600px) {
      .my-library-heading-area .my-library-heading-title {
        margin-top: 35px;
        font-size: 2.5rem;
        margin-bottom: 35px; } }
    @media (min-width: 900px) {
      .my-library-heading-area .my-library-heading-title {
        margin-top: 24px;
        margin-bottom: 0; } }
    @media (min-width: 1800px) {
      .my-library-heading-area .my-library-heading-title {
        margin-top: 20px;
        margin-bottom: 10px; } }

.ml-download-manager-wrapper {
  margin-top: 32px;
  width: 50%;
  text-align: right; }
  @media (min-width: 900px) {
    .ml-download-manager-wrapper {
      margin-top: 20px;
      margin-right: 2rem;
      width: auto; } }
  .ml-download-manager-wrapper .download-text {
    font-size: 0.8rem; }
  .ml-download-manager-wrapper .store-icon-download {
    font-size: 1.5rem; }
    @media (min-width: 900px) {
      .ml-download-manager-wrapper .store-icon-download {
        padding-left: 0.5rem;
        font-size: 2rem; } }

.no-movies-in-library .no-lib-items-icon {
  font-size: 96px; }

.player {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1060;
  /* Sit on top */
  /*padding-top: 100px; */
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.activate-body {
  font-family: "MarkOT", Helvetica, Arial, sans-serif;
  font-style: normal;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #0a0b0d;
  background-attachment: fixed;
  background-image: url(https://storemedia.cineplex.com/Attachments/NewItems/Generic_1920x1080_20171123232139_0.jpg); }
  .activate-body .activate-logo {
    width: 16.666666666666668%;
    position: relative;
    z-index: 994;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    float: left;
    height: 4rem; }
    .activate-body .activate-logo img {
      vertical-align: middle;
      border: 0; }
  .activate-body .activate-page {
    width: 100%; }
    .activate-body .activate-page .activate-main-nav {
      width: 100%;
      font-size: 20px;
      line-height: 20px;
      height: 4rem;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center; }
    .activate-body .activate-page .login-btn {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-transform: uppercase; }
      @media (min-width: 280px) {
        .activate-body .activate-page .login-btn {
          margin-top: 32px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .login-btn {
          margin-top: 40px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .login-btn {
          margin-top: 48px;
          height: 60px; } }
    @media (min-width: 280px) {
      .activate-body .activate-page .activate-page-body {
        text-align: left;
        margin-top: 106px; } }
    @media (min-width: 900px) {
      .activate-body .activate-page .activate-page-body {
        margin-top: 113px; } }
    @media (min-width: 1800px) {
      .activate-body .activate-page .activate-page-body {
        text-align: center;
        margin-top: 116px; } }
    .activate-body .activate-page .activate-page-body .element-width {
      margin: auto; }
      @media (min-width: 320px) {
        .activate-body .activate-page .activate-page-body .element-width {
          width: 278px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .activate-page-body .element-width {
          width: 444px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .element-width {
          width: 472px; } }
      .activate-body .activate-page .activate-page-body .element-width .go-to-cineplex-btn {
        width: 100%;
        font-weight: 400;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        margin-top: 48px; }
        @media (min-width: 900px) {
          .activate-body .activate-page .activate-page-body .element-width .go-to-cineplex-btn {
            font-size: 12px;
            line-height: 16px; } }
        @media (min-width: 1800px) {
          .activate-body .activate-page .activate-page-body .element-width .go-to-cineplex-btn {
            font-size: 25px;
            line-height: 16px; } }
      .activate-body .activate-page .activate-page-body .element-width .code-validation-box {
        display: block;
        width: 100%;
        margin-top: 45px; }
        .activate-body .activate-page .activate-page-body .element-width .code-validation-box .qrc-validate-btn {
          display: block;
          margin: 0px auto;
          width: 100%; }
        .activate-body .activate-page .activate-page-body .element-width .code-validation-box .code-error-message {
          font-family: "MarkOT", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #EF3434;
          width: 100%;
          text-align: left;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          letter-spacing: -0.408px;
          height: 30px; }
    .activate-body .activate-page .activate-page-body .limit-reached {
      margin: auto; }
      @media (min-width: 280px) {
        .activate-body .activate-page .activate-page-body .limit-reached {
          width: 276px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .activate-page-body .limit-reached {
          width: 444px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .limit-reached {
          width: 724px; } }
      .activate-body .activate-page .activate-page-body .limit-reached .device-list-box {
        margin-top: 100px;
        width: 100%; }
        .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item {
          text-align: left;
          padding-bottom: 20px; }
          .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-name {
            display: block;
            font-weight: 400;
            letter-spacing: -0.408px;
            color: #FFFFFF; }
            @media (min-width: 280px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-name {
                font-size: 16px;
                line-height: 26px; } }
            @media (min-width: 900px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-name {
                font-size: 20px;
                line-height: 28px; } }
            @media (min-width: 1800px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-name {
                font-size: 24px;
                line-height: 34px; } }
          .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-date {
            float: left;
            font-weight: 400;
            letter-spacing: -0.408px;
            color: #A7A8AA;
            opacity: 0.8; }
            @media (min-width: 280px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-date {
                font-size: 12px;
                line-height: 16px; } }
            @media (min-width: 900px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-date {
                font-size: 14px;
                line-height: 20px; } }
            @media (min-width: 1800px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-date {
                font-size: 16px;
                line-height: 22px; } }
          .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .dremove-btn {
            float: right;
            font-weight: 500;
            text-align: right;
            letter-spacing: -0.408px;
            color: #EEEEEE; }
            @media (min-width: 280px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .dremove-btn {
                font-size: 12px;
                line-height: 16px; } }
            @media (min-width: 900px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .dremove-btn {
                font-size: 14px;
                line-height: 20px; } }
            @media (min-width: 1800px) {
              .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .dremove-btn {
                font-size: 16px;
                line-height: 22px; } }
          .activate-body .activate-page .activate-page-body .limit-reached .device-list-box .device-item .device-line-brake {
            width: 100%;
            border: 1px solid #8D8D99;
            flex: none;
            order: 1;
            flex-grow: 0; }
    .activate-body .activate-page .activate-page-body .activate-title {
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0; }
      @media (min-width: 280px) {
        .activate-body .activate-page .activate-page-body .activate-title {
          font-size: 20px;
          line-height: 28px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .activate-page-body .activate-title {
          font-size: 22px;
          line-height: 30px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .activate-title {
          font-size: 32px;
          line-height: 44px; } }
    .activate-body .activate-page .activate-page-body .device-message {
      font-weight: 400;
      letter-spacing: -0.408px;
      padding-top: 8px; }
      @media (min-width: 280px) {
        .activate-body .activate-page .activate-page-body .device-message {
          font-size: 16px;
          line-height: 20px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .activate-page-body .device-message {
          font-size: 20px;
          line-height: 28px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .device-message {
          font-size: 24px;
          line-height: 34px; } }
    .activate-body .activate-page .activate-page-body .device-limit-message {
      font-weight: 400;
      letter-spacing: -0.408px;
      padding-top: 8px;
      text-align: left !important; }
      @media (min-width: 280px) {
        .activate-body .activate-page .activate-page-body .device-limit-message {
          font-size: 16px;
          line-height: 20px; } }
      @media (min-width: 900px) {
        .activate-body .activate-page .activate-page-body .device-limit-message {
          font-size: 20px;
          line-height: 28px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .device-limit-message {
          font-size: 24px;
          line-height: 34px; } }
    .activate-body .activate-page .activate-page-body .code-textbox {
      width: 100%;
      display: block;
      margin: 0px auto;
      text-align: center;
      background: rgba(32, 39, 51, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.4);
      font-weight: 400;
      font-size: 32px;
      line-height: 44px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 6.59px; }
      .activate-body .activate-page .activate-page-body .code-textbox.error-message {
        border: 1px solid #FF2F49; }
      @media (min-width: 280px) {
        .activate-body .activate-page .activate-page-body .code-textbox {
          height: 54px; } }
      @media (min-width: 1800px) {
        .activate-body .activate-page .activate-page-body .code-textbox {
          height: 56px; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

#spinner-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0A0B0D;
  opacity: 0.8;
  z-index: 99998;
  margin: auto;
  display: none; }

#lwdAjaxSpinner {
  position: fixed;
  border: none !important;
  display: none;
  z-index: 99999;
  animation: spin 2s linear infinite; }
  @media (min-width: 280px) {
    #lwdAjaxSpinner {
      width: 41.04px;
      height: 41.04px;
      left: calc(50% - 41.04px/2 - 0.11px);
      top: calc(50% - 41.04px/2); } }
  @media (min-width: 900px) {
    #lwdAjaxSpinner {
      width: 41.04px;
      height: 41.04px;
      left: calc(50% - 41.04px/2 - 0.48px);
      top: calc(50% - 41.04px/2 + 0.28px); } }
  @media (min-width: 1800px) {
    #lwdAjaxSpinner {
      width: 41.02px;
      height: 41.02px;
      left: calc(50% - 41.02px/2 - 0.49px);
      top: calc(50% - 41.02px/2 + 0.49px); } }

/*Styles from those who don't know what they're doing*/

/*# sourceMappingURL=maps/cineplex-store-styles.css.map */
