﻿.my-library-wrapper {
    margin-top: 15px;

    @media(min-width:$screen-md) {
        margin-top: 32px;
    }

    .st-upgrade-to-hd {
        padding: 10px 0;
        text-align: center;
    }
}

.my-library-heading-area {
    margin-bottom: 32px;

    @media(min-width:$screen-md) {
        width: auto;
        margin-bottom: 50px;
    }

    .my-library-heading-title {
        margin-top: 34px;

        @media(min-width: $screen-xs) {
            margin-top: 27px;
            font-size: 1.54rem;
            margin-bottom: 36px;
        }

        @media(min-width: $screen-sm) {
            margin-top: 35px;
            font-size: 2.5rem;
            margin-bottom: 35px;
        }

        @media(min-width: $screen-md) {
            margin-top: 24px;
            margin-bottom: 0;
        }

        @media(min-width: $screen-lg) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}

.ml-download-manager-wrapper {
    margin-top: 32px;
    width: 50%;
    text-align: right;

    @media(min-width: $screen-md) {
        margin-top: 20px;
        margin-right: 2rem;
        width: auto;
    }

    .download-text {
        font-size: 0.8rem;
    }

    .store-icon-download {
        font-size: 1.5rem;

        @media(min-width: $screen-md) {
            padding-left: 0.5rem;
            font-size: 2rem;
        }
    }
}

.no-movies-in-library {
    .no-lib-items-icon {
        font-size: 96px;
    }
}
.player {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1060; /* Sit on top */
    /*padding-top: 100px; */ /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
