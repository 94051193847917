﻿.carousel-indicators {
    .playlist-indicator {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: (3*$column-gutter-mobile);

        @media(min-width: $screen-md) {
            width: (3*$column-gutter-desktop);
        }

        &:hover {
            cursor: pointer;
            color: $yellow;
        }

        &.slick-arrow.slick-disabled {
            opacity: 0.3;
            color: $white;
            cursor: not-allowed;
        }

        .rail-icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            display: inline-block;
            font-size: 4.6875vw;

            @media(min-width: $screen-md) {
                font-size: 3.958vw;
            }
        }
    }

    .playlist-back-indicator {
        right: 100%;
    }

    .playlist-next-indicator {
        left: 100%;

        .store-icon-railright {
            &:before {
                position: relative;
                left: -50%;
            }
        }
    }
}
