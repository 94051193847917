﻿.super-ticket-full-width-slider-area {
    margin-bottom: 10%;

    @media(min-width: $screen-md) {
        margin-bottom: 5%;
    }

    @media(min-width: $screen-md) {
        margin-bottom: 0;
    }

    @media(min-width: 1440px) {
        margin-bottom: -6%;
    }

    @media(min-width: $screen-lg) {
        margin-bottom: -12%;
    }

    .slick-list {
        overflow: visible;
    }
}

.superticket-upgrade-redeem-header-area {
    margin-bottom: 32px;

    @media(min-width: $screen-md) {
        margin-bottom: 16px;
    }

    .superticket-header-logo {
        height: 56px;
        vertical-align: bottom;
        margin-bottom: 16px;
        margin-right: 16px;

        @media(min-width: $screen-sm) {
            height: 75px;
        }

        @media(min-width: $screen-md) {
            height: 85px;
        }
    }

    .superticket-header-price-scene-info {
        vertical-align: bottom;
        margin-bottom: 16px;

        .superticket-header-price {
            font-size: 3.05rem;
            line-height: 1;
            font-weight: 700;

            @media(min-width: $screen-md) {
                font-size: 3.8125rem;
            }
        }

        .superticket-header-scene {
            @include flexbox();
            @include align-items(baseline);

            .superticket-header-scene-logo {
                height: 16px;
                margin-right: 8px;

                @media(min-width: $screen-md) {
                    height: 20px;
                }
            }

            .superticket-logo-scene-points {
                font-size: 0.8rem;

                @media(min-width: $screen-md) {
                    font-size: 1rem;
                }
            }
        }
    }

    .superticket-header-description {
        font-size: 1.25rem;
        color: #d8d8d8;

        @media(min-width: $screen-md) {
            font-size: 1.5625rem;
        }

        @media(min-width: $screen-md) {
            font-size: 1.95625rem;
        }
    }
}

.superticket-upgrade-redeem-area-container {
    background: rgba(188, 32, 75, 0.6);
    padding: 15px 20px;
    color: #d8d8d8;
    margin-left: -($container-gutter-mobile-scaled);
    margin-right: -($container-gutter-mobile-scaled);

    @media(min-width: $screen-sm) {
        padding: 48px;
    }

    @media(min-width: $screen-md) {
        margin-left: -($container-gutter-desktop-scaled);
        margin-right: -($container-gutter-desktop-scaled);
    }

    .superticket-upgrade-redeem-area-header {
        font-size: 1.5625rem;
        margin-bottom: 32px;
        font-weight: 500;
    }

    .superticket-upgrade-redeem-steps-wrapper {

        @media(min-width: $screen-sm) {
            @include flexbox();
            @include justify-content(space-between);
        }

        .superticket-upgrade-redeem-step {
            width: 100%;
            margin-bottom: 32px;
            @include flexbox();
            @include flex-wrap(wrap);
            @include flex-direction(row);

            @media(min-width: $screen-sm) {
                width: 29%;
                margin-bottom: 0px;
            }

            @media(min-width: $screen-md) {
                width: 30%;
            }

            .superticket-upgrade-redeem-step-header {

                @include flexbox();
                @include align-items(center);
                @include justify-content(space-between);
                width: 100%;
                margin-bottom: 16px;

                i {
                    font-size: 2.5rem;

                    @media(min-width: $screen-md) {
                        font-size: 3rem;
                    }
                }
            }

            .superticket-upgrade-redeem-step-content {
                margin-bottom: 16px;
                width: 100%;

                .superticket-upgrade-redeem-step-title {
                    font-size: 1.25rem;
                    font-weight: bold;
                    margin-bottom: 8px;
                }

                .superticket-upgrade-redeem-step-description {
                    font-size: 0.8rem;

                    @media(min-width: $screen-md) {
                        font-size: 1rem;
                    }
                }
            }

            .superticket-upgrade-redeem-btn {
                width: 100%;
                white-space: normal;
                font-size: 1rem;
                line-height: 1.25;

                @media(min-width: $screen-md) {
                    width: 12.8125rem;
                }

                @media(min-width: $screen-lg) {
                    width: 20.75rem;
                }
            }
        }
    }
}

.superticket-upgrade-redeem-area-container-second-layer {
    background: linear-gradient(126deg, $superticket-modal-bg-color, #560075);
    margin-left: -($container-gutter-mobile-scaled);
    margin-right: -($container-gutter-mobile-scaled);
    margin-bottom: -$mobile-bottom-padding;
    padding: 15px 20px;

    @media(min-width: $screen-sm) {
        padding: 48px;
    }

    @media(min-width: $screen-md) {
        margin-left: -($container-gutter-desktop-scaled);
        margin-right: -($container-gutter-desktop-scaled);
    }

    .superticket-movie-card-wrapper {
        padding-bottom: 48px;

        .superticket-card-section-title {
            margin-bottom: 16px;

            @media(min-width: $screen-sm) {
                margin-bottom: 32px;
            }

            @media(min-width: $screen-md) {
                margin-bottom: 48px;
            }

            .card-section-title-name {

                @media(min-width: $screen-sm) {
                    font-size: 1.25rem;
                }

                @media(min-width: $screen-md) {
                    font-size: 1.5625rem;
                }
            }
        }
    }

    .superticket-links {
        font-size: 0.8rem;

        @media(min-width: $screen-md) {
            font-size: 1rem;
        }

        .superticket-link {
            margin-bottom: 5px;
            display: block;
            color: #d8d8d8;

            &:hover,
            &:focus {
                color: $yellow;
            }

            @media(min-width: $screen-sm) {
                display: inline-block;
                margin-bottom: 0px;
            }

            &.superticket-terms-and-conditions-btn {
                outline: none;
                border: none;
                background: transparent;
                padding: 0;

                &:hover,
                &:focus {
                    color: $yellow;
                }
            }
        }

        .superticket-link-divider {
            display: inline-block;
        }
    }
}

.superticket-modal {

    .superticket-modal-container {

        &.superticket-modal-container-available-movies {
            overflow: hidden;
           background: $superticket-modal-bg-color;

            @media(min-width: $screen-sm) {
                max-width: 552px;
            }
        }

        .modal-header {
            .superticket-modal-notice {
                margin-top: 32px;
                font-size: 0.8rem;

                @media(min-width: $screen-md) {
                    font-size: 1rem;
                }
            }
        }

        .superticket-modal-body {

            &.available-movies-superticket-modal-body {
                overflow: hidden;
                padding: 0 16px;

                @media(min-width: $screen-sm) {
                    padding: 0 1.5rem 0 48px;
                }
            }

            .superticket-available-movie-wrap {
                height: 80vh;
                overflow: auto;
                visibility: hidden;

                @media(min-width: $screen-sm) {
                    height: 70vh;
                }

                &.jspScrollable {
                    visibility: visible;
                }

                .st-movie-wrapper {
                    @media(min-width: $screen-sm) {
                        padding-right: 7.773109243697478991596638655462%;
                        padding-bottom: 40px;
                    }
                }

                .movie-card-wrap {
                    display: inline-block;
                    width: percentage(1/3);
                    margin-bottom: 17px;
                    float: left;

                    &:hover {
                        .movie {
                            transform: scale(1);
                        }
                    }
                }
            }

            .st-upgrade-dropdown {
                .superticket-upgrade-modal-how-to-upgrade-link {
                    padding-bottom: 5px;

                    .store-icon-down {
                        display: none;
                        font-size: 1.6rem;
                    }

                    .store-icon-chevron {
                        display: block;
                        font-size: 1.6rem;
                    }
                }

                .st-upgrade-dropdown-content {
                    display: none;
                }

                &.open {
                    .store-icon-chevron {
                        display: none;
                    }

                    .store-icon-down {
                        display: block;
                    }

                    .st-upgrade-dropdown-content {
                        display: block;
                    }
                }
            }

            .superticket-upgrade-modal-how-to-upgrade-link {
                @include flexbox();
                @include align-items(center);
            }

            .superticket-nav-pills {
                margin: 15px -15px;

                @media(min-width: $screen-sm){
                    margin: 48px -15px;
                }

                > li {
                    a {
                        padding: 0 15px;
                    }
                }
            }

            .super-ticket-form {
                position: relative;
            }

            .superticket-form-loader {
                position: absolute;
                height: 100%;
                width: 100%;
                padding: 0;
                top: -32px;
                left: 0;
            }

            .super-ticket-field-wrap {

                &.upgrade-redeem-area-loading {
                    visibility: hidden;
                }

                .super-ticket-input-wrapper {
                    @include flexbox();
                    @include flex-direction(column);
                    @include flex-wrap(no-wrap);

                    @media(min-width: $screen-sm) {
                        @include flex-direction(row);
                        @include flex-wrap(wrap);
                    }

                    .super-ticket-field {
                        @include flexbox();
                        @include flex-flow(column);
                        @include justify-content(space-between);
                        padding-bottom: 10px;

                        @media(min-width: $screen-sm) {
                            padding-bottom: 0;
                            margin-right: 3%;
                        }

                        @media(min-width: $screen-md) {
                            margin-right: 16px;
                        }

                        .superticket-upgrade-modal-input-field-name {
                            color: #d8d8d8;
                            padding-bottom: 7px;
                        }

                        .superticket-upgrade-modal-input {
                            background: #202733;
                            border: none;
                            outline: none;
                            height: 48px;
                            color: $white;
                            padding-left: 16px;
                            padding-right: 16px;
                            width: 100%;
                        }

                        &.super-ticket-field-redeem {
                            width: 100%;

                            .validationMessage {
                                display: block;
                                position: static;
                                padding: 10px;
                            }
                        }
                    }

                    .super-ticket-field1 {
                        width: 100%;

                        @media(min-width: $screen-sm) {
                            width: 30%;
                        }
                    }

                    .super-ticket-field2 {
                        width: 100%;

                        @media(min-width: $screen-sm) {
                            width: 40%;
                        }
                    }

                    .super-ticket-field3 {
                        width: 100%;

                        @media(min-width: $screen-sm) {
                            width: 21%;
                        }
                    }
                }

                .superticket-modal-btn {
                    padding: 10px 50px;
                    margin-top: 32px;
                    margin-bottom: 48px;
                    font-size: 1.25rem;

                    @media(min-width: $screen-sm) {
                        padding: 12px 100px;
                        margin-bottom: 20px;
                    }

                    @media(min-width: $screen-lg){
                        padding: 14px 125px 15px;
                    }

                    &.superticket-redeem-modal-btn {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        &.superticket-modal-container-redirect {
            background-size: cover;

            @media(min-width: $screen-sm){
                height: auto;
            }

            .superticket-redeem-poster {
                width: 138px;
                height: 207px;
                background: $white;
                margin-bottom: 16px;
            }

            .superticket-redeem-modal-title {
                font-size: 1.25rem;
                font-weight: bold;

                @media(min-width: $screen-sm) {
                    font-size: 1.5625rem;
                }

                @media(min-width: $screen-md) {
                    font-size: 1.95625rem;
                }
            }

            .superticket-redeem-modal-add-library-text {
                font-size: 1rem;
                margin-bottom: 32px;

                @media(min-width: $screen-sm) {
                    font-size: 1.25rem;
                }

                @media(min-width: $screen-md) {
                    font-size: 1.5625rem;
                }
            }

            .superticket-redeem-modal-library-btn {
                margin-bottom: 16px;
            }
        }
    }
}

#superticket-redeem-modal {
    .superticket-form-loader {
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 0;
        top: 0;
        left: 0;
    }
}