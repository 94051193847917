﻿.loader-wrap {
    padding-top: $loader-vertical-padding;
    padding-bottom: $loader-vertical-padding;
    padding-left: $loader-horizontal-padding;
    padding-right: $loader-horizontal-padding;
}

.loader-wrap-entire-page {
    position: relative;
    height: 100%;
    width: 100%;

    &.loader-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loader-spinner {
    display: inline-block;
    position: relative;

    &.loader-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.small-loader {
        .spinner-img {
            height: $sm-loader;
            width: $sm-loader;
        }
    }


    &.md-loader {
        .spinner-img {
            height: $md-loader;
            width: $md-loader;
        }
    }


    &.large-loader {
        .spinner-img {
            height: $lg-loader;
            width: $lg-loader;
        }
    }

    .spinner-img {
        display: inline-block;
        max-height: 100%;
        max-width: 100%;
        animation: spin $loader-animation-length linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader-box {
    display: inline-block;
    position: relative;
    height: $square-loader-length;
    width: $square-loader-length;
    overflow: visible;

    &.loader-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.small-loader {
        height: $small-square-loader-length;
        width: $small-square-loader-length;
    }

    &.md-loader {
        height: $med-square-loader-length;
        width: $med-square-loader-length;
    }

    &.large-loader {
        height: $large-square-loader-length;
        width: $large-square-loader-length;
    }

    .load-line {
        position: absolute;
        height: 100%;
        width: 100%;
        border: 0px solid $loader-color;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .top-line {
        border-top-width: $loader-border-width;
        animation: box-loader-top $loader-animation-length ease infinite;
    }

    .right-line {
        border-right-width: $loader-border-width;
        animation: box-loader-right $loader-animation-length ease infinite;
    }

    .bottom-line {
        border-bottom-width: $loader-border-width;
        animation: box-loader-bottom $loader-animation-length ease infinite;
    }

    .left-line {
        border-left-width: $loader-border-width;
        animation: box-loader-left $loader-animation-length ease infinite;
    }
}

@keyframes box-loader-top {
    0% {
        transform: translateY(-$square-loader-transition-distance);
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    25% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }

    60% {
        opacity: 1;
    }

    75% {
        transform: translateY(-$square-loader-transition-distance);
        opacity: 0;
    }

    100% {
        transform: translateY(-$square-loader-transition-distance);
        opacity: 0;
    }
}

@keyframes box-loader-right {
    0% {
        transform: translateX($square-loader-transition-distance);
        opacity: 0;
    }

    8.33% {
        transform: translateX($square-loader-transition-distance);
        opacity: 0;
    }

    23.33% {
        opacity: 1;
    }

    33.33% {
        transform: translateX(0);
    }

    58.33% {
        transform: translateX(0);
    }

    68.33% {
        opacity: 1;
    }

    83.33% {
        transform: translateX($square-loader-transition-distance);
        opacity: 0;
    }

    100% {
        transform: translateX($square-loader-transition-distance);
        opacity: 0;
    }
}

@keyframes box-loader-bottom {
    0% {
        transform: translateY($square-loader-transition-distance);
        opacity: 0;
    }

    16.67% {
        transform: translateY($square-loader-transition-distance);
        opacity: 0;
    }

    31.67% {
        opacity: 1;
    }

    41.67% {
        transform: translateY(0);
    }

    66.67% {
        transform: translateY(0);
    }

    76.67% {
        opacity: 1;
    }

    91.67% {
        transform: translateY($square-loader-transition-distance);
        opacity: 0;
    }

    100% {
        transform: translateY($square-loader-transition-distance);
        opacity: 0;
    }
}

@keyframes box-loader-left {
    0% {
        transform: translateX(-$square-loader-transition-distance);
        opacity: 0;
    }

    25% {
        transform: translateX(-$square-loader-transition-distance);
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(0);
    }

    85% {
        opacity: 1;
    }

    100% {
        transform: translateX(-$square-loader-transition-distance);
        opacity: 0;
    }
}
