﻿body,
html {
    overflow-x: hidden;
}

/*body.modal-open{
    padding-right: 0px !important;
}*/

body {
    &.connect-open {
        overflow: hidden;
        position: fixed;
        width: 100%;

        @media(min-width: $grid-float-breakpoint) {
            position: static;
            overflow-y: auto;
            width: auto;
        }
    }
}

#Wrapper {
    position: relative;
    padding-top: $body-top-padding;
    display: block;
    overflow-x: hidden;
    margin: 0 auto;
    max-width: $screen-max;

    &.unfixed-nav {
        padding-top: 0;
    }

    .wrapper-fade-right,
    .wrapper-fade-left {
        position: absolute;
        top: 0;
        bottom: 0;
        display: none;
        width: 2.3438%;
        z-index: 10000;

        @media(min-width: $screen-max) {
            display: block;
        }
    }

    .wrapper-fade-left {

        @media(min-width: $screen-max) {
            left: 0;
            height: 100%;
            background: linear-gradient(to left, rgba(10,11,13,0), rgba(10,11,13,1));
        }
    }

    .wrapper-fade-right {

        @media(min-width: $screen-max) {
            right: 0;
            height: 100%;
            background: linear-gradient(to right, rgba(10,11,13,0), rgba(10,11,13,1));
        }
    }

    .body-content {
        min-height: 60vh;
        padding-bottom: 128px;
    }
}

#Footer {
    position: relative;
}

form {
    position: relative;
}

.validationMessage {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    color: $brand-danger;
    padding-top: 0.5rem;
}
