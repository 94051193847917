﻿.search-genre-page-container {
    ~ .movie-slider {
        visibility: hidden;
        position: relative;
        height: 0px;
        width: 100%;

        .movie-card-section-wrapper {
            position: absolute;
            left: -10000000px;
            width: 100%;
        }
    }

    &.no-search-results {

        .result-container {
            height: 0 !important;
        }

        ~ .movie-slider {
            position: relative;
            height: auto;
            visibility: visible;

            .movie-card-section-wrapper {
                position: relative;
                left: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .result-container {
        overflow: hidden;
        padding-top: 52px;

        @media(min-width: $screen-md) {
            padding-top: 83px;
        }
    }

    .movie-card-section-wrapper {
        @media(min-width: $screen-md) {
            padding-bottom: 3.7125847%;
        }
    }
}

.genre-page-container {
    margin: 0;
    padding-top: calc(56.25vw - 4rem);

    @media(min-width: $screen-sm) {
        padding-top: calc(33.333vw - 4rem);
    }

    @media(min-width: $screen-md) {
        padding-top: calc(16.666vw - 4rem);
    }

    .result-container {
        overflow: hidden;
        padding-top: 32px;

        @media(min-width: $screen-md) {
            padding-top: 83px;
        }
    }

    .sort-dropdown {

        h3 {
            font-size: 1em;
            line-height: 1.25;
        }

        &.no-results {
            @media(min-width: $screen-md) {
                display: none;
            }
        }
    }
}

.search-page-container {
    padding-top: 32px;

    @media(min-width: $screen-md) {
        padding-top: 96px;
    }

    @media(min-width: $screen-lg) {
        padding-top: 152px;
    }

    .result-container {
        padding-top: 32px;

        @media(min-width: $screen-md) {
            padding-top: 60px;
        }
    }
}

.info-header-genre-page {
    margin-bottom: 0;

    .info-container {

        .genre-result-container {

            .genre-chosen {


                h2 {
                    font-size: 1.25rem;
                    line-height: 1.25;
                    color: #fff;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    @media(min-width: $screen-sm) {
                        font-size: 1.5625rem;
                    }

                    @media(min-width: $screen-md) {
                        font-size: 1.95625rem;
                    }
                }
            }

            .genre-page-results-filter-area {
                font-size: 1rem;
                line-height: 1.95625rem;

                .num-filters-displayed-section {
                    display: inline-block;
                    padding-bottom: 0.25rem;
                }

                .clear-filters-button-on-page {
                    font-size: 1.5rem;
                    border: none;
                    background: transparent;

                    &:hover {
                        color: $yellow;
                    }
                }
            }
        }
    }
}

.search-page-box {
    position: relative;
    width: 60%;
    height: 3.5rem;
    background: white;
    margin-left: 20%;
    margin-top: 88px;
    margin-bottom: 64px;

    #search-page-search-form {
        width: 100%;
        height: 100%;

        .store-icon-search {
            position: absolute;
            top: 0;
            left: 0;
            color: #000000;
            font-size: 1.4rem;
            padding-top: 1.125rem;
            padding-left: 1.0625rem;
            font-weight: bold;
        }

        .search-box-text {
            color: #000000;
            font-size: 1.5625rem;
            padding-top: 0.5rem;
            padding-left: 3.125rem;
            padding-right: 3rem;
            width: 100%;
            height: 100%;
            border: none;
            background-color: #E4E4E4;
        }

        .store-icon-chevron {
            position: absolute;
            top: 0;
            right: 0;
            color: #000000;
            font-size: 1.4rem;
            padding-top: 1.125rem;
            padding-left: 0;
            padding-right: 1.0625rem;
            border: none;
            background: transparent;
            font-weight: bold;
            outline: none;
        }
    }
}

//Search and Genre Page General Styles

.search-page-loader {
    visibility: hidden;

    &.display-loader {
        visibility: visible;
    }

    &.on-last-search-page {
        display: none;
    }
}

.search-page-search-box {
    display: none;

    &.loaded {
        display: block;
    }

    .filter-items {
        margin-left: -$row-gutter-mobile;
        margin-right: -$row-gutter-mobile;

        @media(min-width: $gutter-breakpoint) {
            margin-left: 0;
            margin-right: 0;
        }

        .filter-item-name {
            padding-left: $column-gutter-mobile;
            padding-right: $column-gutter-mobile;
            float: left;

            @media(min-width: $gutter-breakpoint) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.info-header {
    font-family: MarkOT;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    .info-container {

        .search-result-container {
            @include inline-flex();
            @include align-items(flex-end);
            @include flex-wrap(nowrap);

            &.search-only {
                font-size: 1.25rem;

                @media(min-width: $screen-md) {
                    font-size: 1.95625rem;
                }

                .numeric-result,
                .search-result {
                    overflow: visible;
                }
            }

            &.genre-only {

                .genre-chosen {
                    h2 {
                        font-size: 1.25rem;
                        line-height: 1.25;
                        color: #fff;
                        margin-top: 0px;
                        margin-bottom: 0px;

                        @media(min-width: $screen-md) {
                            font-size: 1.95625rem;
                        }
                    }
                }

                .numeric-result,
                .numeric-result-mobile,
                .search-result {
                    font-size: 1.25rem;
                    /*@media(min-width: $screen-md) {
                font-size: 1.5rem;
            }*/
                }
            }
        }



        .search-result {

            .search-result-div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: bottom;
                max-width: 7rem;

                @media(min-width: $screen-md) {
                    max-width: 12rem;
                }

                @media(min-width: 1200px) {
                    max-width: 28rem;
                }

                @media(min-width: $screen-lg) {
                    max-width: 35rem;
                }
            }
        }
    }

    .num-filters-displayed-section {
        display: inline-block;
        padding-bottom: 0.33rem;
    }

    .clear-filters-button-on-page {
        //align-self:flex-end;
        font-size: 1.25rem;
        border: none;
        background: transparent;
        display: inline-block;
        line-height: 1.25;

        @media(min-width: $screen-md) {
            line-height: 1;
            padding-bottom: 0.33rem;
        }

        &:hover {
            color: $yellow;
        }
    }

    .sort-dropdown {

        h3 {
            font-size: 1em;
            line-height: 1.25;

            @media(max-width: $screen-sm-max) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .dropdown-toggle {
            z-index: 1;
            position: relative;
            vertical-align: middle;

            .store-icon-filter {
                font-size: 1.5rem;
                vertical-align: middle;

                @media(min-width: $screen-sm) {
                    font-size: 2rem;
                }
            }

            .sort-filter-text {
                vertical-align: middle;
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .dropdown-menu {
            padding: 0 0;
            width: auto;
            background-color: $search-filter-dropdown-background-color;
            margin-top: 25px;
            z-index: 2;

            @media(max-width: $screen-md - 1) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 101vh;
                //padding-top: 25vh;
                width: 100%;
                overflow: auto;
                margin-top: 0px;
                z-index: 3;
            }

            .arrow-up {
                position: absolute;
                @include point(bottom, #1F3370, 25px, 25px, 0, 2.35rem, 100%, 0);
                border-top-width: 0px;
            }
        }

        .search-sort {
            color: $white;
            //height: 180px;
            width: 100%;
            padding: 67px $container-gutter-mobile 14px;
            background: #1F3370;

            @media(min-width: $screen-md) {
                width: 850px;
                padding: 17px 0 0;
            }

            @media(min-width: 1050px) {
                width: 1000px;
                //height: 160px;
            }

            .search-sort-title {
                float: left;
                font-size: 1rem;
                font-weight: normal;

                @media(min-width: $screen-md) {
                    font-size: 1.25rem;
                    padding-left: 33px;
                    width: 25.6%;
                }

                @media(min-width: $screen-lg) {
                    font-size: 1rem;
                }
            }

            .search-sort-sorting {
                .filter-item-name {
                    font-size: 0.8rem;

                    @media(min-width: $screen-md) {
                        font-size: 1rem;
                    }

                    @media(min-width: $screen-lg) {
                        font-size: 0.8rem;
                    }

                    .checkbox-btn {
                        padding: 3px 0;

                        @media(min-width: $screen-md) {
                            padding: 0;
                            padding: 9px 0;
                        }
                    }
                }
            }

            .store-icon-close {
                color: $white;
                cursor: pointer;
                font-size: 1.5rem;

                &.align-for-filter {
                    @media(min-width: $screen-md) {
                        margin-right: 30px;
                    }
                }
                &:hover, &:focus {
                    color: #f1c400;
                    font-weight:500;
                }
            }

            .filter-items {
                margin-top: 0px;
                margin-left: 1px !important;

                @media(min-width: $screen-md) {
                    margin-left: 0px !important;
                    padding-bottom: 12px;
                }

                .filter-item-name {
                    padding-top: 22px;
                    width: 50%;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    margin-left: 0px !important;
                    margin-right: 0px !important;

                    @media(min-width: $screen-md) {
                        width: 15.1875rem;
                        padding-top: 28px;
                    }

                    .checkbox-btn {
                        height: 1.375rem;
                        width: 100%;
                        margin-bottom: 0;

                        @media(min-width: $screen-md) {
                            height: 2.5625rem;
                        }

                        @media(min-width: $screen-lg) {
                            height: 2.05rem;
                        }
                    }
                }
            }
        }

        .dropdown-backdrop {
            z-index: 0;
        }
    }
}

.filter-section-header {
    background-color: $search-filter-dropdown-background-color;
    width: 100%;
    @include flexbox();
    @include align-items(center);
    padding-top: 20px;
    padding-left: $container-gutter-mobile;

    @media(min-width: $screen-md) {
        padding-bottom: 32px;
        padding-left: 33px;
    }

    .filter-section-title {
        display: inline-block;
        font-size: 1rem;

        @media(min-width: $screen-md) {
            font-size: 1.25rem;
        }

        @media(min-width: $screen-lg) {
            font-size: 1rem;
        }
    }

    .clear-all-button {
        display: inline-block;
        position: absolute;
        right: 0;
        padding-right: $container-gutter-mobile;
        font-size: 0.8rem;
        border: none;
        background-color: transparent;
        outline: none;

        @media(min-width: $screen-md) {
            font-size: 1rem;
            padding-right: 33px;
        }

        @media(min-width: $screen-lg) {
            font-size: 0.8rem;
        }

        &:hover {
            color: $yellow;
        }

        &:focus {
            color: $yellow;
        }
    }
}

$filter-title-width: 25.6%;

.filters-dropdown {
    .filter-wrapper {
        color: #000000;
        background-color: $search-filter-dropdown-background-color;
        display: block;
        padding: 0 $container-gutter-mobile $mobile-bottom-padding;

        @media(min-width: $screen-md) {
            padding: 0;
            display: inline-block;
        }

        .filter-section {
            padding-top: 13px;
            margin-right: -12px;

            @media(min-width: $screen-md) {
                margin-right: 0;
                padding-top: 0px;
            }

            &:last-child {
                .filter-items {
                    padding-bottom: 12px;
                }
            }
        }

        .filter-section-name {
            margin-bottom: 0px;
            display: block;
            font-size: 0.8rem;
            color: $white;
            font-weight: 400;
            padding-bottom: 14px;

            @media(min-width: $screen-md) {
                float: left;
                padding-top: 6px;
                font-size: 1rem;
                padding-left: 33px;
                width: $filter-title-width;
            }

            @media(min-width: $screen-lg) {
                font-size: 0.8rem;
            }
        }

        .filter-items {
            display: block;
            float: left;
            width: 100%;
            /*margin-top: 25px;*/
            @media(min-width: $screen-md) {
                /*padding-left: 268px;*/
                margin-top: 0px;
                padding-right: 32px;
                padding-bottom: 24px;
                width: (100%-$filter-title-width);
            }

            .filter-item-name {
                width: 50%;
                height: auto;
                position: relative;
                padding-bottom: 8px;
                //padding-top: 8px;
                font-weight: 400;

                @media(min-width: $screen-sm) {
                    width: 33.33333333333333%;
                    max-width: 300px;
                }

                @media(min-width: $screen-md) {
                    width: 12.5rem;
                    margin-right: 24px;
                    margin-bottom: 0px;
                    padding-bottom: 24px;
                }

                @media(min-width: $screen-lg) {
                    width: 10rem;
                }

                .checkbox-btn {
                    border: 1px solid $search-inactive-filter-color;
                    color: $search-inactive-filter-color;
                    background: transparent;
                    padding: 3px 0 4px;
                    margin-bottom: 0;
                    font-weight: 400;
                    /*@include inline-flex;
                    @include justify-content(center);
                    @include align-items(center);*/
                    @media(min-width: $screen-md) {
                        padding: 6px 0 5px;
                    }

                    span {
                        font-size: 0.6375rem;

                        @media(min-width: $screen-sm) {
                            font-size: 0.8rem;
                        }

                        @media(min-width: $screen-md) {
                            font-size: 1rem;
                        }

                        @media(min-width: $screen-lg) {
                            font-size: 0.8rem;
                        }
                    }

                    &:hover {
                        border: 1px solid $yellow;
                        color: $yellow;
                    }
                }

                .checkbox-btn.focused-outline {
                    border: 1px solid #F1C400;
                    color: #F1C400;
                }

                .refinement-chkbox {
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;

                    &:hover {
                        + .checkbox-btn {
                            border: 1px solid $yellow;
                            color: $yellow;
                        }
                    }

                    &:checked {

                        + .checkbox-btn {
                            color: #0a0b0d;
                            background: $search-inactive-filter-color;
                        }

                        &:hover {
                            + .checkbox-btn {
                                background: $yellow;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }

        .filter-items.Release.Year {

            .filter-item-name {
                font-size: 0.8rem;
                width: 33.33333333333333%;

                @media(min-width: $screen-sm) {
                    width: 25%;
                }

                @media(min-width: $screen-md) {
                    width: 5.6875rem;
                    padding-right: 10px;
                    margin-right: 0;
                    padding-bottom: 10px;
                }

                .checkbox-btn {
                    margin-bottom: 0;

                    span {
                        font-size: 0.6375rem;

                        @media(min-width: $screen-md) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}

.result-container {

    .search-records {

        .movie-card-section-wrapper {

            @include flexbox();
            @include justify-content(flex-start);

            .movie-card-wrap {
                display: inline-block;
                width: percentage(1/3);

                @media(min-width: $screen-sm) {
                    width: percentage(1/4);
                }

                @media(min-width: $screen-md) {
                    width: percentage(1/6);
                }

                @media(min-width: $screen-lg) {
                    width: percentage(1/8);
                }
            }
        }
    }
}


//SAYT SUGGESTION STYLES

#ui-id-2.ui-menu.ui-autocomplete {
    position: absolute;
}
