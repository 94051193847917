﻿.mc-rating {
    height: 42px;
    width: 42px;
    background: rgba(80, 164, 27, 0.8);
}

.mpaa-rating {
    font-weight: bold;
    border: solid 1px #a7a8aa;
    padding: 1px 5px;
    color: #d8d8d8;
}

.quality-box {
    font-size: 0.8rem;
    line-height: 1;
    border: 1px solid #a7a8aa;
    padding: 0.3125rem 0 0.1875rem;
    width: 2.25rem;

    @media(min-width: $screen-md) {
        font-size: 1rem;
        padding: 0.3125rem 0;
        width: 2.625rem;
    }
}

.cta-btn {
    font-size: 0.8rem;
    padding: 6px 24px 4px;

    @media(min-width: $screen-md) {
        font-size: 1rem;
        padding: 11px 32px 10px;
    }

    @media(min-width: $screen-lg) {
        padding: 14px 32px 13px;
    }
}

.movie-grid-filters {
    width: 100%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 2rem;

    @media(min-width: $screen-sm) {
        margin-top: 20px;
        height: 2.5rem;
    }

    @media(min-width: $screen-md) {
        width: auto;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 1rem;
    }

    @media(min-width: $screen-lg) {
        height: 2rem;
        margin-bottom: 0;
    }

    .filter-items {
        @media(min-width: $screen-sm) {
            height: 2.5rem;
        }

        @media(min-width: $screen-lg) {
            height: 2rem;
        }
    }

    .filters-wrap {
        display: inline-block;
        width: 100%;

        @media(min-width: $screen-sm) {
            width: 26.75rem;
        }

        @media(min-width: $screen-lg) {
            width: 21.4rem;
        }
    }

    .filter-wrap {
        position: relative;
        width: 50%;
        margin: 0 auto;
        font-size: 0.8rem;

        @media(min-width: $screen-sm) {
            font-size: 1rem;
        }

        @media(min-width: $screen-lg) {
            font-size: 0.8rem;
        }

        .checkbox-btn {
            padding: 4px 0;

            @media(min-width: $screen-sm) {
                padding: 9px 0;
            }
        }
    }
}

.empty-scaling-icon {
    font-size: 3.125rem;
    @media(min-width: $screen-md){
        font-size: 6rem;
    }
}

.large-play-btn{
    font-size: 64px;

    @media(min-width: $screen-lg){
        font-size: 90px;
    }
}