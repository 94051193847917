﻿.cineplex_connect_modal {
    .modal.connect-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100001;
        display: none;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        transform: translate(0,0);

        &.in {
            display: block !important;
        }

        .modal-dialog {
            max-height: 100%;
            overflow: auto;
            margin: 10px;

            @media(min-width: $screen-sm) {
                margin: 30px auto;
            }
        }
    }
}

#DotComConnectMobileMenu {
    .navbar-fixed-bottom {
        .navbar-nav {
            li {
                a {
                    color: #FFF;
                }
            }
        }
    }
}
