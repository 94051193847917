/*VARIABLES*/
@import "../../vendor/bootstrap_v3/bootstrap/modifications/_variables-modified.scss";
@import "_variables.scss";

/*MIXINS*/
@import "mixins/_general-classes-mixins.scss";
@import "mixins/styling-mixins";
@import "mixins/_flex.scss";

/*GENERAL STYLES*/
@import "_fonts.scss";
@import "_general-classes.scss";
@import "_base.scss";
@import "_ie.scss";
@import "_loader.scss";
@import "_autocomplete.scss";
@import "_jspStyles.scss";
@import "_forms.scss";
@import "_legacy-styles.scss";
@import "_connect.scss";
@import "_carousels.scss";
@import "_generic-modals.scss";
@import "_movie-grids.scss";
@import "_common-elements.scss";
@import "_outdatedbrowserstyles.scss";

/*TEMPLATE STYLES*/
@import "template/_navbar.scss";
@import "template/_footer.scss";

/*MODULE STYLES*/
@import "modules/_full-width-promo-module.scss";
@import "modules/_image-promo-area-module.scss";
@import "modules/_movie-carousel-module.scss";
@import "modules/_deal-banner-module.scss";
@import "modules/_promo-banner-module.scss";
@import "modules/_confirm-play-modal.scss";

/*VENDOR*/
@import "../../vendor/slick/slick.scss";
@import "../../vendor/slick/slick-theme.mod.scss";

/*PAGES*/
@import "pages/_search-and-genre-page.scss";
@import "pages/_pdp-page.scss";
@import "pages/_checkout-page.scss";
@import "pages/_superticket-page.scss";
@import "pages/_my-library-page.scss";
@import "pages/_activate.scss";

/*Styles from those who don't know what they're doing*/
@import "_unformatted.scss";