﻿@mixin generatePaddingClassesWithBreakpoints($breakpoints, $sizes, $directions) {
    @each $breakpointName, $breakpointSize in $breakpoints {
        @media(min-width: $breakpointSize){
            @each $sizeName, $size in $sizes{
                @each $direction in $directions {
                    .pad-#{$direction}-#{$breakpointName}-#{$sizeName}{
                        padding-#{$direction}: $size;
                    }
                }
            }
        }
    }
}

@mixin generateMarginClassesWithBreakpoints($breakpoints, $sizes, $directions) {
    @each $breakpointName, $breakpointSize in $breakpoints {
        @media(min-width: $breakpointSize) {
            @each $sizeName, $size in $sizes {
                @each $direction in $directions {
                    .pad-#{$direction}-#{$breakpointName}-#{$sizeName} {
                        padding-#{$direction}: $size;
                    }
                }
            }
        }
    }
}

@mixin generatePaddingClasses($sizes, $directions) {
    .no-pad {
        padding: 0;
    }

    @each $sizename, $size in $sizes {
        .pad-#{$sizename} {
            padding: $size;
        }

        @each $direction in $directions {
            .pad-#{$direction}-#{$sizename} {
                padding-#{$direction}: $size;
            }
        }
    }

    @each $direction in $directions {
        .no-pad-#{$direction} {
            padding-#{$direction}: 0;
        }
    }
}

@mixin generateMarginClasses($sizes, $directions) {
    .no-margin {
        margin: 0;
    }

    @each $sizename, $size in $sizes {
        .margin-#{$sizename} {
            margin: $size;
        }

        @each $direction in $directions {
            .margin-#{$direction}-#{$sizename} {
                margin-#{$direction}: $size;
            }
        }
    }

    @each $direction in $directions {
        .no-margin-#{$direction} {
            margin-#{$direction}: 0;
        }
    }
}


@mixin generateOpacityClasses($opacities) {
    @each $opacityLevel, $opacity in $opacities {
        .op-#{$opacityLevel} {
            opacity: $opacity;
        }
    }
}
