﻿#Wrapper {

    &.legacy-wrapper {

        .navbar.navbar-default {
            background: $legacy-menu-bg;

            &.primary-nav-scrolled,
            &.search-open {
                background: $legacy-menu-bg;
            }
        }

        .body-content {
            color: $legacy-text-color;

            a {
                color: $legacy-link-color;

                &:hover,
                &:focus {
                    color: $legacy-link-hvr-color;
                }

                &:active,
                &:visited {
                    color: $legacy-link-active-color;
                }
            }
        }


        .btn-primary, .btn-primary-small {
            color: $legacy-btn-primary-text-color;
            border: $legacy-btn-primary-border;
            font-size: $legacy-btn-primary-text-size;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
            -webkit-border-radius: $legacy-btn-primary-border-radius;
            -moz-border-radius: $legacy-btn-primary-border-radius;
            -ms-border-radius: $legacy-btn-primary-border-radius;
            -o-border-radius: $legacy-btn-primary-border-radius;
            border-radius: $legacy-btn-primary-border-radius;
            @include linear-gradient(top, bottom, $legacy-btn-primary-gradiant-start-color,$legacy-btn-primary-gradiant-end-color);
            -webkit-box-shadow: $legacy-btn-primary-box-shadow;
            -moz-box-shadow: $legacy-btn-primary-box-shadow;
            box-shadow: $legacy-btn-primary-box-shadow;
            padding: $legacy-btn-primary-padding;
            text-shadow: $legacy-btn-primary-text-shadow;

            &:hover,
            &:focus {
                color: $legacy-btn-primary-text-hvr-color;
                background: $legacy-btn-primary-hvr-fallback-color;
                @include linear-gradient(top, bottom, $legacy-btn-primary-hvr-gradiant-start-color,$legacy-btn-primary-hvr-gradiant-end-color);
                -webkit-box-shadow: $legacy-btn-primary-hvr-box-shadow;
                -moz-box-shadow: $legacy-btn-primary-hvr-box-shadow;
                box-shadow: $legacy-btn-primary-hvr-box-shadow;
                border: $legacy-btn-primary-hvr-border;
                outline: 0 none;
            }

            &.disabled {
                @include linear-gradient(top, bottom, $legacy-btn-primary-disabled-gradiant-start-color, $legacy-btn-primary-disabled-gradiant-end-color);
                opacity: $legacy-btn-primary-disabled-opacity;

                &:hover,
                &:focus {
                    @include linear-gradient(top, bottom, $legacy-btn-primary-disabled-gradiant-start-color, $legacy-btn-primary-disabled-gradiant-end-color);
                    opacity: $legacy-btn-primary-disabled-opacity;
                }
            }

            &:disabled {
                @include linear-gradient(top, bottom, $legacy-btn-primary-disabled-gradiant-start-color, $legacy-btn-primary-disabled-gradiant-end-color);
                opacity: $legacy-btn-primary-disabled-opacity;

                &:hover,
                &:focus {
                    @include linear-gradient(top, bottom, $legacy-btn-primary-disabled-gradiant-start-color, $legacy-btn-primary-disabled-gradiant-end-color);
                    opacity: $legacy-btn-primary-disabled-opacity;
                }
            }
        }

        .btn-primary-small, .btn-secondary-small {
            padding: 4px 6px;
        }
        //SLIDER STYLES:
        .movie-card-section-wrapper {

            .see-all-card {
                position: relative;

                .see-all-card-content {
                    background: #FFF;

                    border: 1px solid $legacy-link-color;

                    &:hover,
                    &:focus {
                        //background: $legacy-btn-primary-hvr-fallback-color;
                        color: $legacy-link-hvr-color;
                        border: 1px solid $legacy-link-hvr-color;

                        .see-all-card-text {
                            color: $legacy-link-hvr-color;
                        }
                    }

                    .see-all-card-text {
                        color: $legacy-link-color;
                    }
                }
            }
        }
    }
}
