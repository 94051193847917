﻿//Colour Styles

.bg-white {
    background: $white;
}

.bg-transparent{
    background: transparent;
}

//Text Styles

.text-underline {
    text-decoration: underline;
}

.no-text-decoration {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.text-small {
    font-size: $font-size-small;
}

.text-large {
    font-size: $font-size-large;
}

.text-bold {
    font-weight: bold;
}

.font-wt-lt {
    font-weight: $text-weight-light;
}

.font-wt-reg {
    font-weight: $text-weight-reg;
}

.font-wt-md {
    font-weight: $text-weight-medium;
}

.font-wt-hvy {
    font-weight: $text-weight-heavy;
}

.prevent-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-wrap {
    white-space: nowrap;
    text-wrap: none;
}

//Icon Styles
.icon-lg {
    font-size: 48px;
}

//Sizing
.fill {
    width: 100%;
    height: 100%;
}

.fill-width {
    width: 100%;
}

.fill-height {
    height: 100%;
}

.max-width-fill {
    max-width: 100%;
}

.max-height-fill {
    max-height: 100%;
}

.content-width-fill > * {
    width: 100%;
}

.content-max-width-fill > * {
    max-width: 100%;
}

content-height-fill > * {
    height: 100%;
}

.content-max-height-fill > * {
    max-height: 100%;
}

.overflow-hidden {
    overflow: hidden;
}

.clear {
    clear: both;
}

@include generatePaddingClasses($spacing, $directions);
@include generatePaddingClassesWithBreakpoints($breakpoints, $spacing, $directions);
@include generateMarginClasses($spacing, $directions);
@include generateMarginClassesWithBreakpoints($breakpoints, $spacing, $directions);

@include generateOpacityClasses($opacities);

.anti-container-gutter {
    margin-left: - percentage(($container-gutter-mobile/100%) / (1 - 2 * ($container-gutter-mobile / 100%)));
    margin-right: - percentage(($container-gutter-mobile/100%) / (1 - 2 * ($container-gutter-mobile / 100%)));

    @media(min-width: $screen-md) {
        margin-left: -$container-gutter-desktop-scaled;
        margin-right: -$container-gutter-desktop-scaled;
    }
}

//Alignment Classes

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.vertical-align-middle {
    vertical-align: middle;
    white-space: nowrap;
}

.vertical-align-middle > * {
    vertical-align: middle;
    white-space: initial;
    display: inline-block;
    float: none;
}

.vertical-align-middle:before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}


.vertical-align-bottom {
    vertical-align: bottom;
    /*white-space: nowrap;*/
}

.vertical-align-bottom > * {
    vertical-align: bottom;
    white-space: initial;
    display: inline-block;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-top > * {
    vertical-align: top;
    white-space: initial;
    display: inline-block;
}

.vertical-align-bottom:before {
    border-width: 0px;
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    margin: 0;
    padding: 0;
    vertical-align: bottom;
}

.absolute-align-center {
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.absolute-vertical-align-bottom {
    position: absolute;
    bottom: 0;
}

.top {
    top: 0;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.bottom {
    bottom: 0;
}

//FLEX ALIGNMENT CLASSES

.inline-flex{
    @include inline-flex();
}

.flex-align-top {
    @include inline-flex();
    @include align-items(flex-start);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
}

.flex-align-bottom {
    @include inline-flex();
    @include align-items(flex-end);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
}

.flex-align-center {
    @include inline-flex();
    @include align-items(center);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
}

.flex-align-center {
    @include inline-flex();
    @include align-items(center);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
}

.inline-align-center {
    display: inline;
    @include align-items(center);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
}

.flex-justify-flex-start {
    @include justify-content(flex-start);
}

.flex-justify-flex-end {
    @include justify-content(flex-end);
}

.flex-justify-space-between {
    @include justify-content(space-between);
}

.flex-justify-space-around {
    @include justify-content(space-around);
}

.flex-justify-center{
    @include justify-content(center);
}

.align-self-start {
    @include align-self(flex-start);
}

.align-self-end {
    @include align-self(flex-end);
}

.align-self-center {
    @include align-self(center);
}

.flex-direction-column{
    @include flex-direction(column);
}

.flex-direction-row{
    @include flex-direction(row);
}

.flex-direction-column-reverse{
    @include flex-direction(column-reverse);
}

.flex-direction-row-reverse{
    @include flex-direction(row-reverse);
}

.flex-wrap{
    @include flex-wrap(wrap);
}

//List Classes

.no-list-style {
    list-style: none;
}

//Pointer Classes

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

//Display Classes

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

//Border styles

.border-grey {
    border: solid 1px $grey;
}

/*Error classes*/
.redeem-error {
    color: $redeem-error;
}

.redeem-success {
    color: $redeem-success;
}

.error-box {
    background: $main-medium-color;
    height: auto;
    width: 100%;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    max-width: 360px;
    margin-top: 25px;

    .arrow-up {
        position: absolute;
        @include point(bottom, $main-medium-color, 25px, 25px, 0, 73.3333%, 100%, 0);
        border-top-width: 0;
    }

    .error-popup-message {
        padding: 20px;

        @media(min-width: $screen-sm) {
            padding: 50px;
        }
    }
}


.tooltip {
    @media (max-width : $screen-md + 149) {
        display: none !important;
    }
}

.tooltip.bottom {
    .tooltip-inner {
        background-color: #0A0B0D;
        border: 2px solid #9DACC1;
        color: #9DACC1;
        padding: 8px 16px 8px 16px;
    }

    .tooltip-arrow {
        border-bottom-color: #9DACC1;
    }

    .tooltip.show {
        opacity: 1;
    }
}
