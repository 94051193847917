﻿#checkout-wrap {
    margin-top: 2rem;
    /*.order-success-modal.in,
    .order-failure-modal.in{
        background-color: #151b24;

        @media(min-width: $screen-sm) {
            background-color: transparent;
        }
    }*/

    .order-failure-modal {
        .modal-content {

            .modal-title {
                margin-top: 34px;
            }

            .modal-footer {
                padding: 32px 32px 0;
            }
        }
    }

    .edit-btn-wrap{
        margin:auto;
        margin-right:0;
    }

    .edit-btn {
        border: none;
        background: transparent;
        font-weight: bold;
        margin: auto;
        margin-right: 0;
        padding: 0;
        outline: none;
        color: $white;

        @media(min-width: $screen-md) {
            font-size: 1.25rem;
        }

        &:hover,
        &:focus {
            color: $yellow;
        }
    }

    .add-card-button {
        border: none;
        background: transparent;
        padding: 0;
        outline: none;
        width: 100%;
        @include flexbox();
        @include justify-content(space-between);

        .add-button {
            font-weight: bold;
            color: $white;

            &:hover {
                color: $yellow;
            }
        }
    }

    .checkout-subtext {
        font-size: 0.8rem;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            font-size: 1rem;
        }
    }

    .order-button {

        @media(min-width: $screen-sm) {
            font-size: 0.8rem;
            padding: 8px 8px;
        }

        @media(min-width: $screen-md) {
            padding: 0.75em 1em 0.6875em;
            font-size: 1.25rem;
        }
    }

    .section-scene {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .section-title {
        font-weight: 500;
        font-size: 1.25rem;
        margin-bottom: 32px;
        margin-top: 0;

        @media(min-width: $screen-md) {
            font-size: 1.5625rem;
        }

        &.scene-title {
            @include inline-flex();
            @include align-items(baseline);
        }

        .scene-title-logo {
            height: 1.5rem;

            &.fr-align {
                margin-left: 10px;
            }

            @media(min-width: $screen-md) {
                height: 1.953125rem;
            }
        }
    }

    .co-scaled-text {
        font-size: 1rem;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            font-size: 1.25rem;
        }
    }

    .paypal-account-details {
        width: calc(100% - 4rem);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .scene-gift-card-error-box {
        font-size: 1rem;
        z-index: 2;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            margin-left: -1.875rem;
        }
    }

    .order-success-modal {
        &.in {
            display: block;
        }

        .success-message {
            font-size: 1.25rem;
        }

        .modal-content {
            padding-bottom: 32px;

            @media(min-width: $screen-md) {
                padding: 0;
            }
        }

        .order-success-modal-footer {
            font-size: 0.75rem;
            margin-top: 16px;
            display: none;

            @media(min-width: $screen-mobile-checkout-brakepoint) {
                margin-top: 0;
            }
        }
    }

    .rental-modal.order-success-modal {
        .modal-content {
            padding-bottom: 64px;

            @media(min-width: $screen-md) {
                padding: 0;
            }
        }

        .order-success-modal-footer {
            display: block;
        }
    }
}

.checkradio {
    vertical-align: top;
}

.checkout-checkboxes {
    @include flex-shrink(0);
}

.modal-selection-options {
    padding-left: 1rem;
    padding-right: 40px;
    @include flex-grow(1);

    @media(min-width: $screen-md) {
        padding-left: 46px;
    }

    .paypal-user-text {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        padding-bottom: 4px;
        max-width: 5rem;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            max-width: 12rem;
        }

        @media(min-width: $screen-md) {
            max-width: 20rem;
        }
    }
}

.checkout-error {
    padding-bottom: 100px;

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        padding-bottom: 200px;
    }

    @media(min-width: $screen-md) {
        padding-bottom: 400px;
    }
}

.checkout-loader-wrap {
    visibility: hidden;
    position: absolute;
    left: -1000000000px;
    width: 100%;

    &.checkout-loaded {
        visibility: visible;
        position: static;
        left: 0;
    }
}

.top-row {
    float: none;
}

.card-modal {
    width: 100vw;
    height: 100vh;
    padding-right: 0 !important;
    padding-top: 10vh;
    padding-bottom: 10vh;

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        font-size: 1.25rem;
        /*height: auto;
        max-height: 80vh;*/
    }

    &.in {
        background: transparent;
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
        @include align-items(center);
        @include justify-content(center);
    }

    .card-modal-container {
        background: $checkout-modal-content;
        width: $xs-container-width;
        margin: auto 0;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            width: $sm-container-width;
            max-width: 662px;
        }

        @media(min-width: $screen-lg) {
            max-width: 882px;
        }
    }

    .modal-header {
        background: $checkout-modal-header;
        height: auto;
        border-width: 0;
        position: relative;
        width: 100%;
        float: left;
        padding: 32px;

        @media(min-width: $screen-md) {
            padding: 40px;
        }

        @media(min-width: $screen-lg) {
            padding: 48px;
        }

        .store-icon-close {
            position: absolute;
            cursor: pointer;
            top: 1rem;
            right: 1rem;
            font-size: 1.25rem;

            @media(min-width: $screen-md) {
                font-size: 1.5rem;
            }

            &:hover,
            &:focus {
                color: $yellow;
            }
        }

        .card-modal-header-add-button {
            vertical-align: top;

            .glyphicon-plus {
                vertical-align: top;
                font-size: 1.25rem;
                line-height: 1.25;
                margin-top: -0.25rem;

                @media(min-width: $screen-mobile-checkout-brakepoint) {
                    font-size: 2.25rem;
                    line-height: 1;
                    margin-top: -0.5rem;
                }
            }
        }

        .modal-title {
            margin-bottom: 16px;
            font-size: 1.5625rem;
            color: $white;
        }

        .add-paypal-button {
            display: block;
            margin-top: 10px;

            @media(min-width: $screen-mobile-checkout-brakepoint) {
                display: inline-block;
                margin-top: 0px;
                margin-left: 48px;
            }
        }
    }

    .modal-content {
        background: transparent;
        height: auto;
        box-shadow: none;
        border: none;
        width: 100%;
        float: left;
        padding-bottom: 64px;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            padding-bottom: 48px;
        }

        .credit-card-modal-list {
            @include flexbox();
            @include align-items(center);
        }

        a {
            display: block;
            margin-left: 48px;

            &.for-card-modals {
                margin-top: 32px;
                @include flexbox();
                @include align-items(center);
                @include justify-content(space-between);
            }

            input {
                margin-right: 6%;
            }

            .credit-card-img {
                width: 56px;
                height: 28px;
                border: 1px solid $white;

                @media(min-width: $screen-mobile-checkout-brakepoint) {
                    width: 80px;
                    height: 40px;
                }

                @media(min-width: $screen-lg) {
                    width: 96px;
                    height: 48px;
                }
            }

            &:hover,
            &:focus,
            &:active {

                &.credit-card-modal-list {
                    color: $yellow;
                    font-weight: bold;
                }

                .faux-input {
                    border-color: $yellow;
                }
            }

            &.option-selected {

                &:hover,
                &:focus {
                    .faux-input {
                        background: $yellow;
                        border-color: $yellow;
                    }

                    .credit-card-img {
                        border-color: $yellow;
                    }

                    color: $yellow;
                }
            }
        }
    }

    .card-modal-subtext {
        font-size: 0.8rem;
    }

    &.billing-address-modal {
        .modal-content {
            a {
                &.for-card-modals {
                    @media(min-width: $screen-md) {
                        display: block;
                    }
                }
            }
        }
    }
}

.scene-points-earned {
    @include inline-flex();
    @include align-items(baseline);
    font-size: 0.8rem;

    @media(min-width: $screen-md) {
        font-size: 1rem;
    }

    .checkout-scene-logo {
        height: 16px;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            height: 12px;
        }

        @media(min-width: 740px) {
            height: 16px;
        }

        @media(min-width: $screen-md) {
            height: 20px;
        }
    }

    &.hide-scene-points-earned {
        display: none !important;
    }
}

.checkout-payment-checkboxes {

    &:hover,
    &:focus {
        .checkradio {
            .faux-input {
                border-color: $yellow;
            }
        }
    }

    &.disable-checkbox {
        opacity: 0.3;

        &:hover,
        &:focus {
            cursor: not-allowed;
            color: $white;

            .checkradio {
                cursor: not-allowed;

                .checkbox {
                    cursor: not-allowed;
                }

                .faux-input {
                    border-color: $white;
                }
            }
        }
    }

    &.checkbox-active {
        &:hover,
        &:focus {
            .checkradio {
                .faux-input {
                    background: $yellow;
                    border-color: $yellow;
                }
            }
        }
    }

    .checkradio {
        .faux-input {
            border-color: $checkout-default-font;
        }
    }
}

.checkout-container {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-start);

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        @include flex-direction(row);
    }
}

.purchase-item {

    &.for-mobile {
        margin-left: ($checkout-order-summary-anti-gutter);
        margin-right: ($checkout-order-summary-anti-gutter);
        padding-left: $container-gutter-mobile;
        padding-right: $container-gutter-mobile;
    }

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        margin-top: 32px;
    }

    @media(min-width: $checkout-desktop-breakpoint) {
        margin-top: 0px;
    }

    .purchase-selections {
        /*@include flexbox();
        @include align-items(flex-start);*/
        .movie-purchase-plan {
            color: $checkout-default-font;
            font-size: 1.25rem;

            @media(min-width: $screen-md) {
                font-size: 1.5625rem;
            }
        }

        .movie-purchase-quality {
            font-size: 0.8rem;
            line-height: 1;
            border: 1px solid #a7a8aa;
            padding: 0.3125rem 0 0.1875rem;
            width: 2.25rem;

            @media(min-width: $screen-md) {
                font-size: 1rem;
                padding: 0.3125rem 0;
                width: 2.625rem;
            }
        }

        .uhd-label {
            height: 1.375rem;
            padding-left: 0.25rem;

            @media(min-width: $screen-md) {
                height: 1.75rem;
            }
        }
    }

    .pdp-movie-title {
        font-size: 1.5625rem;
        color: $white;
        line-height: 1.25;
        margin-bottom: 0px;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            font-size: 1.95625rem;
        }

        @media(min-width: $screen-md) {
            margin-top: 8px;
            font-size: 2.44125rem;
        }
    }
}

.checkout-left-side {
    float: none;
    @include align-self(flex-start);
    @include order(1);

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        @include order(0);
        width: (51.74259545616747948138456048531% + 1.72414%);
        padding-right: 0;
        margin-right: 5.46%;
    }

    @media(min-width: $screen-md) {
        width: 56.537%;
        margin-right: 15.082%;
    }

    @media(min-width: $checkout-desktop-breakpoint) {
        width: 58.333333333333333333333333333333%;
        margin-right: 15.437037037037037%;
    }

    @media(min-width: $screen-lg) {
        width: 50%;
        margin-right: 23.29%;
    }

    .checkout-image-container {
        @media(min-width: $screen-mobile-checkout-brakepoint) {
            width: 19.53125vw;
        }

        @media(min-width: $screen-md) {
            width: (100%-71.43%);
            padding-right: 0.81967vw;
        }

        @media(min-width: $screen-lg) {
            width: 25%;
        }
    }

    .purchase-button-area {
        float: right;
        color: $white;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            float: left;
        }

        @media(min-width: $screen-md) {
            width: 71.43%;
            padding-left: 0.81967vw;
        }

        @media(min-width: $screen-lg) {
            width: 75%;
        }

        .checkout-bundle-section {
            margin-top: 30px;
            margin-bottom: 76px;

            @media(min-width: $screen-mobile-checkout-brakepoint) {
                margin-top: 0px;
            }

            .checkout-bundle-button {
                font-size: 1rem;
                border: none;
                background: transparent;
                padding: 0;
                text-align: left;
                outline: none;
                @include flexbox();
                @include align-items(center);
                padding-top: 0;

                @media(min-width: $screen-mobile-checkout-brakepoint) {
                    padding-top: 1rem;
                }


                @media(min-width: $screen-md) {
                    padding-top: 0;
                    font-size: 1.5625rem;
                }

                .bundle-expand-icon {
                    display: inline-block;
                    border: 2px solid $checkout-default-font;
                    border-radius: 50%;
                    height: 38px;
                    width: 38px;
                    font-size: 38px;

                    .bundle-expand-plus {
                        display: inline-block;
                        position: relative;
                        text-align: center;
                        font-size: 38px;
                        height: 38px;
                        width: 38px;
                        top: -6px;
                        left: -1px;
                    }
                }

                &:hover,
                &:focus {
                    .bundle-expand-icon {
                        border-color: $yellow;
                    }

                    color: $yellow;
                }
            }

            .checkout-bundle-modal-section {
                padding-right: 0px !important;
                background: #090A0C;

                @media(min-width: $screen-mobile-checkout-brakepoint) {
                    background: transparent;
                }

                .bundle-purchase-image {
                    width: 138px;
                    height: auto;
                }

                .checkout-bundle-modal-content {
                    border-width: 0;
                    background-image: url("/Content/img2017/rock-background-modal.png");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-color: #090A0C;

                    .modal-title {
                        font-size: 1.95625rem;
                        font-weight: 700;
                    }

                    .bundle-link {
                        font-size: 1rem;
                        text-transform: uppercase;
                        margin-left: 0;
                        margin-bottom: 30px;
                    }

                    .checkout-bundle-modal-back-btn {
                        padding: 10px 0;
                        width: 50%;
                    }
                }
            }
        }

        #sceneCard {

            .scene-card-name {
                max-width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.for-modal {
                    max-width: 7rem;
                }
            }

            #sceneCardsSummaryDetails {

                .payment-info-line {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(flex-start);
                }
            }
            /*.selected-info {

                .select-card {

                    @include flexbox();
                    @include align-items(center);
                }
            }*/
            .redeem-message {
                font-size: 0.8rem;
                vertical-align: top;
                color: $checkout-default-font;

                @media(min-width: 1000px) {
                    font-size: 1.25rem;
                }
            }
        }

        .payment-method-header {
            width: 100%;
            @include flex-wrap(wrap);

            h2 {
                font-size: 1.5625rem;
                margin-top: 0px;
                margin-bottom: 0px;
                line-height: 1.25;
            }

            .payment-title {
                white-space: nowrap;
            }

            .payment-images {
                white-space: nowrap;
                display: block;

                .payment-method-img {
                    height: 21px;
                }
            }
        }

        #paymentCard {

            #creditCardsSummaryDetails {

                .credit-card-hidden-part {
                    display: none;

                    @media(min-width: 330px) {
                        display: inline-block;
                    }

                    @media(min-width: $screen-mobile-checkout-brakepoint) {
                        display: none;
                    }

                    @media(min-width: 645px) {
                        display: inline-block;
                    }

                    @media(min-width: $screen-md) {
                        display: none;
                    }

                    @media(min-width: 1000px) {
                        display: inline-block;
                    }
                }
            }
        }

        #giftCard {
            .pay-with-gift-card-text {
                font-size: 0.8rem;
                color: $checkout-default-font;

                @media(min-width: 1000px) {
                    font-size: 1.25rem;
                }
            }
        }

        #promoCode {
            .promo-code-entry-area {
                position: relative;
                background: $checkout-promo-area-background;

                .promo-code-text,
                .entered-promo-code {
                    display: inline-block;
                    border: none;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 15px;
                    padding-right: 5rem;
                    width: 100%;
                    background: transparent;
                    outline: none;
                    font-size: 1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media(min-width: $screen-md) {
                        font-size: 1.25rem;
                        padding-right: 7rem;
                    }

                    &.fr {
                        @media(max-width: $screen-mobile-checkout-brakepoint - 1) {
                            font-size: 12px;
                        }
                    }

                    &::placeholder {
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .promo-btn {
                    display: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                    border: none;
                    outline: none;
                    background: transparent;
                    padding: 10px 16px;
                    font-weight: 700;
                    color: $white;

                    &.fr {
                        @media(max-width: $screen-mobile-checkout-brakepoint - 1) {
                            font-size: 14px;
                        }
                    }

                    &:hover,
                    &:focus {
                        color: $yellow;
                    }

                    &.promo-apply-btn-visible {
                        display: block;
                    }
                }

                display: none;

                &.promo-area-loaded {
                    display: block;
                }
            }

            .checkout-promo-loader {
                padding: 0;
            }

            .promo-code-wrap-valid-code {

                .promo-code-line {
                    width: 100%;

                    @media(min-width: $screen-md) {
                        font-size: 1.25rem;
                    }

                    .promo-btn-valid-code {
                        font-weight: bold;
                        cursor: pointer;

                        &:hover,
                        &:focus {
                            color: $yellow;
                        }
                    }
                }
            }
        }

        #billingAddress {
            padding-bottom: 64px;

            @media(min-width: $screen-mobile-checkout-brakepoint) {
                padding-bottom: 0;
            }
        }
    }
}

.summary-right-column {
    @include order(0);
    background: $checkout-summary-area-background;
    color: $checkout-default-font;
    float: none;
    font-size: 1rem;
    margin: 0 ($checkout-order-summary-anti-gutter) 32px;
    padding-left: $container-gutter-mobile;
    padding-right: $container-gutter-mobile;
    padding-bottom: 32px;
    width: auto;

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        float: none;
        @include flexbox();
        @include flex-direction(column);
        @include flex-grow(1);
        @include order(2);
        margin: -6rem -6.25vw -128px 0;
        padding: 6rem 6.25vw 0;
    }

    @media(min-width: $screen-md) {
        font-size: 1.25rem;
        margin: -6rem -2.3438vw -128px 0;
        padding: 6rem 2.3438vw 0;
    }

    #orderInfo {
        @media(min-width: $screen-md) {
            margin-top: 139px;
        }

        &.has-bundle {
            @media(min-width: $screen-md) {
                margin-top: 275px;
            }
        }
    }

    #totalInfo {
        padding-top: 32px;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            padding-top: 0;
        }

        @media(min-width: $screen-md) {
            padding-top: 32px;
        }
    }

    .order-summary-title {
        font-weight: 500;
        font-size: 1.25rem;
        margin-top: 0px;
        line-height: 1.25;

        @media(min-width: $screen-md) {
            font-size: 1.5625rem;
        }
    }

    .total-line {
        font-weight: 500;
        font-size: 1.25rem;

        @media(min-width: $screen-md) {
            font-size: 1.5625rem;
        }
    }

    .place-order-section {
        display: none;

        &.checkout-place-order-mobile {
            display: none;

            &.submit-order-load {
                display: block;

                @media(min-width: $screen-mobile-checkout-brakepoint) {
                    display: none;
                }
            }
        }

        &.submit-order-load {
            display: block;
        }
    }
}

.checkout-place-order-mobile {
    display: none;

    &.submit-order-load {
        display: block;

        @media(min-width: $screen-mobile-checkout-brakepoint) {
            display: none;
        }
    }
}

.checkout-loader-mobile {
    display: block;

    @media(min-width: $screen-mobile-checkout-brakepoint) {
        display: none !important;
    }
}

.checkout-hidden-mobile-breakpoint {
    @media(min-width: $screen-sm) and (max-width: $screen-mobile-checkout-brakepoint - 1) {
        display: none !important;
    }
}
.checkout-visible-mobile-breakpoint {
    @media(min-width: $screen-sm) and (max-width: $screen-mobile-checkout-brakepoint - 1) {
        display: block !important;
    }
}