﻿
// General Elements:
.checkradio {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    vertical-align: middle;
    cursor: pointer;

    &.round {
        .faux-input {
            border-radius: 50%;

            &::before {
                top: 2px !important;
            }
        }
    }

    input[type=checkbox] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        &:hover,
        &:focus {
            + .faux-input {
                color: $yellow;
                width: 100%;
                height: 100%;
                border: 1px solid $yellow;
            }
        }

        &:checked,
        &.checked {
            + .faux-input {
                color: $black;
                background: $gray-light;
                border: 1px solid $gray-light;

                &::before {
                    content: "\79";
                    font-family: 'CineplexStoreFont' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: bold;
                    font-variant: normal;
                    font-size: 1.25rem;
                    text-transform: none;
                    line-height: 1;
                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: relative;
                    top: 0;
                    left: 1px;
                }
            }

            &:hover,
            &:focus {
                + .faux-input {
                    width: 100%;
                    height: 100%;
                    border: 1px solid $yellow;
                    background: $yellow;
                }
            }
        }
    }

    .faux-input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $white;
        background: transparent;

        &::before {
            content: "";
        }
    }
}


.checkbox-btn {
    border: 1px solid $checkbox-btn-inactive-color;
    color: $checkbox-btn-inactive-color;
    background: transparent;
    padding: 5px;
    text-align: center;

    span {
        font-size: 1rem;
    }

    &:hover,
    &:focus {
        border: 1px solid $checkbox-btn-hvr-color;
        color: $checkbox-btn-hvr-color;
    }
}

.refinement-chkbox {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 !important;

    &:hover,
    &:focus {
        + .checkbox-btn {
            border: 1px solid $checkbox-btn-hvr-color;
            color: $checkbox-btn-hvr-color;
        }
    }

    &:checked {

        + .checkbox-btn {
            color: $checkbox-btn-active-color;
            background: $checkbox-btn-inactive-color;
        }

        &:hover,
        &:focus {
            + .checkbox-btn {
                background: $checkbox-btn-active-hvr-bg-color;
                color: $checkbox-btn-active-hvr-color;
            }
        }
    }
}
