﻿.deal-banner {
    padding: 6.25vw $container-gutter-mobile;
    background: linear-gradient(103deg, rgba(0, 53, 142, 0.4), rgba(86, 0, 117, 0.4));
    margin-top: 32px;
    margin-left: - $container-gutter-mobile-scaled;
    margin-bottom: 32px;
    margin-right: - $container-gutter-mobile-scaled;

    @media(min-width: $screen-md) {
        padding: 3.125vw $container-gutter-desktop;
        margin-left: -$container-gutter-desktop-scaled;
        margin-right: -$container-gutter-desktop-scaled;
    }

    &.trailer-deal {
        padding-top: 0px;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 1rem;
        background: linear-gradient(280deg, #00358e, rgba(86, 0, 117, 0.1));

        .deal-content {
            text-align: center;

            @media(min-width: $screen-sm) {
                text-align: left;
            }
        }

        .trailer-deal-wrap {
            flex-wrap: wrap;

            @media(min-width: $screen-md) {
                flex-wrap: nowrap;
            }
        }
    }


    + .deal-banner {
        margin-top: 0;
    }

    .multi-item-deal {
        visibility: hidden;
        max-height: 15vw;

        &.slick-initialized {
            visibility: visible;
            max-height: none;
        }

        .slick-list {
            @include flexbox();
            @include justify-content(center);
            overflow: visible;
        }

        .slick-track {
            @include flexbox();
            @include align-items(center);
            @include flex-wrap(nowrap);
        }

        .playlist-indicator {
            display: none !important;

            @media(min-width: $screen-md) {
                display: block !important;
            }

            + .slick-list {
                display: block;
                padding-right: 33.3333% + $container-gutter-mobile;
                margin-right: -$container-gutter-mobile;

                @media(min-width: $screen-sm) {
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }

        .deal-item {
            padding: 0 1.5625vw;
            outline: none;

            @media(min-width: $screen-sm) {
                padding: 0 1.5625vw;
            }

            @media(min-width: $screen-md) {
                padding: 0 0.78125vw;
            }

            @media(min-width: $screen-lg) {
                padding: 0 0.78125vw;
            }
        }

        .slick-slide {
            position: relative;
            opacity: 0.5;
            height: auto;
        }

        .slick-active {
            opacity: 1;
        }

        .deal-img {
            @media(min-width: $screen-sm) {
                border: 1px solid transparent;
                display: block;

                &:hover {
                    border: 1px solid #F1c400;
                }
            }
        }
    }
}
