﻿.modal-icon-area {
    padding-bottom: 1rem;
    @include inline-flex();
    @include align-items(center);

    .icon {
        font-size: 1.25rem;
        padding-right: 8px;

        @media(min-width: $pricing-modal-collapse-width) {
            font-size: 2.4rem;
        }
    }

    .icon-text {
        font-size: 1.25rem;

        @media(min-width: $pricing-modal-collapse-width) {
            font-size: 1.5625rem;
        }
    }
}

.modal {
    .modal-content {
        .half-width-modal-buttons {
            @include flexbox();
            @include flex-direction(column);

            @media(min-width: $pricing-modal-collapse-width) {
                @include flex-direction(row);
                padding: 0;
            }

            .confirm-button {
                width: 100%;
                margin: 0;

                @media(min-width: $pricing-modal-collapse-width) {
                    margin: 0 0 16px 24px;
                }

                &:first-child {
                    margin: 0 0 16px;

                    @media(min-width: $pricing-modal-collapse-width) {
                        margin: 0 24px 16px 0;
                    }
                }

                @media(min-width: $pricing-modal-collapse-width) {
                    max-width: 50%;
                }
            }
        }
        .modal-legal {
            font-size: 0.8rem;
            color: #a7a8aa;
        }
    }

    .modal-input {
        color: $white;
        background-color: #202733;
        padding-left: 1rem;
        padding-right: 1rem;
        outline: none;
        border: none;
        height: 48px;
        @include placeholder(1rem, $input-placeholder-color);
    }
}

$pricing-modal-highlight-color: #89a8e1;


.pricing-modal {

    .modal-content {
        padding-bottom: 48px;

        @media(min-width: $pricing-modal-collapse-width) {
            width: 688px;
            padding-bottom: 24px;
        }

        @media(min-width: 768px) {
            width: 768px;
        }

        @media(min-width: 900px) {
            padding-bottom: 0;
        }

        @media(min-width: $screen-lg) {
            width: 920px;
        }
    }

    .modal-header {
        padding-top: 65vw;
        padding-bottom: 0;

        @media(min-width: $pricing-modal-collapse-width) {
            padding-top: 248px;
        }

        @media(min-width: $screen-lg) {
            padding-top: 326px;
        }

        .modal-title {
            font-size: 1.955rem;
            padding-bottom: 0.5rem;
            font-weight: 600;
        }

        .pricingmodal-subtitle--fullwidth {
            width: 100%;

            div {
                margin-bottom: 0.5rem;
            }
        }

        .title-details {
            color: #d8d8d8;
            -ms-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

            .quality-labels-wrap {
                display: flex;
                height: 1.5rem;

                span {
                    border: solid 1px;
                    padding: 0.125rem 0.25rem;
                    font-weight: 700;
                    margin-right: 0.25rem;
                }

                img {
                    height: 1.5rem;
                    margin-right: 0.25rem;
                }
            }
        }
    }

    .modal-body {
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        padding-top: 24px;

        @media (min-width: 900px) {
            padding-top: 32px;
        }

        @media (min-width: 1800px) {
            padding-top: 40px;
        }

        .pricing-plan-wrap {
            @include flexbox();
            @include flex-direction(column);
            text-align: center;
            width: 100%;

            @media(min-width: $pricing-modal-collapse-width) {
                width: 50%;
                margin-bottom: 2.5rem;
            }

            > :nth-child(2) {
                margin-top: -0.5rem;

                @media(min-width: $pricing-modal-collapse-width) {
                    margin-top: 0;
                }
            }

            &.preorder-plans {
                width: 100%;
                @include flex-direction(column);

                @media(min-width: $pricing-modal-collapse-width) {
                    @include flex-direction(row);
                }

                .price-btn-wrapper {
                    @media(min-width: $pricing-modal-collapse-width) {
                        margin-bottom: 0;
                    }
                }
            }

            &:last-child {
                .price-btn-wrapper:last-child {
                    @media(max-width: ($pricing-modal-collapse-width - 1)) {
                        margin-bottom: 0;
                    }
                }
            }

            &.noAvailableRentPlans {
                margin: auto;
            }

            &.noAvailableBuyPlans {
                margin: auto;
            }
        }

        .price-btn-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 32px;

            @media(min-width: $pricing-modal-collapse-width) {
                margin-bottom: 0px;
            }

            .price-scene-align-right {
                @media(min-width: $screen-xs-min - 40) {
                    padding-right: 0;
                }

                @media(min-width: $screen-md) {
                    padding-right: 24px;
                }
            }

            .price-scene-align-left {
                @media(min-width: $screen-xs-min - 40) {
                    padding-left: 0;
                }

                @media(min-width: $screen-md) {
                    padding-left: 14px;
                }
            }


            &.highlighted-btn {
                margin-bottom: 2rem;

                @media(min-width: $pricing-modal-collapse-width) {
                    margin-bottom: 3.25rem;
                    font-size: 1.25rem;
                }

                @media(min-width: $screen-lg) {
                    margin-bottom: 4.2rem;
                }

                &.rent-btn,
                &.preorder-hd {
                    padding-right: 0;

                    @media(min-width: $pricing-modal-collapse-width) {
                        padding-right: 20px;
                    }

                    @media(min-width:$screen-lg) {
                        padding-right: 24px;
                    }
                }

                &.buy-btn,
                &.preorder-sd {
                    padding-left: 0;

                    @media(min-width: $pricing-modal-collapse-width) {
                        padding-left: 20px;
                    }

                    @media(min-width:$screen-lg) {
                        padding-left: 24px;
                    }
                }

                .modal-price-btn {
                    width: 100%;
                    border: 1px solid #FFF;
                    padding: 9px 0;

                    @media(min-width:$pricing-modal-collapse-width) {
                        padding: 12px 0 11px;
                    }

                    @media(min-width: $screen-lg) {
                        padding: 15px 0 14px;
                    }

                    &:hover,
                    &:focus {
                        border: 1px solid $yellow;
                    }

                    &.disabled {
                        color: #75787b;
                        border: 1px solid #404040;
                    }
                }

                .price-scene-info {
                    font-size: 0.8rem;
                    padding-top: 0.5rem;

                    @media(min-width: $pricing-modal-collapse-width) {
                        position: absolute;
                        padding-top: 16px;
                    }
                }
            }

            &.preorder-sd,
            &.preorder-hd {
                .price-scene-info {
                    position: static;
                }
            }

            &.unavailable-price-btn {
                color: #75787b;
            }

            .modal-price-btn {
                border-width: 0px;

                &.disabled {
                    color: #75787b;
                    border: 1px solid #404040;
                }
            }

            .price-scene-info {
                position: static;
                text-align: center;
                font-size: 0.8rem;
                color: $pricing-modal-highlight-color;

                @media(min-width: $pricing-modal-collapse-width) {
                    /*position: absolute;*/
                    top: 100%;
                    left: 0;
                    right: 0;
                }
            }

            .rent-buy-text-aligment {
                font-family: "MarkOT", Helvetica, Arial, sans-serif;
                display: inline-flex;
                font-weight: 400;
                line-height: 28px;
                text-align: center;

                @media(min-width: $screen-xs-min - 40) {
                    font-size: 0.96rem;
                }

                @media(min-width: $screen-md) {
                    font-size: 1.1rem;
                }

                .align-rent-sd-text {
                    @media(min-width: $screen-xs-min - 40) {
                        margin-right: 0;
                    }

                    @media(min-width: $screen-md) {
                        margin-right: 20px;
                    }
                }

                .align-buy-sd-text {
                    @media(min-width: $screen-xs-min - 40) {
                        margin-left: 0;
                    }

                    @media(min-width: $screen-md) {
                        margin-left: 20px;
                    }
                }

                span {
                    @media(min-width: $screen-xs-min - 40) {
                        margin-right: 3px;
                    }

                    @media(min-width: $screen-md) {
                        margin-right: 5px;
                    }
                }
            }

            .pdp-discounted-wrapper {
                white-space: nowrap;

                .pdp-discounted {
                    color: #6C6F75;
                    text-decoration: line-through;
                    padding-left: 5px;
                }
            }

            &.noPaddingRentPlans {
                padding-right: 0 !important;
            }

            &.noPaddingBuyPlans {
                padding-left: 0 !important;
            }
        }



        .pricing-faq {
            font-style: italic;
            color: #b7b7b7;
            text-align: center;

            @media(min-width: $pricing-modal-collapse-width) {
                margin-top: -1rem;
                margin-bottom: 1rem;
                text-align: left;
            }
        }
    }
}
