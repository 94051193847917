﻿.image-promo-area {

    .image-promo-area-container {
        display: block;
        text-align: center;
        @include flex-wrap(wrap);

        @media(min-width: $screen-sm) {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
        }

        .promo-img-wrap {
            margin-top: 32px;
            width: auto;
            height: auto;
            max-width: 100%;
            display: inline-block;

            @media(min-width: $screen-sm){
                max-width: 46.875%;
            }

            @media(min-width: $screen-md){
                max-width: 32.291666666666666666666666666667%;
            }
        }
    }
}
