﻿//Global Breakpoints
$medium-desktop-breakpoint: 1200px;
//Global Variables
$body-top-padding: 4rem;

//Global Colours
$error-color: $red;
$success-color: $green;
$inactive-color: #A7A8AA;

//Spacing variables
$rem-space: 16px;

$xs-space: 5px;
$sm-space: 10px;
$md-space: 15px;
$lg-space: 20px;
$xl-space: 30px;
$half-rem: 0.5*$rem-space;
$rem-space: $rem-space;
$rem-2-space: 2*$rem-space;
$rem-3-space: 2*$rem-space;
$rem-4-space: 4*$rem-space;
$rem-8-space: 8*$rem-space;

$breakpoints: (sm: $screen-sm, md: $screen-md, lg: $screen-lg);

$spacing: ( none: 0, xs: $xs-space, sm: $sm-space, md: $md-space, lg: $lg-space, xl: $xl-space, half-rem: $half-rem, rem: $rem-space, 2-rem: $rem-2-space, 3-rem: $rem-3-space, 4-rem: $rem-4-space, 8-rem: $rem-8-space );

$directions: top left right bottom;

//Opacity Variables

$opacities: (10: 0.1, 20: 0.2, 25: 0.25, 30: 0.3, 40: 0.4, 50: 0.5, 60: 0.6, 70: 0.7, 75: 0.75, 80: 0.8, 90: 0.9);

/*Fonts*/
$base-font-size: 1rem;

$text-weight-light: 200;
$text-weight-reg: 400;
$text-weight-medium: 600;
$text-weight-heavy: 700;

$font-size-large: 1.25 * $base-font-size;
$font-size-small: 0.8 * $base-font-size;

/*Loaders*/

$sm-loader: 50px;
$md-loader: 50px;
$lg-loader: 75px;

$square-loader-length: 50px;
$small-square-loader-length: 25px;
$med-square-loader-length: 50px;
$large-square-loader-length: 150px;
$loader-border-width: 1px;
$loader-color: #F2C40D;
$loader-animation-length: 1s;
$square-loader-transition-distance: 10px;
$loader-vertical-padding: 200px;
$loader-horizontal-padding: 0px;

/*Modals*/
$modal-header-bg: #1a2232;

/*Generic Modals*/
$pricing-modal-collapse-width: $screen-sm;

/*Movie Grid Styles*/
$vert-pad-xs: 16px;
$top-pad-sm: 32.9506%;
$vert-pad-md: 17.805%;
$top-pad-lg: 26.395%;

$bot-pad-sm: 20.64%;
$bot-pad-lg: 16.632885%;

$img-aspect-ratio-xs: 41.86%;
$img-aspect-ratio-sm: 66.82659356%;
$img-aspect-ratio-md: 35.61%;
$img-aspect-ratio-lg: 52.79%;

$top-pad-hvr-sm: $top-pad-sm - percentage(13.79312% / $img-aspect-ratio-sm);
$vert-pad-hvr-md: 0;
$top-pad-hvr-lg: $top-pad-lg - percentage(8.7805% / $img-aspect-ratio-lg);

/*Input / Form Styles*/
$checkbox-btn-inactive-color: #6e96db;
$checkbox-btn-active-color: #00358e;
$checkbox-btn-hvr-color: $yellow;
$checkbox-btn-active-hvr-bg-color: $yellow;
$checkbox-btn-active-hvr-color: $black;

$input-placeholder-color: $gray-light;

/*Navbar Styles*/
$navbar-full-color: #05327D;
$navbar-start-color: rgba(5, 50, 125, 0.701613);
$navbar-medium-color: rgba(5, 50, 125, 0.453985);
$navbar-end-color: rgba(5, 50, 125, 0);
$navbar-scrolled-start-color: rgba(5, 50, 125, 0.99);
$navbar-scrolled-end-color: rgba(5, 50, 125, 0.32);
$navbar-dropdown-link-color: $navbar-default-link-color;
$navbar-dropdown-link-hover-color: $navbar-default-link-hover-color;
$navbar-dropdown-active-color: $navbar-default-link-active-color;
$navbar-dropdown-link-hover-bg: $navbar-default-link-hover-bg;
$collapsed-connect-menu-bg: #00358e;

$main-nav-right-padding: 2.10vw;

$mobile-nav-pad-top: 1.25rem;

$nav-item-top-pad-xs: 2rem;
$nav-item-top-pad-sm: 2rem;
$nav-item-vertical-pad-md: 1rem;

$nav-item-vertical-margin-xs: 0;
$nav-item-vertical-margin-sm: 0;
$nav-item-vertical-margin-md: 0;
$nav-item-vertical-margin-lg: 0;

$subnav-item-vertical-pad-xs: 0;
$subnav-item-vertical-margin-xs: 0;
$subnav-item-vertical-pad-sm: 0;
$subnav-item-vertical-margin-sm: 0;

$mobile-nav-gutter-vw: 6.25vw;
$tablet-nav-gutter-vw: 3.125vw;
$mobile-dropdown-subnav-gutter: ($container-gutter-mobile/0.96);
$tablet-dropdown-subnav-gutter: ($container-gutter-mobile/0.5);
$collapse-menu-width-sm: 50vw;
$collapse-menu-width-xs: 93.75vw;

$subnav-heading-height-xs-sm: 8rem;

$navbar-scene-hidden-breakpoint: 1400px;
$navbar-search-collapse-breakpoint: 1350px;

/*Connect Styles*/
$connect-dropdown-bg: linear-gradient(139deg, rgba(0, 53, 142, 0.95), rgba(86, 0, 117, 0.95));

/*Search Styles*/
$sayt-font-weight: 400;
$sayt-font-family: $font-family-base;

$sayt-header-color: $white;
$sayt-widget-bg-color: #1b3171;
$sayt-bg-color: transparent;
$sayt-color: $white;
$sayt-highlight-color: $yellow;

$sayt-exterior-border: 0px solid transparent;

$sayt-border-color: rgba(0,0,0,0);
$sayt-border: 0px solid $sayt-border-color;

$sayt-highlighted-border-color: $sayt-highlight-color;
$sayt-highlighted-bg-color: transparent;
$sayt-highlighted-border: 0px solid rgba(0,0,0,0);

/*Footer Styles*/
$footer-bg-color: #212833;
$footer-unimportant-txt-color: #FFFFFF;
$footer-middle-padding: 64px;
$footer-bottom-padding: 64px;
/*Mobile Menu Styles*/
$mobile-navbar-start-color: rgba(0, 53, 142, 1);
$mobile-navbar-end-color: rgba(86, 0, 117, 1);

/*Full width promo styles*/
$indicator-height-xs: 6px;
$indicator-height-sm: 8px;
$indicator-height-md: 10px;
$indicator-height-lg: 12px;

$indicator-horizontal-padding-xs: 4px;
$indicator-horizontal-padding-sm: 6px;
$indicator-horizontal-padding-md: 8px;
$indicator-horizontal-padding-lg: 10px;

$indicator-vertical-padding-xs: $indicator-horizontal-padding-xs;
$indicator-vertical-padding-sm: $indicator-horizontal-padding-sm;
$indicator-vertical-padding-md: 11px;
$indicator-vertical-padding-lg: 14px;

/*Movie Rail/ Carousel Styles*/
$slides-text-inactive-color: $inactive-color;

/*Overlay Styles*/
$french-version-badge-color: #89a8e1;
$french-version-border-color: #202633;

/*Error Styles*/
$redeem-error: #ff2f49;
$redeem-success: #3c763d;

/*---------------------PAGE SPECIFIC VARIABLES--------------------*/

/*Search Results Page and Genres Variables*/
$search-inactive-filter-color: #6e96db;
$search-active-filter-color: #00358e;
$search-filter-dropdown-background-color: #00358e;
$search-filter-dropdown-top-color: #1b3171;

/*PDP variables*/
$pdp-highlight-color: #89a8e1 ;
$pdp-grey-border: $inactive-color;
$pdp-deal-font: inherit;
$pdp-overlay-background: #1b3171;
$input-background: #1a2232;
$pdp-light-grey-font: #75787b;

$pdp-discount-mobile-overlay-breakpoint: 360px;
$pdp-modal-tablet-overlay-breakpoint: 750px;
$pdp-desktop-button-breakpoint: 1350px;
$pdp-mobile-button-breakpoint: 680px;

/*PVOD variables*/
$pvod-background-color: #22072e;
$pvod-highlight-color: #9760c8;

/*CHECKOUT VARIABLES*/
$checkout-order-summary-anti-gutter: -5.172413793103448275862068965517%;
$checkout-modal-header: $modal-header-bg;
$checkout-modal-content: $modal-content-bg;
$checkout-default-font: $gray-light;
$checkout-promo-area-background: #202733;
$checkout-summary-area-background: rgba(27, 49, 113, 0.3);
$checkout-desktop-breakpoint: 1200px;


/*SUPERTICKET VARIABLES*/
//$superticket-modal-header-bg-color: $modal-header-bg;
$superticket-modal-bg-color: $modal-content-bg;

/*MY LIBRARY VARIABLES*/
$movie-hover-scale-amount: 1.17561;
$movie-text-wrap-breakpoint: 1300px;
$movie-text-adjusted-font-size: 0.85rem;
$movie-text-font-size: 1.25rem;

//------------ LEGACY VARIABLES -------------

$legacy-menu-bg: #23326F;
$legacy-text-color: #000;
$legacy-link-color: #2d6892;
$legacy-link-hvr-color: #2c3e4c;
$legacy-link-active-color: #2c3e4c;

//Primary-button-styles
$legacy-btn-primary-padding: 8px 12px 7px;
$legacy-btn-primary-text-size: 1em;
$legacy-btn-primary-text-shadow: rgba(0, 0, 0, 0.901961) 0px -1px 0px;
$legacy-btn-primary-gradiant-start-color: #447ea3;
$legacy-btn-primary-gradiant-end-color: #2a4e6b;
$legacy-btn-primary-fallback-color: #366485;
$legacy-btn-primary-text-color: #FFF;
$legacy-btn-primary-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
$legacy-btn-primary-border: 1px solid #345c7c;
$legacy-btn-primary-border-radius: 5px;
$legacy-btn-primary-hvr-gradiant-start-color: #285e84;
$legacy-btn-primary-hvr-gradiant-end-color: #193b53;
$legacy-btn-primary-hvr-fallback-color: #1f4a69;
$legacy-btn-primary-text-hvr-color: #FFF;
$legacy-btn-primary-hvr-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
$legacy-btn-primary-hvr-border: 1px solid #183a52;
$legacy-btn-primary-disabled-gradiant-start-color: #447EA3;
$legacy-btn-primary-disabled-gradiant-end-color: #2A4E6B;
$legacy-btn-primary-disabled-opacity: 0.5;

//---------- END OF LEGACY VARIABLES ------------------
