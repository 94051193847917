﻿
.footer-container {
    background: $footer-bg-color;
    width: 100%;
    height: 100%;
    font-family: MarkOT;
    font-size: 0.8rem;
    color: #ffffff;
    padding-top: 24px;
    padding-bottom: $footer-bottom-padding;

    @media(min-width: $screen-sm) {
        padding-top: 48px;
        padding-bottom: $footer-bottom-padding;
    }

    .footer-logo-image {
        img {
            margin-bottom: 48px;

            @media(min-width: $screen-md) {
                margin-bottom: 0;
            }
        }
    }

    .footer-links {
        text-align: left;
        margin-bottom: 32px;

        @media(min-width: $screen-sm) {
            margin-bottom: 0px;
        }

        .link-array {


            .actual-links {
                margin-bottom: 32px;
                font-weight: 400;

                @media(min-width: $screen-md) {
                    margin-bottom: 1rem;
                }

                @media(min-width: $screen-lg){
                    margin-bottom: 32px;
                }
            }
        }
    }

    .footer-email-btn-wrapper {
        margin-top: 18px;

        @media(min-width: $screen-md) {
            margin-top: 0;
            margin-left:0;
            width: 33.33334%;
        }

        @media(min-width: 1400px){
            width: 16.66667%;
            margin-left: 16.66667%;
        }
    }
    /*.footer-email-area-container {
        float: left;

        @media(min-width: $screen-sm) {
            float: right;
        }


        .footer-email-area {
            float: left;

            @media(min-width: $screen-sm) {
                float: right;
            }

            .footer-email-entry {
                margin-top: 7px;

                .footer-email-box-area {
                    @include flexbox();
                }

                .footer-email-placeholder {
                    border: solid 2px #ffffff;
                    width: calc(100% - 5.25rem);
                    color: $footer-unimportant-txt-color;
                    background: $footer-bg-color;
                    vertical-align: bottom;

                    @media(min-width: 1000px) {
                        width: 14rem;
                    }
                }

                .footer-email-join-button {
                    padding: 7px 1.25rem;
                    line-height: 1rem;
                    font-size: 1rem;
                    border-width: 2px;
                }
            }
        }
    }*/
    .footer-social-media-section {
        @include justify-content(flex-start);
        margin: $footer-middle-padding 0 12px;

        @media(min-width: $screen-sm) {
            margin-bottom: 0;
            @include justify-content(space-between);
        }

        @media(min-width: $screen-md) {
            margin-top: 0;
            @include justify-content(flex-start);
            @include align-items(flex-end);
        }

        .footer-follow-us-text {
            margin-right: 20px;
        }

        .footer-social-icons {
            .footer-social-link {
                margin-right: 37px;

                i {
                    font-size: 1.5rem;
                }

                &:last-child {
                    i {
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .footer-text-icon-section {
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items(center);
        @include flex-direction(column);

        @media(min-width: $screen-sm) {
            @include flex-direction(row);
            margin-top: 48px;
        }

        @media(min-width: $screen-md) {
            @include align-items(flex-end);
            margin-top: 0;
        }

        .bot-footer-link {
            display: inline-block;
            letter-spacing: 1.1px;
            font-size: 0.64rem;
            width: 100%;
            float: none;
            margin: 0.75rem 0;

            @media(min-width: $screen-sm) {
                margin: 0 0 0 32px;
                float: right;
                width: auto;
            }

            &:first-child {
                margin-left: 0;
            }
            /*&:last-child {
                margin-top: 0;
            }*/
        }
    }

    .footer-row-two {
        .accesbility-logo {
            height: 1.5rem;
        }

        @media(min-width: $screen-md) {
            width: 100%;
            float: left;
            clear: both;
            margin-top: $footer-middle-padding;
        }
    }
    //spacer area used on pages with no footer nav
    .footer-spacer-area {
        height: 70px;

        @media(min-width: $screen-sm) {
            height: 118px;
        }

        @media(min-width: $screen-md) {
            height: 150px;
        }
    }

    .footer-row-three {
        margin-top: 4px;
        text-align: left;
        font-size: 0.511875rem;
        letter-spacing: 0.8px;
        color: $footer-unimportant-txt-color;
        float: left;
        width: 100%;

        @media(min-width: $screen-sm) {
            text-align: right;
            margin-top: 16px;
        }

        p {
            line-height: 1em;
            margin-bottom: 0.5em;
        }
    }
    //Styles For No Nav in footer
    .no-nav-accessability {
        .accesbility-logo {
            width: 64px;
            margin-bottom: 32px;
            float: left;

            @media(min-width: $screen-sm) {
                margin-bottom: 42px;
                float: right;
            }

            @media(min-width: $screen-md) {
                margin-bottom: 15px;
            }
        }
    }
}
