﻿#promo-banner-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: auto;
    z-index: 1050;
    display: none;
    border-bottom: #2253a8 solid 1px;

    .promo-banner-body {
        min-height: 4rem;
        display: flex;
        background: #002C6B; //linear-gradient(93deg, rgba(3,25,72,1), rgba(44,0,60,1));
        border-bottom: #1a2232 solid 1px;

        &:before,
        &:after {
            display: none;
        }
        /*&.subscription {

            @media(min-width: $screen-lg - 359) {
                min-height: 60px;
            }

            @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
                min-height: 48px;
            }

            @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
                min-height: 47px;
            }

            @media(max-width: $screen-sm + 167) {
                min-height: 65px;
            }
        }*/
    }

    .promo-html-wrapper {
        padding: 0.5rem;
        font-size: 12px;
        /*@media(min-width: 493px) {
            font-size: 11px;
        }

        @media(max-width: 492px) {
            font-size: 8px;
        }*/
    }

    .promo-banner-close {
        color: #fff;
        font-size: 1rem;

        @media(min-width: $screen-sm) {
            font-size: 1.5rem;
        }

        @media(min-width: $screen-md) {
            font-size: 1.6rem;
        }
    }

    &.banner-in {
        display: block;

        + #Wrapper {
            padding-top: 8rem;

            .primary-nav {
                top: 4rem;

                &.subscription {
                    @media(min-width: $screen-lg - 359) {
                        top: 60px;
                    }

                    @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
                        top: 48px;
                    }

                    @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
                        top: 47px;
                    }

                    @media(max-width: $screen-sm + 167) {
                        top: 65px;
                    }
                }
            }

            &.unfixed-nav {
                padding-top: 0;
                margin-top: 4rem;

                &.subscription {
                    @media(min-width: $screen-lg - 359) {
                        margin-top: 60px;
                    }

                    @media(min-width: $screen-md + 124) and (max-width: $screen-lg - 360) {
                        margin-top: 48px;
                    }

                    @media(min-width: $screen-sm + 168) and (max-width: $screen-md + 123) {
                        margin-top: 47px;
                    }

                    @media(max-width: $screen-sm + 167) {
                        margin-top: 65px;
                    }
                }

                .primary-nav {
                    top: 0rem;
                }
            }
        }
    }

    &.promo-error {
        background: linear-gradient(92deg, rgba(200, 16, 43, 0.8), rgba(117, 0, 72, 0.8));
    }
}
