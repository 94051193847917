﻿.movie-slider {
    + .movie-slider {
        margin-top: 30px;
    }
}

.movie-card-section-wrapper {
    position: relative;
    z-index: 1;

    &.movie-playlist-wrapper {
        padding-bottom: 0;
    }

    .card-section-title {
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-left: $column-gutter-mobile;
        padding-right: $column-gutter-mobile;
        height: auto;

        @media(min-width: $gutter-breakpoint) {
            padding-left: $column-gutter-desktop;
            padding-right: $column-gutter-desktop;
        }

        .card-section-title-text {
            float: left;

            .card-section-title-name {
                font-size: 1rem;
                margin-top:0px;
                margin-bottom:0px;

                @media(min-width: $screen-md) {
                    font-size: 1.25rem;
                }

                @media(min-width: $screen-lg) {
                    font-size: 1.25rem;
                }
            }

            .store-icon-chevron {
                font-size: 1.25rem;
                font-weight: 700;
                padding-bottom: 0.25rem;

                @media(min-width: $screen-md) {
                    font-size: 1.5rem;
                }

                @media(min-width: $screen-lg) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .movie-card-slider-wrap {
        box-sizing: content-box;
        opacity: 0;
        margin: 0;

        @media(min-width: $screen-sm) {
            margin: 2.7592953821656050955414012738854% 0;
        }

        @media(min-width: $screen-md) {
            margin: 1.723175% 0;
        }

        @media(min-width: $screen-lg) {
            margin: 1.38826733% 0;
        }

        .movie-card-wrap {
            float: left;
            width: 33.33333%;
            display: none;

            @media(min-width: $screen-sm) {
                width: 25%;
            }

            @media(min-width: $screen-md) {
                width: 16.66666%;
            }

            @media(min-width: $screen-lg) {
                width: 12.5%;
            }

            &:first-child {
                display: block;
            }

            &.slick-slide.slick-active {
                opacity: 1;
            }

            &.slick-slide {
                opacity: 0.3;
            }
        }

        &.slick-initialized {
            opacity: 1;

            .movie-card-wrap {
                display: block;
            }

            &:before {
                display: none;
            }

            .see-all-card {
                position: relative;

                .see-all-card-content {
                    background: $btn-default-bg;
                    border: 1px solid $btn-default-color;
                    display: block;
                    width: 90.16920922133475260%;
                    position: relative;

                    &:hover {
                        background: $btn-default-color;
                        border: 1px solid $btn-default-color;

                        .see-all-card-text {
                            color: $main-dark;
                        }
                    }

                    .see-all-card-text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $btn-default-color;
                        font-size: 1rem;
                        text-transform: uppercase;
                        text-align: center;
                        white-space: nowrap;

                        @media(min-width: $screen-sm) {
                            font-size: 1.3rem;
                        }

                        @media(min-width: $screen-md) {
                            font-size: 1.5625rem;
                        }
                    }
                }

                .see-all-card-content::before {
                    display: block;
                    content: "";
                    width: 100%;
                    padding-top: 149.6410256410256%;
                }
            }
        }

        &:hover {
            .playlist-indicator {
               display: block !important;
            }
        }

        .playlist-indicator {
            display: none !important;
        }

        .slick-list {
            overflow: hidden;

            @media(min-width: $screen-sm) {
                overflow: visible;
            }
        }
    }
}
