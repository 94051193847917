﻿.activate-body {
    font-family: "MarkOT", Helvetica, Arial, sans-serif;
    font-style: normal;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #0a0b0d;
    background-attachment: fixed;
    background-image: url(https://storemedia.cineplex.com/Attachments/NewItems/Generic_1920x1080_20171123232139_0.jpg);


    .activate-logo {
        width: 16.666666666666668%;
        position: relative;
        z-index: 994;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        float: left;
        height: 4rem;
        img {
            vertical-align: middle;
            border: 0;
        }
    }

    .activate-page {
        width: 100%;

        .activate-main-nav {
            width: 100%;
            font-size: 20px;
            line-height: 20px;
            height: 4rem;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            align-items: center;
        }

        .login-btn {
            width: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;

            @media(min-width: $screen-xs-min - 40) {
                margin-top: 32px;
            }

            @media(min-width: $screen-md-min) {
                margin-top: 40px;
            }

            @media(min-width: $screen-lg-min) {
                margin-top: 48px;
                height: 60px;
            }
        }

        .activate-page-body {
            @media(min-width: $screen-xs-min - 40) {
                text-align: left;
                margin-top: 106px;
            }

            @media(min-width: $screen-md-min) {
                margin-top: 113px;
            }

            @media(min-width: $screen-lg-min) {
                text-align: center;
                margin-top: 116px;
            }

            .element-width {
                margin: auto;

                @media(min-width: $screen-xs-min) {
                    width: 278px;
                }

                @media(min-width: $screen-md-min) {
                    width: 444px;
                }

                @media(min-width: $screen-lg-min) {
                    width: 472px;
                }

                .go-to-cineplex-btn {
                    width: 100%;
                    font-weight: 400;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    margin-top: 48px;

                    @media(min-width: $screen-md-min) {
                        font-size: 12px;
                        line-height: 16px;
                    }

                    @media(min-width: $screen-lg-min) {
                        font-size: 25px;
                        line-height: 16px;
                    }
                }
                .code-validation-box {
                    display: block;
                    width: 100%;
                    margin-top: 45px;

                    .qrc-validate-btn {
                        display: block;
                        margin: 0px auto;
                        width: 100%;
                    }

                    .code-error-message {
                        font-family: "MarkOT", Helvetica, Arial, sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #EF3434;
                        width: 100%;
                        text-align: left;
                        margin-bottom: 12px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.408px;
                        height: 30px;
                    }
                }
            }

            .limit-reached {
                margin: auto;

                @media(min-width: $screen-xs-min - 40) {
                    width: 276px;
                }

                @media(min-width: $screen-md-min) {
                    width: 444px;
                }

                @media(min-width: $screen-lg-min) {
                    width: 724px;
                }

                .device-list-box {
                    margin-top: 100px;
                    width: 100%;

                    .device-item {
                        text-align: left;
                        padding-bottom: 20px;

                        .device-name {
                            display: block;
                            font-weight: 400;
                            letter-spacing: -0.408px;
                            color: #FFFFFF;

                            @media(min-width: $screen-xs-min - 40) {
                                font-size: 16px;
                                line-height: 26px;
                            }

                            @media(min-width: $screen-md-min) {
                                font-size: 20px;
                                line-height: 28px;
                            }

                            @media(min-width: $screen-lg-min) {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }

                        .device-date {
                            float: left;
                            font-weight: 400;
                            letter-spacing: -0.408px;
                            color: #A7A8AA;
                            opacity: 0.8;

                            @media(min-width: $screen-xs-min - 40) {
                                font-size: 12px;
                                line-height: 16px;
                            }

                            @media(min-width: $screen-md-min) {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            @media(min-width: $screen-lg-min) {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }

                        .dremove-btn {
                            float: right;
                            font-weight: 500;
                            text-align: right;
                            letter-spacing: -0.408px;
                            color: #EEEEEE;

                            @media(min-width: $screen-xs-min - 40) {
                                font-size: 12px;
                                line-height: 16px;
                            }

                            @media(min-width: $screen-md-min) {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            @media(min-width: $screen-lg-min) {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }

                        .device-line-brake {
                            width: 100%;
                            border: 1px solid #8D8D99;
                            flex: none;
                            order: 1;
                            flex-grow: 0;
                        }
                    }
                }
            }

            .activate-title {
                font-weight: 500;
                margin-top: 0;
                margin-bottom: 0;

                @media(min-width: $screen-xs-min - 40) {
                    font-size: 20px;
                    line-height: 28px;
                }

                @media(min-width: $screen-md-min) {
                    font-size: 22px;
                    line-height: 30px;
                }

                @media(min-width: $screen-lg-min) {
                    font-size: 32px;
                    line-height: 44px;
                }
            }

            .device-message {
                font-weight: 400;
                letter-spacing: -0.408px;
                padding-top: 8px;

                @media(min-width: $screen-xs-min - 40) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media(min-width: $screen-md-min) {
                    font-size: 20px;
                    line-height: 28px;
                }

                @media(min-width: $screen-lg-min) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }

            .device-limit-message {
                font-weight: 400;
                letter-spacing: -0.408px;
                padding-top: 8px;
                text-align: left !important;

                @media(min-width: $screen-xs-min - 40) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media(min-width: $screen-md-min) {
                    font-size: 20px;
                    line-height: 28px;
                }

                @media(min-width: $screen-lg-min) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }

            .code-textbox {
                width: 100%;
                display: block;
                margin: 0px auto;
                text-align: center;
                background: rgba(32, 39, 51, 0.8);
                border: 1px solid rgba(255, 255, 255, 0.4);
                font-weight: 400;
                font-size: 32px;
                line-height: 44px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 6.59px;

                &.error-message {
                    border: 1px solid #FF2F49;
                }

                @media(min-width: $screen-xs-min - 40) {
                    height: 54px;
                }

                @media(min-width: $screen-lg-min) {
                    height: 56px;
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

#spinner-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0A0B0D;
    opacity: 0.8;
    z-index: 99998;
    margin: auto;
    display: none;
}

#lwdAjaxSpinner {
    position: fixed;
    border: none !important;
    display: none;
    z-index: 99999;
    animation: spin 2s linear infinite;

    @media(min-width: $screen-xs-min - 40) {
        width: 41.04px;
        height: 41.04px;
        left: calc(50% - 41.04px/2 - 0.11px);
        top: calc(50% - 41.04px/2);
    }

    @media(min-width: $screen-md-min) {
        width: 41.04px;
        height: 41.04px;
        left: calc(50% - 41.04px/2 - 0.48px);
        top: calc(50% - 41.04px/2 + 0.28px);
    }

    @media(min-width: $screen-lg-min) {
        width: 41.02px;
        height: 41.02px;
        left: calc(50% - 41.02px/2 - 0.49px);
        top: calc(50% - 41.02px/2 + 0.49px);
    }
}
