﻿.pdp-movie-card-wrap {
    @include flexbox();
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    /*margin-left: -8px;

    @media(min-width: $screen-sm) {
        margin-left: -15px;
    }*/
    .movie-card-wrap {
        display: inline-block;
        width: percentage(1/3);
        margin-top: 0;

        @media(min-width: $screen-sm) {
            width: percentage(1/4);
            margin: 2.7592953821656050955414012738854% 0;
        }

        @media(min-width: $screen-md) {
            margin: 1.723175% 0;
        }

        @media(min-width: $screen-lg) {
            width: percentage(1/5);
            margin: 1.38826733% 0;
        }
    }
}

#pdp-background-fade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    z-index: 0;
    opacity: 0;
    transition: opacity linear 0.5s;

    &.fade-in {
        opacity: 0.7;
    }
}

#pvod-background-fade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: $pvod-background-color;
    z-index: 0;
    opacity: 0.3;
    transition: opacity linear 0.5s;

    &.fade-in {
        opacity: 0.9;
    }
}

#pvod-img {
    width: 211px;
    height: 21px;
    margin-left: 0.5vw;

    @media(min-width: $screen-xs + 1) and (max-width:$screen-md - 1) {
        margin-left: 1.55vw;
        width: 152px;
        height: 16px;
    }

    @media(max-width: $screen-xs) {
        margin-left: 1.55vw;
        width: 86px;
        height: 8px;
    }
}

.pdp-wrapper {
    position: relative;
    margin-top: 8px;

    @media(min-width: $screen-sm) {
        margin-top: 34px;
    }

    .pdp-closed-captions {
        font-weight: bold;
        border: solid 1px $pdp-grey-border;
        padding: 1px 5px;
    }

    .pdp-subheading {
        font-size: 1rem;
        text-transform: none;
        display: block;
        margin-bottom: 0.5rem;
        margin-top: 0;

        @media(min-width: $screen-md) {
            font-size: 1.25rem
        }
    }

    .pdp-total-container {
        visibility: hidden;
        position: absolute;
        left: -1000000000px;
        width: 100%;

        &.loaded {
            visibility: visible;
            position: static;
            left: 0;
        }
    }

    .pdp-watchlist-btn {
        display: block;
        font-size: 32px;
        /*height: 32px;*/
        @media(min-width: $screen-lg) {
            font-size: 48px;
            /*height: 48px;*/
        }

        &.watchlisted {
            .remove-watchlist {
                display: block;
            }

            .add-watchlist {
                display: none;
            }
        }

        &.with-expiry {

            @media(max-width: ($screen-sm - 1)) {
                margin-bottom: 32px;
            }

            @media(min-width: $screen-md) and (max-width: $pdp-desktop-button-breakpoint) {
                margin-bottom: 32px;
            }
        }

        .remove-watchlist {
            display: none;
        }
    }

    .wl-loader-wrap {
        height: 32px;
        width: 32px;

        @media(min-width: $screen-lg) {
            height: 48px;
            width: 48px;
        }

        .wl-loader {
            height: 32px;
            width: 32px;

            @media(min-width: $screen-lg) {
                height: 48px;
                width: 48px;
            }
        }
    }

    .pdp-information {
        margin-top: 32.5vw;

        @media(min-width: $screen-sm) {
            margin-top: calc(56.38vw - 4rem);
        }

        @media(min-width: $screen-md) {
            margin-top: calc(26.96vw - 4rem);
        }

        @media(min-width: $screen-lg) {
            margin-top: calc(23.96vw - 4rem);
        }

        &.pdp-generic-mobile {
            @media(max-width: ($screen-sm - 1)) {
                margin-top: 0;
            }

            @media(min-width: $screen-sm) and (max-width: $screen-md - 1) {
                margin-top: 11.671631226590379567640900109122%;
            }

            .pdp-generic-image {
                display: block;

                @media(min-width: $screen-md) {
                    display: none;
                }
            }
        }

        &.pdp-generic {

            @media(min-width: $screen-md) {
                margin-top: 11.671631226590379567640900109122%;
            }
        }

        .pdp-generic-image {
            width: 46.61%;
            display: none;
            padding: 0 0 15%;

            @media(min-width: $screen-sm) {
                width: 30.859375%;
                padding-bottom: 5.75%;
            }
        }
    }

    .pdp-left-side-image {

        .pdp-movie-image-wrap {
            .movie-img {
                max-width: 93%;
            }
        }
    }

    .pdp-generic-image {
        display: none;
    }

    .pdp-right-side-information {
        font-family: MarkOT;
        font-size: 1rem;

        .pdp-movie-information {
            font-size: 0.8rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            padding-bottom: 0.25rem;
            margin-top: 8px;

            @media(min-width: $screen-md) {
                font-size: 1rem;
            }

            &.metacritic--margin > div,
            &.metacritic--margin > img {
                margin-top: 0.5rem;

                @media(min-width: $screen-md) {
                    margin-top: 0rem;
                }
            }


            .pdp-title-wrap {
                font-size: 1.5625rem;
                font-weight: bold;

                margin-top:0px;
                margin-bottom:0px;

                @media(min-width: $screen-sm) {
                    font-size: 3.052rem;
                }
            }

            .pdp-subtitle-info {
                flex-basis: 100%;
                height: 22px;
                display: flex;
                vertical-align: middle;
                flex: 1 1 calc(100% - 7rem);
                width: calc(100% - 7rem);
                white-space: nowrap;

                @media(min-width: $screen-md) {
                    height: 29px;
                }

                .pdp-subtitle-metalogo {
                    height: 42px;
                    display: flex;
                    align-items: center;

                    @media(min-width: $screen-md) {
                        height: 29px;
                    }
                }
            }

            .title-quality-padding {
                margin-right: 8px;

                @media(min-width: $screen-sm) {
                    margin-right: 16px;
                }
            }

            .movie-quality-wrapper {
                border: 1px solid #a7a8aa;
                font-weight: 700;
                margin-right: .25rem;
                width: 2.625rem;
                height: 22px;
                vertical-align: middle;
                display: inline-block;
                margin-right: 1rem;

                @media(min-width: $pdp-mobile-button-breakpoint) {
                    display: inline-block;
                }

                @media(min-width: $screen-md) {
                    height: 29px;
                }

                @media(min-width: $screen-lg) {
                    margin-bottom: 0;
                }

                .movie-quality {
                    align-items: center;
                    justify-content: center;
                    height: 22px;

                    @media(min-width: $screen-xs) {
                        line-height: 23px;
                    }

                    @media(min-width: $screen-md) {
                        height: 29px;
                        line-height: 29px;
                    }

                    @media(min-width: $screen-lg) {
                        line-height: 30px;
                    }
                }
            }

            .pdp-quality-label {
                height: 22px;
                margin-right: 0.5rem;

                @media(min-width: $pdp-mobile-button-breakpoint) {
                    display: inline-block;
                }

                @media(min-width: $screen-md) {
                    height: 29px;
                }

                @media(min-width: $screen-lg) {
                    margin-bottom: 0;
                }
            }

            .pdp-movie-release-year {
                color: $pdp-grey-border;
            }

            .pdp-rating {
                font-weight: bold;
                border: solid 1px $pdp-grey-border;
                padding: 0 8px;
                line-height: 22px;
                height: 22px;
                margin-right: calc(1rem - 6px);

                @media(min-width: $screen-md) {
                    height: 29px;
                    line-height: 29px;
                }
            }

            .pdp-genres, a {
                color: $pdp-grey-border;
                padding: 5px 0;

                @media(min-width: $screen-sm) {
                    padding: 0;
                }
            }
        }

        .pdp-buttons {
            margin-bottom: .75rem;
            margin-top: .75rem;

            @media(min-width: $screen-md) {
                margin-bottom: 1.5rem;
                margin-top: 1.5rem;
            }

            .pdp-button-wrap {
                @include flexbox();
                @include flex-wrap(wrap);
                @include align-items(center);

                @media(min-width: $pdp-mobile-button-breakpoint) {
                    @include justify-content(flex-start);
                }

                .pdp-trailer-btn,
                .pdp-owned-controls {
                    cursor: pointer;
                    font-weight: normal;
                    margin-right: 32px;
                    margin-bottom: .75rem;
                    margin-top: .75rem;

                    @media(min-width: $screen-md) {
                        margin-bottom: 1.5rem;
                        margin-top: 1.5rem;
                    }

                    &.with-expiry {

                        .pdp-play-btn-expiry {
                            .pdp-play-btn {
                                margin-right: 5px;
                            }
                        }
                    }

                    .expiry-text {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        font-size: 0.675rem;
                        color: $yellow;
                        padding-top: 5px;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            font-size: 0.8rem;
                        }

                        span {
                            white-space: nowrap;
                        }
                    }

                    .play-btn-link, .store-icon-play, .store-icon-mylibrary {
                        color: $yellow;
                        @include flexbox();
                        @include align-items(center);
                        @include flex-direction(row);
                    }

                    .store-icon-play, .store-icon-mylibrary {
                        width: 3rem;
                        height: 3rem;
                        font-size: 3rem;
                        position: relative;
                        left: -0.25rem;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            width: 4rem;
                            height: 4rem;
                            font-size: 4rem;
                        }

                        @media(min-width: $screen-md) {
                            width: 6rem;
                            height: 6rem;
                            font-size: 6rem;
                        }
                    }

                    .play-btn-link {
                        font-size: 0.8rem;

                        @media(min-width: $screen-md) {
                            font-size: 1rem;
                        }
                    }

                    &:hover {
                        text-shadow: 0px 1px 0px $yellow;

                        span:hover {
                            text-shadow: none;
                            font-weight: bold;
                        }
                    }
                }

                .pdp-trailer-btn-purchased {
                    margin-right: 32px;
                    margin-bottom: .75rem;
                    margin-top: .75rem;

                    @media(min-width: $screen-md) {
                        margin-bottom: 1.5rem;
                        margin-top: 1.5rem;
                    }
                    /*&.with-expiry {
                        margin-bottom: 2rem;
                    }*/
                    i {
                        font-size: 2rem;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            font-size: 3rem;
                        }

                        @media(min-width: $screen-md) {
                            font-size: 4rem;
                        }

                        @media(min-width: $screen-lg) {
                            font-size: 4.5rem;
                        }
                    }

                    a {
                        font-size: 0.8rem;
                        line-height: 1;

                        @media(min-width: $screen-md) {
                            font-size: 1rem;
                            line-height: 1.25;
                        }
                    }
                }

                .pdp-deal-info {
                    position: static;
                    top: 100%;
                    left: 0;
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(flex-start);
                    width: 100%;
                    padding-top: 16px;
                    text-align: center;
                    font-size: 0.8rem;
                    color: $pdp-highlight-color;
                    white-space: nowrap;

                    @media(min-width: $pdp-mobile-button-breakpoint) {
                        position: absolute;
                        max-width: 128px;
                        text-align: left;
                    }

                    @media(min-width: 620px) {
                        max-width: 100%;
                    }

                    @media(min-width: $pdp-desktop-button-breakpoint) {
                        font-size: 1rem;
                    }

                    .pdp-scene-info {
                        font-size: 1.75rem;
                        margin-right: 0.25rem;
                        display: none;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            display: inline-block;
                        }

                        @media(min-width: $pdp-desktop-button-breakpoint) {
                            font-size: 2rem;
                        }
                    }
                }

                .pricing-plan {
                    .pdp-rent-wrap {
                        position: relative;

                        .pdp-buy-rent-btn {
                            text-align: center;
                            padding: 0 0 3px;
                            width: 5.625rem;

                            @media(min-width: $pdp-mobile-button-breakpoint) {
                                width: 10.75rem;
                                padding: 7px;
                            }

                            @media(min-width: $screen-md) {
                                width: 12rem;
                                padding: 12px 22px;
                            }

                            @media(min-width: $pdp-desktop-button-breakpoint) {
                                width: 13.125rem;
                            }

                            @media(min-width: $screen-lg) {
                                width: 13.1rem;
                            }

                            &.super-ticket-hd-upgrade-btn,
                            &.pdp-preordered-btn {
                                width: auto;
                                padding-left: 5px;
                                padding-right: 5px;
                                margin-top: -16px;

                                @media(min-width: $pdp-mobile-button-breakpoint) {
                                    width: 10.75rem;
                                    padding: 7px;
                                    margin-top: 0;
                                }

                                @media(min-width: $screen-sm) {
                                    width: 12.75rem;
                                    padding: 12px 22px;
                                }

                                @media(min-width: $screen-md) {
                                    width: 16rem;
                                    padding: 12px 22px;
                                }
                            }

                            &.pdp-preorder-btn {
                                line-height: 0.75;

                                @media(min-width: $pdp-mobile-button-breakpoint) {
                                    line-height: 1.5625em;
                                }


                                .movie-price {
                                    margin-top: 0;

                                    @media(min-width: $pdp-mobile-button-breakpoint) {
                                        margin-top: -10px;
                                    }
                                }
                            }

                            .buy-rent-text, .from-text {
                                font-size: 0.6375rem;

                                @media(min-width: $pdp-mobile-button-breakpoint) {
                                    font-size: 1rem;
                                }
                            }

                            .from-text {
                                line-height: 1rem;
                            }

                            .movie-price {
                                font-size: 1rem;
                                margin-top: -10px;
                            }

                            .movie-price {
                                display: block;

                                @media(min-width: $pdp-mobile-button-breakpoint) {
                                    display: inline-block;
                                }
                            }
                        }

                        .pdp-preordered-btn {
                            cursor: default;
                            font-size: 0.6375rem;
                            padding: 8px 5px;

                            @media(min-width: $pdp-mobile-button-breakpoint) {
                                padding: 0 5px;
                                font-size: 1rem;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                color: $white;
                                border-color: $white;
                                opacity: 1;
                            }

                            .store-icon-check {
                                display: none;
                                border: 1px solid $white;
                                padding: 3px;
                                border-radius: 50%;
                                margin-right: 5px;

                                @media(min-width: $pdp-mobile-button-breakpoint) {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }

                .harmonized-pricing {
                    margin-bottom: .75rem;
                    margin-top: .75rem;

                    @media(min-width: $screen-md) {
                        margin-bottom: 1.5rem;
                        margin-top: 1.5rem;
                    }

                    @media(max-width: $pdp-mobile-button-breakpoint - 1) {
                        width: 100%;
                        margin-top: 1rem;

                        .two-btn-wrap {
                            width: 100%;

                            .price-btn {
                                width: 100%;
                            }
                        }
                    }

                    .two-btn-wrap.pvodRent:hover {
                        .pvod-price-btn.rent {
                            border: 1px solid #f1c400;
                            color: #f1c400;
                        }

                        .pvod-sale-info.rent {
                            background: #f1c400;
                        }
                    }
                }

                .single-btn-wrap {
                    margin-top: 16px;

                    @media(min-width: $pdp-mobile-button-breakpoint) {
                        margin-top: 0;
                    }

                    .price-btn {
                        width: 100%;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            width: auto;
                        }
                    }
                }
                //Two Button Styles
                .two-btn-pricing-wrap {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    position: relative;
                    width: 100%;

                    @media(min-width: $pdp-mobile-button-breakpoint) {
                        width: auto;
                    }

                    @media(min-width: $screen-md) {
                        width: 100%;
                    }

                    @media(min-width: $pdp-desktop-button-breakpoint) {
                        width: auto;
                    }

                    &.alt-plans-in {
                        display: block;
                    }

                    .two-btn-wrap {
                        width: 100%;
                        margin-top: 1.25rem;
                        margin-right: 2rem;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            width: auto;
                        }





                        &:first-child {
                            @media(min-width: $pdp-mobile-button-breakpoint) {
                                margin: 1.5rem 2rem 1.5rem 0;
                            }
                        }

                        + .two-btn-wrap {

                            @media(min-width: $pdp-mobile-button-breakpoint) {
                                margin: 1.5rem 2rem 1.5rem 0;
                            }

                            @media(min-width: $screen-lg) {
                                margin-right: 3rem;
                            }
                        }

                        .price-btn {
                            @media(max-width: ($pdp-mobile-button-breakpoint - 1)) {
                                width: 100%;
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }

                        .pdp-deal-info {
                            display: none;

                            @media(min-width: $pdp-mobile-button-breakpoint) {
                                display: block;
                            }

                            @media(min-width: $screen-md) {
                                font-size: 0.8rem;
                            }
                        }
                    }

                    .two-btn-wrap.pvodBuy:hover {
                        .pvod-price-btn.buy {
                            border: 1px solid #f1c400;
                            color: #f1c400;
                        }

                        .pvod-sale-info.buy {
                            background: #f1c400;
                        }
                    }

                    .two-btn-wrap.pvodRent:hover {
                        .pvod-price-btn.rent {
                            border: 1px solid #f1c400;
                            color: #f1c400;
                        }

                        .pvod-sale-info.rent {
                            background: #f1c400;
                        }
                    }

                    .two-btn-wrap.pvodPre:hover {
                        .pvod-price-btn.pre {
                            border: 1px solid #f1c400;
                            color: #f1c400;
                        }

                        .pvod-sale-info.pre {
                            background: #f1c400;
                        }
                    }

                    .pdp-max-reward {
                        text-align: left;
                        position: static;
                        display: block;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            display: none;
                        }
                    }
                }

                .pdp-trailer-btn,
                .pdp-owned-controls,
                .pdp-trailer-btn-purchased,
                .pdp-watchlist-btn,
                .pricing-plan,
                .super-ticket-hd-upgrade {
                    &:first-child {
                        margin-left: 0;
                    }

                    + .pdp-trailer-btn,
                    + .pdp-owned-controls,
                    + .pdp-trailer-btn-purchased,
                    + .pdp-watchlist-btn,
                    + .super-ticket-hd-upgrade {
                        margin-left: 0;
                    }

                    ~ .pricing-plan,
                    + .super-ticket-hd-upgrade {
                        padding-top: 16px;
                        margin-left: 0;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            padding-top: 0px;
                            margin-right: 32px;
                        }

                        @media(min-width: $screen-md) {
                            padding-top: 0px;
                            margin-right: 32px;
                        }

                        @media(min-width: $pdp-desktop-button-breakpoint) {
                            padding-top: 0px;
                            margin-right: 32px;
                        }

                        @media(min-width: $screen-lg) {
                            margin-right: 48px;
                        }
                    }


                    + .single-btn-wrap {
                        margin-left: 0px;

                        @media(min-width: $pdp-mobile-button-breakpoint) {
                            margin-left: 32px;
                        }

                        @media(min-width: $screen-lg) {
                            margin-left: 48px;
                        }
                    }
                }
            }
        }

        .mobile-padding {
            @media(min-width: $pdp-mobile-button-breakpoint) {
                margin-bottom: 4rem;
            }
        }

        .pricing-faq {
            font-family: MarkOT;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            font-size: 1rem;
            line-height: 39px;
            color: #ffffff;
            margin-bottom: 1.375rem;

            @media(max-width: $screen-sm-max) {
                margin-top: 4px;
            }

            @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                font-size: 1rem;
                line-height: 1.95;
            }

            @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                font-size: 0.8rem;
                line-height: 1.95;
            }
        }

        .not-eligible-for-dig {
            font-family: MarkOT;
            margin: 0px;
            font-size: 1rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 26px;
            letter-spacing: normal;
            color: #ffffff;
            vertical-align: middle;
            height: 26px;

            &.synopsis-space {
                margin-bottom: 48px;

                @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                    margin-bottom: 35px;

                    &.fr-spacing {
                        @media(min-width: $screen-xs) and (max-width: $screen-xs + 95) {
                            height: 42px;
                        }
                    }
                }
            }

            @media(min-width: $screen-sm + 80) and (max-width: $screen-md - 1) {
                margin-top: 45px;
            }

            @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                font-size: 1rem;
                line-height: 1.53;
                height: 22px;
            }

            @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                font-size: 0.8rem;
                line-height: 1.95;
                height: 21px;
            }

            .not-eligible-icon {
                width: 26px;
                height: 26px;
                background-image: url('https://storemedia.cineplex.com/1cineplexstoreassets/promo/icon_info_24px.svg');
                background-size: 100% 100%;
                float: left;
                margin-right: 6px;

                @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                    width: 22px;
                    height: 22px;
                }

                @media(max-width: $screen-xs-max) {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .dc-plus-form {
            font-size: 0.8rem;

            @media(min-width: $screen-md) {
                font-size: 1rem;
            }

            .dc-plus-form-popup-btn {
                border: none;
                background: transparent;
                padding: 0;
                font-weight: 600;
                outline: none;

                &:hover {
                    color: $yellow;
                }
            }

            .dc-plus-submit-btn {
                width: 100%;

                @media(min-width: $screen-sm) {
                    width: 50%;
                }
            }
        }

        .pdp-movie-details {

            .pdp-info-area {
                line-height: 1.95;
            }

            &.top-synopsis-space {
                @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                    margin-top: 25px;
                }
            }

            .pdp-main-synopsis {
                font-size: 1rem;

                @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                    font-size: 1rem;
                }

                @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                    font-size: 0.8rem;
                }

                .line-1,
                .line-2,
                .line-3 {
                    display: inline-block;
                    font-size: 1rem;

                    @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                        font-size: 1rem;
                    }

                    @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                        font-size: 0.8rem;
                    }
                }

                .line-2 {
                    color: $pdp-grey-border;
                }

                .line-3 {
                    color: $pdp-light-grey-font;
                }
            }

            .dots-before-read-more {
                color: $pdp-light-grey-font;
            }

            .read-more-button {
                display: inline;
                border: none;
                background: transparent;
                color: $white;
                text-align: left;
                padding-left: 0;
                padding-right: 0;
                font-size: 1rem;

                &:hover {
                    color: $yellow;
                }

                @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                    font-size: 1rem;
                }

                @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                    font-size: 0.8rem;
                }
            }

            .pdp-info-body {
                color: #d8d8d8;
                font-size: 1rem;

                @media(min-width: $screen-sm) and (max-width: $screen-sm-max) {
                    font-size: 1rem;
                }

                @media(min-width: $screen-xs) and (max-width: $screen-xs-max) {
                    font-size: 0.8rem;
                }

                a {
                    color: #d8d8d8;

                    &:hover,
                    &:focus {
                        color: $yellow;
                    }
                }
            }
        }
    }

    .related-movies-section {
        .slick-list {
            &.draggable {
                @media(min-width: $screen-md) {
                    overflow: hidden;
                }
            }
        }

        .movie-card-slider-wrap {
            margin: 0;
        }

        .movie-card-wrap {
            .movie {
                padding-top: 9%;
                padding-bottom: 9%;
            }
        }

        .card-section-title {
            padding-bottom: 1rem;
            margin-bottom: 0;
        }
        /*.playlist-next-indicator {
            left: auto;
            right: 0.5rem;
        }

        .playlist-back-indicator {
            left: 0.5rem;
            right: auto;
        }*/
    }

    .pdp-purchase-overlay {
        white-space: nowrap;
    }
}


.pricing-btn-wrap {
    width: 100%;

    @media(min-width: $screen-sm) {
        width: auto;
    }

    &.with-special {
        .price-btn {
            border: 1px solid $pdp-highlight-color;
        }

        .pvod-price-btn {
            border: 1px solid $pvod-highlight-color;
        }

        .pdp-sale-info {
            background: $pdp-highlight-color;
            color: #0a0b0d;
            font-weight: 700;
            font-style: italic;
            text-align: center;
            font-size: 0.85rem;

            @media(min-width: $screen-md) {
                font-size: 1rem;
                line-height: 1.5;
            }
        }

        .pvod-sale-info {
            background: $pvod-highlight-color;
            color: #0a0b0d;
            font-weight: 700;
            font-style: italic;
            text-align: center;
            font-size: 0.85rem;

            @media(min-width: $screen-md) {
                font-size: 1rem;
                line-height: 1.5;
            }
        }
    }

    &.multi-btn-wrap {
        .pvod-price-btn {
            border: 1px solid $pvod-highlight-color;

            @media(max-width: $screen-md) {
                width: 100%;
                margin-bottom: 1.25rem;
            }
        }

        .pdp-sale-info {
            position: absolute;
            bottom: 100%;
            left: 0;
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: -1px;

            @media(min-width: $screen-md) {
                width: 66.6666%;
            }
        }

        .pvod-sale-info {
            position: absolute;
            bottom: 100%;
            background: $pvod-highlight-color;
            color: #0a0b0d;
            font-weight: 700;
            font-style: italic;
            left: 0;
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: -1px;

            @media(min-width: $screen-md) {
                width: 66.6666%;
                line-height: 1.5rem;
            }
        }
    }

    .pdp-deal-info {
        color: $pdp-highlight-color;
    }
}

#pdp-price-modal {
    .generic-img-wrap {
        display: none;
        padding-bottom: 42px;
    }

    &.pdp-generic-mobile {
        .generic-img-wrap {
            @media(min-width: $screen-md) {
                display: block;
            }

            .generic-img {
                max-height: 238px;
                max-width: 158px;
            }
        }

        .modal-header {
            padding-top: 48px;
        }
    }
}

#rental-confirm-modal {
    .modal-footer {
        @media(max-width: ($screen-sm - 1)) {
            padding-top: 32px;
        }
    }
}

.watch-btn-wrap {
    display: none;
}

.player {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1060; /* Sit on top */
    /*padding-top: 100px; */ /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
