﻿//Movie Item/Card Styles
.movie-card-section-wrapper {
    font-family: MarkOT;
    color: $white;
    text-decoration: none;
    padding-bottom: 32px;

    @media(min-width: $screen-sm) {
        padding-bottom: 9.23%;
    }

    @media(min-width: $screen-md) {
        padding-bottom: 4.23%;
    }

    @media(min-width: 1440px) {
        padding-bottom: 56px;
    }

    .card {
        padding: 0 5.17242%;

        @media(min-width: $screen-sm) {
            padding: 0 6.89656%;
        }

        @media(min-width: $screen-md) {
            padding: 0 4.91802%;
        }
    }
}

.movie-card-wrap {
    display: block;

    .movie {
        width: 100%;
    }

    .card-caption {
        font-size: 1rem;
        font-style: italic;
        line-height: 1;
        text-align: left;
        color: #6e96db;
    }
}


.image-div-container {

    .image-div {
        padding-bottom: 149.6410256410256%;
        background: transparent;
        height: 0;
        position: relative;

        .movie-french-tag-outline {
            position: absolute;
            bottom: 0;
            left: 0;
            border-width: 24.5px;
            border-color: transparent transparent $french-version-border-color $french-version-border-color;
            z-index: 1;

            @media(min-width: 768px) {
                border-width: 32.5px;
            }

            @media(min-width: 1080px) {
                border-width: 36.5px;
            }

            @media(min-width: 1440px) {
                border-width: 40.5px;
            }

            @media(min-width: 1900px) {
                border-width: 48.5px;
            }

            .movie-french-tag {
                position: absolute;
                bottom: 0px;
                left: 0px;
                border-style: solid;
                border-width: 24px;
                border-color: transparent transparent $french-version-badge-color $french-version-badge-color;
                z-index: 1;

                @media(min-width: 768px) {
                    border-width: 32px;
                }

                @media(min-width: 1080px) {
                    border-width: 36px;
                }

                @media(min-width: 1440px) {
                    border-width: 40px;
                }

                @media(min-width: 1900px) {
                    border-width: 48px;
                }

                .movie-french-tag-written {
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    font-size: 12.8px;
                    font-weight: bold;
                    letter-spacing: -1.5px;

                    @media(min-width: 768px) {
                        top: 3px;
                        font-size: 16px;
                    }

                    @media(min-width: 1080px) {
                        top: 5px;
                        font-size: 18px;
                    }

                    @media(min-width: 1440px) {
                        top: 7px;
                        font-size: 20px;
                    }

                    @media(min-width: 1900px) {
                        top: 9px;
                        font-size: 25px;
                    }
                }
            }
        }

        .image {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;

            img {
                width: 100%;
            }
        }
    }
}

.movie-cover a, a:focus {
    .image-div{
        border: 1px solid #fff;
     }
}


.movie {
    .card {
        .image-div-container {
            .image-div {
                outline: 1px solid white;
                outline-offset: -2px;

                @media(min-width: 3000px) {
                    outline-offset: -3px;
                }

                @media(min-width: 4500px) {
                    outline-offset: -4px;
                }

                .movie-title-container {
                    position: absolute;
                    display: table;
                    height: 100%;
                    width: 100%;
                    padding: 8px;

                    .movie-title-text {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                        font-size: 12.8px;

                        @media(min-width: 1024px) {
                            font-size: 16px;
                        }

                        @media(min-width: 1920px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.pdp-generic-image.pull-right {
    .image-div {
        padding-bottom: inherit;
        background: inherit;
        height: inherit;
        position: relative;

        .movie-french-tag-outline {
            border-width: 32.5px;

            .movie-french-tag {
                bottom: -32.5px;
                left: -32.5px;
                border-width: 32px;

                .movie-french-tag-written {
                    top: 4px;
                    right: 4px;
                    font-size: 16px;
                }
            }
        }
    }
}

.card-text {
    margin-top: 0.5625rem;
    display: none;

    @media(min-width: $screen-md) {
        display: block;
    }

    .card-title {
        font-size: 1rem;
        line-height: 1rem;
        color: $slides-text-inactive-color;
        visibility: hidden;

        @media(min-width: $screen-md) {
            visibility: visible;
        }
    }

    .purchase-buttons {
        margin-top: 15px;
        text-transform: uppercase;
        white-space: nowrap;

        @media(min-width: $screen-md) {
            @include flexbox();
            @include justify-content(space-between);
            vertical-align: middle;
        }

        .card-buy-button,
        .card-buy-button-price,
        .purchase-button-divider,
        .card-rent-button,
        .card-rent-button-price {
            visibility: hidden;
        }

        .card-buy-button,
        .card-rent-button {

            @media(min-width: 1100px) {
                font-size: 0.8rem;
            }

            @media(min-width: 1350px) {
                font-size: 1rem;
            }

            font-size: 0.6rem;
            font-weight: 700;
        }

        .card-buy-button-price,
        .card-rent-button-price,
        .purchase-button-divider {

            @media(min-width: 1100px) {
                font-size: 0.8rem;
            }

            @media(min-width: 1350px) {
                font-size: 1rem;
            }

            font-size: 0.6rem;
            font-weight: 400;
        }
    }
}

@media(min-width: $screen-md) {
    .movie-card-wrap:hover {
        .movie {
            transform: scale(1.164251);

            .card {
                text-decoration: none;

                img {
                    margin-bottom: 10px;
                }

                .card-text {
                    visibility: visible;

                    .card-title {
                        /*font-size: 1.25rem;*/
                        color: $white;
                    }

                    .card-title:hover {
                        color: $yellow;
                    }

                    .purchase-buttons {
                        .card-buy-button,
                        .card-rent-button,
                        .card-buy-button-price,
                        .card-rent-button-price,
                        .purchase-button-divider {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}


.movie-grid-heading-area {
    padding: 32px 0;

    @media(min-width: $screen-md) {
        padding-bottom: 3.7125847%;
    }

    @media(min-width: $screen-lg) {
        padding-bottom: 4.2%;
    }

    .movie-grid-title-text {
        font-size: 1.5625rem;
    }
}

.movie-card-section-wrapper {

    .movie-card-grid-wrap {

        @include flexbox();
        @include justify-content(flex-start);
        @include flex-wrap(wrap);

        .movie-card-wrap {
            display: inline-block;
            width: percentage(1/3);

            @media(min-width: $screen-sm) {
                width: percentage(1/4);
            }

            @media(min-width: $screen-md) {
                width: percentage(1/6);
            }

            @media(min-width: $screen-lg) {
                width: percentage(1/8);
            }

            .movie {
                width: 100%;
                display: inline-block;
                padding-bottom: 32px;

                @media(min-width: $screen-sm) {
                    padding-bottom: 36.9182%;
                }

                @media(min-width: $screen-md) {
                    padding-bottom: 22.276137%;
                }

                @media(min-width: $screen-lg) {
                    padding-bottom: 33.6%;
                }
            }
        }
    }
}

.movie-grid-with-overlay {

    .library-section-title-name {
        font-family: 'Mark OT';
        font-style: normal;
        font-weight: 200;
        line-height: 32px;


        @media(min-width: $screen-xs) {
            font-size: 1rem;
            padding-bottom: 16.19px;
        }

        @media(min-width: $screen-sm) {
            font-size: 1.25rem;
            padding-bottom: 35.54px;
        }

        @media(min-width: $screen-md) {
            font-size: 1.563rem;
            padding-bottom: 41.4px;
        }
    }

    &.even-vert-pad {
        .movie-card-wrap {
            .movie {
                padding-top: $vert-pad-xs;
                padding-bottom: 0;

                @media(min-width: $screen-sm) {
                    padding-top: $top-pad-sm;
                    padding-bottom: $bot-pad-sm;
                }

                @media(min-width: $screen-md) {
                    padding-top: $vert-pad-md;
                    padding-bottom: $vert-pad-md;
                }

                @media(min-width: $screen-lg) {
                    padding-top: $top-pad-lg;
                    padding-bottom: $bot-pad-lg;
                }
            }
        }
    }

    &.no-scale {
        .movie-card-wrap {
            &:hover {
                transform: none;
            }

            .movie {
                transform: none;

                &:hover {

                    @media(min-width: $screen-md) {
                        padding-top: $vert-pad-hvr-md;
                        padding-bottom: $vert-pad-hvr-md;
                        margin: 0 -8.7805%;
                        transform: none;
                        width: auto;
                        height: 100%;
                    }

                    @media(min-width: $screen-lg) {
                        padding-top: $top-pad-hvr-lg;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.not-touch {
            .movie-card-wrap {
                .movie {
                    &:hover {
                        @media(min-width: $screen-sm) {
                            @media(max-width: ($screen-md - 1)) {
                                width: auto;
                                margin: 0 -13.79312%;
                                padding-top: $top-pad-hvr-sm;
                                padding-bottom: 0;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &.not-touch {
        .movie {
            &:hover {
                @media(min-width: $screen-sm) {
                    .movie-overlay-gradient {
                        display: block;

                        &.wl-overlay {
                            @include flexbox();
                        }
                    }
                }
            }
        }
    }

    .movies-wrap {
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(wrap);
    }

    .movie-card-wrap {
        width: 33.33333%;
        padding-left: $column-gutter-mobile;
        padding-right: $column-gutter-mobile;

        @media(min-width: $screen-sm) {
            width: 25%;
            padding-left: $column-gutter-mobile;
            padding-right: $column-gutter-mobile;
        }

        @media(min-width: $screen-md) {
            width: 16.66667%;
            padding-left: $column-gutter-desktop;
            padding-right: $column-gutter-desktop;
        }

        @media(min-width: $screen-lg) {
            width: 12.5%;
        }
    }

    .movie {
        padding-bottom: $img-aspect-ratio-xs;

        @media(min-width: $screen-md) {
            padding-bottom: $img-aspect-ratio-md;
        }

        @media(min-width: $screen-lg) {
            padding-bottom: $img-aspect-ratio-lg;
        }

        &:hover {
            @media(min-width: $screen-md) {
                transform: scale($movie-hover-scale-amount);
            }

            .movie-overlay-gradient {
                @media(min-width: $screen-md) {
                    display: block;
                }

                &.wl-overlay {
                    @media(min-width: $screen-md) {
                        @include flexbox();
                    }
                }
            }

            .movie-playback-percent-wrap {
                @media(min-width: $screen-md) {
                    bottom: -1px;
                }
            }

            .card-text {
                @media(min-width: $screen-md) {
                    top: ((8px/$movie-hover-scale-amount) - 8px);
                }

                @media(min-width: $movie-text-wrap-breakpoint) {
                    top: ((16px/$movie-hover-scale-amount) - 16px);
                }
            }

            .card-caption {
                @media(min-width: $screen-md) {
                    top: -4px;
                    font-size: ($movie-text-adjusted-font-size/$movie-hover-scale-amount);
                }

                @media(min-width: $movie-text-wrap-breakpoint) {
                    font-size: ($movie-text-font-size/$movie-hover-scale-amount);
                }

                @media(min-width: $screen-lg) {
                    font-size: (1rem/$movie-hover-scale-amount);
                }
            }
        }

        .image-div {
            overflow: hidden;
        }

        .card-text {
            position: relative;
            display: block;
            font-size: $movie-text-adjusted-font-size;
            padding-top: 8px;
            margin-top: 0;

            @media(min-width: $movie-text-wrap-breakpoint) {
                padding-top: 16px;
                font-size: $movie-text-font-size;
            }
        }

        .card-title {
            position: relative;
            display: none;
            font-size: $movie-text-adjusted-font-size;
            white-space: nowrap;
            width: auto;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.25;

            @media(min-width: $screen-md) {
                display: inline-block;
            }

            @media(min-width: $movie-text-wrap-breakpoint) {
                font-size: $movie-text-font-size;
            }

            @media(min-width: $screen-lg) {
                font-size: $movie-text-font-size/1.25;
            }
        }

        .card-caption {
            position: relative;
            display: block;
            font-size: $movie-text-adjusted-font-size;
            line-height: 1;
            height: 1.25rem;

            @media(min-width: $movie-text-wrap-breakpoint) {
                margin-top: 0.25rem;
                font-size: $movie-text-font-size;
            }

            @media(min-width: $screen-lg) {
                margin-top: 0.2rem;
                font-size: 1rem;
            }
        }
    }

    .movie-overlay-gradient {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 100%;
        width: 100%;
        @include linear-gradient(bottom, top, rgba(0, 53, 142, 0.9), rgba(11, 12, 15, 0.1));

        .mc-rating {
            height: 34px;
            width: 34px;
            font-size: 1rem;

            @media(min-width: $screen-lg) {
                height: 44px;
                width: 44px;
            }
        }

        .movie-overlay-info-wrap {
            z-index: 2;
            padding: 32px 16px 16px;

            @media(min-width: $screen-sm) {
                padding: 32px 16px 12px;
            }

            .rmv-wl-btn {
                position: relative;
                top: -4px;
                font-size: 32px;

                @media(min-width: $medium-desktop-breakpoint) {
                    font-size: 40px;
                }

                @media(min-width: $screen-lg) {
                    font-size: 48px;
                }
            }

            .movie-title {
                font-size: 1.0736525660296328108224178655787rem;
            }

            .preordered-display {
                color: $white;
                border-color: $white;
                cursor: initial;
            }
        }

        .st-upgrade-to-hd {
            display: block;
            position: absolute;
            bottom: 16px;
            left: 16px;
            right: 16px;
            z-index: 2;

            @media(min-width: $screen-lg) {
                font-size: 0.80em;
            }
        }

        &.wl-overlay {
            .movie-play-link {
                transform: none;
                position: static;
                display: none;
            }

            .buy-btn {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .movie-overlay-info-wrap {
                .movie-title {
                    display: block;
                    font-size: 0.8rem;

                    @media(min-width: $medium-desktop-breakpoint) {
                        font-size: 1rem;
                    }
                }
            }

            &.purchased {
                .movie-play-link {
                    display: block;

                    @media(min-width: $screen-lg) {
                        height: 120px;
                        width: 120px;
                        font-size: 120px;
                    }
                }

                .buy-btn {
                    display: none;
                }

                &.not-preorder {
                    .movie-overlay-info-wrap {
                        z-index: 2;
                        padding: 32px 16px 32px;

                        @media(min-width: $medium-desktop-breakpoint) {
                            padding: 32px 16px 48px;
                        }
                    }
                }
            }
        }
    }

    .movie-playback-percent-wrap {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        background: rgba(32, 39, 51, 0.8);
        height: 8px;

        @media(min-width: $screen-md) {
            height: 16px;
        }

        .movie-playback-percentage {
            position: absolute;
            left: 0;
            height: 100%;
            background-color: #6e96db;
        }
    }

    .movie-play-link {
        display: block;
        @include absoluteCenter();
        font-size: 99px;
        height: 99px;
        width: 99px;
        z-index: 2;
    }

    .movie-pdp-link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
